export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: { input: string; output: string; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: object; output: object; }
};

export type Account = {
  __typename?: 'Account';
  billingAccounts?: Maybe<Array<BillingAccount>>;
  deals?: Maybe<Array<Deal>>;
  enquiry?: Maybe<Enquiry>;
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  orderConfirmation?: Maybe<OrderConfirmation>;
  outcomingInvoice?: Maybe<OutcomingInvoice>;
  payment?: Maybe<Payment>;
  payout?: Maybe<Payout>;
  providerOrder?: Maybe<ProviderOrder>;
  providers?: Maybe<Array<Provider>>;
};

export type AccountRelatedObjects = {
  __typename?: 'AccountRelatedObjects';
  deals?: Maybe<Array<Deal>>;
  enquiries?: Maybe<Array<Enquiry>>;
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  orderConfirmations?: Maybe<Array<OrderConfirmation>>;
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
  providerOrders?: Maybe<Array<ProviderOrder>>;
};

export type AccountingFieldsType = {
  __typename?: 'AccountingFieldsType';
  remark?: Maybe<Scalars['String']['output']>;
  sachkontoNumber?: Maybe<Scalars['Int']['output']>;
  vatCode?: Maybe<Scalars['Int']['output']>;
  vatPercent?: Maybe<Scalars['Int']['output']>;
  xometryVatNumber?: Maybe<Scalars['JSON']['output']>;
};

export type ActivityRecord = {
  __typename?: 'ActivityRecord';
  author?: Maybe<Scalars['JSON']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of AddLogisticInvoicePosition */
export type AddLogisticInvoicePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  positions: Array<OmsInputsLogisticInvoicePosition>;
};

/** Autogenerated return type of AddLogisticInvoicePosition */
export type AddLogisticInvoicePositionPayload = {
  __typename?: 'AddLogisticInvoicePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  logisticInvoice?: Maybe<LogisticInvoice>;
};

export type AddToMasterShippingValidationErrors = {
  __typename?: 'AddToMasterShippingValidationErrors';
  errors: Array<Scalars['String']['output']>;
  shippingId: Scalars['ID']['output'];
};

export type AdditionalService = {
  __typename?: 'AdditionalService';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  priceMoney: Money;
  typeName: TypeNameEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Float']['output'];
  valueMoney: Money;
  vatRate?: Maybe<Scalars['Float']['output']>;
};


export type AdditionalServicePriceMoneyArgs = {
  currencyCode?: InputMaybe<CurrencyEnum>;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type Agg = {
  __typename?: 'Agg';
  docCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum AsyncStatusEnum {
  Error = 'error',
  Pending = 'pending',
  Ready = 'ready',
  RequestRequired = 'request_required'
}

export type AttachedFile = {
  __typename?: 'AttachedFile';
  cleanedId?: Maybe<Scalars['Int']['output']>;
  context: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCleaned: Scalars['Boolean']['output'];
  isStp?: Maybe<Scalars['Boolean']['output']>;
  largeUrl?: Maybe<Scalars['String']['output']>;
  mediumUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  preview?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewLargeUrl?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuidId: Scalars['String']['output'];
};

export type AttributesConflict = {
  __typename?: 'AttributesConflict';
  attribute: Scalars['String']['output'];
  databaseValue?: Maybe<GenericSerialized>;
  newValue?: Maybe<GenericSerialized>;
  originalValue?: Maybe<GenericSerialized>;
};

export type Authorization = {
  __typename?: 'Authorization';
  authorized: Scalars['Boolean']['output'];
};

export type AutoAssignmentSettings = {
  __typename?: 'AutoAssignmentSettings';
  customerOrderGrade?: Maybe<Array<CustomerOrderGradeEnum>>;
  languages?: Maybe<Array<LanguageEnum>>;
  maxDeals?: Maybe<Scalars['Int']['output']>;
  overloadPossible?: Maybe<Scalars['Boolean']['output']>;
  technology?: Maybe<Array<Scalars['Int']['output']>>;
};

export type AutocompleteGroup = {
  __typename?: 'AutocompleteGroup';
  docCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  items: Array<SearchItemType>;
  type: Scalars['String']['output'];
};

export type Balance = {
  __typename?: 'Balance';
  brutto: BalanceEntity;
  currency: Scalars['String']['output'];
  netto: BalanceEntity;
};

export type BalanceDetails = {
  __typename?: 'BalanceDetails';
  data?: Maybe<PaymentBalanceTerm>;
  status?: Maybe<AsyncStatusEnum>;
};

export type BalanceEntity = {
  __typename?: 'BalanceEntity';
  balance: Scalars['Float']['output'];
  balanceMoney: Money;
  booked: Scalars['Float']['output'];
  bookedMoney: Money;
  completed: Scalars['Float']['output'];
  completedMoney: Money;
  paid: Scalars['Float']['output'];
  paidMoney: Money;
};

export type BalanceLine = {
  __typename?: 'BalanceLine';
  balance?: Maybe<Money>;
  balancedLine?: Maybe<Scalars['Boolean']['output']>;
  billingAccountId?: Maybe<Scalars['ID']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  dealId: Scalars['ID']['output'];
  invoiced?: Maybe<Money>;
  invoicedInDiffCurrency?: Maybe<Money>;
  level: Scalars['Int']['output'];
  lineObjectId: Scalars['ID']['output'];
  methodDealId?: Maybe<Scalars['ID']['output']>;
  methodName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  objectState?: Maybe<Scalars['String']['output']>;
  objectType: Scalars['String']['output'];
  ocTotalAmountMoney?: Maybe<Money>;
  orderedNotInvoicedMoney?: Maybe<Money>;
  overdueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  overdueDays?: Maybe<Scalars['Int']['output']>;
  paid?: Maybe<Money>;
  paidInDiffCurrency?: Maybe<Money>;
  productionStage?: Maybe<Scalars['String']['output']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  balance?: Maybe<Scalars['Float']['output']>;
};

export type BankPayment = {
  __typename?: 'BankPayment';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney: Money;
  bankPaymentExportStatusCode?: Maybe<Scalars['String']['output']>;
  bankPaymentExportStatusText?: Maybe<Scalars['String']['output']>;
  batchExport?: Maybe<Export>;
  batchImportId?: Maybe<Scalars['ID']['output']>;
  counterparty?: Maybe<Scalars['JSON']['output']>;
  counterpartyId?: Maybe<Scalars['ID']['output']>;
  counterpartyType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  direction: Scalars['String']['output'];
  endToEndId?: Maybe<Scalars['String']['output']>;
  exportRow?: Maybe<ExportRow>;
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  jpmCheckAt?: Maybe<Scalars['ISO8601Date']['output']>;
  jpmStatus?: Maybe<Scalars['String']['output']>;
  outlyingTransactionType?: Maybe<Scalars['String']['output']>;
  paymentDetails?: Maybe<Scalars['JSON']['output']>;
  payments?: Maybe<Array<Payment>>;
  payouts?: Maybe<Array<Payout>>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  state: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xometryBankAccount?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of BankPaymentsBulkApprove */
export type BankPaymentsBulkApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BankPaymentsBulkApprove */
export type BankPaymentsBulkApprovePayload = {
  __typename?: 'BankPaymentsBulkApprovePayload';
  bankPayments: Array<WorkspacesUpdatedBankPayment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsBulkRecognizeAs */
export type BankPaymentsBulkRecognizeAsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  recognizeAs: Scalars['String']['input'];
};

/** Autogenerated return type of BankPaymentsBulkRecognizeAs */
export type BankPaymentsBulkRecognizeAsPayload = {
  __typename?: 'BankPaymentsBulkRecognizeAsPayload';
  bankPayments: Array<WorkspacesUpdatedBankPayment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsBulkSendToBank */
export type BankPaymentsBulkSendToBankInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BankPaymentsBulkSendToBank */
export type BankPaymentsBulkSendToBankPayload = {
  __typename?: 'BankPaymentsBulkSendToBankPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsBulkSendToBankVerify */
export type BankPaymentsBulkSendToBankVerifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BankPaymentsBulkSendToBankVerify */
export type BankPaymentsBulkSendToBankVerifyPayload = {
  __typename?: 'BankPaymentsBulkSendToBankVerifyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsCreateExportToDatev */
export type BankPaymentsCreateExportToDatevInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durationFrom?: InputMaybe<Scalars['String']['input']>;
  durationTo?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  period: Scalars['String']['input'];
  withRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of BankPaymentsCreateExportToDatev */
export type BankPaymentsCreateExportToDatevPayload = {
  __typename?: 'BankPaymentsCreateExportToDatevPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  datevBankPaymentsExport: BankPaymentsExport;
};

/** Autogenerated input type of BankPaymentsCreateFromInvoice */
export type BankPaymentsCreateFromInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceNumberErp: Scalars['String']['input'];
  payoutAmounts: Scalars['JSON']['input'];
};

/** Autogenerated return type of BankPaymentsCreateFromInvoice */
export type BankPaymentsCreateFromInvoicePayload = {
  __typename?: 'BankPaymentsCreateFromInvoicePayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsCreateFromPayout */
export type BankPaymentsCreateFromPayoutInput = {
  amount: Scalars['Float']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payoutId: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsCreateFromPayout */
export type BankPaymentsCreateFromPayoutPayload = {
  __typename?: 'BankPaymentsCreateFromPayoutPayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsDestroy */
export type BankPaymentsDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsDestroy */
export type BankPaymentsDestroyPayload = {
  __typename?: 'BankPaymentsDestroyPayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsDestroyWithPayouts */
export type BankPaymentsDestroyWithPayoutsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsDestroyWithPayouts */
export type BankPaymentsDestroyWithPayoutsPayload = {
  __typename?: 'BankPaymentsDestroyWithPayoutsPayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsDraftExport */
export type BankPaymentsDraftExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of BankPaymentsDraftExport */
export type BankPaymentsDraftExportPayload = {
  __typename?: 'BankPaymentsDraftExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type BankPaymentsExport = {
  __typename?: 'BankPaymentsExport';
  accountingPeriod: Scalars['ISO8601Date']['output'];
  bankPayments?: Maybe<Array<BankPayment>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of BankPaymentsExport */
export type BankPaymentsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of BankPaymentsExport */
export type BankPaymentsExportPayload = {
  __typename?: 'BankPaymentsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of BankPaymentsLinkToAccount */
export type BankPaymentsLinkToAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  counterpartyId: Scalars['ID']['input'];
  counterpartyType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsLinkToAccount */
export type BankPaymentsLinkToAccountPayload = {
  __typename?: 'BankPaymentsLinkToAccountPayload';
  bankPayment: WorkspacesUpdatedBankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsPaymentsCreate */
export type BankPaymentsPaymentsCreateInput = {
  attributes: OmsInputsBankPaymentsPaymentsCreateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BankPaymentsPaymentsCreate */
export type BankPaymentsPaymentsCreatePayload = {
  __typename?: 'BankPaymentsPaymentsCreatePayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsRecreate */
export type BankPaymentsRecreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsRecreate */
export type BankPaymentsRecreatePayload = {
  __typename?: 'BankPaymentsRecreatePayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsToggleApprove */
export type BankPaymentsToggleApproveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsToggleApprove */
export type BankPaymentsToggleApprovePayload = {
  __typename?: 'BankPaymentsToggleApprovePayload';
  bankPayment: WorkspacesUpdatedBankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsUpdate */
export type BankPaymentsUpdateInput = {
  attributes: OmsInputsBankPaymentsUpdateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of BankPaymentsUpdateJpmStatus */
export type BankPaymentsUpdateJpmStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsUpdateJpmStatus */
export type BankPaymentsUpdateJpmStatusPayload = {
  __typename?: 'BankPaymentsUpdateJpmStatusPayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsUpdateOutlyingType */
export type BankPaymentsUpdateOutlyingTypeInput = {
  attributes: OmsInputsBankPaymentsUpdateOutlyingTypeType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BankPaymentsUpdateOutlyingType */
export type BankPaymentsUpdateOutlyingTypePayload = {
  __typename?: 'BankPaymentsUpdateOutlyingTypePayload';
  bankPayment: WorkspacesUpdatedBankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of BankPaymentsUpdate */
export type BankPaymentsUpdatePayload = {
  __typename?: 'BankPaymentsUpdatePayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BankPaymentsUpdateRegisteredAt */
export type BankPaymentsUpdateRegisteredAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
  overwrite: Scalars['Boolean']['input'];
  registeredAt: Scalars['String']['input'];
};

/** Autogenerated return type of BankPaymentsUpdateRegisteredAt */
export type BankPaymentsUpdateRegisteredAtPayload = {
  __typename?: 'BankPaymentsUpdateRegisteredAtPayload';
  bankPayments: Array<BankPayment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Base = {
  __typename?: 'Base';
  conversions: Array<Conversion>;
  documentId: Scalars['ID']['output'];
  documentNumber: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
  secondaryAmountCurrency?: Maybe<CurrencyEnum>;
};

export type Base64FileType = {
  __typename?: 'Base64FileType';
  fileBase64Encode: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Batch = {
  __typename?: 'Batch';
  category: BatchCategoryEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  fulfillment: BatchFulfillment;
  id: Scalars['ID']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  invoiceAllocations: Array<BatchInvoice>;
  providerOrderId?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Int']['output'];
  source: BatchSource;
  type: BatchTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  updatedBy?: Maybe<User>;
  updatedByAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  valueMoney: Money;
};

export enum BatchBasedTypeEnum {
  /** Represents a Enquiry */
  Enquiry = 'ENQUIRY',
  /** Represents a Deal::OrderConfirmation */
  OrderConfirmation = 'ORDER_CONFIRMATION'
}

export enum BatchCategoryEnum {
  Recycle = 'recycle',
  Repair = 'repair',
  Reproduction = 'reproduction',
  Return = 'return',
  Standard = 'standard'
}

export type BatchCollection = {
  __typename?: 'BatchCollection';
  batches: Array<Batch>;
  category: BatchCategoryEnum;
  date: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  type: BatchTypeEnum;
  valueMoney: Money;
};

export type BatchCreditNote = {
  __typename?: 'BatchCreditNote';
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type BatchFulfillment = {
  __typename?: 'BatchFulfillment';
  fulfilledQuantity: Scalars['Int']['output'];
  remainingQuantity: Scalars['Int']['output'];
  shipments: Array<BatchShipment>;
};

export type BatchGroup = {
  __typename?: 'BatchGroup';
  basedOn: BatchBasedTypeEnum;
  batchCollection: Array<BatchCollection>;
  customerTotalOrdered: Scalars['Int']['output'];
};

export type BatchInvoice = {
  __typename?: 'BatchInvoice';
  amount: Money;
  creditNotes: Array<BatchCreditNote>;
  isPaid: Scalars['Boolean']['output'];
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BatchProviderOrder = {
  __typename?: 'BatchProviderOrder';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type BatchShipment = {
  __typename?: 'BatchShipment';
  quantity: Scalars['Int']['output'];
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BatchSource = {
  __typename?: 'BatchSource';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: BatchSourceEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid: Scalars['ID']['output'];
};

export enum BatchSourceEnum {
  /** Represents a Deal::Lot */
  DealLot = 'DEAL_LOT',
  /** Represents a Enquiry::Part */
  EnquiryPart = 'ENQUIRY_PART'
}

export enum BatchTypeEnum {
  Contracted = 'contracted',
  Production = 'production'
}

export type BillingAccount = {
  __typename?: 'BillingAccount';
  accountType: Scalars['String']['output'];
  address?: Maybe<Scalars['String']['output']>;
  badPayer: Scalars['Boolean']['output'];
  billingAccountsPersons?: Maybe<Array<BillingAccountsPerson>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditLimitMoney: Money;
  creditLimitRequestMoney: Money;
  datevId?: Maybe<Scalars['ID']['output']>;
  /** N + 1, don't query for collection */
  dealsCount: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dnbDunsNumber?: Maybe<Scalars['String']['output']>;
  dnbExchangedRecommendation: ExchangedRecommendation;
  dnbHasRecommendation: Scalars['Boolean']['output'];
  dnbRecommendationMoney: DnBMoney;
  files: Array<AttachedFile>;
  id: Scalars['ID']['output'];
  intacctId?: Maybe<Scalars['String']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentTermsDays?: Maybe<Scalars['Int']['output']>;
  paymentTermsType: PaymentTermsTypeEnum;
  paymentTermsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  paymentTermsUpdatedBy?: Maybe<User>;
  payments?: Maybe<Array<Payment>>;
  persons: Array<Person>;
  region?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type: BillingAccountsTypeEnumType;
  updatedAfterRegistered?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** TR */
  vatExempt: Scalars['Boolean']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
  vatRegistered: Scalars['Boolean']['output'];
  vatRegistrationCountry?: Maybe<Scalars['String']['output']>;
  vatValidationStatus?: Maybe<BillingAccountsVatValidationStatusEnumType>;
  vatValidationTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type BillingAccountFilesArgs = {
  context?: InputMaybe<Array<BillingAccountsAttachedFilesContextEnumType>>;
};


export type BillingAccountPaymentsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of BillingAccountSendToCheck */
export type BillingAccountSendToCheckInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BillingAccountSendToCheck */
export type BillingAccountSendToCheckPayload = {
  __typename?: 'BillingAccountSendToCheckPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsAddToPersons */
export type BillingAccountsAddToPersonsInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BillingAccountsAddToPersons */
export type BillingAccountsAddToPersonsPayload = {
  __typename?: 'BillingAccountsAddToPersonsPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export enum BillingAccountsAttachedFilesContextEnumType {
  Default = 'default',
  ManualVatValidation = 'manual_vat_validation',
  PaymentTerms = 'payment_terms'
}

/** Autogenerated input type of BillingAccountsAttachedFilesDelete */
export type BillingAccountsAttachedFilesDeleteInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsAttachedFilesDelete */
export type BillingAccountsAttachedFilesDeletePayload = {
  __typename?: 'BillingAccountsAttachedFilesDeletePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsCalculateBalanceDetails */
export type BillingAccountsCalculateBalanceDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id: Scalars['ID']['input'];
  withLines?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of BillingAccountsCalculateBalanceDetails */
export type BillingAccountsCalculateBalanceDetailsPayload = {
  __typename?: 'BillingAccountsCalculateBalanceDetailsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of BillingAccountsCreate */
export type BillingAccountsCreateInput = {
  attributes: OmsInputsBillingAccountType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BillingAccountsCreate */
export type BillingAccountsCreatePayload = {
  __typename?: 'BillingAccountsCreatePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsDelete */
export type BillingAccountsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsDelete */
export type BillingAccountsDeletePayload = {
  __typename?: 'BillingAccountsDeletePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsExport */
export type BillingAccountsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of BillingAccountsExport */
export type BillingAccountsExportPayload = {
  __typename?: 'BillingAccountsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of BillingAccountsMerge */
export type BillingAccountsMergeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fromBillingAccountId: Scalars['ID']['input'];
  toBillingAccountId: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsMerge */
export type BillingAccountsMergePayload = {
  __typename?: 'BillingAccountsMergePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type BillingAccountsOrganization = {
  __typename?: 'BillingAccountsOrganization';
  addBillingAccountToNewPersons: Scalars['Boolean']['output'];
  addCreditLimitToNewPersons: Scalars['Boolean']['output'];
  billingAccount: BillingAccount;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  organization: Organization;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type BillingAccountsOrganizationType = {
  addBillingAccountToNewPersons?: InputMaybe<Scalars['Boolean']['input']>;
  addCreditLimitToNewPersons?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of BillingAccountsOrganizations */
export type BillingAccountsOrganizationsInput = {
  attributes: BillingAccountsOrganizationType;
  billingAccountOrganizationId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BillingAccountsOrganizations */
export type BillingAccountsOrganizationsPayload = {
  __typename?: 'BillingAccountsOrganizationsPayload';
  billingAccountOrganization: BillingAccountsOrganization;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsPaymentTermsUpdate */
export type BillingAccountsPaymentTermsUpdateInput = {
  attributes: OmsInputsBillingAccountPaymentTermsType;
  billingAccountId: Scalars['ID']['input'];
  cascade: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BillingAccountsPaymentTermsUpdate */
export type BillingAccountsPaymentTermsUpdatePayload = {
  __typename?: 'BillingAccountsPaymentTermsUpdatePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type BillingAccountsPerson = {
  __typename?: 'BillingAccountsPerson';
  billingAccount: BillingAccount;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  person: Person;
  personCreditLimitAccess: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of BillingAccountsRemoveFromPerson */
export type BillingAccountsRemoveFromPersonInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsRemoveFromPerson */
export type BillingAccountsRemoveFromPersonPayload = {
  __typename?: 'BillingAccountsRemoveFromPersonPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsRequestCreditLimit */
export type BillingAccountsRequestCreditLimitInput = {
  attributes: OmsInputsBillingAccountRequestCreditLimitType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsRequestCreditLimit */
export type BillingAccountsRequestCreditLimitPayload = {
  __typename?: 'BillingAccountsRequestCreditLimitPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsSetAsDefault */
export type BillingAccountsSetAsDefaultInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsSetAsDefault */
export type BillingAccountsSetAsDefaultPayload = {
  __typename?: 'BillingAccountsSetAsDefaultPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsSuccessManual */
export type BillingAccountsSuccessManualInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<OmsInputsAttachmentType>>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsSuccessManual */
export type BillingAccountsSuccessManualPayload = {
  __typename?: 'BillingAccountsSuccessManualPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsToggleCreditLimitAccess */
export type BillingAccountsToggleCreditLimitAccessInput = {
  billingAccountId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enableAccessForAll?: InputMaybe<Scalars['Boolean']['input']>;
  personIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of BillingAccountsToggleCreditLimitAccess */
export type BillingAccountsToggleCreditLimitAccessPayload = {
  __typename?: 'BillingAccountsToggleCreditLimitAccessPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export enum BillingAccountsTypeEnumType {
  /** customer */
  Customer = 'customer',
  /** partner */
  Partner = 'partner'
}

/** Autogenerated input type of BillingAccountsUpdateCascade */
export type BillingAccountsUpdateCascadeInput = {
  attributes: OmsInputsBillingAccountUpdateCascadeType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsUpdateCascade */
export type BillingAccountsUpdateCascadePayload = {
  __typename?: 'BillingAccountsUpdateCascadePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsUpdateCreditLimit */
export type BillingAccountsUpdateCreditLimitInput = {
  attributes: OmsInputsBillingAccountCreditLimitType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsUpdateCreditLimit */
export type BillingAccountsUpdateCreditLimitPayload = {
  __typename?: 'BillingAccountsUpdateCreditLimitPayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BillingAccountsUpdate */
export type BillingAccountsUpdateInput = {
  attributes: OmsInputsBillingAccountType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of BillingAccountsUpdate */
export type BillingAccountsUpdatePayload = {
  __typename?: 'BillingAccountsUpdatePayload';
  billingAccount: BillingAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export enum BillingAccountsVatValidationStatusEnumType {
  Failed = 'Failed',
  Invalid = 'Invalid',
  Pending = 'Pending',
  Valid = 'Valid'
}

export type BuildedOutcomingInvoiceRelation = {
  __typename?: 'BuildedOutcomingInvoiceRelation';
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  deal: Deal;
  id?: Maybe<Scalars['Int']['output']>;
  outcomingInvoice: OutcomingInvoice;
  payment: Payment;
  paymentBalanceBeforeAllocatedMoney: Money;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  totalAmountBruttoMoney: Money;
};

export type ByJson = {
  __typename?: 'ByJson';
  at?: Maybe<Scalars['String']['output']>;
  by?: Maybe<Scalars['String']['output']>;
};

export type CalendarEvent = ProductionEventType | ProviderOrderLeadtimeEventType | PublicHolidayEventType;

export type CascadeBillingAccountsObjectListType = {
  __typename?: 'CascadeBillingAccountsObjectListType';
  deals?: Maybe<Array<Deal>>;
};

export type CascadeShippingAddressesObjectListType = {
  __typename?: 'CascadeShippingAddressesObjectListType';
  deals?: Maybe<Array<Deal>>;
};

export type Certificate = {
  __typename?: 'Certificate';
  eurValueMoney?: Maybe<Money>;
  gbpValueMoney?: Maybe<Money>;
  key: Scalars['String']['output'];
  kind: Scalars['String']['output'];
  notice?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tryValueMoney?: Maybe<Money>;
  usdValueMoney?: Maybe<Money>;
};

/** Autogenerated input type of ChangePrimary */
export type ChangePrimaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  newPrimaryContactId: Scalars['ID']['input'];
};

/** Autogenerated return type of ChangePrimary */
export type ChangePrimaryPayload = {
  __typename?: 'ChangePrimaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

export type City = {
  __typename?: 'City';
  name: Scalars['String']['output'];
};

export type Claim = {
  __typename?: 'Claim';
  claimPositions: Array<ClaimPosition>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['ID']['output'];
  events: Array<Event>;
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  state?: Maybe<Scalars['String']['output']>;
  typeClaim?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
};

export type ClaimPosition = {
  __typename?: 'ClaimPosition';
  claim: Claim;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerPrice?: Maybe<Scalars['Float']['output']>;
  customerPriceCurrency: CurrencyEnum;
  customerPriceMoney?: Maybe<Money>;
  followingLots?: Maybe<Array<Lot>>;
  id: Scalars['Int']['output'];
  lot?: Maybe<Lot>;
  part: Part;
  providerOrder?: Maybe<ProviderOrder>;
  providerPrice?: Maybe<Scalars['Float']['output']>;
  providerPriceCurrency: CurrencyEnum;
  providerPriceMoney?: Maybe<Money>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ClaimsAggregations = {
  __typename?: 'ClaimsAggregations';
  closed: Array<Scalars['ID']['output']>;
  declined: Array<Scalars['ID']['output']>;
  open: Array<Scalars['ID']['output']>;
};

/** Autogenerated input type of ClaimsCreate */
export type ClaimsCreateInput = {
  attributes: OmsInputsDealsClaimType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClaimsCreate */
export type ClaimsCreatePayload = {
  __typename?: 'ClaimsCreatePayload';
  claim: Claim;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ClaimsDestroy */
export type ClaimsDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ClaimsDestroy */
export type ClaimsDestroyPayload = {
  __typename?: 'ClaimsDestroyPayload';
  claim: Claim;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ClaimsExport */
export type ClaimsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of ClaimsExport */
export type ClaimsExportPayload = {
  __typename?: 'ClaimsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ClaimsUpdate */
export type ClaimsUpdateInput = {
  attributes: OmsInputsDealsClaimType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ClaimsUpdate */
export type ClaimsUpdatePayload = {
  __typename?: 'ClaimsUpdatePayload';
  claim: Claim;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Color = {
  __typename?: 'Color';
  background?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ConformityCheckEnum {
  /** has_issues */
  HasIssues = 'has_issues',
  /** issues_resolved */
  IssuesResolved = 'issues_resolved',
  /** ok */
  Ok = 'ok'
}

export type Contact = {
  __typename?: 'Contact';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  disallowToPool?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  openPassword?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  preferredContactType?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['ID']['output'];
  telegram?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  verifiedAt?: Maybe<Scalars['String']['output']>;
  verifyKey?: Maybe<Scalars['String']['output']>;
  viber?: Maybe<Scalars['String']['output']>;
  wechat?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ContactsCreate */
export type ContactsCreateInput = {
  attributes: OmsInputsContactType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of ContactsCreate */
export type ContactsCreatePayload = {
  __typename?: 'ContactsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

/** Autogenerated input type of ContactsDelete */
export type ContactsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ContactsDelete */
export type ContactsDeletePayload = {
  __typename?: 'ContactsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ContactsMove */
export type ContactsMoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  providerNumber: Scalars['String']['input'];
};

/** Autogenerated return type of ContactsMove */
export type ContactsMovePayload = {
  __typename?: 'ContactsMovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

/** Autogenerated input type of ContactsUpdate */
export type ContactsUpdateInput = {
  attributes: OmsInputsContactType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ContactsUpdate */
export type ContactsUpdatePayload = {
  __typename?: 'ContactsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact: Contact;
};

export type Conversion = {
  __typename?: 'Conversion';
  baseAmountMoney: Money;
  secondaryAmountMoney?: Maybe<Money>;
};

export type CorrectionReference = {
  __typename?: 'CorrectionReference';
  id: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type CorrectionReferences = {
  __typename?: 'CorrectionReferences';
  invCustomer?: Maybe<Array<CorrectionReference>>;
  invPartner?: Maybe<Array<CorrectionReference>>;
  invWriteOff?: Maybe<Array<CorrectionReference>>;
  orderedActual?: Maybe<Array<CorrectionReference>>;
  orderedHold?: Maybe<Array<CorrectionReference>>;
  prodDelivered?: Maybe<Array<CorrectionReference>>;
  prodPlaced?: Maybe<Array<CorrectionReference>>;
  prodPlacing?: Maybe<Array<CorrectionReference>>;
  prodProcessing?: Maybe<Array<CorrectionReference>>;
  prodShipped2c?: Maybe<Array<CorrectionReference>>;
  prodShipped2x?: Maybe<Array<CorrectionReference>>;
  prodWarehouse?: Maybe<Array<CorrectionReference>>;
  qaClaims?: Maybe<Array<CorrectionReference>>;
  qaIssues?: Maybe<Array<CorrectionReference>>;
};

export type Counter = {
  __typename?: 'Counter';
  activeTasks: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  personUnreadMessages: Scalars['Int']['output'];
  providerUnreadMessages: Scalars['Int']['output'];
  sources: Array<MessageSource>;
  unreadMessages: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CountersSettings = {
  __typename?: 'CountersSettings';
  id: Scalars['ID']['output'];
  targetCounter: Scalars['String']['output'];
  targetTable: Scalars['String']['output'];
  workspacePresetId?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of CreateAutomatically */
export type CreateAutomaticallyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  options?: InputMaybe<Scalars['JSON']['input']>;
};

/** Autogenerated return type of CreateAutomatically */
export type CreateAutomaticallyPayload = {
  __typename?: 'CreateAutomaticallyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateBillingAccountsExport */
export type CreateBillingAccountsExportInput = {
  accountingPeriod?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durationFrom?: InputMaybe<Scalars['ISO8601Date']['input']>;
  durationTo?: InputMaybe<Scalars['ISO8601Date']['input']>;
  exportType?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateBillingAccountsExport */
export type CreateBillingAccountsExportPayload = {
  __typename?: 'CreateBillingAccountsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportBillingAccounts: ExportBillingAccountType;
};

export type CreateDhlOrderSteps = {
  __typename?: 'CreateDHLOrderSteps';
  buildCommercialInvoice?: Maybe<CreateOrderStep>;
  createShipment?: Maybe<CreateOrderStep>;
  sendEmail?: Maybe<CreateOrderStep>;
  updateShipping?: Maybe<CreateOrderStep>;
};

/** Autogenerated input type of CreateEnquiryPartDiscount */
export type CreateEnquiryPartDiscountInput = {
  attributes: OmsInputsPromotionDiscountType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateEnquiryPartDiscount */
export type CreateEnquiryPartDiscountPayload = {
  __typename?: 'CreateEnquiryPartDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of CreateLogisticInvoice */
export type CreateLogisticInvoiceInput = {
  amountBrutto: Scalars['Float']['input'];
  amountNetto: Scalars['Float']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  invoiceFiles?: InputMaybe<Array<OmsInputsAttachmentType>>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  issueDate: Scalars['ISO8601Date']['input'];
  logisticOperator: Scalars['String']['input'];
  number: Scalars['String']['input'];
  paymentDueDate: Scalars['ISO8601Date']['input'];
  registeredAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  sourceFiles?: InputMaybe<Array<OmsInputsAttachmentType>>;
};

/** Autogenerated return type of CreateLogisticInvoice */
export type CreateLogisticInvoicePayload = {
  __typename?: 'CreateLogisticInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  logisticInvoice?: Maybe<LogisticInvoice>;
};

export type CreateOrderStep = {
  __typename?: 'CreateOrderStep';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateProvidersExport */
export type CreateProvidersExportInput = {
  accountingPeriod?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durationFrom?: InputMaybe<Scalars['ISO8601Date']['input']>;
  durationTo?: InputMaybe<Scalars['ISO8601Date']['input']>;
  exportType?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateProvidersExport */
export type CreateProvidersExportPayload = {
  __typename?: 'CreateProvidersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportProviders: ExportProviderType;
};

export type CreditNote = {
  __typename?: 'CreditNote';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
};

/** Autogenerated input type of CrmForceCreateDeal */
export type CrmForceCreateDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CrmForceCreateDeal */
export type CrmForceCreateDealPayload = {
  __typename?: 'CrmForceCreateDealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of CrmForceCreateOrganization */
export type CrmForceCreateOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CrmForceCreateOrganization */
export type CrmForceCreateOrganizationPayload = {
  __typename?: 'CrmForceCreateOrganizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of CrmForceCreatePerson */
export type CrmForceCreatePersonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CrmForceCreatePerson */
export type CrmForceCreatePersonPayload = {
  __typename?: 'CrmForceCreatePersonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Available currencies */
export enum CurrenciesEnum {
  /** BGN */
  Bgn = 'BGN',
  /** CHF */
  Chf = 'CHF',
  /** CZK */
  Czk = 'CZK',
  /** DKK */
  Dkk = 'DKK',
  /** EUR */
  Eur = 'EUR',
  /** GBP */
  Gbp = 'GBP',
  /** HUF */
  Huf = 'HUF',
  /** ISK */
  Isk = 'ISK',
  /** NOK */
  Nok = 'NOK',
  /** PLN */
  Pln = 'PLN',
  /** RON */
  Ron = 'RON',
  /** SEK */
  Sek = 'SEK',
  /** TRY */
  Try = 'TRY',
  /** USD */
  Usd = 'USD'
}

/** Autogenerated input type of CurrencyConversion */
export type CurrencyConversionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currency: CurrenciesEnum;
  currencyType: CurrencyTypeEnum;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  documentId: Scalars['Int']['input'];
  documentType: Scalars['String']['input'];
};

/** Autogenerated return type of CurrencyConversion */
export type CurrencyConversionPayload = {
  __typename?: 'CurrencyConversionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum CurrencyEnum {
  /** BGN */
  Bgn = 'BGN',
  /** CHF */
  Chf = 'CHF',
  /** CZK */
  Czk = 'CZK',
  /** DKK */
  Dkk = 'DKK',
  /** EUR */
  Eur = 'EUR',
  /** GBP */
  Gbp = 'GBP',
  /** HUF */
  Huf = 'HUF',
  /** ISK */
  Isk = 'ISK',
  /** NOK */
  Nok = 'NOK',
  /** PLN */
  Pln = 'PLN',
  /** RON */
  Ron = 'RON',
  /** SEK */
  Sek = 'SEK',
  /** TRY */
  Try = 'TRY',
  /** USD */
  Usd = 'USD'
}

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  rate: Scalars['Float']['output'];
};

/** Primary or secondary currency for conversion */
export enum CurrencyTypeEnum {
  /** Convert document's primary currency */
  Primary = 'primary',
  /** Convert document's secondary (for children objects) currency */
  Secondary = 'secondary'
}

export enum CustomerOrderGradeEnum {
  A = 'a',
  B = 'b',
  C = 'c'
}

export type DatevFieldsType = {
  __typename?: 'DatevFieldsType';
  remark?: Maybe<Scalars['String']['output']>;
  sachkontoNumber?: Maybe<Scalars['Int']['output']>;
  vatCode?: Maybe<Scalars['Int']['output']>;
  vatPercent?: Maybe<Scalars['Int']['output']>;
  xometryVatNumber?: Maybe<Scalars['JSON']['output']>;
};

export type Deal = {
  __typename?: 'Deal';
  certificates?: Maybe<Scalars['JSON']['output']>;
  claims?: Maybe<Array<Claim>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  customerOrderCode?: Maybe<Scalars['String']['output']>;
  customerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  enquiry?: Maybe<Enquiry>;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  externalCrmUrl?: Maybe<Scalars['String']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  googleDriveUrl?: Maybe<Scalars['String']['output']>;
  grade: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  invoiced?: Maybe<Scalars['Float']['output']>;
  isExpress?: Maybe<Scalars['Boolean']['output']>;
  isForSoftwareTesting: Scalars['Boolean']['output'];
  isHighVolume: Scalars['Boolean']['output'];
  isImportant: Scalars['Boolean']['output'];
  isMcNeeded?: Maybe<Scalars['Boolean']['output']>;
  isNpg: Scalars['Boolean']['output'];
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedBy?: Maybe<User>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isPreQuoted?: Maybe<Scalars['Boolean']['output']>;
  isSamplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  isTpo: Scalars['Boolean']['output'];
  manualProcessingComment?: Maybe<Scalars['String']['output']>;
  manualProcessingEmails: Scalars['Boolean']['output'];
  manualProcessingFinance: Scalars['Boolean']['output'];
  manualProcessingIncomingInvoices: Scalars['Boolean']['output'];
  manualProcessingJobs: Scalars['Boolean']['output'];
  manualProcessingOrderConfirmation: Scalars['Boolean']['output'];
  manualProcessingOutcomingInvoices: Scalars['Boolean']['output'];
  manualProcessingShippings: Scalars['Boolean']['output'];
  manualProcessingStages: Scalars['Boolean']['output'];
  measurementProtocols?: Maybe<Array<Scalars['String']['output']>>;
  movedTo?: Maybe<IntercompanyObject>;
  number: Scalars['String']['output'];
  orderConfirmation?: Maybe<OrderConfirmation>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  originalDeals: Array<Deal>;
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
  owner?: Maybe<PdUser>;
  payments?: Maybe<Array<Payment>>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['ID']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveOrderValue?: Maybe<Scalars['Float']['output']>;
  pipedrivePipeline?: Maybe<Scalars['String']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  pipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  pipedriveStatusUpdatedBy?: Maybe<User>;
  pipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  pipedriveUrl?: Maybe<Scalars['String']['output']>;
  pmId?: Maybe<Scalars['ID']['output']>;
  productionCompleted: Scalars['Boolean']['output'];
  productionManager?: Maybe<User>;
  productionStage?: Maybe<ProductionStageEnum>;
  purchaseOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  reasonOfLose?: Maybe<Scalars['String']['output']>;
  repetitiveDeals: Array<Deal>;
  samplesConfirmationStatus?: Maybe<ProviderOrdersSamplesConfirmationStatusEnum>;
  shippings?: Maybe<Array<Shipping>>;
  slackUrl?: Maybe<Scalars['String']['output']>;
  slackUrlRequested?: Maybe<Scalars['Boolean']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  testDeal: Scalars['Boolean']['output'];
  totalPaidNett?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  utm?: Maybe<Utm>;
  vat: Scalars['Float']['output'];
};


export type DealOutcomingInvoicesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type DealPaymentsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type DealAggregations = {
  __typename?: 'DealAggregations';
  additionalTags?: Maybe<Array<Scalars['String']['output']>>;
  bagAndTag: Scalars['Boolean']['output'];
  consistencyCheck: Scalars['Boolean']['output'];
  openClaims: ClaimsAggregations;
  partsOrigins?: Maybe<Array<Scalars['String']['output']>>;
  priceMethod?: Maybe<PriceMethodEnum>;
  processes?: Maybe<Array<Scalars['String']['output']>>;
  qualityControlIssues: Array<Scalars['ID']['output']>;
  qualityControlOpenIssues: Array<Scalars['ID']['output']>;
  quoteSource?: Maybe<Scalars['String']['output']>;
  risks?: Maybe<Array<Scalars['String']['output']>>;
  shippingInstructions?: Maybe<Array<Scalars['String']['output']>>;
};

export type DealBalanceRecord = {
  __typename?: 'DealBalanceRecord';
  balanceBrutto: Scalars['Float']['output'];
  balanceBruttoMoney: Money;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  documentReferences: DealBalanceRecordDocumentReferences;
  id: Scalars['Int']['output'];
  invoicedBrutto: Scalars['Float']['output'];
  invoicedBruttoMoney: Money;
  maxOverdueDays: Scalars['Int']['output'];
  organization: Organization;
  organizationId: Scalars['ID']['output'];
  paidBrutto: Scalars['Float']['output'];
  paidBruttoMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type DealBalanceRecordDocumentReference = {
  __typename?: 'DealBalanceRecordDocumentReference';
  date: Scalars['ISO8601Date']['output'];
  displayName: Scalars['String']['output'];
  meta?: Maybe<DealBalanceRecordDocumentReferenceMeta>;
  number?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['ID']['output'];
  objectType: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DealBalanceRecordDocumentReferenceMeta = {
  __typename?: 'DealBalanceRecordDocumentReferenceMeta';
  creditNote?: Maybe<Scalars['Boolean']['output']>;
  isRefund?: Maybe<Scalars['Boolean']['output']>;
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  overdue?: Maybe<Scalars['Int']['output']>;
};

export type DealBalanceRecordDocumentReferences = {
  __typename?: 'DealBalanceRecordDocumentReferences';
  invoiced?: Maybe<Array<DealBalanceRecordDocumentReference>>;
  paid?: Maybe<Array<DealBalanceRecordDocumentReference>>;
};

/** Autogenerated input type of DealBalanceRecordsExportForOrganization */
export type DealBalanceRecordsExportForOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  organizationId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealBalanceRecordsExportForOrganization */
export type DealBalanceRecordsExportForOrganizationPayload = {
  __typename?: 'DealBalanceRecordsExportForOrganizationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of DealPartsCreateMeasurementTemplates */
export type DealPartsCreateMeasurementTemplatesInput = {
  attributes: Array<OmsInputsDealPartsMeasurementTemplateType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealPartsCreateMeasurementTemplates */
export type DealPartsCreateMeasurementTemplatesPayload = {
  __typename?: 'DealPartsCreateMeasurementTemplatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  measurements: Array<QualityControlsMeasurementType>;
};

/** Autogenerated input type of DealPartsCreateQualityControlFiles */
export type DealPartsCreateQualityControlFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fileIds: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealPartsCreateQualityControlFiles */
export type DealPartsCreateQualityControlFilesPayload = {
  __typename?: 'DealPartsCreateQualityControlFilesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  files: Array<AttachedFile>;
};

export type DealShippingAddressType = {
  __typename?: 'DealShippingAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['ID']['output']>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DealsAddRepetitiveDeal */
export type DealsAddRepetitiveDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  originalDealId: Scalars['ID']['input'];
  repetitiveDealId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsAddRepetitiveDeal */
export type DealsAddRepetitiveDealPayload = {
  __typename?: 'DealsAddRepetitiveDealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  originalDeal: Deal;
  repetitiveDeal: Deal;
};

/** Autogenerated input type of DealsBulkActionsUpdateProductionManager */
export type DealsBulkActionsUpdateProductionManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealIds: Array<Scalars['ID']['input']>;
  pmId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsBulkActionsUpdateProductionManager */
export type DealsBulkActionsUpdateProductionManagerPayload = {
  __typename?: 'DealsBulkActionsUpdateProductionManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DealsCalcPartsState */
export type DealsCalcPartsStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsCalcPartsState */
export type DealsCalcPartsStatePayload = {
  __typename?: 'DealsCalcPartsStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of DealsCompleteProduction */
export type DealsCompleteProductionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pipedriveStatus: PipedriveStatusEnum;
  reasonOfLose?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DealsCompleteProduction */
export type DealsCompleteProductionPayload = {
  __typename?: 'DealsCompleteProductionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of DealsCreateSlackChannel */
export type DealsCreateSlackChannelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsCreateSlackChannel */
export type DealsCreateSlackChannelPayload = {
  __typename?: 'DealsCreateSlackChannelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of DealsExport */
export type DealsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of DealsExport */
export type DealsExportPayload = {
  __typename?: 'DealsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of DealsRemoveRepetitiveDeal */
export type DealsRemoveRepetitiveDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  originalDealId: Scalars['ID']['input'];
  repetitiveDealId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsRemoveRepetitiveDeal */
export type DealsRemoveRepetitiveDealPayload = {
  __typename?: 'DealsRemoveRepetitiveDealPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  originalDeal: Deal;
  repetitiveDeal: Deal;
};

/** Autogenerated input type of DealsReopenProduction */
export type DealsReopenProductionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsReopenProduction */
export type DealsReopenProductionPayload = {
  __typename?: 'DealsReopenProductionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

export type DealsShippings = {
  actualDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  actualShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  providerOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingNumber?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DealsSourceType = {
  __typename?: 'DealsSourceType';
  canceled: Scalars['Boolean']['output'];
  className?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  files: Array<AttachedFile>;
  id: Scalars['ID']['output'];
  lots?: Maybe<Array<Lot>>;
  sourceId: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of DealsUpdate */
export type DealsUpdateInput = {
  attributes: OmsInputsDealsDealType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsUpdate */
export type DealsUpdatePayload = {
  __typename?: 'DealsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of DealsUpdatePerson */
export type DealsUpdatePersonInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsUpdatePerson */
export type DealsUpdatePersonPayload = {
  __typename?: 'DealsUpdatePersonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of DealsUpdateProductionManager */
export type DealsUpdateProductionManagerInput = {
  attributes: OmsInputsDealUpdateProductionManager;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DealsUpdateProductionManager */
export type DealsUpdateProductionManagerPayload = {
  __typename?: 'DealsUpdateProductionManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deal: Deal;
};

/** Autogenerated input type of DeliveriesConvertToShipping */
export type DeliveriesConvertToShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeliveriesConvertToShipping */
export type DeliveriesConvertToShippingPayload = {
  __typename?: 'DeliveriesConvertToShippingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingId: Scalars['ID']['output'];
};

/** Autogenerated input type of DeliveriesConvertToStorage */
export type DeliveriesConvertToStorageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

/** Autogenerated return type of DeliveriesConvertToStorage */
export type DeliveriesConvertToStoragePayload = {
  __typename?: 'DeliveriesConvertToStoragePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  storageId: Scalars['ID']['output'];
};

/** Autogenerated input type of DeliveriesUpdateComments */
export type DeliveriesUpdateCommentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeliveriesUpdateComments */
export type DeliveriesUpdateCommentsPayload = {
  __typename?: 'DeliveriesUpdateCommentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  delivery: Delivery;
};

export type Delivery = {
  __typename?: 'Delivery';
  actualDeliveryDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  actualShipmentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  attachedFiles?: Maybe<Array<AttachedFile>>;
  billingAddress?: Maybe<Scalars['String']['output']>;
  brutto?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  convertedToId?: Maybe<Scalars['String']['output']>;
  convertedToType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['ID']['output'];
  deliveryBasis?: Maybe<Scalars['String']['output']>;
  deliveryNoteLink?: Maybe<Scalars['String']['output']>;
  deliveryOrderNumber?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  direction: Scalars['String']['output'];
  expectedDeliveryDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expectedShipmentDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  logisticAggregator?: Maybe<Scalars['String']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  lots?: Maybe<Array<Lot>>;
  number: Scalars['String']['output'];
  packagingReportCheck: Scalars['String']['output'];
  partialDelivery?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  providerOrder?: Maybe<ProviderOrder>;
  receivedDelivery: Scalars['String']['output'];
  shippingAddress?: Maybe<Scalars['String']['output']>;
  shippingCost: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Float']['output']>;
};

export enum DependenceEnum {
  /** claim */
  Claim = 'claim',
  /** enquiry */
  Enquiry = 'enquiry',
  /** order_confirmation */
  OrderConfirmation = 'order_confirmation',
  /** provider_order */
  ProviderOrder = 'provider_order',
  /** quality_control */
  QualityControl = 'quality_control'
}

/** Autogenerated input type of DestoryLogisticInvoicePosition */
export type DestoryLogisticInvoicePositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  logisticInvoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestoryLogisticInvoicePosition */
export type DestoryLogisticInvoicePositionPayload = {
  __typename?: 'DestoryLogisticInvoicePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  logisticInvoice?: Maybe<LogisticInvoice>;
};

export type Dimension = {
  __typename?: 'Dimension';
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
  z?: Maybe<Scalars['Float']['output']>;
};

/** D&B specific money type with extended currencies */
export type DnBMoney = DnBMoneyInterface & {
  __typename?: 'DnBMoney';
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

/** Contains price and currency */
export type DnBMoneyInterface = {
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type DocumentNoteType = {
  __typename?: 'DocumentNoteType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  documentId?: Maybe<Scalars['ID']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  emotion?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

/** Autogenerated input type of DocumentNotesCreate */
export type DocumentNotesCreateInput = {
  attributes: OmsInputsDocumentNoteType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DocumentNotesCreate */
export type DocumentNotesCreatePayload = {
  __typename?: 'DocumentNotesCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  note: DocumentNoteType;
};

/** Autogenerated input type of DocumentNotesDelete */
export type DocumentNotesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DocumentNotesDelete */
export type DocumentNotesDeletePayload = {
  __typename?: 'DocumentNotesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DocumentReference = {
  __typename?: 'DocumentReference';
  count: Scalars['Int']['output'];
  objectId: Scalars['Int']['output'];
  objectType: Scalars['String']['output'];
};

export type DocumentReferenceGroup = {
  __typename?: 'DocumentReferenceGroup';
  in?: Maybe<Array<DocumentReference>>;
  out?: Maybe<Array<DocumentReference>>;
};

export type DocumentReferences = {
  __typename?: 'DocumentReferences';
  invCustomer?: Maybe<DocumentReferenceGroup>;
  invPartner?: Maybe<DocumentReferenceGroup>;
  invWriteOff?: Maybe<DocumentReferenceGroup>;
  orderedActual?: Maybe<DocumentReferenceGroup>;
  orderedHold?: Maybe<DocumentReferenceGroup>;
  prodDelivered?: Maybe<DocumentReferenceGroup>;
  prodPlaced?: Maybe<DocumentReferenceGroup>;
  prodPlacing?: Maybe<DocumentReferenceGroup>;
  prodProcessing?: Maybe<DocumentReferenceGroup>;
  prodShipped2c?: Maybe<DocumentReferenceGroup>;
  prodShipped2x?: Maybe<DocumentReferenceGroup>;
  prodWarehouse?: Maybe<DocumentReferenceGroup>;
  qaClaims?: Maybe<DocumentReferenceGroup>;
  qaIssues?: Maybe<DocumentReferenceGroup>;
};

/** Autogenerated input type of DraftingDealPartsExport */
export type DraftingDealPartsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of DraftingDealPartsExport */
export type DraftingDealPartsExportPayload = {
  __typename?: 'DraftingDealPartsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type DraftingEngineer = {
  __typename?: 'DraftingEngineer';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPartsUntouched12Hrs: DraftingEngineerCounter;
  draftingEngineerId?: Maybe<Scalars['ID']['output']>;
  hasExpressParts: DraftingEngineerCounter;
  id?: Maybe<Scalars['ID']['output']>;
  myStartedDeals: DraftingEngineerCounter;
  newWaitRework: DraftingEngineerCounter;
  podId?: Maybe<Scalars['Int']['output']>;
  requestAfterPoPlaced: DraftingEngineerCounter;
  reworkOrdersUpcoming: DraftingEngineerCounter;
  reworkOrdersVip: DraftingEngineerCounter;
  rfqCleaningNew: DraftingEngineerCounter;
  rfqUntouched12Hrs: DraftingEngineerCounter;
  tasksMyTeam: DraftingEngineerCounter;
  tasksUntouched12Hrs: DraftingEngineerCounter;
  tasksVip: DraftingEngineerCounter;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type DraftingEngineerCounter = {
  __typename?: 'DraftingEngineerCounter';
  dealsCount: Scalars['Int']['output'];
  partsCount: Scalars['Int']['output'];
};

/** Autogenerated input type of DraftingEnquiryPartsExport */
export type DraftingEnquiryPartsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of DraftingEnquiryPartsExport */
export type DraftingEnquiryPartsExportPayload = {
  __typename?: 'DraftingEnquiryPartsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of EmailLogin */
export type EmailLoginInput = {
  attributes: OmsInputsUserEmailLogin;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EmailLogin */
export type EmailLoginPayload = {
  __typename?: 'EmailLoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  user: User;
};

/** Autogenerated input type of EnquiriesBulkActionsCreateRfq */
export type EnquiriesBulkActionsCreateRfqInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EnquiriesBulkActionsCreateRfq */
export type EnquiriesBulkActionsCreateRfqPayload = {
  __typename?: 'EnquiriesBulkActionsCreateRfqPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

/** Autogenerated input type of EnquiriesBulkActionsPartsRestartWorkflow */
export type EnquiriesBulkActionsPartsRestartWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EnquiriesBulkActionsPartsRestartWorkflow */
export type EnquiriesBulkActionsPartsRestartWorkflowPayload = {
  __typename?: 'EnquiriesBulkActionsPartsRestartWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EnquiriesBulkActionsUpdateRelatedDealLots */
export type EnquiriesBulkActionsUpdateRelatedDealLotsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enquiryId: Scalars['ID']['input'];
  partIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EnquiriesBulkActionsUpdateRelatedDealLots */
export type EnquiriesBulkActionsUpdateRelatedDealLotsPayload = {
  __typename?: 'EnquiriesBulkActionsUpdateRelatedDealLotsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EnquiriesBulkNotesUpdate */
export type EnquiriesBulkNotesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notes: Scalars['String']['input'];
  partIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EnquiriesBulkNotesUpdate */
export type EnquiriesBulkNotesUpdatePayload = {
  __typename?: 'EnquiriesBulkNotesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of EnquiriesCancelModeration */
export type EnquiriesCancelModerationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enquiryId: Scalars['ID']['input'];
};

/** Autogenerated return type of EnquiriesCancelModeration */
export type EnquiriesCancelModerationPayload = {
  __typename?: 'EnquiriesCancelModerationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of EnquiriesExport */
export type EnquiriesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of EnquiriesExport */
export type EnquiriesExportPayload = {
  __typename?: 'EnquiriesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of EnquiriesUpdate */
export type EnquiriesUpdateInput = {
  attributes: OmsInputsEnquiriesEnquiryType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of EnquiriesUpdateKamModeration */
export type EnquiriesUpdateKamModerationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kamModerationRequired: Scalars['Boolean']['input'];
};

/** Autogenerated return type of EnquiriesUpdateKamModeration */
export type EnquiriesUpdateKamModerationPayload = {
  __typename?: 'EnquiriesUpdateKamModerationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated return type of EnquiriesUpdate */
export type EnquiriesUpdatePayload = {
  __typename?: 'EnquiriesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

export type Enquiry = {
  __typename?: 'Enquiry';
  appliedCouponValue?: Maybe<Scalars['Float']['output']>;
  appliedCouponValueMoney?: Maybe<Money>;
  appliedPromotions?: Maybe<Array<OmsEnquiryAppliedPromotionType>>;
  assignedEng?: Maybe<Scalars['ID']['output']>;
  assignedSa?: Maybe<Scalars['ID']['output']>;
  billingAccount?: Maybe<Scalars['JSON']['output']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  certificates?: Maybe<Scalars['JSON']['output']>;
  certificatesPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  ignoreMinimumOrderValue: Scalars['Boolean']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  isHighVolume: Scalars['Boolean']['output'];
  isTpo: Scalars['Boolean']['output'];
  lockQuoteLimit?: Maybe<Scalars['Boolean']['output']>;
  minOrderAddition?: Maybe<Scalars['Float']['output']>;
  minOrderAdditionMoney?: Maybe<Money>;
  moderationFinishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  moderationRequestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  movedTo?: Maybe<IntercompanyObject>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  orderedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  orderedBy?: Maybe<OrderedBy>;
  parts: Array<OmsEnquiryPartType>;
  partsValue?: Maybe<Scalars['Float']['output']>;
  partsValueMoney?: Maybe<Money>;
  prepayDiscount?: Maybe<Scalars['Float']['output']>;
  prepayDiscountMoney?: Maybe<Money>;
  quotingState: QuotingStateEnum;
  shippingAddress?: Maybe<Scalars['JSON']['output']>;
  shippingAddressStructured?: Maybe<Scalars['JSON']['output']>;
  shippingCost?: Maybe<Scalars['Float']['output']>;
  shippingCostMoney?: Maybe<Money>;
  state: Scalars['String']['output'];
  totalAmountBrut: Scalars['Float']['output'];
  totalAmountBrutMoney: Money;
  totalAmountNet: Scalars['Float']['output'];
  totalAmountNetMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatAmount?: Maybe<Scalars['Float']['output']>;
  vatAmountMoney?: Maybe<Money>;
  vatRate?: Maybe<Scalars['Float']['output']>;
  vatRateIsCustom: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EnquiryPartsUpdateRfqFields */
export type EnquiryPartsUpdateRfqFieldsInput = {
  attributes: OmsInputsEnquiriesPartsRfqType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
  unlinkOffer?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of EnquiryPartsUpdateRfqFields */
export type EnquiryPartsUpdateRfqFieldsPayload = {
  __typename?: 'EnquiryPartsUpdateRfqFieldsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of EnquiryPromotionsExport */
export type EnquiryPromotionsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of EnquiryPromotionsExport */
export type EnquiryPromotionsExportPayload = {
  __typename?: 'EnquiryPromotionsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of EnquirySyncForRfq */
export type EnquirySyncForRfqInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of EnquirySyncForRfq */
export type EnquirySyncForRfqPayload = {
  __typename?: 'EnquirySyncForRfqPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of EnquiryTransferForRfq */
export type EnquiryTransferForRfqInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
  toRegion: RegionEnum;
};

/** Autogenerated return type of EnquiryTransferForRfq */
export type EnquiryTransferForRfqPayload = {
  __typename?: 'EnquiryTransferForRfqPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

export type ErpPermission = {
  __typename?: 'ErpPermission';
  action: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  group: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ErpPermissionsGrouped = {
  __typename?: 'ErpPermissionsGrouped';
  erpPermissions: Array<ErpPermission>;
  group: Scalars['String']['output'];
};

export type Error = {
  __typename?: 'Error';
  createdAt: Scalars['ISO8601DateTime']['output'];
  errorCode: Scalars['String']['output'];
  errorMessage: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Event = {
  __typename?: 'Event';
  active: Scalars['Boolean']['output'];
  author: User;
  content: Scalars['JSON']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventType: EventType;
  id: Scalars['Int']['output'];
  sourceId: Scalars['Int']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type EventType = {
  __typename?: 'EventType';
  content: Scalars['JSON']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  dependence: DependenceEnum;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum EventsTypeEnum {
  /** Provider Order Event */
  PoLeadtimeEvent = 'po_leadtime_event',
  /** Production Event */
  ProductionEvent = 'production_event',
  /** Public Holiday Event */
  PublicHolidayEvent = 'public_holiday_event'
}

export type ExchangedRecommendation = {
  __typename?: 'ExchangedRecommendation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  recommendationMoney?: Maybe<Money>;
  success: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Export = {
  __typename?: 'Export';
  bankPayments: Array<BankPayment>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  exportRows?: Maybe<Array<ExportRow>>;
  id: Scalars['ID']['output'];
  linesProcessed: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type ExportBillingAccountType = {
  __typename?: 'ExportBillingAccountType';
  billingAccounts?: Maybe<Array<BillingAccount>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  exportType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  selectedObjects: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export type ExportProviderType = {
  __typename?: 'ExportProviderType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  exportType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  providers?: Maybe<Array<Provider>>;
  selectedObjects: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export type ExportRow = {
  __typename?: 'ExportRow';
  amount: Scalars['Float']['output'];
  apiJson?: Maybe<Scalars['JSON']['output']>;
  bankPaymentExportId: Scalars['ID']['output'];
  bankPaymentId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentDetails?: Maybe<Scalars['JSON']['output']>;
  state: ExportRowStateEnum;
  transactionId: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ExportRowStateEnum {
  /** completed */
  Completed = 'completed',
  /** failed */
  Failed = 'failed',
  /** pending */
  Pending = 'pending'
}

export type ExtendedMatch = {
  __typename?: 'ExtendedMatch';
  candidatesMatchedQuantity?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  error?: Maybe<Error>;
  id: Scalars['Int']['output'];
  inquiryDetail?: Maybe<InquiryDetail>;
  matchCandidates?: Maybe<Array<MatchCandidate>>;
  matchDataCriteria?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Finance = {
  __typename?: 'Finance';
  advanceRequests: Scalars['Int']['output'];
  advancesWithOverdue: Scalars['Int']['output'];
  bankPaymentsIncomingNotApproved: Scalars['Int']['output'];
  bankPaymentsIncomingNotRecognized: Scalars['Int']['output'];
  bankPaymentsOutcomingNotAccepted: Scalars['Int']['output'];
  bankPaymentsOutcomingNotRecognized: Scalars['Int']['output'];
  bankPaymentsOutcomingPending: Scalars['Int']['output'];
  dealsWithOverdueGt30Days: Scalars['Int']['output'];
  iiOverpaid: Scalars['Int']['output'];
  iiPublishedNotInDatev: Scalars['Int']['output'];
  iiReadyForCheckAll: Scalars['Int']['output'];
  iiReadyForCheckPod1: Scalars['Int']['output'];
  iiReadyForCheckPod2: Scalars['Int']['output'];
  iiReadyForCheckPod3: Scalars['Int']['output'];
  iiReadyForCheckPod4: Scalars['Int']['output'];
  managerNeedFix: Scalars['Int']['output'];
  oiPublishedNotInDatev: Scalars['Int']['output'];
  oiReadyForCheck: Scalars['Int']['output'];
  ordersWithFailedBaValidation: Scalars['Int']['output'];
  outcomingInvoicesWithOverdue: Scalars['Int']['output'];
  partnerAccountChangeRequests: Scalars['Int']['output'];
  partnersStopPayouts: Scalars['Int']['output'];
  paymentsCompletedNotInDatev: Scalars['Int']['output'];
  payoutsCompletedNotInDatev: Scalars['Int']['output'];
  refundRequests: Scalars['Int']['output'];
  xcnReadyForCheck: Scalars['Int']['output'];
};

export type Finish = {
  __typename?: 'Finish';
  colors?: Maybe<Array<Color>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nameVersions: Scalars['JSON']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  priceTag: Scalars['String']['output'];
  processId: Scalars['ID']['output'];
  ralEnabled?: Maybe<Scalars['Boolean']['output']>;
  restrictedMaterials: Array<Scalars['Int']['output']>;
};

/** Autogenerated input type of FrameworkBatchesDelete */
export type FrameworkBatchesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  groupBatchIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of FrameworkBatchesDelete */
export type FrameworkBatchesDeletePayload = {
  __typename?: 'FrameworkBatchesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of FrameworkBatchesExport */
export type FrameworkBatchesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of FrameworkBatchesExport */
export type FrameworkBatchesExportPayload = {
  __typename?: 'FrameworkBatchesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of FrameworkBatchesGroupCreateOrUpdate */
export type FrameworkBatchesGroupCreateOrUpdateInput = {
  attributes: OmsInputsFrameworkBatchesGroupType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of FrameworkBatchesGroupCreateOrUpdate */
export type FrameworkBatchesGroupCreateOrUpdatePayload = {
  __typename?: 'FrameworkBatchesGroupCreateOrUpdatePayload';
  batchCollection: Array<BatchCollection>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Gauge = {
  __typename?: 'Gauge';
  createdAt: Scalars['ISO8601DateTime']['output'];
  default: Scalars['Boolean']['output'];
  i18nKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Float']['output'];
};

/** Autogenerated input type of GenerateCsvBillingAccountsExport */
export type GenerateCsvBillingAccountsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenerateCsvBillingAccountsExport */
export type GenerateCsvBillingAccountsExportPayload = {
  __typename?: 'GenerateCsvBillingAccountsExportPayload';
  base64File: Base64FileType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenerateCsvProvidersExport */
export type GenerateCsvProvidersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenerateCsvProvidersExport */
export type GenerateCsvProvidersExportPayload = {
  __typename?: 'GenerateCsvProvidersExportPayload';
  base64File: Base64FileType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type GenericSerialized = {
  __typename?: 'GenericSerialized';
  type: GenericSerializedTypeEnum;
  value?: Maybe<Scalars['String']['output']>;
};

export enum GenericSerializedTypeEnum {
  /** Boolean */
  Boolean = 'Boolean',
  /** Float */
  Float = 'Float',
  /** ISO8601 Date */
  Iso8601Date = 'ISO8601Date',
  /** ISO8601 DateTime */
  Iso8601DateTime = 'ISO8601DateTime',
  /** Int */
  Int = 'Int',
  /** String */
  String = 'String'
}

export type GlobalJobOffer = {
  __typename?: 'GlobalJobOffer';
  counterofferLeadtime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  counterofferLeadtimeWithDap?: Maybe<Scalars['ISO8601DateTime']['output']>;
  counterofferValue?: Maybe<Scalars['Float']['output']>;
  counterofferValueWithDap: Scalars['Float']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  dapCostsApprox: Scalars['Float']['output'];
  dapDeliveryDaysApprox: Scalars['Int']['output'];
  decidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decisionDaysAgo?: Maybe<Scalars['Int']['output']>;
  decisionState: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  leadtime?: Maybe<Scalars['ISO8601DateTime']['output']>;
  leadtimeWithDap?: Maybe<Scalars['ISO8601DateTime']['output']>;
  provider: GlobalJobOfferProvider;
  region: Scalars['String']['output'];
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  responseComment?: Maybe<Scalars['String']['output']>;
  responseDaysAgo?: Maybe<Scalars['Int']['output']>;
  responseState: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueWithDap: Scalars['Float']['output'];
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type GlobalJobOfferProvider = {
  __typename?: 'GlobalJobOfferProvider';
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  metricsOrdersInProdCount?: Maybe<Scalars['Int']['output']>;
  metricsOrdersInProdValue?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  placingBoardCancellationRatio?: Maybe<Scalars['Float']['output']>;
  rankAne?: Maybe<Scalars['Float']['output']>;
  rankCancelRatio?: Maybe<Scalars['Float']['output']>;
  rankClaimRatio?: Maybe<Scalars['Float']['output']>;
  rankQuality?: Maybe<Scalars['Float']['output']>;
  rankTiming?: Maybe<Scalars['Float']['output']>;
  rankTimingRatio?: Maybe<Scalars['Float']['output']>;
  rankTotal?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
};

export type Grade = {
  __typename?: 'Grade';
  advicesLengthInHours: Scalars['Int']['output'];
  advicesPerMonth: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currencyPerPoint: Scalars['Float']['output'];
  freeCads: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  infinityAdvices: Scalars['Boolean']['output'];
  moneySpentNeeded: Scalars['Int']['output'];
  pointsPerCurrency: Scalars['Int']['output'];
  pointsPerInvite: Scalars['Int']['output'];
  rank: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type HelpLink = {
  __typename?: 'HelpLink';
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  hint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type HsCode = {
  __typename?: 'HsCode';
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptionEn?: Maybe<Scalars['String']['output']>;
  hsCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  materialPatterns: Array<Scalars['String']['output']>;
  materialTypeIds?: Maybe<Array<Scalars['Int']['output']>>;
  names: Array<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Import = {
  __typename?: 'Import';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  importRows?: Maybe<Array<ImportRow>>;
  linesProcessed: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type ImportRow = {
  __typename?: 'ImportRow';
  amount: Scalars['Float']['output'];
  bankPaymentId: Scalars['ID']['output'];
  bankPaymentImportId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentDetails?: Maybe<Scalars['JSON']['output']>;
  state: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IncomingInvoice = {
  __typename?: 'IncomingInvoice';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote: Scalars['Boolean']['output'];
  creditNotes?: Maybe<Array<IncomingInvoice>>;
  currency: Scalars['String']['output'];
  datevComment?: Maybe<Scalars['String']['output']>;
  deal: Deal;
  dealId: Scalars['ID']['output'];
  dealProviderOrderId: Scalars['ID']['output'];
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  intacctComment?: Maybe<Scalars['String']['output']>;
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  invoiceChecked?: Maybe<Scalars['JSON']['output']>;
  invoicePrepared?: Maybe<Scalars['JSON']['output']>;
  invoiceReady?: Maybe<Scalars['JSON']['output']>;
  isPartial: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  numberErp: Scalars['String']['output'];
  overrideAccountingDataDisabled: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentDueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  payouts?: Maybe<Array<Payout>>;
  provider: IncomingInvoicesProviderType;
  providerBankDetails?: Maybe<Scalars['JSON']['output']>;
  providerBillingAddress?: Maybe<Scalars['JSON']['output']>;
  providerContact?: Maybe<Scalars['JSON']['output']>;
  providerOrder?: Maybe<ProviderOrder>;
  providerOrderId: Scalars['ID']['output'];
  refInvoice?: Maybe<IncomingInvoice>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  selfBilling: Scalars['Boolean']['output'];
  state: IncomingInvoiceStateEnum;
  totalAmountBrutto?: Maybe<Scalars['Float']['output']>;
  totalAmountBruttoMoney: Money;
  totalAmountNetto?: Maybe<Scalars['Float']['output']>;
  totalAmountNettoMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validity?: Maybe<IncomingInvoiceValidityEnum>;
  vatAmount?: Maybe<Scalars['Float']['output']>;
  vatAmountMoney: Money;
  vatAvg?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use `self_billing` field */
  xometryIssuedCreditNote: Scalars['Boolean']['output'];
};


export type IncomingInvoiceCreditNotesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type IncomingInvoicePayoutsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type IncomingInvoiceRefInvoiceArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type IncomingInvoiceExport = {
  __typename?: 'IncomingInvoiceExport';
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export enum IncomingInvoiceStateEnum {
  /** canceled */
  Canceled = 'canceled',
  /** draft */
  Draft = 'draft',
  /** published */
  Published = 'published',
  /** rejected */
  Rejected = 'rejected',
  /** sent_to_mikrojump */
  SentToMikrojump = 'sent_to_mikrojump'
}

export enum IncomingInvoiceValidityEnum {
  /** finance_ready */
  FinanceReady = 'finance_ready',
  /** manager_need_fix */
  ManagerNeedFix = 'manager_need_fix',
  /** manager_ready */
  ManagerReady = 'manager_ready',
  /** partner_need_fix */
  PartnerNeedFix = 'partner_need_fix',
  /** partner_ready */
  PartnerReady = 'partner_ready',
  /** TR ONLY: rejected_by_pm */
  RejectedByPm = 'rejected_by_pm'
}

/** Autogenerated input type of IncomingInvoicesBulkBankPaymentCreate */
export type IncomingInvoicesBulkBankPaymentCreateInput = {
  attributes: Scalars['JSON']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of IncomingInvoicesBulkBankPaymentCreate */
export type IncomingInvoicesBulkBankPaymentCreatePayload = {
  __typename?: 'IncomingInvoicesBulkBankPaymentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of IncomingInvoicesBulkDatevExport */
export type IncomingInvoicesBulkDatevExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of IncomingInvoicesBulkDatevExport */
export type IncomingInvoicesBulkDatevExportPayload = {
  __typename?: 'IncomingInvoicesBulkDatevExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  export: IncomingInvoiceExport;
};

/** Autogenerated input type of IncomingInvoicesBulkGeneratePayouts */
export type IncomingInvoicesBulkGeneratePayoutsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of IncomingInvoicesBulkGeneratePayouts */
export type IncomingInvoicesBulkGeneratePayoutsPayload = {
  __typename?: 'IncomingInvoicesBulkGeneratePayoutsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of IncomingInvoicesBulkIntacctExport */
export type IncomingInvoicesBulkIntacctExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of IncomingInvoicesBulkIntacctExport */
export type IncomingInvoicesBulkIntacctExportPayload = {
  __typename?: 'IncomingInvoicesBulkIntacctExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  export: IntacctIncomingInvoiceExportType;
};

/** Autogenerated input type of IncomingInvoicesBulkMarkAsPaid */
export type IncomingInvoicesBulkMarkAsPaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  invoiceIds: Array<Scalars['ID']['input']>;
  markAsRegistered: Scalars['Boolean']['input'];
  paidAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  transactionType: Scalars['String']['input'];
};

/** Autogenerated return type of IncomingInvoicesBulkMarkAsPaid */
export type IncomingInvoicesBulkMarkAsPaidPayload = {
  __typename?: 'IncomingInvoicesBulkMarkAsPaidPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of IncomingInvoicesExport */
export type IncomingInvoicesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of IncomingInvoicesExport */
export type IncomingInvoicesExportPayload = {
  __typename?: 'IncomingInvoicesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of IncomingInvoicesOverrideDatev */
export type IncomingInvoicesOverrideDatevInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  sachkontoNumber?: InputMaybe<Scalars['String']['input']>;
  vatCode?: InputMaybe<Scalars['String']['input']>;
  vatPercent: Scalars['Int']['input'];
  xometryVatCountry?: InputMaybe<XometryVatCountryEnum>;
};

/** Autogenerated return type of IncomingInvoicesOverrideDatev */
export type IncomingInvoicesOverrideDatevPayload = {
  __typename?: 'IncomingInvoicesOverrideDatevPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: IncomingInvoice;
};

export type IncomingInvoicesProviderType = {
  __typename?: 'IncomingInvoicesProviderType';
  id: Scalars['ID']['output'];
  intacctId?: Maybe<Scalars['String']['output']>;
  legalName: Scalars['String']['output'];
  payoutTransferType: TransferEnum;
  vatId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of IncomingInvoicesReallocate */
export type IncomingInvoicesReallocateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  reallocateTo: Array<OmsInputsIncomingInvoicesReallocateToType>;
};

/** Autogenerated return type of IncomingInvoicesReallocate */
export type IncomingInvoicesReallocatePayload = {
  __typename?: 'IncomingInvoicesReallocatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payouts: Array<Payout>;
};

/** Autogenerated input type of IncomingInvoicesSendToMikrojump */
export type IncomingInvoicesSendToMikrojumpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of IncomingInvoicesSendToMikrojump */
export type IncomingInvoicesSendToMikrojumpPayload = {
  __typename?: 'IncomingInvoicesSendToMikrojumpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: IncomingInvoice;
};

export type Init = {
  __typename?: 'Init';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  payoutTransferType: Scalars['String']['output'];
  providerId: Scalars['ID']['output'];
  providerName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InputsMoneyType = {
  amount: Scalars['String']['input'];
  amountCents: Scalars['String']['input'];
  currencyCode: CurrencyEnum;
};

export type InputsProvidersSearch = {
  countries?: InputMaybe<Array<Scalars['String']['input']>>;
  nameOrId?: InputMaybe<Scalars['String']['input']>;
  onlyResponded?: InputMaybe<Scalars['Boolean']['input']>;
  rfqNumber?: InputMaybe<Scalars['String']['input']>;
  states?: InputMaybe<Array<ProviderStateEnum>>;
  tagIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InquiryDetail = {
  __typename?: 'InquiryDetail';
  createdAt: Scalars['ISO8601DateTime']['output'];
  duns?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  registrationNumberType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type IntacctIncomingInvoiceExportType = {
  __typename?: 'IntacctIncomingInvoiceExportType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export type IntacctOutcomingInvoiceExportType = {
  __typename?: 'IntacctOutcomingInvoiceExportType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of IntacctOutcomingInvoiceExportZipMutation */
export type IntacctOutcomingInvoiceExportZipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
};

/** Autogenerated return type of IntacctOutcomingInvoiceExportZipMutation */
export type IntacctOutcomingInvoiceExportZipMutationPayload = {
  __typename?: 'IntacctOutcomingInvoiceExportZipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportZipState: IntacctZipStateType;
  jobId: Scalars['String']['output'];
};

export type IntacctZipStateType = {
  __typename?: 'IntacctZipStateType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zipState: ZipStateIntacctEnumType;
};

export type IntercompanyObject = {
  __typename?: 'IntercompanyObject';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId?: Maybe<Scalars['ID']['output']>;
  foreignDealId?: Maybe<Scalars['ID']['output']>;
  foreignObjectId?: Maybe<Scalars['ID']['output']>;
  foreignObjectNumber?: Maybe<Scalars['String']['output']>;
  foreignObjectType: Scalars['String']['output'];
  foreignRegion: Scalars['String']['output'];
  foreignRole?: Maybe<RoleEnum>;
  id: Scalars['ID']['output'];
  objectId: Scalars['ID']['output'];
  objectType: Scalars['String']['output'];
  relationType?: Maybe<RelationEnum>;
  role?: Maybe<RoleEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Invite = {
  __typename?: 'Invite';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  invitee?: Maybe<Person>;
  inviteeEmail?: Maybe<Scalars['String']['output']>;
  inviteeId?: Maybe<Scalars['ID']['output']>;
  person: Person;
  personId: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
};

export type InvoicePosition = {
  __typename?: 'InvoicePosition';
  accountingCategory: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  pricePerPieceMoney: Money;
  quantity: Scalars['Int']['output'];
  ref?: Maybe<Ref>;
  refId?: Maybe<Scalars['String']['output']>;
  refType?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatRate: Scalars['Float']['output'];
};

export type Job = {
  __typename?: 'Job';
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerLotsValue: Scalars['Float']['output'];
  deal: Deal;
  id: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  providerOrder?: Maybe<ProviderOrder>;
  publicationEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publicationStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Float']['output'];
  valueCurrency: Scalars['String']['output'];
};

export type JobOffer = {
  __typename?: 'JobOffer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  shipAllowDirectLegal?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectLegalXomSales?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectOrder?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectProvider?: Maybe<Scalars['Boolean']['output']>;
  shipDirection?: Maybe<ProviderOrdersShipDirectionEnum>;
  shipDirectionToEndCustomer?: Maybe<Scalars['Boolean']['output']>;
  shipIncoterms?: Maybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly: Scalars['Boolean']['output'];
  shipIncotermsCity?: Maybe<Scalars['String']['output']>;
  shipIncotermsCountry?: Maybe<Scalars['String']['output']>;
  shipQcRequired?: Maybe<Scalars['Boolean']['output']>;
  shipWarehouse?: Maybe<WarehouseInstructionEnum>;
  shippingInstructionsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingInstructionsUpdatedBy?: Maybe<User>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of JobOffersExport */
export type JobOffersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of JobOffersExport */
export type JobOffersExportPayload = {
  __typename?: 'JobOffersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of JobOffersShippingInstructionUpdates */
export type JobOffersShippingInstructionUpdatesInput = {
  attributes: OmsInputsShippingInstructions;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of JobOffersShippingInstructionUpdates */
export type JobOffersShippingInstructionUpdatesPayload = {
  __typename?: 'JobOffersShippingInstructionUpdatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobOffer: JobOffer;
};

export type JobStatistic = {
  __typename?: 'JobStatistic';
  applicationCount: Scalars['Int']['output'];
  autoAccepted: Scalars['Boolean']['output'];
  counterofferCount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  irrelevantCount: Scalars['Int']['output'];
  offersCount: Scalars['Int']['output'];
  seenCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of JobsExport */
export type JobsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of JobsExport */
export type JobsExportPayload = {
  __typename?: 'JobsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export enum LanguageEnum {
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it'
}

export type LeadTime = {
  __typename?: 'LeadTime';
  date: Scalars['ISO8601Date']['output'];
};

export type LogisticInvoice = {
  __typename?: 'LogisticInvoice';
  allocatedSuccessfully: Money;
  amountBrutto: Money;
  amountNetto: Money;
  checkedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  checkedBy?: Maybe<User>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  invoiceFiles?: Maybe<Array<AttachedFile>>;
  issueDate: Scalars['ISO8601Date']['output'];
  logisticOperator: Scalars['String']['output'];
  notAllocated: Money;
  notFullyAllocated: Money;
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  paymentDueDate: Scalars['ISO8601Date']['output'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publishedBy?: Maybe<User>;
  registeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  sourceFiles?: Maybe<Array<AttachedFile>>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type LogisticInvoicePosition = {
  __typename?: 'LogisticInvoicePosition';
  allocatedCharges?: Maybe<Money>;
  amountBrutto: Money;
  amountNetto: Money;
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvDataRaw?: Maybe<Scalars['String']['output']>;
  csvImportId?: Maybe<Scalars['Int']['output']>;
  csvImportLineNumber?: Maybe<Scalars['Int']['output']>;
  expenseType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logisticInvoice?: Maybe<LogisticInvoice>;
  shipping?: Maybe<Shipping>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Lot = {
  __typename?: 'Lot';
  claims: Array<Claim>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerPrice: Scalars['Float']['output'];
  customerPriceCurrency: CurrencyEnum;
  customerPriceMoney: Money;
  dealId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  part: Part;
  partId: Scalars['Int']['output'];
  providerOrder?: Maybe<ProviderOrder>;
  providerPrice: Scalars['Float']['output'];
  providerPriceCurrency: CurrencyEnum;
  providerPriceMoney: Money;
  quantity: Scalars['Int']['output'];
  shipping?: Maybe<Shipping>;
  source?: Maybe<Source>;
  sourceId?: Maybe<Scalars['Int']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatRate: Scalars['Float']['output'];
};


export type LotCustomerPriceMoneyArgs = {
  currencyCode?: InputMaybe<CurrencyEnum>;
};


export type LotProviderPriceMoneyArgs = {
  currencyCode?: InputMaybe<CurrencyEnum>;
};

export type LoyaltyBalance = {
  __typename?: 'LoyaltyBalance';
  /** depricated, please load grades separately */
  configs?: Maybe<Array<Grade>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  discountCode?: Maybe<Scalars['String']['output']>;
  hasPending: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  invites?: Maybe<Array<Invite>>;
  level: Grade;
  moneySaved: Scalars['Float']['output'];
  moneySpent: Scalars['Float']['output'];
  points: Scalars['Int']['output'];
  pointsEarned: Scalars['Int']['output'];
  pointsPending: Scalars['Int']['output'];
  pointsSpent: Scalars['Int']['output'];
  tillNextLevel: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of LoyaltyTransactionsCreate */
export type LoyaltyTransactionsCreateInput = {
  attributes: OmsInputsLoyaltyTransactionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of LoyaltyTransactionsCreate */
export type LoyaltyTransactionsCreatePayload = {
  __typename?: 'LoyaltyTransactionsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transaction: Transaction;
};

export enum LoyaltyTransactionsReferenceEnumType {
  Claim = 'Claim',
  Deal = 'Deal',
  Person = 'Person'
}

export enum LoyaltyTransactionsTriggerEnumType {
  Compensation = 'compensation',
  Correction = 'correction',
  Coupon = 'coupon',
  Invite = 'invite',
  Ordered = 'ordered',
  Transfer = 'transfer'
}

export enum LoyaltyTransactionsTypeEnumType {
  Inflow = 'inflow',
  Outflow = 'outflow'
}

/** Autogenerated input type of MarkAsRegisteredBillingAccountsExport */
export type MarkAsRegisteredBillingAccountsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
  objectIds: Array<Scalars['ID']['input']>;
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  registeredAt: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of MarkAsRegisteredBillingAccountsExport */
export type MarkAsRegisteredBillingAccountsExportPayload = {
  __typename?: 'MarkAsRegisteredBillingAccountsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportBillingAccounts: Array<BillingAccount>;
};

/** Autogenerated input type of MarkAsRegisteredProvidersExport */
export type MarkAsRegisteredProvidersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
  objectIds: Array<Scalars['ID']['input']>;
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  registeredAt: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of MarkAsRegisteredProvidersExport */
export type MarkAsRegisteredProvidersExportPayload = {
  __typename?: 'MarkAsRegisteredProvidersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportProviders: Array<Provider>;
};

export type Marking = {
  __typename?: 'Marking';
  createdAt: Scalars['ISO8601DateTime']['output'];
  i18nKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type MasterShipping = {
  __typename?: 'MasterShipping';
  actualDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  actualShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  attachedFiles: Array<AttachedFile>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customsDocumentsStatus: ShippingCustomsDocumentsStatusEnum;
  deliveryBasis?: Maybe<ShippingDeliveryBasisEnum>;
  deliveryType?: Maybe<ShippingDeliveryTypeEnum>;
  destinationAddress?: Maybe<DealShippingAddressType>;
  direction: ShippingDirectionEnum;
  expectedDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  expectedShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  holdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  holdBy?: Maybe<User>;
  holdById?: Maybe<Scalars['BigInt']['output']>;
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  isExport: Scalars['Boolean']['output'];
  isImport: Scalars['Boolean']['output'];
  isImportAndExport: Scalars['Boolean']['output'];
  logisticAggregator?: Maybe<Scalars['String']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  movementReferenceNumber?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['BigInt']['output']>;
  positionNaming: ShippingPositionNamingEnum;
  proofOfDeliveryScheduled: Scalars['Boolean']['output'];
  proofOfDeliverySkipped: Scalars['Boolean']['output'];
  shippingAlertAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingAlertBy?: Maybe<User>;
  shippingAlertById?: Maybe<Scalars['BigInt']['output']>;
  shippingAlertMessage?: Maybe<Scalars['String']['output']>;
  shippingCost: Money;
  shippingOrderNumber?: Maybe<Scalars['String']['output']>;
  shippingParameters?: Maybe<Array<ShippingParameters>>;
  shippings: Array<Shipping>;
  sourceAddress?: Maybe<DealShippingAddressType>;
  state: ShippingStateEnum;
  trackingCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingRecords: Array<ShippingTrackingRecord>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xometryNumber?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MasterShippingsCreateFromShipping */
export type MasterShippingsCreateFromShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingId: Scalars['ID']['input'];
};

/** Autogenerated return type of MasterShippingsCreateFromShipping */
export type MasterShippingsCreateFromShippingPayload = {
  __typename?: 'MasterShippingsCreateFromShippingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
};

/** Autogenerated input type of MasterShippingsCreate */
export type MasterShippingsCreateInput = {
  attributes: OmsInputsMasterShippingType;
  childrenIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MasterShippingsCreate */
export type MasterShippingsCreatePayload = {
  __typename?: 'MasterShippingsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
};

/** Autogenerated input type of MasterShippingsShipmentOrderCreateDhlOrder */
export type MasterShippingsShipmentOrderCreateDhlOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  destinationAddress: OmsInputsShipmentOrderDhlAddressType;
  expectedDeliveryDate: Scalars['ISO8601Date']['input'];
  masterShippingId: Scalars['ID']['input'];
  movementReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  operator: Scalars['String']['input'];
  pickupOptions: OmsInputsShipmentOrderPickupOptionsType;
  plannedShippingDate: Scalars['ISO8601DateTime']['input'];
  productCode: Scalars['String']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  sourceAddress: OmsInputsShipmentOrderDhlAddressType;
};

/** Autogenerated return type of MasterShippingsShipmentOrderCreateDhlOrder */
export type MasterShippingsShipmentOrderCreateDhlOrderPayload = {
  __typename?: 'MasterShippingsShipmentOrderCreateDhlOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of MasterShippingsShipmentOrderRequestShippingOptions */
export type MasterShippingsShipmentOrderRequestShippingOptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationAddress: OmsInputsShipmentOrderAddressType;
  masterShippingId: Scalars['ID']['input'];
  operators: Array<ShipmentOrderOperatorsEnum>;
  packages: Array<OmsInputsShipmentOrderPackageType>;
  plannedShippingDate: Scalars['ISO8601Date']['input'];
  sourceAddress: OmsInputsShipmentOrderAddressType;
};

/** Autogenerated return type of MasterShippingsShipmentOrderRequestShippingOptions */
export type MasterShippingsShipmentOrderRequestShippingOptionsPayload = {
  __typename?: 'MasterShippingsShipmentOrderRequestShippingOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of MasterShippingsUpdate */
export type MasterShippingsUpdateInput = {
  attributes: OmsInputsMasterShippingType;
  childrenIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of MasterShippingsUpdate */
export type MasterShippingsUpdatePayload = {
  __typename?: 'MasterShippingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
};

/** Autogenerated input type of MasterShippingsUpdateTracking */
export type MasterShippingsUpdateTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  masterShippingId: Scalars['ID']['input'];
};

/** Autogenerated return type of MasterShippingsUpdateTracking */
export type MasterShippingsUpdateTrackingPayload = {
  __typename?: 'MasterShippingsUpdateTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping?: Maybe<MasterShipping>;
};

export type MatchCandidate = {
  __typename?: 'MatchCandidate';
  createdAt: Scalars['ISO8601DateTime']['output'];
  displaySequence: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  organization: OrganizationData;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Material = {
  __typename?: 'Material';
  /** to be deprecated, do not use */
  gauges: Array<MaterialGauge>;
  gaugesNew: Array<Gauge>;
  hardware: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ingredient?: Maybe<Scalars['String']['output']>;
  materialType: MaterialType;
  materialTypeId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Scalars['JSON']['output']>;
  partMarkings?: Maybe<Scalars['JSON']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  process: OmsEnquiryProcessType;
  surfaceRoughness?: Maybe<Scalars['JSON']['output']>;
  threading: Scalars['Boolean']['output'];
  /** to be deprecated, do not use */
  tolerance?: Maybe<Scalars['JSON']['output']>;
  tolerancesNew: Array<Tolerance>;
  weldQuantity: Scalars['Boolean']['output'];
};

export type MaterialGauge = {
  __typename?: 'MaterialGauge';
  default?: Maybe<Scalars['Boolean']['output']>;
  iqeValue?: Maybe<Scalars['String']['output']>;
  mm?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MaterialType = {
  __typename?: 'MaterialType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  process: OmsEnquiryProcessType;
};

export type Message = {
  __typename?: 'Message';
  attachments: Array<Attachment>;
  author: MessageUser;
  authorId: Scalars['ID']['output'];
  authorType: UserEnum;
  containsUnsafeLink: Scalars['Boolean']['output'];
  content?: Maybe<Scalars['String']['output']>;
  contentRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deal?: Maybe<Deal>;
  dealId?: Maybe<Scalars['ID']['output']>;
  dealPage?: Maybe<Scalars['Int']['output']>;
  dealTitle?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  editedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBookmark: Scalars['Boolean']['output'];
  isVisibleToClient: Scalars['Boolean']['output'];
  isVisibleToPartner: Scalars['Boolean']['output'];
  isWhatsAppMessageError: Scalars['Boolean']['output'];
  mentions: Array<Scalars['ID']['output']>;
  pinned: Scalars['Boolean']['output'];
  pinnedDirection?: Maybe<PinnedDirectionsEnum>;
  replyOn?: Maybe<MessageReply>;
  source?: Maybe<MessageSource>;
  sourcePage: Scalars['Int']['output'];
  task?: Maybe<MessageTask>;
  type?: Maybe<MessageTypeEnum>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  collection: Array<Message>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum MessageDirectionEnum {
  C2X = 'C2X',
  P2X = 'P2X',
  X2C = 'X2C',
  X2P = 'X2P',
  X2X = 'X2X'
}

export type MessageFilter = {
  __typename?: 'MessageFilter';
  authorType?: Maybe<UserEnum>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  direction?: Maybe<MessageDirectionEnum>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ignoreCounters: Scalars['Boolean']['output'];
  messageType?: Maybe<MessageFilterTypeEnum>;
  muteNotifications: Scalars['Boolean']['output'];
  sourceType?: Maybe<MessageSourceTypeEnum>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageFilterGroup = {
  __typename?: 'MessageFilterGroup';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  filters?: Maybe<Array<MessageFilter>>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum MessageFilterTypeEnum {
  DocumentNote = 'DOCUMENT_NOTE',
  General = 'GENERAL',
  IncomingEmail = 'INCOMING_EMAIL',
  OutcomingEmail = 'OUTCOMING_EMAIL',
  PricingWizard = 'PRICING_WIZARD',
  RfqOfferSubmitComment = 'RFQ_OFFER_SUBMIT_COMMENT',
  RfqProcessComment = 'RFQ_PROCESS_COMMENT',
  Robot = 'ROBOT',
  TaskCall = 'TASK_CALL',
  TaskChangesInDeal = 'TASK_CHANGES_IN_DEAL',
  TaskCheckingMaterialCertificate = 'TASK_CHECKING_MATERIAL_CERTIFICATE',
  TaskCheckpoint = 'TASK_CHECKPOINT',
  TaskCheckStatus = 'TASK_CHECK_STATUS',
  TaskCreateDocument = 'TASK_CREATE_DOCUMENT',
  TaskDiscussIssue = 'TASK_DISCUSS_ISSUE',
  TaskDrawing = 'TASK_DRAWING',
  TaskGeneral = 'TASK_GENERAL',
  TaskInvoiceIssue = 'TASK_INVOICE_ISSUE',
  TaskPlacingIssues = 'TASK_PLACING_ISSUES',
  TaskPlacingRepeatDeal = 'TASK_PLACING_REPEAT_DEAL',
  TaskReplacingRequest = 'TASK_REPLACING_REQUEST',
  TaskRequestApprove = 'TASK_REQUEST_APPROVE',
  TaskRevenueForecastDiff = 'TASK_REVENUE_FORECAST_DIFF',
  TaskShipping = 'TASK_SHIPPING',
  TaskTalkToCustomer = 'TASK_TALK_TO_CUSTOMER',
  TaskTalkToPartner = 'TASK_TALK_TO_PARTNER',
  Whatsapp = 'WHATSAPP'
}

export type MessageReply = {
  __typename?: 'MessageReply';
  authorId: Scalars['ID']['output'];
  authorType: UserEnum;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  previewText: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MessageSource = {
  __typename?: 'MessageSource';
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  customerLastReadMessageId?: Maybe<Scalars['ID']['output']>;
  customerWhatsAppStatus?: Maybe<WhatsAppStatusEnum>;
  dealId?: Maybe<Scalars['ID']['output']>;
  dealTitle?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followingUserIds: Array<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isFollowed?: Maybe<Scalars['Boolean']['output']>;
  parent?: Maybe<MessageSource>;
  partnerLastReadMessageId?: Maybe<Scalars['ID']['output']>;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  subscription?: Maybe<Subscription>;
  title?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum MessageSourceTypeEnum {
  BillingAccount = 'BILLING_ACCOUNT',
  Deal = 'DEAL',
  DealClaim = 'DEAL_CLAIM',
  DealIncomingInvoice = 'DEAL_INCOMING_INVOICE',
  DealOrderConfirmation = 'DEAL_ORDER_CONFIRMATION',
  DealOutcomingInvoice = 'DEAL_OUTCOMING_INVOICE',
  DealPart = 'DEAL_PART',
  DealPayment = 'DEAL_PAYMENT',
  DealPayout = 'DEAL_PAYOUT',
  DealProviderOrder = 'DEAL_PROVIDER_ORDER',
  DealQualityControl = 'DEAL_QUALITY_CONTROL',
  DealShipping = 'DEAL_SHIPPING',
  DealStorage = 'DEAL_STORAGE',
  Enquiry = 'ENQUIRY',
  EnquiryPart = 'ENQUIRY_PART',
  Job = 'JOB',
  JobOffer = 'JOB_OFFER',
  MasterShipping = 'MASTER_SHIPPING',
  Person = 'PERSON',
  Provider = 'PROVIDER',
  Rfq = 'RFQ',
  RfqOffer = 'RFQ_OFFER'
}

/** Autogenerated input type of MessageSubscriptionsBulkActionsMarkAllRead */
export type MessageSubscriptionsBulkActionsMarkAllReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriptionIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of MessageSubscriptionsBulkActionsMarkAllRead */
export type MessageSubscriptionsBulkActionsMarkAllReadPayload = {
  __typename?: 'MessageSubscriptionsBulkActionsMarkAllReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MessageSubscriptionsBulkActionsSubscribe */
export type MessageSubscriptionsBulkActionsSubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriberId: Scalars['ID']['input'];
  subscriptions: Array<OmsInputsMessageSubscriptionsSubscriptionType>;
};

/** Autogenerated return type of MessageSubscriptionsBulkActionsSubscribe */
export type MessageSubscriptionsBulkActionsSubscribePayload = {
  __typename?: 'MessageSubscriptionsBulkActionsSubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MessageSubscriptionsBulkActionsUnsubscribe */
export type MessageSubscriptionsBulkActionsUnsubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  subscriberId: Scalars['ID']['input'];
  subscriptions: Array<OmsInputsMessageSubscriptionsSubscriptionType>;
};

/** Autogenerated return type of MessageSubscriptionsBulkActionsUnsubscribe */
export type MessageSubscriptionsBulkActionsUnsubscribePayload = {
  __typename?: 'MessageSubscriptionsBulkActionsUnsubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MessageTask = {
  __typename?: 'MessageTask';
  assignees: Array<Scalars['ID']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedBy?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deadline?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  priority?: Maybe<Priority>;
  setAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  taskType?: Maybe<TaskType>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum MessageTypeEnum {
  DocumentNote = 'document_note',
  General = 'general',
  IncomingEmail = 'incoming_email',
  OutcomingEmail = 'outcoming_email',
  PricingWizard = 'pricing_wizard',
  RfqOfferSubmitComment = 'rfq_offer_submit_comment',
  RfqProcessComment = 'rfq_process_comment',
  Robot = 'robot',
  TaskCall = 'task_call',
  TaskChangesInDeal = 'task_changes_in_deal',
  TaskCheckStatus = 'task_check_status',
  TaskCheckingMaterialCertificate = 'task_checking_material_certificate',
  TaskCheckpoint = 'task_checkpoint',
  TaskCreateDocument = 'task_create_document',
  TaskDiscussIssue = 'task_discuss_issue',
  TaskDrawing = 'task_drawing',
  TaskGeneral = 'task_general',
  TaskInvoiceIssue = 'task_invoice_issue',
  TaskPlacingIssues = 'task_placing_issues',
  TaskPlacingRepeatDeal = 'task_placing_repeat_deal',
  TaskReplacingRequest = 'task_replacing_request',
  TaskRequestApprove = 'task_request_approve',
  TaskRevenueForecastDiff = 'task_revenue_forecast_diff',
  TaskShipping = 'task_shipping',
  TaskTalkToCustomer = 'task_talk_to_customer',
  TaskTalkToPartner = 'task_talk_to_partner',
  Whatsapp = 'whatsapp'
}

export type MessageUser = {
  __typename?: 'MessageUser';
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCurrent: Scalars['Boolean']['output'];
  isRobot?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: UserEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of MessagesExport */
export type MessagesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of MessagesExport */
export type MessagesExportPayload = {
  __typename?: 'MessagesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of MessengerAttachmentsUpload */
export type MessengerAttachmentsUploadInput = {
  attachments: Array<OmsInputsMessengerAttachmentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerAttachmentsUpload */
export type MessengerAttachmentsUploadPayload = {
  __typename?: 'MessengerAttachmentsUploadPayload';
  attachments: Array<Attachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MessengerChannelMarkRead */
export type MessengerChannelMarkReadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  lastReadMessageId?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

/** Autogenerated return type of MessengerChannelMarkRead */
export type MessengerChannelMarkReadPayload = {
  __typename?: 'MessengerChannelMarkReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  source: MessageSource;
};

/** Autogenerated input type of MessengerChannelSubscribe */
export type MessengerChannelSubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

/** Autogenerated return type of MessengerChannelSubscribe */
export type MessengerChannelSubscribePayload = {
  __typename?: 'MessengerChannelSubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  source: MessageSource;
};

/** Autogenerated input type of MessengerChannelUnsubscribe */
export type MessengerChannelUnsubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

/** Autogenerated return type of MessengerChannelUnsubscribe */
export type MessengerChannelUnsubscribePayload = {
  __typename?: 'MessengerChannelUnsubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  source: MessageSource;
};

/** Autogenerated input type of MessengerMessageBookmark */
export type MessengerMessageBookmarkInput = {
  bookmark: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageBookmark */
export type MessengerMessageBookmarkPayload = {
  __typename?: 'MessengerMessageBookmarkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageCreateFromTemplate */
export type MessengerMessageCreateFromTemplateInput = {
  attributes: OmsInputsMessengerTemplatedMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageCreateFromTemplate */
export type MessengerMessageCreateFromTemplatePayload = {
  __typename?: 'MessengerMessageCreateFromTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageCreate */
export type MessengerMessageCreateInput = {
  attributes: OmsInputsMessengerMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageCreate */
export type MessengerMessageCreatePayload = {
  __typename?: 'MessengerMessageCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageDelete */
export type MessengerMessageDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageDelete */
export type MessengerMessageDeletePayload = {
  __typename?: 'MessengerMessageDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageFilterAssignUser */
export type MessengerMessageFilterAssignUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageFilterIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageFilterAssignUser */
export type MessengerMessageFilterAssignUserPayload = {
  __typename?: 'MessengerMessageFilterAssignUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilters: Array<MessageFilter>;
};

/** Autogenerated input type of MessengerMessageFilterCreate */
export type MessengerMessageFilterCreateInput = {
  attributes: OmsInputsMessengerMessageFilterType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageFilterCreate */
export type MessengerMessageFilterCreatePayload = {
  __typename?: 'MessengerMessageFilterCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilter: MessageFilter;
};

/** Autogenerated input type of MessengerMessageFilterDelete */
export type MessengerMessageFilterDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageFilterDelete */
export type MessengerMessageFilterDeletePayload = {
  __typename?: 'MessengerMessageFilterDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MessengerMessageFilterGroupAssignUser */
export type MessengerMessageFilterGroupAssignUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageFilterGroupIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageFilterGroupAssignUser */
export type MessengerMessageFilterGroupAssignUserPayload = {
  __typename?: 'MessengerMessageFilterGroupAssignUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilterGroups: Array<MessageFilterGroup>;
};

/** Autogenerated input type of MessengerMessageFilterGroupCreate */
export type MessengerMessageFilterGroupCreateInput = {
  attributes: OmsInputsMessengerMessageFilterGroupType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageFilterGroupCreate */
export type MessengerMessageFilterGroupCreatePayload = {
  __typename?: 'MessengerMessageFilterGroupCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilterGroup: MessageFilterGroup;
};

/** Autogenerated input type of MessengerMessageFilterGroupDelete */
export type MessengerMessageFilterGroupDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageFilterGroupDelete */
export type MessengerMessageFilterGroupDeletePayload = {
  __typename?: 'MessengerMessageFilterGroupDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MessengerMessageFilterGroupUpdate */
export type MessengerMessageFilterGroupUpdateInput = {
  attributes: OmsInputsMessengerMessageFilterGroupType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageFilterGroupUpdate */
export type MessengerMessageFilterGroupUpdatePayload = {
  __typename?: 'MessengerMessageFilterGroupUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilterGroup: MessageFilterGroup;
};

/** Autogenerated input type of MessengerMessageFilterUpdate */
export type MessengerMessageFilterUpdateInput = {
  attributes: OmsInputsMessengerMessageFilterType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of MessengerMessageFilterUpdate */
export type MessengerMessageFilterUpdatePayload = {
  __typename?: 'MessengerMessageFilterUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messageFilter: MessageFilter;
};

/** Autogenerated input type of MessengerMessagePin */
export type MessengerMessagePinInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
  pinned?: InputMaybe<Scalars['Boolean']['input']>;
  pinnedDirection?: InputMaybe<PinnedDirectionsEnum>;
};

/** Autogenerated return type of MessengerMessagePin */
export type MessengerMessagePinPayload = {
  __typename?: 'MessengerMessagePinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageTaskComplete */
export type MessengerMessageTaskCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageTaskComplete */
export type MessengerMessageTaskCompletePayload = {
  __typename?: 'MessengerMessageTaskCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageTaskRevert */
export type MessengerMessageTaskRevertInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageTaskRevert */
export type MessengerMessageTaskRevertPayload = {
  __typename?: 'MessengerMessageTaskRevertPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageTaskUpdateAssignees */
export type MessengerMessageTaskUpdateAssigneesInput = {
  assignees: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageTaskUpdateAssignees */
export type MessengerMessageTaskUpdateAssigneesPayload = {
  __typename?: 'MessengerMessageTaskUpdateAssigneesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

/** Autogenerated input type of MessengerMessageUpdate */
export type MessengerMessageUpdateInput = {
  attributes: OmsInputsMessengerMessageType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['ID']['input'];
};

/** Autogenerated return type of MessengerMessageUpdate */
export type MessengerMessageUpdatePayload = {
  __typename?: 'MessengerMessageUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message: Message;
};

export type Method = BankPayment | Payout;

export type Money = MoneyInterface & {
  __typename?: 'Money';
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: CurrencyEnum;
};

/** Contains price and currency */
export type MoneyInterface = {
  amount: Scalars['String']['output'];
  amountCents: Scalars['String']['output'];
  currencyCode: CurrencyEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** NOT IMPLEMENTED IN UK */
  addLogisticInvoicePosition?: Maybe<AddLogisticInvoicePositionPayload>;
  bankPaymentsBulkApprove?: Maybe<BankPaymentsBulkApprovePayload>;
  bankPaymentsBulkRecognizeAs?: Maybe<BankPaymentsBulkRecognizeAsPayload>;
  /** NOT IMPLEMENTED IN UK */
  bankPaymentsBulkSend?: Maybe<BankPaymentsBulkSendToBankPayload>;
  /** NOT IMPLEMENTED IN UK */
  bankPaymentsBulkSendVerify?: Maybe<BankPaymentsBulkSendToBankVerifyPayload>;
  /** NOT IMPLEMENTED IN UK */
  bankPaymentsCreateExportToDatev?: Maybe<BankPaymentsCreateExportToDatevPayload>;
  bankPaymentsCreateFromInvoice?: Maybe<BankPaymentsCreateFromInvoicePayload>;
  bankPaymentsCreateFromPayout?: Maybe<BankPaymentsCreateFromPayoutPayload>;
  bankPaymentsDestroy?: Maybe<BankPaymentsDestroyPayload>;
  bankPaymentsDestroyWithPayouts?: Maybe<BankPaymentsDestroyWithPayoutsPayload>;
  bankPaymentsLinkToAccount?: Maybe<BankPaymentsLinkToAccountPayload>;
  bankPaymentsPaymentsCreate?: Maybe<BankPaymentsPaymentsCreatePayload>;
  /** NOT IMPLEMENTED IN UK */
  bankPaymentsRecreate?: Maybe<BankPaymentsRecreatePayload>;
  bankPaymentsToggleApprove?: Maybe<BankPaymentsToggleApprovePayload>;
  bankPaymentsUpdate?: Maybe<BankPaymentsUpdatePayload>;
  bankPaymentsUpdateJpmStatus?: Maybe<BankPaymentsUpdateJpmStatusPayload>;
  bankPaymentsUpdateOutlyingType?: Maybe<BankPaymentsUpdateOutlyingTypePayload>;
  bankPaymentsUpdateRegisteredAt?: Maybe<BankPaymentsUpdateRegisteredAtPayload>;
  billingAccountCalculateBalanceDetails?: Maybe<BillingAccountsCalculateBalanceDetailsPayload>;
  billingAccountsAddToPersons?: Maybe<BillingAccountsAddToPersonsPayload>;
  billingAccountsAttachedFilesDelete?: Maybe<BillingAccountsAttachedFilesDeletePayload>;
  billingAccountsCreate?: Maybe<BillingAccountsCreatePayload>;
  billingAccountsDelete?: Maybe<BillingAccountsDeletePayload>;
  billingAccountsMerge?: Maybe<BillingAccountsMergePayload>;
  billingAccountsOrganizationsUpdate?: Maybe<BillingAccountsOrganizationsPayload>;
  billingAccountsPaymentTermsUpdate?: Maybe<BillingAccountsPaymentTermsUpdatePayload>;
  billingAccountsRemoveFromPerson?: Maybe<BillingAccountsRemoveFromPersonPayload>;
  billingAccountsRequestCreditLimit?: Maybe<BillingAccountsRequestCreditLimitPayload>;
  /** NOT IMPLEMENTED IN UK */
  billingAccountsSendToCheck?: Maybe<BillingAccountSendToCheckPayload>;
  billingAccountsSetAsDefault?: Maybe<BillingAccountsSetAsDefaultPayload>;
  billingAccountsSuccessManual?: Maybe<BillingAccountsSuccessManualPayload>;
  billingAccountsToggleCreditLimitAccess?: Maybe<BillingAccountsToggleCreditLimitAccessPayload>;
  billingAccountsUpdate?: Maybe<BillingAccountsUpdatePayload>;
  billingAccountsUpdateCascade?: Maybe<BillingAccountsUpdateCascadePayload>;
  billingAccountsUpdateCreditLimit?: Maybe<BillingAccountsUpdateCreditLimitPayload>;
  bulkUpdateRfqStatus?: Maybe<PartsBulkUpdateRfqStatusesPayload>;
  claimsCreate?: Maybe<ClaimsCreatePayload>;
  claimsDestroy?: Maybe<ClaimsDestroyPayload>;
  claimsUpdate?: Maybe<ClaimsUpdatePayload>;
  /** NOT IMPLEMENTED IN UK */
  createLogisticInvoice?: Maybe<CreateLogisticInvoicePayload>;
  crmForceCreateDeal?: Maybe<CrmForceCreateDealPayload>;
  crmForceCreateOrganization?: Maybe<CrmForceCreateOrganizationPayload>;
  crmForceCreatePerson?: Maybe<CrmForceCreatePersonPayload>;
  currencyConversion?: Maybe<CurrencyConversionPayload>;
  dealBalanceRecordsExportForOrganization?: Maybe<DealBalanceRecordsExportForOrganizationPayload>;
  dealPartsCreateMeasurementTemplates?: Maybe<DealPartsCreateMeasurementTemplatesPayload>;
  dealPartsCreateQualityControlFiles?: Maybe<DealPartsCreateQualityControlFilesPayload>;
  dealsAddRepetitiveDeal?: Maybe<DealsAddRepetitiveDealPayload>;
  dealsBulkActionsUpdateProductionManager?: Maybe<DealsBulkActionsUpdateProductionManagerPayload>;
  dealsCalcPartsState?: Maybe<DealsCalcPartsStatePayload>;
  dealsCompleteProduction?: Maybe<DealsCompleteProductionPayload>;
  dealsCreateSlackChannel?: Maybe<DealsCreateSlackChannelPayload>;
  dealsRemoveRepetitiveDeal?: Maybe<DealsRemoveRepetitiveDealPayload>;
  dealsReopenProduction?: Maybe<DealsReopenProductionPayload>;
  dealsUpdate?: Maybe<DealsUpdatePayload>;
  dealsUpdatePerson?: Maybe<DealsUpdatePersonPayload>;
  dealsUpdateProductionManager?: Maybe<DealsUpdateProductionManagerPayload>;
  deliveriesConvertToShipping: DeliveriesConvertToShippingPayload;
  deliveriesConvertToStorage: DeliveriesConvertToStoragePayload;
  deliveriesUpdateComments?: Maybe<DeliveriesUpdateCommentsPayload>;
  /** NOT IMPLEMENTED IN UK */
  destroyLogisticInvoicePosition?: Maybe<DestoryLogisticInvoicePositionPayload>;
  documentNotesCreate?: Maybe<DocumentNotesCreatePayload>;
  documentNotesDelete?: Maybe<DocumentNotesDeletePayload>;
  enquiriesAuditCancelModeration?: Maybe<EnquiriesCancelModerationPayload>;
  enquiriesBulkActionsCreateRfq?: Maybe<EnquiriesBulkActionsCreateRfqPayload>;
  enquiriesBulkActionsPartsRestartWorkflow?: Maybe<EnquiriesBulkActionsPartsRestartWorkflowPayload>;
  enquiriesBulkActionsUpdateRelatedDealLots?: Maybe<EnquiriesBulkActionsUpdateRelatedDealLotsPayload>;
  enquiriesBulkNotesUpdate?: Maybe<EnquiriesBulkNotesUpdatePayload>;
  enquiriesPartsPromotionsCreateDiscount?: Maybe<CreateEnquiryPartDiscountPayload>;
  enquiriesPartsPromotionsDestroyDiscount?: Maybe<PartsPromotionsDestroyDiscountPayload>;
  enquiriesPartsPromotionsUpdateDiscount?: Maybe<PartsPromotionsUpdateDiscountPayload>;
  enquiriesPromotionsCreate?: Maybe<PromotionsCreatePayload>;
  enquiriesPromotionsCreateDiscount?: Maybe<PromotionsCreateDiscountPayload>;
  enquiriesPromotionsDestroyDiscount?: Maybe<PromotionsDestroyDiscountPayload>;
  enquiriesPromotionsUpdate?: Maybe<PromotionsUpdatePayload>;
  enquiriesPromotionsUpdateDiscount?: Maybe<PromotionsUpdateDiscountPayload>;
  enquiriesSyncForRfq?: Maybe<EnquirySyncForRfqPayload>;
  enquiriesTransferForRfq?: Maybe<EnquiryTransferForRfqPayload>;
  enquiriesUpdate?: Maybe<EnquiriesUpdatePayload>;
  enquiriesUpdateKamModeration?: Maybe<EnquiriesUpdateKamModerationPayload>;
  exportBillingAccountCreate?: Maybe<CreateBillingAccountsExportPayload>;
  exportBillingAccountGenerateCsv?: Maybe<GenerateCsvBillingAccountsExportPayload>;
  exportBillingAccountMarkRegistered?: Maybe<MarkAsRegisteredBillingAccountsExportPayload>;
  exportProviderCreate?: Maybe<CreateProvidersExportPayload>;
  exportProviderGenerateCsv?: Maybe<GenerateCsvProvidersExportPayload>;
  exportProviderMarkAsRegistered?: Maybe<MarkAsRegisteredProvidersExportPayload>;
  frameworkBatchesDelete?: Maybe<FrameworkBatchesDeletePayload>;
  frameworkBatchesGroupCreateOrUpdate?: Maybe<FrameworkBatchesGroupCreateOrUpdatePayload>;
  /** NOT IMPLEMENTED IN UK */
  incomingInvoiceOverrideDatev?: Maybe<IncomingInvoicesOverrideDatevPayload>;
  /** NOT IMPLEMENTED IN UK */
  incomingInvoiceSendToMikrojump?: Maybe<IncomingInvoicesSendToMikrojumpPayload>;
  incomingInvoicesBulkBankPaymentCreate?: Maybe<IncomingInvoicesBulkBankPaymentCreatePayload>;
  incomingInvoicesBulkDatevExport?: Maybe<IncomingInvoicesBulkDatevExportPayload>;
  incomingInvoicesBulkGeneratePayouts?: Maybe<IncomingInvoicesBulkGeneratePayoutsPayload>;
  incomingInvoicesBulkIntacctExport?: Maybe<IncomingInvoicesBulkIntacctExportPayload>;
  incomingInvoicesBulkMarkAsPaid?: Maybe<IncomingInvoicesBulkMarkAsPaidPayload>;
  incomingInvoicesReallocate?: Maybe<IncomingInvoicesReallocatePayload>;
  jobOffersShippingInstructionsUpdate?: Maybe<JobOffersShippingInstructionUpdatesPayload>;
  jobsCreateAutomatically?: Maybe<CreateAutomaticallyPayload>;
  jobsRequestAutomatically?: Maybe<RequestAutomaticallyPayload>;
  loyaltyTransactionsCreate: LoyaltyTransactionsCreatePayload;
  masterShippingsCreate: MasterShippingsCreatePayload;
  masterShippingsCreateFromShipping: MasterShippingsCreateFromShippingPayload;
  masterShippingsShipmentOrderCreateDhlOrder?: Maybe<MasterShippingsShipmentOrderCreateDhlOrderPayload>;
  /** Fetch available options for master shipping */
  masterShippingsShipmentOrderRequestShippingOptions?: Maybe<MasterShippingsShipmentOrderRequestShippingOptionsPayload>;
  masterShippingsUpdate: MasterShippingsUpdatePayload;
  masterShippingsUpdateTracking?: Maybe<MasterShippingsUpdateTrackingPayload>;
  messageSubscriptionsBulkActionsMarkAllRead?: Maybe<MessageSubscriptionsBulkActionsMarkAllReadPayload>;
  messageSubscriptionsBulkActionsSubscribe?: Maybe<MessageSubscriptionsBulkActionsSubscribePayload>;
  messageSubscriptionsBulkActionsUnsubscribe?: Maybe<MessageSubscriptionsBulkActionsUnsubscribePayload>;
  messengerAttachmentsUpload?: Maybe<MessengerAttachmentsUploadPayload>;
  messengerMessageBookmark?: Maybe<MessengerMessageBookmarkPayload>;
  messengerMessageCreate?: Maybe<MessengerMessageCreatePayload>;
  messengerMessageCreateFromTemplate?: Maybe<MessengerMessageCreateFromTemplatePayload>;
  messengerMessageDelete?: Maybe<MessengerMessageDeletePayload>;
  messengerMessageFilterAssignUser?: Maybe<MessengerMessageFilterAssignUserPayload>;
  messengerMessageFilterCreate?: Maybe<MessengerMessageFilterCreatePayload>;
  messengerMessageFilterDelete?: Maybe<MessengerMessageFilterDeletePayload>;
  messengerMessageFilterGroupAssignUser?: Maybe<MessengerMessageFilterGroupAssignUserPayload>;
  messengerMessageFilterGroupCreate?: Maybe<MessengerMessageFilterGroupCreatePayload>;
  messengerMessageFilterGroupDelete?: Maybe<MessengerMessageFilterGroupDeletePayload>;
  messengerMessageFilterGroupUpdate?: Maybe<MessengerMessageFilterGroupUpdatePayload>;
  messengerMessageFilterUpdate?: Maybe<MessengerMessageFilterUpdatePayload>;
  messengerMessagePin?: Maybe<MessengerMessagePinPayload>;
  messengerMessageTaskComplete?: Maybe<MessengerMessageTaskCompletePayload>;
  messengerMessageTaskRevert?: Maybe<MessengerMessageTaskRevertPayload>;
  messengerMessageTaskUpdateAssignees?: Maybe<MessengerMessageTaskUpdateAssigneesPayload>;
  messengerMessageUpdate?: Maybe<MessengerMessageUpdatePayload>;
  messengerSourceMarkRead?: Maybe<MessengerChannelMarkReadPayload>;
  messengerSourceSubscribe?: Maybe<MessengerChannelSubscribePayload>;
  messengerSourceUnsubscribe?: Maybe<MessengerChannelUnsubscribePayload>;
  organizationBalanceRecordsForceRecalculate?: Maybe<OrganizationBalanceRecordsForceRecalculatePayload>;
  organizationsBulkActionsUpdateProductionManager?: Maybe<OrganizationsBulkActionsUpdateProductionManagerPayload>;
  organizationsCreate?: Maybe<OrganizationsCreatePayload>;
  organizationsMerge?: Maybe<OrganizationsMergePayload>;
  organizationsReactivationTransfer?: Maybe<OrganizationsReactivationTransferPayload>;
  organizationsRequestDescription?: Maybe<OrganizationsRequestDescriptionPayload>;
  organizationsTransferToRegion?: Maybe<OrganizationTransferToRegionPayload>;
  organizationsUpdate?: Maybe<OrganizationsUpdatePayload>;
  organizationsUpdateProductionManager?: Maybe<OrganizationsUpdateProductionManagerPayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoiceExportZip?: Maybe<OutcomingInvoiceExportZipPayload>;
  outcomingInvoiceExportZipIntacct?: Maybe<IntacctOutcomingInvoiceExportZipMutationPayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoiceOverrideDatev?: Maybe<OutcomingInvoicesOverrideDatevPayload>;
  outcomingInvoiceOverridePaymentTermsDate?: Maybe<OutcomingInvoicesOverridePaymentTermsDatePayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoiceRollbackMikrojumpSync?: Maybe<OutcomingInvoicesRollbackMikrojumpSyncPayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoiceSendToMikrojump?: Maybe<OutcomingInvoicesSendToMikrojumpPayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoicesBulkDatevExport?: Maybe<OutcomingInvoicesBulkDatevExportPayload>;
  outcomingInvoicesBulkIntacctExport?: Maybe<OutcomingInvoicesBulkIntacctExportPayload>;
  outcomingInvoicesBulkMarkAsPaid?: Maybe<OutcomingInvoicesBulkMarkAsPaidPayload>;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoicesReject?: Maybe<OutcomingInvoicesRejectPayload>;
  packageTrackingEventsCreate?: Maybe<PackageTrackingEventsCreatePayload>;
  packageTrackingEventsDelete?: Maybe<PackageTrackingEventsDeletePayload>;
  packageTrackingEventsUpdate?: Maybe<PackageTrackingEventsUpdatePayload>;
  partStateCorrectionsCreate?: Maybe<PartStateCorrectionsCreatePayload>;
  partStateCorrectionsDestroy?: Maybe<PartStateCorrectionsDestroyPayload>;
  partStateCorrectionsUpdate?: Maybe<PartStateCorrectionsUpdatePayload>;
  /** NOT IMPLEMENTED IN UK */
  paymentOutcomingInvoiceRelationsCreateExportToDatev?: Maybe<PaymentOutcomingInvoiceRelationsCreateExportToDatevPayload>;
  /** NOT IMPLEMENTED IN UK */
  paymentOutcomingInvoiceRelationsUpdateRegisteredAt?: Maybe<PaymentOutcomingInvoiceRelationsUpdateRegisteredAtPayload>;
  /** NOT IMPLEMENTED IN UK */
  paymentsCreateExportToDatev?: Maybe<PaymentsCreateExportToDatevPayload>;
  paymentsTransferBetweenDeals?: Maybe<PaymentsTransferBetweenDealsPayload>;
  paymentsUpdateMethod?: Maybe<PaymetsUpdateMethodPayload>;
  paymentsUpdateRegisteredAt?: Maybe<PaymentsUpdateRegisteredAtPayload>;
  payoutsBankPaymentApproveAndCreate?: Maybe<PayoutBankPaymentApproveAndCreatePayload>;
  payoutsBulkBankPaymentApproveAndCreate?: Maybe<PayoutsBulkBankPaymentApproveAndCreatePayload>;
  payoutsBulkSetAsPaid?: Maybe<PayoutsBulkSetAsPaidPayload>;
  payoutsCancel?: Maybe<PayoutsCancelPayload>;
  payoutsComplete?: Maybe<PayoutsCompletePayload>;
  payoutsCompleteAgainstAnother?: Maybe<PayoutsCompleteAgainstAnotherPayload>;
  payoutsCreate?: Maybe<PayoutsCreatePayload>;
  payoutsDestroy?: Maybe<PayoutsDestroyPayload>;
  payoutsUpdate?: Maybe<PayoutsUpdatePayload>;
  payoutsUpdateMethod?: Maybe<PayoutsUpdateMethodPayload>;
  personsCreate?: Maybe<PersonsCreatePayload>;
  personsImpersonate?: Maybe<PersonsImpersonatePayload>;
  personsMerge?: Maybe<PersonsMergePayload>;
  personsOrganizationChange?: Maybe<PersonsOrganizationChangePayload>;
  personsReactivationTransfer?: Maybe<PersonsReactivationTransferPayload>;
  personsSyncContact?: Maybe<PersonsSyncContactPayload>;
  personsUpdate?: Maybe<PersonsUpdatePayload>;
  personsUpdateDefaultAddress?: Maybe<PersonsUpateDefaultAddressPayload>;
  personsVerifyWhatsappNumber?: Maybe<PersonsVerifyWhatsAppNumberPayload>;
  productionEventsCreate?: Maybe<ProductionEventsCreatePayload>;
  productionEventsDelete?: Maybe<ProductionEventsDeletePayload>;
  productionEventsUpdate?: Maybe<ProductionEventsUpdatePayload>;
  providerOrdersBalanceRecordsExportForProvider?: Maybe<ProviderOrdersBalanceRecordsExportForProviderPayload>;
  providerOrdersCreate?: Maybe<ProviderOrdersCreatePayload>;
  providerOrdersDestroy?: Maybe<ProviderOrdersDestroyPayload>;
  providerOrdersShippingInstructionsUpdate?: Maybe<ProviderOrdersShippingInstructionUpdatesPayload>;
  providerOrdersSplit?: Maybe<ProviderOrdersSplitPayload>;
  providerOrdersUpdate?: Maybe<ProviderOrdersUpdatePayload>;
  providersChangePrimary?: Maybe<ChangePrimaryPayload>;
  providersContactsCreate?: Maybe<ContactsCreatePayload>;
  providersContactsDelete?: Maybe<ContactsDeletePayload>;
  providersContactsMove?: Maybe<ContactsMovePayload>;
  providersContactsUpdate?: Maybe<ContactsUpdatePayload>;
  providersCreate?: Maybe<ProvidersCreatePayload>;
  providersDelete?: Maybe<ProvidersDeletePayload>;
  providersUpdate?: Maybe<ProvidersUpdatePayload>;
  providersUpdateTag?: Maybe<UpdateTagPayload>;
  publicDomainsCreate?: Maybe<PublicDomainsCreatePayload>;
  qualityControlsCreate?: Maybe<QualityControlsCreatePayload>;
  qualityControlsGeneratePdfReport: QualityControlsGeneratePdfReportPayload;
  qualityControlsPositionDelete?: Maybe<QualityControlsPositionsDeletePayload>;
  qualityControlsPositionUpdate?: Maybe<QualityControlsPositionsUpdatePayload>;
  qualityControlsPositionsUpdateMeasurements?: Maybe<QualityControlsPositionsUpdateMeasurementsPayload>;
  qualityControlsRepack?: Maybe<QualityControlsRepackPayload>;
  qualityControlsUpdate?: Maybe<QualityControlsUpdatePayload>;
  rfqsCancel?: Maybe<RfqsCancelPayload>;
  rfqsClose?: Maybe<RfqsClosePayload>;
  rfqsDelete?: Maybe<RfqsDeletePayload>;
  rfqsOffersApply?: Maybe<RfqsOffersApplyPayload>;
  rfqsOffersDelete?: Maybe<RfqsOffersDeletePayload>;
  rfqsOffersFindOrCreate?: Maybe<RfqsOffersFindOrCreatePayload>;
  rfqsOffersPricesDelete?: Maybe<RfqsOffersPricesDeletePayload>;
  rfqsOffersUpdate?: Maybe<RfqsOffersUpdatePayload>;
  rfqsPositionsDelete?: Maybe<RfqsPositionsDeletePayload>;
  rfqsPublish?: Maybe<RfqsPublishPayload>;
  rfqsReactivate?: Maybe<RfqsReactivatePayload>;
  rfqsUpdate?: Maybe<RfqsUpdatePayload>;
  rolesUpdatePermissions?: Maybe<RolesUpdatePermissionsPayload>;
  shipmentOrderCreateDhlOrder?: Maybe<ShipmentOrderCreateDhlOrderPayload>;
  shipmentOrderCreateEurosenderOrder?: Maybe<ShipmentOrderCreateEurosenderOrderPayload>;
  shipmentOrderCreateJumingoOrder?: Maybe<ShipmentOrderCreateJumingoOrderPayload>;
  shipmentOrderCreatePacklinkOrder?: Maybe<ShipmentOrderCreatePacklinkOrderPayload>;
  shipmentOrderCreateUpsOrder?: Maybe<ShipmentOrderCreateUpsOrderPayload>;
  shipmentOrderCreateYurticiOrder?: Maybe<ShipmentOrderCreateYurticiOrderPayload>;
  /** Fetch available options for shipping */
  shipmentOrderRequestShippingOptions?: Maybe<ShipmentOrderRequestShippingOptionsPayload>;
  shippingAddressesCreate?: Maybe<ShippingAddressesCreatePayload>;
  shippingAddressesDelete?: Maybe<ShippingAddressesDeletePayload>;
  shippingAddressesForOrgPersonsCreate?: Maybe<ShippingAddressesForOrgPersonsCreatePayload>;
  shippingAddressesUpdate?: Maybe<ShippingAddressesUpdatePayload>;
  shippingAddressesUpdateCascade?: Maybe<ShippingAddressesUpdateCascadePayload>;
  shippingCalculationsApplyCost: ShippingCalculationsApplyCostPayload;
  shippingCalculationsCreate: ShippingCalculationsCreatePayload;
  shippingCalculationsDelete: ShippingCalculationsDeletePayload;
  shippingsAddToMasterShipping: ShippingsAddToMasterShippingPayload;
  shippingsBulkAddToMasterShipping: ShippingsBulkAddToMasterShippingPayload;
  shippingsBulkMoveToState?: Maybe<ShippingsBulkMoveToStatePayload>;
  shippingsBulkRemoveFromMasterShipping: ShippingsBulkRemoveFromMasterShippingPayload;
  shippingsBulkUpdateTracking?: Maybe<ShippingsBulkUpdateTrackingPayload>;
  shippingsCreate: ShippingsCreatePayload;
  shippingsUpdate: ShippingsFormUpdatePayload;
  shippingsUpdateTracking?: Maybe<ShippingsUpdateTrackingPayload>;
  /** NOT IMPLEMENTED IN UK */
  updateLogisticInvoice?: Maybe<UpdateLogisticInvoicePayload>;
  /** NOT IMPLEMENTED IN UK */
  updateLogisticInvoicePosition?: Maybe<UpdateLogisticInvoicePositionPayload>;
  updateRfqFields?: Maybe<EnquiryPartsUpdateRfqFieldsPayload>;
  usersAssignRoles?: Maybe<UsersAssignRolesPayload>;
  usersEmailLogin?: Maybe<EmailLoginPayload>;
  usersUpdatePermissions?: Maybe<UsersUpdatePermissionsPayload>;
  usersUpdateProfile?: Maybe<UsersUpdateProfilePayload>;
  workspacePresetsAssignDefaultPreset?: Maybe<WorkspacePresetsAssignDefaultPresetPayload>;
  workspacePresetsCreate?: Maybe<WorkspacePresetsCreatePayload>;
  workspacePresetsDelete?: Maybe<WorkspacePresetsDeletePayload>;
  workspacePresetsRemoveDefaultPreset?: Maybe<WorkspacePresetsRemoveDefaultPresetPayload>;
  workspacePresetsUpdate?: Maybe<WorkspacePresetsUpdatePayload>;
  workspacesBankPaymentsDraftExport?: Maybe<BankPaymentsDraftExportPayload>;
  workspacesBankPaymentsExport?: Maybe<BankPaymentsExportPayload>;
  workspacesBillingAccountsExport?: Maybe<BillingAccountsExportPayload>;
  workspacesClaimsExport?: Maybe<ClaimsExportPayload>;
  workspacesDealsExport?: Maybe<DealsExportPayload>;
  workspacesDraftingDealPartsExport?: Maybe<DraftingDealPartsExportPayload>;
  workspacesDraftingEnquiryPartsExport?: Maybe<DraftingEnquiryPartsExportPayload>;
  workspacesEnquiriesExport?: Maybe<EnquiriesExportPayload>;
  workspacesEnquiryPromotionsExport?: Maybe<EnquiryPromotionsExportPayload>;
  workspacesFrameworkBatchesExport?: Maybe<FrameworkBatchesExportPayload>;
  workspacesIncomingInvoicesExport?: Maybe<IncomingInvoicesExportPayload>;
  workspacesJobOffersExport?: Maybe<JobOffersExportPayload>;
  workspacesJobsExport?: Maybe<JobsExportPayload>;
  workspacesMessagesExport?: Maybe<MessagesExportPayload>;
  workspacesOrganizationBalancesExport?: Maybe<OrganizationBalancesExportPayload>;
  workspacesOrganizationsExport?: Maybe<OrganizationsExportPayload>;
  workspacesOutcomingInvoicesExport?: Maybe<OutcomingInvoicesExportPayload>;
  workspacesPartnerBalancesExport?: Maybe<PartnerBalancesExportPayload>;
  workspacesPaymentsExport?: Maybe<PaymentsExportPayload>;
  workspacesPayoutsExport?: Maybe<PayoutsExportPayload>;
  workspacesPersonsExport?: Maybe<PersonsExportPayload>;
  workspacesProviderOrdersExport?: Maybe<ProviderOrdersExportPayload>;
  workspacesProvidersExport?: Maybe<ProvidersExportPayload>;
  workspacesQualityControlsExport?: Maybe<QualityControlsExportPayload>;
  workspacesRefundsExport?: Maybe<RefundsExportPayload>;
  workspacesRfqOffersExport?: Maybe<RfqOffersExportPayload>;
  workspacesRfqPartnersExport?: Maybe<RfqPartnersExportPayload>;
  workspacesRfqsExport?: Maybe<RfqsExportPayload>;
  workspacesShippingAddressesExport?: Maybe<ShippingAddressesExportPayload>;
  workspacesShippingsExport?: Maybe<ShippingsExportPayload>;
  workspacesStoragesExport?: Maybe<StoragesExportPayload>;
  workspacesSubscriptionsExport?: Maybe<SubscriptionsExportPayload>;
  workspacesTasksExport?: Maybe<TasksExportPayload>;
  workspacesUsersExport?: Maybe<UsersExportPayload>;
};


export type MutationAddLogisticInvoicePositionArgs = {
  input: AddLogisticInvoicePositionInput;
};


export type MutationBankPaymentsBulkApproveArgs = {
  input: BankPaymentsBulkApproveInput;
};


export type MutationBankPaymentsBulkRecognizeAsArgs = {
  input: BankPaymentsBulkRecognizeAsInput;
};


export type MutationBankPaymentsBulkSendArgs = {
  input: BankPaymentsBulkSendToBankInput;
};


export type MutationBankPaymentsBulkSendVerifyArgs = {
  input: BankPaymentsBulkSendToBankVerifyInput;
};


export type MutationBankPaymentsCreateExportToDatevArgs = {
  input: BankPaymentsCreateExportToDatevInput;
};


export type MutationBankPaymentsCreateFromInvoiceArgs = {
  input: BankPaymentsCreateFromInvoiceInput;
};


export type MutationBankPaymentsCreateFromPayoutArgs = {
  input: BankPaymentsCreateFromPayoutInput;
};


export type MutationBankPaymentsDestroyArgs = {
  input: BankPaymentsDestroyInput;
};


export type MutationBankPaymentsDestroyWithPayoutsArgs = {
  input: BankPaymentsDestroyWithPayoutsInput;
};


export type MutationBankPaymentsLinkToAccountArgs = {
  input: BankPaymentsLinkToAccountInput;
};


export type MutationBankPaymentsPaymentsCreateArgs = {
  input: BankPaymentsPaymentsCreateInput;
};


export type MutationBankPaymentsRecreateArgs = {
  input: BankPaymentsRecreateInput;
};


export type MutationBankPaymentsToggleApproveArgs = {
  input: BankPaymentsToggleApproveInput;
};


export type MutationBankPaymentsUpdateArgs = {
  input: BankPaymentsUpdateInput;
};


export type MutationBankPaymentsUpdateJpmStatusArgs = {
  input: BankPaymentsUpdateJpmStatusInput;
};


export type MutationBankPaymentsUpdateOutlyingTypeArgs = {
  input: BankPaymentsUpdateOutlyingTypeInput;
};


export type MutationBankPaymentsUpdateRegisteredAtArgs = {
  input: BankPaymentsUpdateRegisteredAtInput;
};


export type MutationBillingAccountCalculateBalanceDetailsArgs = {
  input: BillingAccountsCalculateBalanceDetailsInput;
};


export type MutationBillingAccountsAddToPersonsArgs = {
  input: BillingAccountsAddToPersonsInput;
};


export type MutationBillingAccountsAttachedFilesDeleteArgs = {
  input: BillingAccountsAttachedFilesDeleteInput;
};


export type MutationBillingAccountsCreateArgs = {
  input: BillingAccountsCreateInput;
};


export type MutationBillingAccountsDeleteArgs = {
  input: BillingAccountsDeleteInput;
};


export type MutationBillingAccountsMergeArgs = {
  input: BillingAccountsMergeInput;
};


export type MutationBillingAccountsOrganizationsUpdateArgs = {
  input: BillingAccountsOrganizationsInput;
};


export type MutationBillingAccountsPaymentTermsUpdateArgs = {
  input: BillingAccountsPaymentTermsUpdateInput;
};


export type MutationBillingAccountsRemoveFromPersonArgs = {
  input: BillingAccountsRemoveFromPersonInput;
};


export type MutationBillingAccountsRequestCreditLimitArgs = {
  input: BillingAccountsRequestCreditLimitInput;
};


export type MutationBillingAccountsSendToCheckArgs = {
  input: BillingAccountSendToCheckInput;
};


export type MutationBillingAccountsSetAsDefaultArgs = {
  input: BillingAccountsSetAsDefaultInput;
};


export type MutationBillingAccountsSuccessManualArgs = {
  input: BillingAccountsSuccessManualInput;
};


export type MutationBillingAccountsToggleCreditLimitAccessArgs = {
  input: BillingAccountsToggleCreditLimitAccessInput;
};


export type MutationBillingAccountsUpdateArgs = {
  input: BillingAccountsUpdateInput;
};


export type MutationBillingAccountsUpdateCascadeArgs = {
  input: BillingAccountsUpdateCascadeInput;
};


export type MutationBillingAccountsUpdateCreditLimitArgs = {
  input: BillingAccountsUpdateCreditLimitInput;
};


export type MutationBulkUpdateRfqStatusArgs = {
  input: PartsBulkUpdateRfqStatusesInput;
};


export type MutationClaimsCreateArgs = {
  input: ClaimsCreateInput;
};


export type MutationClaimsDestroyArgs = {
  input: ClaimsDestroyInput;
};


export type MutationClaimsUpdateArgs = {
  input: ClaimsUpdateInput;
};


export type MutationCreateLogisticInvoiceArgs = {
  input: CreateLogisticInvoiceInput;
};


export type MutationCrmForceCreateDealArgs = {
  input: CrmForceCreateDealInput;
};


export type MutationCrmForceCreateOrganizationArgs = {
  input: CrmForceCreateOrganizationInput;
};


export type MutationCrmForceCreatePersonArgs = {
  input: CrmForceCreatePersonInput;
};


export type MutationCurrencyConversionArgs = {
  input: CurrencyConversionInput;
};


export type MutationDealBalanceRecordsExportForOrganizationArgs = {
  input: DealBalanceRecordsExportForOrganizationInput;
};


export type MutationDealPartsCreateMeasurementTemplatesArgs = {
  input: DealPartsCreateMeasurementTemplatesInput;
};


export type MutationDealPartsCreateQualityControlFilesArgs = {
  input: DealPartsCreateQualityControlFilesInput;
};


export type MutationDealsAddRepetitiveDealArgs = {
  input: DealsAddRepetitiveDealInput;
};


export type MutationDealsBulkActionsUpdateProductionManagerArgs = {
  input: DealsBulkActionsUpdateProductionManagerInput;
};


export type MutationDealsCalcPartsStateArgs = {
  input: DealsCalcPartsStateInput;
};


export type MutationDealsCompleteProductionArgs = {
  input: DealsCompleteProductionInput;
};


export type MutationDealsCreateSlackChannelArgs = {
  input: DealsCreateSlackChannelInput;
};


export type MutationDealsRemoveRepetitiveDealArgs = {
  input: DealsRemoveRepetitiveDealInput;
};


export type MutationDealsReopenProductionArgs = {
  input: DealsReopenProductionInput;
};


export type MutationDealsUpdateArgs = {
  input: DealsUpdateInput;
};


export type MutationDealsUpdatePersonArgs = {
  input: DealsUpdatePersonInput;
};


export type MutationDealsUpdateProductionManagerArgs = {
  input: DealsUpdateProductionManagerInput;
};


export type MutationDeliveriesConvertToShippingArgs = {
  input: DeliveriesConvertToShippingInput;
};


export type MutationDeliveriesConvertToStorageArgs = {
  input: DeliveriesConvertToStorageInput;
};


export type MutationDeliveriesUpdateCommentsArgs = {
  input: DeliveriesUpdateCommentsInput;
};


export type MutationDestroyLogisticInvoicePositionArgs = {
  input: DestoryLogisticInvoicePositionInput;
};


export type MutationDocumentNotesCreateArgs = {
  input: DocumentNotesCreateInput;
};


export type MutationDocumentNotesDeleteArgs = {
  input: DocumentNotesDeleteInput;
};


export type MutationEnquiriesAuditCancelModerationArgs = {
  input: EnquiriesCancelModerationInput;
};


export type MutationEnquiriesBulkActionsCreateRfqArgs = {
  input: EnquiriesBulkActionsCreateRfqInput;
};


export type MutationEnquiriesBulkActionsPartsRestartWorkflowArgs = {
  input: EnquiriesBulkActionsPartsRestartWorkflowInput;
};


export type MutationEnquiriesBulkActionsUpdateRelatedDealLotsArgs = {
  input: EnquiriesBulkActionsUpdateRelatedDealLotsInput;
};


export type MutationEnquiriesBulkNotesUpdateArgs = {
  input: EnquiriesBulkNotesUpdateInput;
};


export type MutationEnquiriesPartsPromotionsCreateDiscountArgs = {
  input: CreateEnquiryPartDiscountInput;
};


export type MutationEnquiriesPartsPromotionsDestroyDiscountArgs = {
  input: PartsPromotionsDestroyDiscountInput;
};


export type MutationEnquiriesPartsPromotionsUpdateDiscountArgs = {
  input: PartsPromotionsUpdateDiscountInput;
};


export type MutationEnquiriesPromotionsCreateArgs = {
  input: PromotionsCreateInput;
};


export type MutationEnquiriesPromotionsCreateDiscountArgs = {
  input: PromotionsCreateDiscountInput;
};


export type MutationEnquiriesPromotionsDestroyDiscountArgs = {
  input: PromotionsDestroyDiscountInput;
};


export type MutationEnquiriesPromotionsUpdateArgs = {
  input: PromotionsUpdateInput;
};


export type MutationEnquiriesPromotionsUpdateDiscountArgs = {
  input: PromotionsUpdateDiscountInput;
};


export type MutationEnquiriesSyncForRfqArgs = {
  input: EnquirySyncForRfqInput;
};


export type MutationEnquiriesTransferForRfqArgs = {
  input: EnquiryTransferForRfqInput;
};


export type MutationEnquiriesUpdateArgs = {
  input: EnquiriesUpdateInput;
};


export type MutationEnquiriesUpdateKamModerationArgs = {
  input: EnquiriesUpdateKamModerationInput;
};


export type MutationExportBillingAccountCreateArgs = {
  input: CreateBillingAccountsExportInput;
};


export type MutationExportBillingAccountGenerateCsvArgs = {
  input: GenerateCsvBillingAccountsExportInput;
};


export type MutationExportBillingAccountMarkRegisteredArgs = {
  input: MarkAsRegisteredBillingAccountsExportInput;
};


export type MutationExportProviderCreateArgs = {
  input: CreateProvidersExportInput;
};


export type MutationExportProviderGenerateCsvArgs = {
  input: GenerateCsvProvidersExportInput;
};


export type MutationExportProviderMarkAsRegisteredArgs = {
  input: MarkAsRegisteredProvidersExportInput;
};


export type MutationFrameworkBatchesDeleteArgs = {
  input: FrameworkBatchesDeleteInput;
};


export type MutationFrameworkBatchesGroupCreateOrUpdateArgs = {
  input: FrameworkBatchesGroupCreateOrUpdateInput;
};


export type MutationIncomingInvoiceOverrideDatevArgs = {
  input: IncomingInvoicesOverrideDatevInput;
};


export type MutationIncomingInvoiceSendToMikrojumpArgs = {
  input: IncomingInvoicesSendToMikrojumpInput;
};


export type MutationIncomingInvoicesBulkBankPaymentCreateArgs = {
  input: IncomingInvoicesBulkBankPaymentCreateInput;
};


export type MutationIncomingInvoicesBulkDatevExportArgs = {
  input: IncomingInvoicesBulkDatevExportInput;
};


export type MutationIncomingInvoicesBulkGeneratePayoutsArgs = {
  input: IncomingInvoicesBulkGeneratePayoutsInput;
};


export type MutationIncomingInvoicesBulkIntacctExportArgs = {
  input: IncomingInvoicesBulkIntacctExportInput;
};


export type MutationIncomingInvoicesBulkMarkAsPaidArgs = {
  input: IncomingInvoicesBulkMarkAsPaidInput;
};


export type MutationIncomingInvoicesReallocateArgs = {
  input: IncomingInvoicesReallocateInput;
};


export type MutationJobOffersShippingInstructionsUpdateArgs = {
  input: JobOffersShippingInstructionUpdatesInput;
};


export type MutationJobsCreateAutomaticallyArgs = {
  input: CreateAutomaticallyInput;
};


export type MutationJobsRequestAutomaticallyArgs = {
  input: RequestAutomaticallyInput;
};


export type MutationLoyaltyTransactionsCreateArgs = {
  input: LoyaltyTransactionsCreateInput;
};


export type MutationMasterShippingsCreateArgs = {
  input: MasterShippingsCreateInput;
};


export type MutationMasterShippingsCreateFromShippingArgs = {
  input: MasterShippingsCreateFromShippingInput;
};


export type MutationMasterShippingsShipmentOrderCreateDhlOrderArgs = {
  input: MasterShippingsShipmentOrderCreateDhlOrderInput;
};


export type MutationMasterShippingsShipmentOrderRequestShippingOptionsArgs = {
  input: MasterShippingsShipmentOrderRequestShippingOptionsInput;
};


export type MutationMasterShippingsUpdateArgs = {
  input: MasterShippingsUpdateInput;
};


export type MutationMasterShippingsUpdateTrackingArgs = {
  input: MasterShippingsUpdateTrackingInput;
};


export type MutationMessageSubscriptionsBulkActionsMarkAllReadArgs = {
  input: MessageSubscriptionsBulkActionsMarkAllReadInput;
};


export type MutationMessageSubscriptionsBulkActionsSubscribeArgs = {
  input: MessageSubscriptionsBulkActionsSubscribeInput;
};


export type MutationMessageSubscriptionsBulkActionsUnsubscribeArgs = {
  input: MessageSubscriptionsBulkActionsUnsubscribeInput;
};


export type MutationMessengerAttachmentsUploadArgs = {
  input: MessengerAttachmentsUploadInput;
};


export type MutationMessengerMessageBookmarkArgs = {
  input: MessengerMessageBookmarkInput;
};


export type MutationMessengerMessageCreateArgs = {
  input: MessengerMessageCreateInput;
};


export type MutationMessengerMessageCreateFromTemplateArgs = {
  input: MessengerMessageCreateFromTemplateInput;
};


export type MutationMessengerMessageDeleteArgs = {
  input: MessengerMessageDeleteInput;
};


export type MutationMessengerMessageFilterAssignUserArgs = {
  input: MessengerMessageFilterAssignUserInput;
};


export type MutationMessengerMessageFilterCreateArgs = {
  input: MessengerMessageFilterCreateInput;
};


export type MutationMessengerMessageFilterDeleteArgs = {
  input: MessengerMessageFilterDeleteInput;
};


export type MutationMessengerMessageFilterGroupAssignUserArgs = {
  input: MessengerMessageFilterGroupAssignUserInput;
};


export type MutationMessengerMessageFilterGroupCreateArgs = {
  input: MessengerMessageFilterGroupCreateInput;
};


export type MutationMessengerMessageFilterGroupDeleteArgs = {
  input: MessengerMessageFilterGroupDeleteInput;
};


export type MutationMessengerMessageFilterGroupUpdateArgs = {
  input: MessengerMessageFilterGroupUpdateInput;
};


export type MutationMessengerMessageFilterUpdateArgs = {
  input: MessengerMessageFilterUpdateInput;
};


export type MutationMessengerMessagePinArgs = {
  input: MessengerMessagePinInput;
};


export type MutationMessengerMessageTaskCompleteArgs = {
  input: MessengerMessageTaskCompleteInput;
};


export type MutationMessengerMessageTaskRevertArgs = {
  input: MessengerMessageTaskRevertInput;
};


export type MutationMessengerMessageTaskUpdateAssigneesArgs = {
  input: MessengerMessageTaskUpdateAssigneesInput;
};


export type MutationMessengerMessageUpdateArgs = {
  input: MessengerMessageUpdateInput;
};


export type MutationMessengerSourceMarkReadArgs = {
  input: MessengerChannelMarkReadInput;
};


export type MutationMessengerSourceSubscribeArgs = {
  input: MessengerChannelSubscribeInput;
};


export type MutationMessengerSourceUnsubscribeArgs = {
  input: MessengerChannelUnsubscribeInput;
};


export type MutationOrganizationBalanceRecordsForceRecalculateArgs = {
  input: OrganizationBalanceRecordsForceRecalculateInput;
};


export type MutationOrganizationsBulkActionsUpdateProductionManagerArgs = {
  input: OrganizationsBulkActionsUpdateProductionManagerInput;
};


export type MutationOrganizationsCreateArgs = {
  input: OrganizationsCreateInput;
};


export type MutationOrganizationsMergeArgs = {
  input: OrganizationsMergeInput;
};


export type MutationOrganizationsReactivationTransferArgs = {
  input: OrganizationsReactivationTransferInput;
};


export type MutationOrganizationsRequestDescriptionArgs = {
  input: OrganizationsRequestDescriptionInput;
};


export type MutationOrganizationsTransferToRegionArgs = {
  input: OrganizationTransferToRegionInput;
};


export type MutationOrganizationsUpdateArgs = {
  input: OrganizationsUpdateInput;
};


export type MutationOrganizationsUpdateProductionManagerArgs = {
  input: OrganizationsUpdateProductionManagerInput;
};


export type MutationOutcomingInvoiceExportZipArgs = {
  input: OutcomingInvoiceExportZipInput;
};


export type MutationOutcomingInvoiceExportZipIntacctArgs = {
  input: IntacctOutcomingInvoiceExportZipMutationInput;
};


export type MutationOutcomingInvoiceOverrideDatevArgs = {
  input: OutcomingInvoicesOverrideDatevInput;
};


export type MutationOutcomingInvoiceOverridePaymentTermsDateArgs = {
  input: OutcomingInvoicesOverridePaymentTermsDateInput;
};


export type MutationOutcomingInvoiceRollbackMikrojumpSyncArgs = {
  input: OutcomingInvoicesRollbackMikrojumpSyncInput;
};


export type MutationOutcomingInvoiceSendToMikrojumpArgs = {
  input: OutcomingInvoicesSendToMikrojumpInput;
};


export type MutationOutcomingInvoicesBulkDatevExportArgs = {
  input: OutcomingInvoicesBulkDatevExportInput;
};


export type MutationOutcomingInvoicesBulkIntacctExportArgs = {
  input: OutcomingInvoicesBulkIntacctExportInput;
};


export type MutationOutcomingInvoicesBulkMarkAsPaidArgs = {
  input: OutcomingInvoicesBulkMarkAsPaidInput;
};


export type MutationOutcomingInvoicesRejectArgs = {
  input: OutcomingInvoicesRejectInput;
};


export type MutationPackageTrackingEventsCreateArgs = {
  input: PackageTrackingEventsCreateInput;
};


export type MutationPackageTrackingEventsDeleteArgs = {
  input: PackageTrackingEventsDeleteInput;
};


export type MutationPackageTrackingEventsUpdateArgs = {
  input: PackageTrackingEventsUpdateInput;
};


export type MutationPartStateCorrectionsCreateArgs = {
  input: PartStateCorrectionsCreateInput;
};


export type MutationPartStateCorrectionsDestroyArgs = {
  input: PartStateCorrectionsDestroyInput;
};


export type MutationPartStateCorrectionsUpdateArgs = {
  input: PartStateCorrectionsUpdateInput;
};


export type MutationPaymentOutcomingInvoiceRelationsCreateExportToDatevArgs = {
  input: PaymentOutcomingInvoiceRelationsCreateExportToDatevInput;
};


export type MutationPaymentOutcomingInvoiceRelationsUpdateRegisteredAtArgs = {
  input: PaymentOutcomingInvoiceRelationsUpdateRegisteredAtInput;
};


export type MutationPaymentsCreateExportToDatevArgs = {
  input: PaymentsCreateExportToDatevInput;
};


export type MutationPaymentsTransferBetweenDealsArgs = {
  input: PaymentsTransferBetweenDealsInput;
};


export type MutationPaymentsUpdateMethodArgs = {
  input: PaymetsUpdateMethodInput;
};


export type MutationPaymentsUpdateRegisteredAtArgs = {
  input: PaymentsUpdateRegisteredAtInput;
};


export type MutationPayoutsBankPaymentApproveAndCreateArgs = {
  input: PayoutBankPaymentApproveAndCreateInput;
};


export type MutationPayoutsBulkBankPaymentApproveAndCreateArgs = {
  input: PayoutsBulkBankPaymentApproveAndCreateInput;
};


export type MutationPayoutsBulkSetAsPaidArgs = {
  input: PayoutsBulkSetAsPaidInput;
};


export type MutationPayoutsCancelArgs = {
  input: PayoutsCancelInput;
};


export type MutationPayoutsCompleteArgs = {
  input: PayoutsCompleteInput;
};


export type MutationPayoutsCompleteAgainstAnotherArgs = {
  input: PayoutsCompleteAgainstAnotherInput;
};


export type MutationPayoutsCreateArgs = {
  input: PayoutsCreateInput;
};


export type MutationPayoutsDestroyArgs = {
  input: PayoutsDestroyInput;
};


export type MutationPayoutsUpdateArgs = {
  input: PayoutsUpdateInput;
};


export type MutationPayoutsUpdateMethodArgs = {
  input: PayoutsUpdateMethodInput;
};


export type MutationPersonsCreateArgs = {
  input: PersonsCreateInput;
};


export type MutationPersonsImpersonateArgs = {
  input: PersonsImpersonateInput;
};


export type MutationPersonsMergeArgs = {
  input: PersonsMergeInput;
};


export type MutationPersonsOrganizationChangeArgs = {
  input: PersonsOrganizationChangeInput;
};


export type MutationPersonsReactivationTransferArgs = {
  input: PersonsReactivationTransferInput;
};


export type MutationPersonsSyncContactArgs = {
  input: PersonsSyncContactInput;
};


export type MutationPersonsUpdateArgs = {
  input: PersonsUpdateInput;
};


export type MutationPersonsUpdateDefaultAddressArgs = {
  input: PersonsUpateDefaultAddressInput;
};


export type MutationPersonsVerifyWhatsappNumberArgs = {
  input: PersonsVerifyWhatsAppNumberInput;
};


export type MutationProductionEventsCreateArgs = {
  input: ProductionEventsCreateInput;
};


export type MutationProductionEventsDeleteArgs = {
  input: ProductionEventsDeleteInput;
};


export type MutationProductionEventsUpdateArgs = {
  input: ProductionEventsUpdateInput;
};


export type MutationProviderOrdersBalanceRecordsExportForProviderArgs = {
  input: ProviderOrdersBalanceRecordsExportForProviderInput;
};


export type MutationProviderOrdersCreateArgs = {
  input: ProviderOrdersCreateInput;
};


export type MutationProviderOrdersDestroyArgs = {
  input: ProviderOrdersDestroyInput;
};


export type MutationProviderOrdersShippingInstructionsUpdateArgs = {
  input: ProviderOrdersShippingInstructionUpdatesInput;
};


export type MutationProviderOrdersSplitArgs = {
  input: ProviderOrdersSplitInput;
};


export type MutationProviderOrdersUpdateArgs = {
  input: ProviderOrdersUpdateInput;
};


export type MutationProvidersChangePrimaryArgs = {
  input: ChangePrimaryInput;
};


export type MutationProvidersContactsCreateArgs = {
  input: ContactsCreateInput;
};


export type MutationProvidersContactsDeleteArgs = {
  input: ContactsDeleteInput;
};


export type MutationProvidersContactsMoveArgs = {
  input: ContactsMoveInput;
};


export type MutationProvidersContactsUpdateArgs = {
  input: ContactsUpdateInput;
};


export type MutationProvidersCreateArgs = {
  input: ProvidersCreateInput;
};


export type MutationProvidersDeleteArgs = {
  input: ProvidersDeleteInput;
};


export type MutationProvidersUpdateArgs = {
  input: ProvidersUpdateInput;
};


export type MutationProvidersUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationPublicDomainsCreateArgs = {
  input: PublicDomainsCreateInput;
};


export type MutationQualityControlsCreateArgs = {
  input: QualityControlsCreateInput;
};


export type MutationQualityControlsGeneratePdfReportArgs = {
  input: QualityControlsGeneratePdfReportInput;
};


export type MutationQualityControlsPositionDeleteArgs = {
  input: QualityControlsPositionsDeleteInput;
};


export type MutationQualityControlsPositionUpdateArgs = {
  input: QualityControlsPositionsUpdateInput;
};


export type MutationQualityControlsPositionsUpdateMeasurementsArgs = {
  input: QualityControlsPositionsUpdateMeasurementsInput;
};


export type MutationQualityControlsRepackArgs = {
  input: QualityControlsRepackInput;
};


export type MutationQualityControlsUpdateArgs = {
  input: QualityControlsUpdateInput;
};


export type MutationRfqsCancelArgs = {
  input: RfqsCancelInput;
};


export type MutationRfqsCloseArgs = {
  input: RfqsCloseInput;
};


export type MutationRfqsDeleteArgs = {
  input: RfqsDeleteInput;
};


export type MutationRfqsOffersApplyArgs = {
  input: RfqsOffersApplyInput;
};


export type MutationRfqsOffersDeleteArgs = {
  input: RfqsOffersDeleteInput;
};


export type MutationRfqsOffersFindOrCreateArgs = {
  input: RfqsOffersFindOrCreateInput;
};


export type MutationRfqsOffersPricesDeleteArgs = {
  input: RfqsOffersPricesDeleteInput;
};


export type MutationRfqsOffersUpdateArgs = {
  input: RfqsOffersUpdateInput;
};


export type MutationRfqsPositionsDeleteArgs = {
  input: RfqsPositionsDeleteInput;
};


export type MutationRfqsPublishArgs = {
  input: RfqsPublishInput;
};


export type MutationRfqsReactivateArgs = {
  input: RfqsReactivateInput;
};


export type MutationRfqsUpdateArgs = {
  input: RfqsUpdateInput;
};


export type MutationRolesUpdatePermissionsArgs = {
  input: RolesUpdatePermissionsInput;
};


export type MutationShipmentOrderCreateDhlOrderArgs = {
  input: ShipmentOrderCreateDhlOrderInput;
};


export type MutationShipmentOrderCreateEurosenderOrderArgs = {
  input: ShipmentOrderCreateEurosenderOrderInput;
};


export type MutationShipmentOrderCreateJumingoOrderArgs = {
  input: ShipmentOrderCreateJumingoOrderInput;
};


export type MutationShipmentOrderCreatePacklinkOrderArgs = {
  input: ShipmentOrderCreatePacklinkOrderInput;
};


export type MutationShipmentOrderCreateUpsOrderArgs = {
  input: ShipmentOrderCreateUpsOrderInput;
};


export type MutationShipmentOrderCreateYurticiOrderArgs = {
  input: ShipmentOrderCreateYurticiOrderInput;
};


export type MutationShipmentOrderRequestShippingOptionsArgs = {
  input: ShipmentOrderRequestShippingOptionsInput;
};


export type MutationShippingAddressesCreateArgs = {
  input: ShippingAddressesCreateInput;
};


export type MutationShippingAddressesDeleteArgs = {
  input: ShippingAddressesDeleteInput;
};


export type MutationShippingAddressesForOrgPersonsCreateArgs = {
  input: ShippingAddressesForOrgPersonsCreateInput;
};


export type MutationShippingAddressesUpdateArgs = {
  input: ShippingAddressesUpdateInput;
};


export type MutationShippingAddressesUpdateCascadeArgs = {
  input: ShippingAddressesUpdateCascadeInput;
};


export type MutationShippingCalculationsApplyCostArgs = {
  input: ShippingCalculationsApplyCostInput;
};


export type MutationShippingCalculationsCreateArgs = {
  input: ShippingCalculationsCreateInput;
};


export type MutationShippingCalculationsDeleteArgs = {
  input: ShippingCalculationsDeleteInput;
};


export type MutationShippingsAddToMasterShippingArgs = {
  input: ShippingsAddToMasterShippingInput;
};


export type MutationShippingsBulkAddToMasterShippingArgs = {
  input: ShippingsBulkAddToMasterShippingInput;
};


export type MutationShippingsBulkMoveToStateArgs = {
  input: ShippingsBulkMoveToStateInput;
};


export type MutationShippingsBulkRemoveFromMasterShippingArgs = {
  input: ShippingsBulkRemoveFromMasterShippingInput;
};


export type MutationShippingsBulkUpdateTrackingArgs = {
  input: ShippingsBulkUpdateTrackingInput;
};


export type MutationShippingsCreateArgs = {
  input: ShippingsCreateInput;
};


export type MutationShippingsUpdateArgs = {
  input: ShippingsFormUpdateInput;
};


export type MutationShippingsUpdateTrackingArgs = {
  input: ShippingsUpdateTrackingInput;
};


export type MutationUpdateLogisticInvoiceArgs = {
  input: UpdateLogisticInvoiceInput;
};


export type MutationUpdateLogisticInvoicePositionArgs = {
  input: UpdateLogisticInvoicePositionInput;
};


export type MutationUpdateRfqFieldsArgs = {
  input: EnquiryPartsUpdateRfqFieldsInput;
};


export type MutationUsersAssignRolesArgs = {
  input: UsersAssignRolesInput;
};


export type MutationUsersEmailLoginArgs = {
  input: EmailLoginInput;
};


export type MutationUsersUpdatePermissionsArgs = {
  input: UsersUpdatePermissionsInput;
};


export type MutationUsersUpdateProfileArgs = {
  input: UsersUpdateProfileInput;
};


export type MutationWorkspacePresetsAssignDefaultPresetArgs = {
  input: WorkspacePresetsAssignDefaultPresetInput;
};


export type MutationWorkspacePresetsCreateArgs = {
  input: WorkspacePresetsCreateInput;
};


export type MutationWorkspacePresetsDeleteArgs = {
  input: WorkspacePresetsDeleteInput;
};


export type MutationWorkspacePresetsRemoveDefaultPresetArgs = {
  input: WorkspacePresetsRemoveDefaultPresetInput;
};


export type MutationWorkspacePresetsUpdateArgs = {
  input: WorkspacePresetsUpdateInput;
};


export type MutationWorkspacesBankPaymentsDraftExportArgs = {
  input: BankPaymentsDraftExportInput;
};


export type MutationWorkspacesBankPaymentsExportArgs = {
  input: BankPaymentsExportInput;
};


export type MutationWorkspacesBillingAccountsExportArgs = {
  input: BillingAccountsExportInput;
};


export type MutationWorkspacesClaimsExportArgs = {
  input: ClaimsExportInput;
};


export type MutationWorkspacesDealsExportArgs = {
  input: DealsExportInput;
};


export type MutationWorkspacesDraftingDealPartsExportArgs = {
  input: DraftingDealPartsExportInput;
};


export type MutationWorkspacesDraftingEnquiryPartsExportArgs = {
  input: DraftingEnquiryPartsExportInput;
};


export type MutationWorkspacesEnquiriesExportArgs = {
  input: EnquiriesExportInput;
};


export type MutationWorkspacesEnquiryPromotionsExportArgs = {
  input: EnquiryPromotionsExportInput;
};


export type MutationWorkspacesFrameworkBatchesExportArgs = {
  input: FrameworkBatchesExportInput;
};


export type MutationWorkspacesIncomingInvoicesExportArgs = {
  input: IncomingInvoicesExportInput;
};


export type MutationWorkspacesJobOffersExportArgs = {
  input: JobOffersExportInput;
};


export type MutationWorkspacesJobsExportArgs = {
  input: JobsExportInput;
};


export type MutationWorkspacesMessagesExportArgs = {
  input: MessagesExportInput;
};


export type MutationWorkspacesOrganizationBalancesExportArgs = {
  input: OrganizationBalancesExportInput;
};


export type MutationWorkspacesOrganizationsExportArgs = {
  input: OrganizationsExportInput;
};


export type MutationWorkspacesOutcomingInvoicesExportArgs = {
  input: OutcomingInvoicesExportInput;
};


export type MutationWorkspacesPartnerBalancesExportArgs = {
  input: PartnerBalancesExportInput;
};


export type MutationWorkspacesPaymentsExportArgs = {
  input: PaymentsExportInput;
};


export type MutationWorkspacesPayoutsExportArgs = {
  input: PayoutsExportInput;
};


export type MutationWorkspacesPersonsExportArgs = {
  input: PersonsExportInput;
};


export type MutationWorkspacesProviderOrdersExportArgs = {
  input: ProviderOrdersExportInput;
};


export type MutationWorkspacesProvidersExportArgs = {
  input: ProvidersExportInput;
};


export type MutationWorkspacesQualityControlsExportArgs = {
  input: QualityControlsExportInput;
};


export type MutationWorkspacesRefundsExportArgs = {
  input: RefundsExportInput;
};


export type MutationWorkspacesRfqOffersExportArgs = {
  input: RfqOffersExportInput;
};


export type MutationWorkspacesRfqPartnersExportArgs = {
  input: RfqPartnersExportInput;
};


export type MutationWorkspacesRfqsExportArgs = {
  input: RfqsExportInput;
};


export type MutationWorkspacesShippingAddressesExportArgs = {
  input: ShippingAddressesExportInput;
};


export type MutationWorkspacesShippingsExportArgs = {
  input: ShippingsExportInput;
};


export type MutationWorkspacesStoragesExportArgs = {
  input: StoragesExportInput;
};


export type MutationWorkspacesSubscriptionsExportArgs = {
  input: SubscriptionsExportInput;
};


export type MutationWorkspacesTasksExportArgs = {
  input: TasksExportInput;
};


export type MutationWorkspacesUsersExportArgs = {
  input: UsersExportInput;
};

export type Oc = {
  __typename?: 'OC';
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  deal: Deal;
  id: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  objectType: Scalars['String']['output'];
  paymentTerms?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  totalAmountBrut: Scalars['Float']['output'];
  totalAmountNet: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ObjectList = {
  __typename?: 'ObjectList';
  claims: Array<Claim>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNotes?: Maybe<Array<CreditNote>>;
  deal: Deal;
  deliveries?: Maybe<Array<Delivery>>;
  enquiry?: Maybe<Enquiry>;
  id: Scalars['Int']['output'];
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  jobs?: Maybe<Array<Job>>;
  orderConfirmation?: Maybe<Oc>;
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
  payments: Array<Payment>;
  payouts?: Maybe<Array<Payout>>;
  providerOrders: Array<ProviderOrder>;
  qualityControls?: Maybe<Array<QualityControl>>;
  quotations: Array<Quotation>;
  rfqs?: Maybe<Array<Rfq>>;
  shippings?: Maybe<Array<Shipping>>;
  storages?: Maybe<Array<Storage>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryAppliedPromotionType = {
  __typename?: 'OmsEnquiryAppliedPromotionType';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  enquiryId: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  promotion: OmsEnquiryPromotionType;
  promotionId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryCreditLimitValidationErrorType = {
  __typename?: 'OmsEnquiryCreditLimitValidationErrorType';
  baAccess?: Maybe<Scalars['String']['output']>;
  creditLimitMoney?: Maybe<Money>;
  currentDebtMoney?: Maybe<Money>;
  currentOpenOrdersMoney?: Maybe<Money>;
  currentOrderMoney?: Maybe<Money>;
  name?: Maybe<Scalars['String']['output']>;
  orgAccess?: Maybe<Scalars['String']['output']>;
  personAccess?: Maybe<Scalars['String']['output']>;
  totalMoney?: Maybe<Money>;
  valid: Scalars['Boolean']['output'];
};

export type OmsEnquiryMeasurementProtocol = {
  __typename?: 'OmsEnquiryMeasurementProtocol';
  allowedProcessIds: Array<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priceMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryPartQuantityOption = {
  __typename?: 'OmsEnquiryPartQuantityOption';
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type OmsEnquiryPartQuantityOptionMoney = {
  __typename?: 'OmsEnquiryPartQuantityOptionMoney';
  cost: Scalars['Float']['output'];
  costMoney: Money;
  leadTime: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  priceMoney: Money;
  quantity: Scalars['Int']['output'];
};

export type OmsEnquiryPartTagsMappingType = {
  __typename?: 'OmsEnquiryPartTagsMappingType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  partId: Scalars['ID']['output'];
  tags: Array<Scalars['ID']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryPartType = {
  __typename?: 'OmsEnquiryPartType';
  appliedCouponValueMoney?: Maybe<Money>;
  appliedPromotions?: Maybe<Array<OmsEnquiryAppliedPromotionType>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customMaterial?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  externalRfqOfferId?: Maybe<Scalars['ID']['output']>;
  files: Array<AttachedFile>;
  finish?: Maybe<Finish>;
  hasCleaned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  material?: Maybe<Material>;
  materialType?: Maybe<MaterialType>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  priceMoney?: Maybe<Money>;
  process?: Maybe<OmsEnquiryProcessType>;
  quantity: Scalars['Int']['output'];
  quantityOptions?: Maybe<Array<OmsEnquiryPartQuantityOptionMoney>>;
  rfqIsUrgent?: Maybe<Scalars['Boolean']['output']>;
  rfqNeedEngineer?: Maybe<Scalars['Boolean']['output']>;
  rfqNeedEngineerAt?: Maybe<Scalars['String']['output']>;
  rfqNeedEngineerByName?: Maybe<Scalars['String']['output']>;
  rfqNeedInfo?: Maybe<Scalars['Boolean']['output']>;
  rfqNeedInfoAt?: Maybe<Scalars['String']['output']>;
  rfqNeedInfoByName?: Maybe<Scalars['String']['output']>;
  rfqOffer?: Maybe<RfqOffer>;
  rfqOfferRegion?: Maybe<Scalars['String']['output']>;
  rfqOwner?: Maybe<User>;
  rfqStatus?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  tagList?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryProcessType = {
  __typename?: 'OmsEnquiryProcessType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type OmsEnquiryPromotionType = {
  __typename?: 'OmsEnquiryPromotionType';
  code: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountAmountMoney?: Maybe<Money>;
  discountUnit?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  settings?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

export type OmsInputsAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsAttachmentType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  context?: InputMaybe<Scalars['String']['input']>;
  fileBase64Encode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type OmsInputsBankPaymentsPaymentsCreateType = {
  amountBrut: Scalars['Float']['input'];
  billingAccountId: Scalars['ID']['input'];
  datevComment?: InputMaybe<Scalars['String']['input']>;
  intacctComment?: InputMaybe<Scalars['String']['input']>;
  methodId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

export type OmsInputsBankPaymentsUpdateOutlyingTypeType = {
  outlyingTransactionType?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsBankPaymentsUpdateType = {
  amount: Scalars['Float']['input'];
  bankAccount: Scalars['String']['input'];
  bankAccountHolderName: Scalars['String']['input'];
  bankCode: Scalars['String']['input'];
  bankCountry: Scalars['String']['input'];
  bankName?: InputMaybe<Scalars['String']['input']>;
  endToEndId?: InputMaybe<Scalars['String']['input']>;
  paymentDetailsText?: InputMaybe<Scalars['String']['input']>;
  payoutTransferType: Scalars['String']['input'];
  providerCountry: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
};

export type OmsInputsBillingAccountCreditLimitType = {
  creditLimit?: InputMaybe<Scalars['Float']['input']>;
  creditLimitCurrency?: InputMaybe<CurrencyEnum>;
  creditLimitRequest?: InputMaybe<Scalars['Float']['input']>;
  dnbDunsNumber?: InputMaybe<Scalars['String']['input']>;
  dnbHasRecommendation?: InputMaybe<Scalars['Boolean']['input']>;
  dnbRecommendation?: InputMaybe<Scalars['Float']['input']>;
  dnbRecommendationCurrency?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsBillingAccountPaymentTermsType = {
  badPayer?: InputMaybe<Scalars['Boolean']['input']>;
  creditLimit: OmsInputsMoneyType;
  creditLimitRequest?: InputMaybe<Scalars['Float']['input']>;
  files?: InputMaybe<Array<OmsInputsAttachmentType>>;
  paymentTermsDays?: InputMaybe<Scalars['Int']['input']>;
  paymentTermsType: PaymentTermsTypeEnum;
};

export type OmsInputsBillingAccountRequestCreditLimitType = {
  creditLimitRequest?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsBillingAccountType = {
  accountType: Scalars['String']['input'];
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  datevId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  intacctId?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  personId?: InputMaybe<Scalars['ID']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
  vatRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  vatRegistrationCountry?: InputMaybe<Scalars['String']['input']>;
  vatValidationStatus?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type OmsInputsBillingAccountUpdateCascadeType = {
  cascadeToObjects: Array<OmsInputsCascadeToObjectType>;
};

export type OmsInputsCascadeToObjectType = {
  id: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
};

export type OmsInputsCertificateType = {
  amount: Scalars['Float']['input'];
  positions: Scalars['Int']['input'];
};

export type OmsInputsCertificatesType = {
  cert_compliance_2_1?: InputMaybe<OmsInputsCertificateType>;
  cert_compliance_2_2?: InputMaybe<OmsInputsCertificateType>;
  cert_heat_treatment?: InputMaybe<OmsInputsCertificateType>;
  cert_materials_3_1?: InputMaybe<OmsInputsCertificateType>;
  cert_materials_3_2?: InputMaybe<OmsInputsCertificateType>;
  cert_materials_plastic?: InputMaybe<OmsInputsCertificateType>;
  cert_reach?: InputMaybe<OmsInputsCertificateType>;
  cert_rohs?: InputMaybe<OmsInputsCertificateType>;
};

export type OmsInputsContactType = {
  disallowToPool?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  preferredContactType?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  verifiedAt?: InputMaybe<Scalars['String']['input']>;
  viber?: InputMaybe<Scalars['String']['input']>;
  wechat?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsDealPartsMeasurementTemplateType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  kind: Scalars['String']['input'];
  mark?: InputMaybe<OmsInputsQualityControlsMeasurementMarkType>;
  maxValue?: InputMaybe<Scalars['String']['input']>;
  measurements?: InputMaybe<Scalars['JSON']['input']>;
  minMaxStr?: InputMaybe<Scalars['String']['input']>;
  minValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nominalValue: Scalars['String']['input'];
  partId: Scalars['ID']['input'];
  position: Scalars['Int']['input'];
  qcCanvasId: Scalars['ID']['input'];
  qcPositionId?: InputMaybe<Scalars['ID']['input']>;
  toleranceClass?: InputMaybe<Scalars['String']['input']>;
  toolset?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  verdict?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsDealUpdateProductionManager = {
  pmId?: InputMaybe<Scalars['ID']['input']>;
  updateOrganizationPm?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmsInputsDealsClaimPositionType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  customerPrice: Scalars['Float']['input'];
  customerPriceCurrency?: InputMaybe<CurrencyEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentLotId: Scalars['ID']['input'];
  partId: Scalars['ID']['input'];
  providerOrderId?: InputMaybe<Scalars['ID']['input']>;
  providerPrice: Scalars['Float']['input'];
  providerPriceCurrency?: InputMaybe<CurrencyEnum>;
  quantity: Scalars['Int']['input'];
};

export type OmsInputsDealsClaimType = {
  claimPositionsAttributes: Array<OmsInputsDealsClaimPositionType>;
  conformityCheck?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsDealsDealType = {
  certificates?: InputMaybe<OmsInputsCertificatesType>;
  customerOrderCode?: InputMaybe<Scalars['String']['input']>;
  customerOrderDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  deliveryDeadline?: InputMaybe<Scalars['ISO8601Date']['input']>;
  frameworkAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  grade?: InputMaybe<Scalars['String']['input']>;
  isForSoftwareTesting?: InputMaybe<Scalars['Boolean']['input']>;
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  isNpg?: InputMaybe<Scalars['Boolean']['input']>;
  isOnHold?: InputMaybe<Scalars['Boolean']['input']>;
  isTpo?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingComment?: InputMaybe<Scalars['String']['input']>;
  manualProcessingEmails?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingFinance?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingIncomingInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingJobs?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingOrderConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingOutcomingInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingShippings?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingStages?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<OmsInputsDealsOwnerType>;
  pipedriveOrderValue?: InputMaybe<Scalars['Float']['input']>;
  pipedrivePipeline?: InputMaybe<Scalars['String']['input']>;
  pipedriveStatus?: InputMaybe<Scalars['String']['input']>;
  pmId?: InputMaybe<Scalars['ID']['input']>;
  purchaseOrderDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  reasonOfLose?: InputMaybe<Scalars['String']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  totalPaidNett?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsDealsOwnerType = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsDnBCriteriaType = {
  addressLine?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  duns?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsDocumentNoteType = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  emotion: Scalars['String']['input'];
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type OmsInputsEnquiriesEnquiryType = {
  assignedSa?: InputMaybe<Scalars['ID']['input']>;
  billingAccountId?: InputMaybe<Scalars['ID']['input']>;
  certificates?: InputMaybe<OmsInputsCertificatesType>;
  ignoreMinimumOrderValue?: InputMaybe<Scalars['Boolean']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  xometryVatNumber?: InputMaybe<OmsInputsXometryVatNumberType>;
};

export type OmsInputsEnquiriesPartsRfqType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  rfqIsUrgent?: InputMaybe<Scalars['Boolean']['input']>;
  rfqNeedEngineer?: InputMaybe<Scalars['Boolean']['input']>;
  rfqNeedInfo?: InputMaybe<Scalars['Boolean']['input']>;
  rfqOwnerId?: InputMaybe<Scalars['Int']['input']>;
  rfqStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsFrameworkBatchesBatchType = {
  category: BatchCategoryEnum;
  id?: InputMaybe<Scalars['ID']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  providerOrderId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Int']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: BatchSourceEnum;
};

export type OmsInputsFrameworkBatchesGroupType = {
  batches: Array<OmsInputsFrameworkBatchesBatchType>;
  category: BatchCategoryEnum;
  date: Scalars['ISO8601Date']['input'];
  type: BatchTypeEnum;
};

export type OmsInputsFrameworkBatchesSourceType = {
  sourceId: Scalars['ID']['input'];
  sourceType: BatchSourceEnum;
};

export type OmsInputsIncomingInvoicesReallocateToType = {
  amountMoney: OmsInputsMoneyType;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

export type OmsInputsLogisticInvoicePosition = {
  allocatedCharges: Scalars['Float']['input'];
  expenseType: Scalars['String']['input'];
  shippingId?: InputMaybe<Scalars['ID']['input']>;
};

export type OmsInputsLoyaltyTransactionType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
  pointsAmount: Scalars['Int']['input'];
  referenceId: Scalars['ID']['input'];
  referenceType: LoyaltyTransactionsReferenceEnumType;
  transactionType: LoyaltyTransactionsTypeEnumType;
  trigger: LoyaltyTransactionsTriggerEnumType;
};

export type OmsInputsMasterShippingType = {
  actualShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  customsDocumentsStatus?: InputMaybe<ShippingCustomsDocumentsStatusEnum>;
  deliveryBasis?: InputMaybe<ShippingDeliveryBasisEnum>;
  deliveryType?: InputMaybe<ShippingDeliveryTypeEnum>;
  destinationAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  direction: ShippingDirectionEnum;
  expectedDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  expectedShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  holdUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  logisticAggregator?: InputMaybe<Scalars['String']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  movementReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  positionNaming?: InputMaybe<ShippingPositionNamingEnum>;
  shippingAlert?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<InputsMoneyType>;
  shippingOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingParameters?: InputMaybe<Array<OmsInputsShippingsParametersType>>;
  sourceAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  state: ShippingStateEnum;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsMasterShippingsSearchAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsMessageSubscriptionsSubscriptionType = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

export type OmsInputsMessengerAttachmentType = {
  fileBase64Encode: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type OmsInputsMessengerMessageFilterGroupType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type OmsInputsMessengerMessageFilterType = {
  authorType?: InputMaybe<UserEnum>;
  comment?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<MessageDirectionEnum>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ignoreCounters?: InputMaybe<Scalars['Boolean']['input']>;
  messageType?: InputMaybe<MessageFilterTypeEnum>;
  muteNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  sourceType?: InputMaybe<MessageSourceTypeEnum>;
  title: Scalars['String']['input'];
};

export type OmsInputsMessengerMessageTaskType = {
  assignees?: InputMaybe<Array<Scalars['ID']['input']>>;
  deadline?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  priority: Priority;
  taskType: TaskType;
};

export type OmsInputsMessengerMessageType = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  content: Scalars['String']['input'];
  contentRaw?: InputMaybe<Scalars['String']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  isVisibleToClient?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleToPartner?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Array<Scalars['ID']['input']>>;
  replyOnId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  task?: InputMaybe<OmsInputsMessengerMessageTaskType>;
  type?: InputMaybe<MessageTypeEnum>;
};

export type OmsInputsMessengerTemplatedMessageType = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  bcc?: InputMaybe<Array<Scalars['String']['input']>>;
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  isVisibleToClient?: InputMaybe<Scalars['Boolean']['input']>;
  isVisibleToPartner?: InputMaybe<Scalars['Boolean']['input']>;
  mentions?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OmsInputsMoneyType = {
  amount: Scalars['String']['input'];
  amountCents: Scalars['String']['input'];
  currencyCode: CurrencyEnum;
};

export type OmsInputsOrganizationType = {
  customerOrderGrade?: InputMaybe<Scalars['String']['input']>;
  customerType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailDomains?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  hsCodeNotificationsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  isForSoftwareTesting?: InputMaybe<Scalars['Boolean']['input']>;
  isImportant?: InputMaybe<Scalars['Boolean']['input']>;
  kam?: InputMaybe<OmsInputsDealsOwnerType>;
  manualProcessingComment?: InputMaybe<Scalars['String']['input']>;
  manualProcessingEmails?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingFinance?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingJobs?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingOrderConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingOutcomingInvoices?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingShippings?: InputMaybe<Scalars['Boolean']['input']>;
  manualProcessingStages?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<OmsInputsDealsOwnerType>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsOrganizationUpdatePmCascade = {
  cascadeOption: ProductionManagerCascadeOptionEnum;
  pmId?: InputMaybe<Scalars['ID']['input']>;
};

export type OmsInputsPackageTrackingEventType = {
  eventPattern: Scalars['String']['input'];
  logisticOperator: Scalars['String']['input'];
  packageStatus: Scalars['String']['input'];
};

export type OmsInputsPartStateCorrectionType = {
  comment: Scalars['String']['input'];
  partId: Scalars['Int']['input'];
  state: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type OmsInputsPaymentsUpdateMethodType = {
  methodId?: InputMaybe<Scalars['ID']['input']>;
  methodType?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsPayoutCompleteAgainstAnotherType = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

export type OmsInputsPayoutCompleteType = {
  invoicePaid?: InputMaybe<Scalars['Boolean']['input']>;
  paidAt: Scalars['String']['input'];
  paymentDetails: Scalars['String']['input'];
  poPaid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmsInputsPayoutType = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  approvedAt?: InputMaybe<Scalars['String']['input']>;
  approvedById?: InputMaybe<Scalars['ID']['input']>;
  datevComment?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  intacctComment?: InputMaybe<Scalars['String']['input']>;
  isRefund: Scalars['Boolean']['input'];
  paidAt?: InputMaybe<Scalars['String']['input']>;
  paymentDetails?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsPayoutsUpdateMethodType = {
  methodId?: InputMaybe<Scalars['ID']['input']>;
  methodType?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsPersonContactType = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OmsInputsPersonType = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  carbonCopyEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  contactAttributes?: InputMaybe<OmsInputsPersonContactType>;
  defaultShippingAddressId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  frameworkAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  logisticEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  mailingSuspendedTill?: InputMaybe<Scalars['ISO8601Date']['input']>;
  mailingsSubscription?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  ocEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['ID']['input'];
  owner?: InputMaybe<OmsInputsDealsOwnerType>;
  paymentReminderEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pipedriveOrderValue?: InputMaybe<Scalars['Float']['input']>;
  technicalEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  totalPaidNett?: InputMaybe<Scalars['Float']['input']>;
  tpoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmsInputsPositionParametersType = {
  height: Scalars['Float']['input'];
  length: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  partId?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  weight: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
};

export type OmsInputsPromotionDiscountType = {
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountUnit?: InputMaybe<Scalars['String']['input']>;
  internalComment: Scalars['String']['input'];
  lockQuoteLimit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmsInputsPromotionType = {
  code: Scalars['String']['input'];
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountAmountPercent?: InputMaybe<Scalars['Float']['input']>;
  discountUnit?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  lockQuoteLimit?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  settings?: InputMaybe<Scalars['JSON']['input']>;
};

export type OmsInputsProviderOrderCreateType = {
  conformityCheck?: InputMaybe<ConformityCheckEnum>;
  currency: CurrencyEnum;
  deadline: Scalars['ISO8601Date']['input'];
  dealId: Scalars['ID']['input'];
  fullyPaidAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  issueDate: Scalars['ISO8601Date']['input'];
  lots?: InputMaybe<Array<OmsInputsProviderOrdersLotType>>;
  paymentTerms?: InputMaybe<Scalars['Int']['input']>;
  providerId: Scalars['ID']['input'];
  publicComment?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  qcReportRequired?: InputMaybe<Scalars['Boolean']['input']>;
  samplesConfirmationStatus?: InputMaybe<ProviderOrdersSamplesConfirmationStatusEnum>;
  secondaryProduction?: InputMaybe<Scalars['Boolean']['input']>;
  sendReminders?: InputMaybe<Scalars['Boolean']['input']>;
  services?: InputMaybe<Array<OmsInputsProviderOrdersServiceType>>;
  shipAllowDirectLegal?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectLegalXomSales?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectOrder?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectProvider?: InputMaybe<Scalars['Boolean']['input']>;
  shipDirection?: InputMaybe<ProviderOrdersShipDirectionEnum>;
  shipDirectionToEndCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  shipIncoterms?: InputMaybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly?: InputMaybe<Scalars['Boolean']['input']>;
  shipIncotermsCity?: InputMaybe<Scalars['String']['input']>;
  shipIncotermsCountry?: InputMaybe<Scalars['String']['input']>;
  shipQcRequired?: InputMaybe<Scalars['Boolean']['input']>;
  shipWarehouse?: InputMaybe<WarehouseInstructionEnum>;
  shippingProcessingInstruction?: InputMaybe<ShippingInstructionEnum>;
  state: ProviderOrderStateEnum;
  visibleInPa: Scalars['Boolean']['input'];
};

export type OmsInputsProviderOrderUpdateType = {
  conformityCheck?: InputMaybe<ConformityCheckEnum>;
  currency: CurrencyEnum;
  deadline: Scalars['ISO8601Date']['input'];
  deadlineUpdateComment?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  fullyPaidAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  issueDate: Scalars['ISO8601Date']['input'];
  lots?: InputMaybe<Array<OmsInputsProviderOrdersLotType>>;
  paymentTerms?: InputMaybe<Scalars['Int']['input']>;
  providerId: Scalars['ID']['input'];
  publicComment?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  qcReportRequired?: InputMaybe<Scalars['Boolean']['input']>;
  samplesConfirmationStatus?: InputMaybe<ProviderOrdersSamplesConfirmationStatusEnum>;
  secondaryProduction?: InputMaybe<Scalars['Boolean']['input']>;
  sendReminders?: InputMaybe<Scalars['Boolean']['input']>;
  services?: InputMaybe<Array<OmsInputsProviderOrdersServiceType>>;
  shipAllowDirectLegal?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectLegalXomSales?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectOrder?: InputMaybe<Scalars['Boolean']['input']>;
  shipAllowDirectProvider?: InputMaybe<Scalars['Boolean']['input']>;
  shipDirection?: InputMaybe<ProviderOrdersShipDirectionEnum>;
  shipDirectionToEndCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  shipIncoterms?: InputMaybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly?: InputMaybe<Scalars['Boolean']['input']>;
  shipIncotermsCity?: InputMaybe<Scalars['String']['input']>;
  shipIncotermsCountry?: InputMaybe<Scalars['String']['input']>;
  shipQcRequired?: InputMaybe<Scalars['Boolean']['input']>;
  shipWarehouse?: InputMaybe<WarehouseInstructionEnum>;
  shipmentDateRevisedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  shipmentDateRevisedComment?: InputMaybe<Scalars['String']['input']>;
  shippingProcessingInstruction?: InputMaybe<ShippingInstructionEnum>;
  state: ProviderOrderStateEnum;
  visibleInPa: Scalars['Boolean']['input'];
};

export type OmsInputsProviderOrdersLotType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  parentId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Int']['input'];
};

export type OmsInputsProviderOrdersServiceType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  price: Scalars['Float']['input'];
  quantity: Scalars['Int']['input'];
  typeName: TypeNameEnum;
};

export type OmsInputsProviderType = {
  acceptSelfBilling?: InputMaybe<Scalars['Boolean']['input']>;
  allowAutoacceptOffers?: InputMaybe<Scalars['Boolean']['input']>;
  allowAutoacceptOffersFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  allowDirectshipOrders?: InputMaybe<Scalars['Boolean']['input']>;
  allowDirectshipOrdersFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  autoacceptPoNumberLimit?: InputMaybe<Scalars['Int']['input']>;
  autoacceptPoNumberLimitFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  autoacceptPoValueLimit?: InputMaybe<Scalars['Int']['input']>;
  autoacceptPoValueLimitFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankCountry?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<OmsInputsAddressType>;
  contact?: InputMaybe<OmsInputsContactType>;
  currency?: InputMaybe<CurrencyEnum>;
  datevKonto?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  freeCapacity?: InputMaybe<Scalars['Boolean']['input']>;
  freeCapacityFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  intacctId?: InputMaybe<Scalars['String']['input']>;
  languageList?: InputMaybe<Array<Scalars['String']['input']>>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  limitsComment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notVatPayer?: InputMaybe<Scalars['Boolean']['input']>;
  onImprovement?: InputMaybe<Scalars['Boolean']['input']>;
  onImprovementComment?: InputMaybe<Scalars['String']['input']>;
  onImprovementFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  onImprovementStartedAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  partnerNetworkManagerFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  partnerNetworkManagerId?: InputMaybe<Scalars['ID']['input']>;
  payoutTransferType?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  poNumberLimit?: InputMaybe<Scalars['Int']['input']>;
  poNumberLimitFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  poValueLimit?: InputMaybe<Scalars['Float']['input']>;
  poValueLimitFreezeUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  publicComment?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<OmsInputsAddressType>;
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<OmsInputsTagType>;
  testPartner?: InputMaybe<Scalars['Boolean']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsQualityControlType = {
  dealId: Scalars['ID']['input'];
  fileUuidIds?: InputMaybe<Array<Scalars['String']['input']>>;
  kind?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Array<OmsInputsQualityControlsPositionType>>;
  reportComment?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsQualityControlsMeasurementMarkCordsType = {
  offsetX?: InputMaybe<Scalars['Float']['input']>;
  offsetY?: InputMaybe<Scalars['Float']['input']>;
  r?: InputMaybe<Scalars['Float']['input']>;
  x?: InputMaybe<Scalars['Float']['input']>;
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsQualityControlsMeasurementMarkType = {
  active: Scalars['Boolean']['input'];
  canvasId: Scalars['BigInt']['input'];
  fill: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: OmsInputsQualityControlsMeasurementMarkCordsType;
  point: OmsInputsQualityControlsMeasurementMarkCordsType;
  position: Scalars['String']['input'];
  v: Scalars['String']['input'];
};

export type OmsInputsQualityControlsMeasurementType = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  kind: Scalars['String']['input'];
  mark?: InputMaybe<OmsInputsQualityControlsMeasurementMarkType>;
  maxValue?: InputMaybe<Scalars['String']['input']>;
  measurements: Scalars['JSON']['input'];
  minMaxStr?: InputMaybe<Scalars['String']['input']>;
  minValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nominalValue: Scalars['String']['input'];
  partId?: InputMaybe<Scalars['ID']['input']>;
  position: Scalars['Int']['input'];
  qcCanvasId: Scalars['ID']['input'];
  qcPositionId: Scalars['ID']['input'];
  toleranceClass?: InputMaybe<Scalars['String']['input']>;
  toolset?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  verdict?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsQualityControlsPdfReportDoneBy = {
  date?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsQualityControlsPdfReportParams = {
  customer?: InputMaybe<Scalars['String']['input']>;
  doneBy?: InputMaybe<OmsInputsQualityControlsPdfReportDoneBy>;
  locale?: InputMaybe<Scalars['String']['input']>;
  orderNum?: InputMaybe<Scalars['String']['input']>;
  partIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  preparedFor?: InputMaybe<Scalars['String']['input']>;
  reportBlocks?: InputMaybe<Array<Scalars['String']['input']>>;
  reportType?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsQualityControlsPositionType = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  atmPressure?: InputMaybe<Scalars['Float']['input']>;
  businessVerdict?: InputMaybe<Scalars['String']['input']>;
  businessVerdictComment?: InputMaybe<Scalars['String']['input']>;
  businessVerdictPublished?: InputMaybe<Scalars['Boolean']['input']>;
  customerPrice?: InputMaybe<Scalars['Float']['input']>;
  humidity?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lotId: Scalars['Int']['input'];
  material?: InputMaybe<Scalars['String']['input']>;
  measurements?: InputMaybe<Array<OmsInputsQualityControlsMeasurementType>>;
  name: Scalars['String']['input'];
  officialVerdictComment?: InputMaybe<Scalars['String']['input']>;
  partDetails?: InputMaybe<Scalars['String']['input']>;
  partId: Scalars['Int']['input'];
  postProcessing?: InputMaybe<Scalars['String']['input']>;
  providerOrderId?: InputMaybe<Scalars['ID']['input']>;
  providerPrice?: InputMaybe<Scalars['Float']['input']>;
  qcCoating?: InputMaybe<Scalars['String']['input']>;
  qcCoatingComment?: InputMaybe<Scalars['String']['input']>;
  qcCompliance?: InputMaybe<Scalars['String']['input']>;
  qcComplianceComment?: InputMaybe<Scalars['String']['input']>;
  qcConservation?: InputMaybe<Scalars['String']['input']>;
  qcConservationComment?: InputMaybe<Scalars['String']['input']>;
  qcDocuments?: InputMaybe<Scalars['String']['input']>;
  qcDocumentsComment?: InputMaybe<Scalars['String']['input']>;
  qcFinish?: InputMaybe<Scalars['String']['input']>;
  qcFinishComment?: InputMaybe<Scalars['String']['input']>;
  qcMarking?: InputMaybe<Scalars['String']['input']>;
  qcMarkingComment?: InputMaybe<Scalars['String']['input']>;
  qcOther?: InputMaybe<Scalars['String']['input']>;
  qcOtherComment?: InputMaybe<Scalars['String']['input']>;
  qcPackaging?: InputMaybe<Scalars['String']['input']>;
  qcPackagingComment?: InputMaybe<Scalars['String']['input']>;
  qcQuantity?: InputMaybe<Scalars['String']['input']>;
  qcQuantityComment?: InputMaybe<Scalars['String']['input']>;
  qcQuantityObserved?: InputMaybe<Scalars['Int']['input']>;
  qtyAccepted?: InputMaybe<Scalars['Int']['input']>;
  qtyChecked?: InputMaybe<Scalars['Int']['input']>;
  qtyHold?: InputMaybe<Scalars['Int']['input']>;
  qtyRejected?: InputMaybe<Scalars['Int']['input']>;
  quantity: Scalars['Int']['input'];
  techVerdict?: InputMaybe<Scalars['String']['input']>;
  techVerdictComment?: InputMaybe<Scalars['String']['input']>;
  techVerdictPublished?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Int']['input']>;
};

export type OmsInputsQualityControlsRepackType = {
  action: Scalars['String']['input'];
  conformityCheck?: InputMaybe<Scalars['String']['input']>;
  positions?: InputMaybe<Array<Scalars['Int']['input']>>;
  restAction?: InputMaybe<Scalars['String']['input']>;
  restPositions?: InputMaybe<Array<Scalars['Int']['input']>>;
  restStorageLocation?: InputMaybe<Scalars['String']['input']>;
  restStorageProcessingState?: InputMaybe<Scalars['String']['input']>;
  restUseSameSize?: InputMaybe<Scalars['Boolean']['input']>;
  storageLocation?: InputMaybe<Scalars['String']['input']>;
  storageProcessingState?: InputMaybe<Scalars['String']['input']>;
  updateConformityCheck?: InputMaybe<Scalars['Boolean']['input']>;
  useSameSize?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OmsInputsRfqType = {
  currency?: InputMaybe<CurrencyEnum>;
  needInfo?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  publicComment?: InputMaybe<Scalars['String']['input']>;
  publicationEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  publicationStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  rfqEngineerId?: InputMaybe<Scalars['ID']['input']>;
  selectedPartners?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<RfqsStateEnumType>;
};

export type OmsInputsRfqsOfferType = {
  currency?: InputMaybe<CurrencyEnum>;
  decisionRefusalReason?: InputMaybe<Scalars['String']['input']>;
  decisionState?: InputMaybe<RfqsOffersDecisionStateEnumType>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  prices?: InputMaybe<Array<OmsInputsRfqsOffersPriceType>>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
};

export type OmsInputsRfqsOffersPriceType = {
  appliedMargin?: InputMaybe<Scalars['Float']['input']>;
  customerPrice?: InputMaybe<OmsInputsMoneyType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  leadtime?: InputMaybe<Scalars['Int']['input']>;
  providerPrice?: InputMaybe<OmsInputsMoneyType>;
  quantity: Scalars['Int']['input'];
  rfqPositionId: Scalars['Int']['input'];
};

export type OmsInputsRfqsOffersRateType = {
  enquiryPartId: Scalars['ID']['input'];
  price: OmsInputsMoneyType;
  quantity: Scalars['Int']['input'];
  quotePrice: OmsInputsMoneyType;
};

export type OmsInputsShipmentOrderAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsShipmentOrderDhlAddressType = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type OmsInputsShipmentOrderEurosenderAddressType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type OmsInputsShipmentOrderPackageType = {
  height?: InputMaybe<Scalars['Float']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsShipmentOrderPacklinkAddressType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type OmsInputsShipmentOrderPickupOptionsType = {
  closeTime: Scalars['String']['input'];
  location: Scalars['String']['input'];
};

export type OmsInputsShipmentOrderUpsAddressType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type OmsInputsShipmentOrderYurticiAddressType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type OmsInputsShippingAddressForOrgPersonsType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  zip: Scalars['String']['input'];
};

export type OmsInputsShippingAddressType = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['ID']['input'];
  zip: Scalars['String']['input'];
};

export type OmsInputsShippingAddressUpdateCascadeType = {
  cascadeToObjects: Array<OmsInputsCascadeToObjectType>;
};

export type OmsInputsShippingCalculationType = {
  chargeableWeight: Scalars['Float']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  destinationAddress?: InputMaybe<OmsInputsAddressType>;
  positions?: InputMaybe<Array<OmsInputsPositionParametersType>>;
  sourceAddress?: InputMaybe<OmsInputsAddressType>;
  sourceId: Scalars['Int']['input'];
  sourceType: ShippingCalculationSourceEnum;
};

export type OmsInputsShippingInstructions = {
  shipDirection: ProviderOrdersShipDirectionEnum;
  shipDirectionToEndCustomer: Scalars['Boolean']['input'];
  shipIncoterms: ProviderOrdersIncotermsEnum;
  shipIncotermsByPlaneOnly: Scalars['Boolean']['input'];
  shipIncotermsCity: Scalars['String']['input'];
  shipIncotermsCountry: Scalars['String']['input'];
  shipQcRequired: Scalars['Boolean']['input'];
  shipWarehouse: WarehouseInstructionEnum;
};

export type OmsInputsShippingsAddressType = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  internalComment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type OmsInputsShippingsCreateType = {
  actualDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  actualShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  customsDocumentsStatus?: InputMaybe<ShippingCustomsDocumentsStatusEnum>;
  dealParts?: InputMaybe<Array<OmsInputsShippingsPartType>>;
  deliveryBasis?: InputMaybe<ShippingDeliveryBasisEnum>;
  deliveryNoteComment?: InputMaybe<Scalars['String']['input']>;
  deliveryType?: InputMaybe<ShippingDeliveryTypeEnum>;
  destinationAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  direction: ShippingDirectionEnum;
  directionToEndCustomer: Scalars['Boolean']['input'];
  expectedDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  expectedShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  holdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  holdById?: InputMaybe<Scalars['BigInt']['input']>;
  holdUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  isPartial?: InputMaybe<Scalars['Boolean']['input']>;
  logisticAggregator?: InputMaybe<Scalars['String']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  lots?: InputMaybe<Array<OmsInputsShippingsLotType>>;
  movementReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  noInvoiceRequired?: InputMaybe<Scalars['Boolean']['input']>;
  noInvoiceRequiredComment?: InputMaybe<Scalars['String']['input']>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPartner?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  packagingReportCheckStatus?: InputMaybe<Scalars['String']['input']>;
  positionNaming?: InputMaybe<ShippingPositionNamingEnum>;
  publishedInCa?: InputMaybe<Scalars['Boolean']['input']>;
  publishedInPa?: InputMaybe<Scalars['Boolean']['input']>;
  readyForCollection?: InputMaybe<Scalars['Boolean']['input']>;
  receivedProcessingStatus?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<OmsInputsShippingsServiceType>>;
  shippingAlert?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<OmsInputsMoneyType>;
  shippingOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingParameters?: InputMaybe<Array<OmsInputsShippingsParametersType>>;
  sourceAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  sourceProviderOrderId?: InputMaybe<Scalars['Int']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  warehouseInstruction?: InputMaybe<WarehouseInstructionEnum>;
};

export type OmsInputsShippingsLotType = {
  customerPrice?: InputMaybe<OmsInputsMoneyType>;
  id: Scalars['ID']['input'];
  providerPrice?: InputMaybe<OmsInputsMoneyType>;
  quantity: Scalars['Int']['input'];
};

export type OmsInputsShippingsParametersType = {
  brutto?: InputMaybe<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsShippingsPartType = {
  id: Scalars['ID']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type OmsInputsShippingsServiceType = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  typeName: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type OmsInputsShippingsUpdateType = {
  actualDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  actualShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  customsDocumentsStatus?: InputMaybe<ShippingCustomsDocumentsStatusEnum>;
  dealParts?: InputMaybe<Array<OmsInputsShippingsPartType>>;
  deliveryBasis?: InputMaybe<ShippingDeliveryBasisEnum>;
  deliveryNoteComment?: InputMaybe<Scalars['String']['input']>;
  deliveryType?: InputMaybe<ShippingDeliveryTypeEnum>;
  destinationAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  direction: ShippingDirectionEnum;
  directionToEndCustomer: Scalars['Boolean']['input'];
  expectedDeliveryDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  expectedShipmentDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  holdAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  holdById?: InputMaybe<Scalars['BigInt']['input']>;
  holdUntil?: InputMaybe<Scalars['ISO8601Date']['input']>;
  isPartial?: InputMaybe<Scalars['Boolean']['input']>;
  logisticAggregator?: InputMaybe<Scalars['String']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  lots?: InputMaybe<Array<OmsInputsShippingsLotType>>;
  movementReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  noInvoiceRequired?: InputMaybe<Scalars['Boolean']['input']>;
  noInvoiceRequiredComment?: InputMaybe<Scalars['String']['input']>;
  notifyCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  notifyPartner?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  packagingReportCheckStatus?: InputMaybe<Scalars['String']['input']>;
  positionNaming?: InputMaybe<ShippingPositionNamingEnum>;
  publishedInCa?: InputMaybe<Scalars['Boolean']['input']>;
  publishedInPa?: InputMaybe<Scalars['Boolean']['input']>;
  readyForCollection?: InputMaybe<Scalars['Boolean']['input']>;
  receivedProcessingStatus?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<OmsInputsShippingsServiceType>>;
  shippingAlert?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<OmsInputsMoneyType>;
  shippingOrderNumber?: InputMaybe<Scalars['String']['input']>;
  shippingParameters?: InputMaybe<Array<OmsInputsShippingsParametersType>>;
  sourceAddress?: InputMaybe<OmsInputsShippingsAddressType>;
  sourceProviderOrderId?: InputMaybe<Scalars['Int']['input']>;
  state: ShippingStateEnum;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  warehouseInstruction?: InputMaybe<WarehouseInstructionEnum>;
};

export type OmsInputsTagType = {
  approvedMethods?: InputMaybe<Array<Scalars['Int']['input']>>;
  certifications?: InputMaybe<Array<Scalars['Int']['input']>>;
  industries?: InputMaybe<Array<Scalars['Int']['input']>>;
  languages?: InputMaybe<Array<Scalars['Int']['input']>>;
  materials?: InputMaybe<Array<Scalars['Int']['input']>>;
  partnerSegments?: InputMaybe<Array<Scalars['Int']['input']>>;
  productionMethodFeatures?: InputMaybe<Array<Scalars['Int']['input']>>;
  productionMethods?: InputMaybe<Array<Scalars['Int']['input']>>;
  qualities?: InputMaybe<Array<Scalars['Int']['input']>>;
  restrictedMethods?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type OmsInputsUserAutoAssignmentSettings = {
  customerOrderGrade?: InputMaybe<Array<CustomerOrderGradeEnum>>;
  languages?: InputMaybe<Array<LanguageEnum>>;
  maxDeals?: InputMaybe<Scalars['Int']['input']>;
  overloadPossible?: InputMaybe<Scalars['Boolean']['input']>;
  technology?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type OmsInputsUserEmailLogin = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type OmsInputsUserPermission = {
  expiresAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id: Scalars['ID']['input'];
};

export type OmsInputsUserProfile = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  autoAssignmentSettings?: InputMaybe<OmsInputsUserAutoAssignmentSettings>;
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deputyUserId?: InputMaybe<Scalars['Int']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  podId?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<UserRegionEnum>;
  rfqLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type OmsInputsWorkspacePresetType = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
  workspaceName: Scalars['String']['input'];
};

export type OmsInputsXometryVatNumberType = {
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  reverseCharge?: InputMaybe<Scalars['Boolean']['input']>;
  reverseChargeRemark?: InputMaybe<Scalars['String']['input']>;
  validation?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

export type OmsTypesMessengerSubscriptionFilterType = {
  dealStateOpen?: InputMaybe<Scalars['Boolean']['input']>;
  hasNewFromCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  hasNewFromPartners?: InputMaybe<Scalars['Boolean']['input']>;
  sourceType?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OmsTypesMessengerTaskFilterType = {
  authorId?: InputMaybe<Scalars['ID']['input']>;
  authorType?: InputMaybe<UserEnum>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  createdFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  createdTill?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  deadlineFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  deadlineTill?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dealId?: InputMaybe<Scalars['ID']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Array<Priority>>;
  taskIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  taskType?: InputMaybe<TaskType>;
};

export type OmsTypesMessengerTaskSortType = {
  orderBy: OrderOptions;
  sortBy?: InputMaybe<SortOptions>;
};

export type OrderConfirmation = {
  __typename?: 'OrderConfirmation';
  billingAccount?: Maybe<Scalars['JSON']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  endCustomerShippingAddress?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  lots: Array<Lot>;
  nonPartPositions?: Maybe<Array<AdditionalService>>;
  number: Scalars['String']['output'];
  paymentTermsDays?: Maybe<Scalars['Int']['output']>;
  paymentTermsDiscountDays?: Maybe<Scalars['Int']['output']>;
  paymentTermsDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  paymentTermsType?: Maybe<PaymentTermsTypeEnum>;
  sachkontoNumber?: Maybe<Scalars['ID']['output']>;
  shippingAddress?: Maybe<Scalars['JSON']['output']>;
  totalAmountBrut: Scalars['Float']['output'];
  totalAmountBrutMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xometryVatNumber?: Maybe<XometryVatNumber>;
};

export type OrderConfirmationVat = {
  __typename?: 'OrderConfirmationVat';
  vatRate: Scalars['Float']['output'];
  xomVatNumber?: Maybe<XometryVatNumber>;
};

export enum OrderOptions {
  Asc = 'asc',
  Desc = 'desc'
}

export type OrderedBy = Person | User;

export type Organization = {
  __typename?: 'Organization';
  billingAccounts?: Maybe<Array<BillingAccount>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerOrderGrade?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  deals: Array<Deal>;
  dealsClaim: Scalars['Int']['output'];
  dealsInProduction: Scalars['Int']['output'];
  dealsTotalCount: Scalars['Int']['output'];
  dealsWon: Scalars['Int']['output'];
  defaultShippingAddress?: Maybe<ShippingAddress>;
  defaultShippingAddressId?: Maybe<Scalars['ID']['output']>;
  deleted: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  descriptionUpdatedBy?: Maybe<User>;
  emailDomains?: Maybe<Scalars['String']['output']>;
  externalCrmId?: Maybe<Scalars['String']['output']>;
  externalCrmUrl?: Maybe<Scalars['String']['output']>;
  hsCodeNotificationsDisabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  isForSoftwareTesting: Scalars['Boolean']['output'];
  isImportant: Scalars['Boolean']['output'];
  kam?: Maybe<PdUser>;
  manualProcessingComment?: Maybe<Scalars['String']['output']>;
  manualProcessingEmails: Scalars['Boolean']['output'];
  manualProcessingFinance: Scalars['Boolean']['output'];
  manualProcessingJobs: Scalars['Boolean']['output'];
  manualProcessingOrderConfirmation: Scalars['Boolean']['output'];
  manualProcessingOutcomingInvoices: Scalars['Boolean']['output'];
  manualProcessingShippings: Scalars['Boolean']['output'];
  manualProcessingStages: Scalars['Boolean']['output'];
  movedTo?: Maybe<IntercompanyObject>;
  movedToRegion?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  owner?: Maybe<PdUser>;
  persons?: Maybe<Array<Person>>;
  personsTotalCount: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveUrl: Scalars['String']['output'];
  pm?: Maybe<User>;
  pmId?: Maybe<Scalars['ID']['output']>;
  productionManager?: Maybe<User>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationBalanceRecord = {
  __typename?: 'OrganizationBalanceRecord';
  balanceBrutto: Scalars['Float']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentDue: Scalars['Float']['output'];
  date: Scalars['ISO8601Date']['output'];
  dealBalanceRecords: Array<DealBalanceRecord>;
  id: Scalars['Int']['output'];
  invoicedBrutto: Scalars['Float']['output'];
  organization: Organization;
  organizationId: Scalars['ID']['output'];
  overdueAbove30Days: Scalars['Float']['output'];
  overdueBelow30Days: Scalars['Float']['output'];
  overdueBrutto: Scalars['Float']['output'];
  paidBrutto: Scalars['Float']['output'];
  prepayments: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of OrganizationBalanceRecordsForceRecalculate */
export type OrganizationBalanceRecordsForceRecalculateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  organizationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationBalanceRecordsForceRecalculate */
export type OrganizationBalanceRecordsForceRecalculatePayload = {
  __typename?: 'OrganizationBalanceRecordsForceRecalculatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of OrganizationBalancesExport */
export type OrganizationBalancesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of OrganizationBalancesExport */
export type OrganizationBalancesExportPayload = {
  __typename?: 'OrganizationBalancesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type OrganizationBillingAccounts = {
  __typename?: 'OrganizationBillingAccounts';
  billingAccounts: Array<BillingAccount>;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationData = {
  __typename?: 'OrganizationData';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditLimitRecommendationMoney?: Maybe<DnBMoney>;
  duns: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Phone>;
  primaryAddress?: Maybe<PrimaryAddress>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type OrganizationDeals = {
  __typename?: 'OrganizationDeals';
  deals: Array<Deal>;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationPersons = {
  __typename?: 'OrganizationPersons';
  persons: Array<Person>;
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of OrganizationTransferToRegion */
export type OrganizationTransferToRegionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  toRegion: RegionEnum;
};

/** Autogenerated return type of OrganizationTransferToRegion */
export type OrganizationTransferToRegionPayload = {
  __typename?: 'OrganizationTransferToRegionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsBulkActionsUpdateProductionManager */
export type OrganizationsBulkActionsUpdateProductionManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationIds: Array<Scalars['ID']['input']>;
  pmId: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsBulkActionsUpdateProductionManager */
export type OrganizationsBulkActionsUpdateProductionManagerPayload = {
  __typename?: 'OrganizationsBulkActionsUpdateProductionManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of OrganizationsCreate */
export type OrganizationsCreateInput = {
  attributes: OmsInputsOrganizationType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OrganizationsCreate */
export type OrganizationsCreatePayload = {
  __typename?: 'OrganizationsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsExport */
export type OrganizationsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of OrganizationsExport */
export type OrganizationsExportPayload = {
  __typename?: 'OrganizationsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of OrganizationsMerge */
export type OrganizationsMergeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mergedOrganizationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsMerge */
export type OrganizationsMergePayload = {
  __typename?: 'OrganizationsMergePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsReactivationTransfer */
export type OrganizationsReactivationTransferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsReactivationTransfer */
export type OrganizationsReactivationTransferPayload = {
  __typename?: 'OrganizationsReactivationTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsRequestDescription */
export type OrganizationsRequestDescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsRequestDescription */
export type OrganizationsRequestDescriptionPayload = {
  __typename?: 'OrganizationsRequestDescriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsUpdate */
export type OrganizationsUpdateInput = {
  attributes: OmsInputsOrganizationType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsUpdate */
export type OrganizationsUpdatePayload = {
  __typename?: 'OrganizationsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of OrganizationsUpdateProductionManager */
export type OrganizationsUpdateProductionManagerInput = {
  attributes: OmsInputsOrganizationUpdatePmCascade;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OrganizationsUpdateProductionManager */
export type OrganizationsUpdateProductionManagerPayload = {
  __typename?: 'OrganizationsUpdateProductionManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

export type OutcomingInvoice = {
  __typename?: 'OutcomingInvoice';
  billingAccount?: Maybe<Scalars['JSON']['output']>;
  cashCollectionComment?: Maybe<Scalars['String']['output']>;
  cashCollectionStatus?: Maybe<OutcomingInvoicesCashCollectionEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote: Scalars['Boolean']['output'];
  creditNoteReason?: Maybe<OutcomingInvoicesCreditNoteReasonEnumType>;
  creditNoteReasonClaim?: Maybe<Claim>;
  creditNotes?: Maybe<Array<OutcomingInvoice>>;
  currency: Scalars['String']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  files: Array<AttachedFile>;
  id: Scalars['Int']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  invoiceChecked?: Maybe<OutcomingInvoiceByAt>;
  invoiceNeedFix?: Maybe<OutcomingInvoiceByAt>;
  invoiceReady?: Maybe<OutcomingInvoiceByAt>;
  isStorno: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  negotiatedDueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  organizationVat?: Maybe<Scalars['String']['output']>;
  overrideAccountingDataDisabled: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentInfo?: Maybe<Scalars['String']['output']>;
  paymentRelations?: Maybe<Array<PaymentOutcomingInvoiceRelation>>;
  paymentTermsDate?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentTermsDays?: Maybe<Scalars['Int']['output']>;
  paymentTermsType?: Maybe<PaymentTermsTypeEnum>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<InvoicePosition>>;
  refInvoice?: Maybe<OutcomingInvoice>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  shippingInfo?: Maybe<OutcomingInvoicesShippingInfoType>;
  state: Scalars['String']['output'];
  toBePaidAmountMoney?: Maybe<Money>;
  toBePaidInDifferentCurrency: Scalars['Boolean']['output'];
  totalAmountBrutto?: Maybe<Scalars['Float']['output']>;
  totalAmountBruttoMoney: Money;
  totalAmountNetto?: Maybe<Scalars['Float']['output']>;
  totalAmountNettoMoney: Money;
  uiNumber: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type OutcomingInvoiceFilesArgs = {
  context?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type OutcomingInvoicePaymentInfoArgs = {
  paidAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  paymentRelations: Array<OutcomingInvoicesPaymentRelationType>;
  sourceShippingId?: InputMaybe<Scalars['Int']['input']>;
  totalAmountBruttoMoney?: InputMaybe<OmsInputsMoneyType>;
};


export type OutcomingInvoiceShippingInfoArgs = {
  shippingIds: Array<Scalars['Int']['input']>;
};

export type OutcomingInvoiceByAt = {
  __typename?: 'OutcomingInvoiceByAt';
  at: Scalars['String']['output'];
  by: Scalars['String']['output'];
};

export type OutcomingInvoiceExport = {
  __typename?: 'OutcomingInvoiceExport';
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of OutcomingInvoiceExportZip */
export type OutcomingInvoiceExportZipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
};

/** Autogenerated return type of OutcomingInvoiceExportZip */
export type OutcomingInvoiceExportZipPayload = {
  __typename?: 'OutcomingInvoiceExportZipPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exportZipState: ZipState;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of OutcomingInvoicesBulkDatevExport */
export type OutcomingInvoicesBulkDatevExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of OutcomingInvoicesBulkDatevExport */
export type OutcomingInvoicesBulkDatevExportPayload = {
  __typename?: 'OutcomingInvoicesBulkDatevExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  export: OutcomingInvoiceExport;
};

/** Autogenerated input type of OutcomingInvoicesBulkIntacctExport */
export type OutcomingInvoicesBulkIntacctExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of OutcomingInvoicesBulkIntacctExport */
export type OutcomingInvoicesBulkIntacctExportPayload = {
  __typename?: 'OutcomingInvoicesBulkIntacctExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  export: IntacctOutcomingInvoiceExportType;
};

/** Autogenerated input type of OutcomingInvoicesBulkMarkAsPaid */
export type OutcomingInvoicesBulkMarkAsPaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  invoiceIds: Array<Scalars['ID']['input']>;
  markAsRegistered: Scalars['Boolean']['input'];
  paidAt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  transactionType: Scalars['String']['input'];
};

/** Autogenerated return type of OutcomingInvoicesBulkMarkAsPaid */
export type OutcomingInvoicesBulkMarkAsPaidPayload = {
  __typename?: 'OutcomingInvoicesBulkMarkAsPaidPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export enum OutcomingInvoicesCashCollectionEnum {
  /** Credit Reform */
  CreditReform = 'credit_reform',
  /** Credit Reform Failed */
  CreditReformFailed = 'credit_reform_failed',
  /** Credit Reform Paid */
  CreditReformPaid = 'credit_reform_paid',
  /** Insolvency */
  Insolvency = 'insolvency',
  /** KAM */
  Kam = 'kam',
  /** Production Manager */
  ProductionManager = 'production_manager',
  /** Ship And Hold */
  ShipAndHold = 'ship_and_hold'
}

export enum OutcomingInvoicesCreditNoteReasonEnumType {
  /** Claim */
  Claim = 'claim',
  /** Wrong BA (reissue) */
  WrongBa = 'wrong_ba',
  /** Wrong Prices or Quantity */
  WrongPriceOrQuantity = 'wrong_price_or_quantity'
}

/** Autogenerated input type of OutcomingInvoicesExport */
export type OutcomingInvoicesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of OutcomingInvoicesExport */
export type OutcomingInvoicesExportPayload = {
  __typename?: 'OutcomingInvoicesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of OutcomingInvoicesOverrideDatev */
export type OutcomingInvoicesOverrideDatevInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  remark?: InputMaybe<Scalars['String']['input']>;
  sachkontoNumber?: InputMaybe<Scalars['String']['input']>;
  vatCode?: InputMaybe<Scalars['String']['input']>;
  vatPercent: Scalars['Int']['input'];
  xometryVatCountry?: InputMaybe<XometryVatCountryEnum>;
};

/** Autogenerated return type of OutcomingInvoicesOverrideDatev */
export type OutcomingInvoicesOverrideDatevPayload = {
  __typename?: 'OutcomingInvoicesOverrideDatevPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: OutcomingInvoice;
};

/** Autogenerated input type of OutcomingInvoicesOverridePaymentTermsDate */
export type OutcomingInvoicesOverridePaymentTermsDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  issuedAt: Scalars['ISO8601Date']['input'];
  paymentTermsDate: Scalars['ISO8601Date']['input'];
  paymentTermsType: PaymentTermsTypeEnum;
};

/** Autogenerated return type of OutcomingInvoicesOverridePaymentTermsDate */
export type OutcomingInvoicesOverridePaymentTermsDatePayload = {
  __typename?: 'OutcomingInvoicesOverridePaymentTermsDatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: OutcomingInvoice;
};

export type OutcomingInvoicesPaymentRelationType = {
  paymentId: Scalars['Int']['input'];
  totalAmountBrutto: Scalars['Float']['input'];
};

/** Autogenerated input type of OutcomingInvoicesReject */
export type OutcomingInvoicesRejectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of OutcomingInvoicesReject */
export type OutcomingInvoicesRejectPayload = {
  __typename?: 'OutcomingInvoicesRejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of OutcomingInvoicesRollbackMikrojumpSync */
export type OutcomingInvoicesRollbackMikrojumpSyncInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OutcomingInvoicesRollbackMikrojumpSync */
export type OutcomingInvoicesRollbackMikrojumpSyncPayload = {
  __typename?: 'OutcomingInvoicesRollbackMikrojumpSyncPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: OutcomingInvoice;
};

/** Autogenerated input type of OutcomingInvoicesSendToMikrojump */
export type OutcomingInvoicesSendToMikrojumpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of OutcomingInvoicesSendToMikrojump */
export type OutcomingInvoicesSendToMikrojumpPayload = {
  __typename?: 'OutcomingInvoicesSendToMikrojumpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invoice: OutcomingInvoice;
};

export type OutcomingInvoicesShippingInfoType = {
  __typename?: 'OutcomingInvoicesShippingInfoType';
  kind: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Overview = {
  __typename?: 'Overview';
  auditState: Scalars['String']['output'];
  enquiryId?: Maybe<Scalars['Int']['output']>;
  isDrawingsOk: Scalars['Boolean']['output'];
  parts: Array<Part>;
  quantityPcs: Scalars['Int']['output'];
  quantityTotal: Scalars['Int']['output'];
  source: Scalars['String']['output'];
};

export type PackageTrackingEvent = {
  __typename?: 'PackageTrackingEvent';
  createdAt: Scalars['ISO8601DateTime']['output'];
  eventPattern: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  logisticOperator: Scalars['String']['output'];
  packageStatus: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of PackageTrackingEventsCreate */
export type PackageTrackingEventsCreateInput = {
  attributes: OmsInputsPackageTrackingEventType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PackageTrackingEventsCreate */
export type PackageTrackingEventsCreatePayload = {
  __typename?: 'PackageTrackingEventsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  packageTrackingEvent: PackageTrackingEvent;
};

/** Autogenerated input type of PackageTrackingEventsDelete */
export type PackageTrackingEventsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PackageTrackingEventsDelete */
export type PackageTrackingEventsDeletePayload = {
  __typename?: 'PackageTrackingEventsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of PackageTrackingEventsUpdate */
export type PackageTrackingEventsUpdateInput = {
  attributes: OmsInputsPackageTrackingEventType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PackageTrackingEventsUpdate */
export type PackageTrackingEventsUpdatePayload = {
  __typename?: 'PackageTrackingEventsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  packageTrackingEvent: PackageTrackingEvent;
};

export type PaginationResult = {
  __typename?: 'PaginationResult';
  collection: Array<PaginationUnion>;
  currentPage: Scalars['Int']['output'];
  firstPage: Scalars['Boolean']['output'];
  lastPage: Scalars['Boolean']['output'];
  limitValue: Scalars['Int']['output'];
  nextPage?: Maybe<Scalars['Int']['output']>;
  outOfRange: Scalars['Boolean']['output'];
  prevPage?: Maybe<Scalars['Int']['output']>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaginationUnion = ExportBillingAccountType | ExportProviderType | LogisticInvoicePosition | Shipping;

export type Part = {
  __typename?: 'Part';
  auditReviewRemark: Scalars['String']['output'];
  auditState: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerNote?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['Int']['output']>;
  dimensions?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<AttachedFile>>;
  hsCode?: Maybe<Scalars['String']['output']>;
  hsCodeName?: Maybe<Scalars['String']['output']>;
  hsCodeRef?: Maybe<PartHsCode>;
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  isExpress?: Maybe<Scalars['Boolean']['output']>;
  itemMassKg?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use measurement_protocol_needed instead */
  measurementProtocol?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  postProcessing?: Maybe<Scalars['String']['output']>;
  preQuoted?: Maybe<Scalars['Boolean']['output']>;
  preQuotedBy?: Maybe<Provider>;
  preQuotedComment?: Maybe<Scalars['String']['output']>;
  productionRemark?: Maybe<Scalars['String']['output']>;
  qtyClaim: Scalars['Int']['output'];
  qtyDelivered: Scalars['Int']['output'];
  qtyInProduction: Scalars['Int']['output'];
  qtyOrdered: Scalars['Int']['output'];
  qtyShipped: Scalars['Int']['output'];
  relatedObjects: Array<RelatedObject>;
  samplesComment?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  samplesQuantity?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Tag>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  volumeMm3?: Maybe<Scalars['Float']['output']>;
};


export type PartTagsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type PartHsCode = {
  __typename?: 'PartHsCode';
  autoCode?: Maybe<Scalars['String']['output']>;
  autoPartName?: Maybe<Scalars['String']['output']>;
  autoPurpose?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  confirmedBy?: Maybe<Scalars['Int']['output']>;
  confirmedByRef?: Maybe<User>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  historyCode?: Maybe<Scalars['String']['output']>;
  historyPurpose?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  manualCode?: Maybe<Scalars['String']['output']>;
  manualPartName?: Maybe<Scalars['String']['output']>;
  manualPurpose?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partId: Scalars['BigInt']['output'];
  purpose?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PartStateCorrection = {
  __typename?: 'PartStateCorrection';
  comment: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  part: Part;
  partId: Scalars['Int']['output'];
  publishedBy?: Maybe<User>;
  publishedById?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Int']['output'];
};

/** Autogenerated input type of PartStateCorrectionsCreate */
export type PartStateCorrectionsCreateInput = {
  attributes: OmsInputsPartStateCorrectionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PartStateCorrectionsCreate */
export type PartStateCorrectionsCreatePayload = {
  __typename?: 'PartStateCorrectionsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  partStateCorrection: PartStateCorrection;
};

/** Autogenerated input type of PartStateCorrectionsDestroy */
export type PartStateCorrectionsDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PartStateCorrectionsDestroy */
export type PartStateCorrectionsDestroyPayload = {
  __typename?: 'PartStateCorrectionsDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  partStateCorrection: PartStateCorrection;
};

/** Autogenerated input type of PartStateCorrectionsUpdate */
export type PartStateCorrectionsUpdateInput = {
  attributes: OmsInputsPartStateCorrectionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PartStateCorrectionsUpdate */
export type PartStateCorrectionsUpdatePayload = {
  __typename?: 'PartStateCorrectionsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  partStateCorrection: PartStateCorrection;
};

export type PartStateRecord = {
  __typename?: 'PartStateRecord';
  corrections?: Maybe<CorrectionReferences>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  documentReferences?: Maybe<DocumentReferences>;
  id: Scalars['ID']['output'];
  invCustomer: Scalars['Int']['output'];
  invPartner: Scalars['Int']['output'];
  invWriteOff: Scalars['Int']['output'];
  orderedActual: Scalars['Int']['output'];
  orderedHold: Scalars['Int']['output'];
  part: Part;
  partId: Scalars['Int']['output'];
  prodDelivered: Scalars['Int']['output'];
  prodPlaced: Scalars['Int']['output'];
  prodPlacing: Scalars['Int']['output'];
  prodProcessing: Scalars['Int']['output'];
  prodShipped2c: Scalars['Int']['output'];
  prodShipped2x: Scalars['Int']['output'];
  prodWarehouse: Scalars['Int']['output'];
  qaClaims: Scalars['Int']['output'];
  qaIssues: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PartStateRecordsGroup = {
  __typename?: 'PartStateRecordsGroup';
  corrections: Array<PartStateCorrection>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  partId: Scalars['Int']['output'];
  records: Array<PartStateRecord>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Participants = {
  __typename?: 'Participants';
  assignedSa?: Maybe<User>;
  assignedSaId?: Maybe<Scalars['ID']['output']>;
  auditEngineers: Array<User>;
  auditEngineersIds?: Maybe<Array<Scalars['ID']['output']>>;
  deal: Deal;
  dealId: Scalars['ID']['output'];
  draftingEngineers: Array<User>;
  draftingEngineersIds?: Maybe<Array<Scalars['ID']['output']>>;
  jobOwners: Array<User>;
  jobOwnersIds?: Maybe<Array<Scalars['ID']['output']>>;
  kam?: Maybe<User>;
  kamId?: Maybe<Scalars['ID']['output']>;
  orderedBy?: Maybe<OrderedBy>;
  orderedById?: Maybe<Scalars['ID']['output']>;
  orderedByType?: Maybe<Scalars['String']['output']>;
  partnerNetworkManagers: Array<User>;
  partnerNetworkManagersIds?: Maybe<Array<Scalars['ID']['output']>>;
  pricingEngineer?: Maybe<User>;
  pricingEngineerId?: Maybe<Scalars['ID']['output']>;
  productionManager?: Maybe<User>;
  productionManagerId?: Maybe<Scalars['ID']['output']>;
  rfqOwners: Array<User>;
  rfqOwnersIds?: Maybe<Array<Scalars['ID']['output']>>;
  shippingOwners: Array<User>;
  shippingOwnersIds?: Maybe<Array<Scalars['ID']['output']>>;
};

/** Autogenerated input type of PartnerBalancesExport */
export type PartnerBalancesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of PartnerBalancesExport */
export type PartnerBalancesExportPayload = {
  __typename?: 'PartnerBalancesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of PartsBulkUpdateRfqStatuses */
export type PartsBulkUpdateRfqStatusesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  partIds: Array<Scalars['ID']['input']>;
  rfqStatus: Scalars['String']['input'];
};

/** Autogenerated return type of PartsBulkUpdateRfqStatuses */
export type PartsBulkUpdateRfqStatusesPayload = {
  __typename?: 'PartsBulkUpdateRfqStatusesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of PartsPromotionsDestroyDiscount */
export type PartsPromotionsDestroyDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PartsPromotionsDestroyDiscount */
export type PartsPromotionsDestroyDiscountPayload = {
  __typename?: 'PartsPromotionsDestroyDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of PartsPromotionsUpdateDiscount */
export type PartsPromotionsUpdateDiscountInput = {
  attributes: OmsInputsPromotionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  partId: Scalars['ID']['input'];
};

/** Autogenerated return type of PartsPromotionsUpdateDiscount */
export type PartsPromotionsUpdateDiscountPayload = {
  __typename?: 'PartsPromotionsUpdateDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiryPromotion: OmsEnquiryPromotionType;
};

export type Payment = {
  __typename?: 'Payment';
  amountBrut?: Maybe<Scalars['Float']['output']>;
  amountBrutMoney: Money;
  amountNet?: Maybe<Scalars['Float']['output']>;
  amountNetMoney: Money;
  amountReceived?: Maybe<Scalars['Float']['output']>;
  amountReceivedMoney: Money;
  billingAccount?: Maybe<BillingAccount>;
  billingAccountId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  deal: Deal;
  id: Scalars['Int']['output'];
  invoiceRelations?: Maybe<Array<PaymentOutcomingInvoiceRelation>>;
  isRefund: Scalars['Boolean']['output'];
  method?: Maybe<Scalars['JSON']['output']>;
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  positions?: Maybe<Array<PaymentPosition>>;
  refundMethodType?: Maybe<RefundMethodTypesEnum>;
  refunds?: Maybe<Array<Payment>>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  source?: Maybe<PaymentsSourceType>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type PaymentBalanceTerm = {
  __typename?: 'PaymentBalanceTerm';
  invoiceBalanceMoney: Money;
  lines?: Maybe<Array<BalanceLine>>;
  orderedNotInvoicedMoney: Money;
  overdueMoney: Money;
  prepaidMoney: Money;
};

export type PaymentOutcomingInvoiceRelation = {
  __typename?: 'PaymentOutcomingInvoiceRelation';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote?: Maybe<OutcomingInvoice>;
  date: Scalars['ISO8601Date']['output'];
  deal: Deal;
  id: Scalars['Int']['output'];
  outcomingInvoice: OutcomingInvoice;
  payment: Payment;
  refund?: Maybe<Payment>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  totalAmountBrutto: Scalars['Float']['output'];
  totalAmountBruttoMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of PaymentOutcomingInvoiceRelationsCreateExportToDatev */
export type PaymentOutcomingInvoiceRelationsCreateExportToDatevInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durationFrom: Scalars['ISO8601Date']['input'];
  durationTo: Scalars['ISO8601Date']['input'];
  ids: Array<Scalars['ID']['input']>;
  period: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of PaymentOutcomingInvoiceRelationsCreateExportToDatev */
export type PaymentOutcomingInvoiceRelationsCreateExportToDatevPayload = {
  __typename?: 'PaymentOutcomingInvoiceRelationsCreateExportToDatevPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  datevPaymentOutcomingInvoiceRelationsExport: PaymentOutcomingInvoiceRelationsExport;
};

export type PaymentOutcomingInvoiceRelationsExport = {
  __typename?: 'PaymentOutcomingInvoiceRelationsExport';
  accountingPeriod: Scalars['ISO8601Date']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom: Scalars['ISO8601Date']['output'];
  durationTo: Scalars['ISO8601Date']['output'];
  id: Scalars['Int']['output'];
  relations?: Maybe<Array<PaymentOutcomingInvoiceRelation>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of PaymentOutcomingInvoiceRelationsUpdateRegisteredAt */
export type PaymentOutcomingInvoiceRelationsUpdateRegisteredAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  registeredAt: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of PaymentOutcomingInvoiceRelationsUpdateRegisteredAt */
export type PaymentOutcomingInvoiceRelationsUpdateRegisteredAtPayload = {
  __typename?: 'PaymentOutcomingInvoiceRelationsUpdateRegisteredAtPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  relations: Array<PaymentOutcomingInvoiceRelation>;
};

export type PaymentPosition = {
  __typename?: 'PaymentPosition';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalBrut?: Maybe<Scalars['Float']['output']>;
  totalBrutMoney?: Maybe<Money>;
  totalNet?: Maybe<Scalars['Float']['output']>;
  totalNetMoney?: Maybe<Money>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum PaymentTermsTypeEnum {
  FirstDayOfNextMonth = 'first_day_of_next_month',
  Standard = 'standard'
}

/** Autogenerated input type of PaymentsCreateExportToDatev */
export type PaymentsCreateExportToDatevInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  durationFrom?: InputMaybe<Scalars['String']['input']>;
  durationTo?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
  methodType?: InputMaybe<Scalars['String']['input']>;
  period: Scalars['String']['input'];
  selectedType?: InputMaybe<Scalars['String']['input']>;
  withRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of PaymentsCreateExportToDatev */
export type PaymentsCreateExportToDatevPayload = {
  __typename?: 'PaymentsCreateExportToDatevPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  datevPaymentsExport: PaymentsExport;
};

export type PaymentsExport = {
  __typename?: 'PaymentsExport';
  accountingPeriod: Scalars['ISO8601Date']['output'];
  additionalData?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  csvReports?: Maybe<Array<AttachedFile>>;
  durationFrom?: Maybe<Scalars['ISO8601Date']['output']>;
  durationTo?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  payments?: Maybe<Array<Payment>>;
  payouts?: Maybe<Array<Payout>>;
  selected: Array<Scalars['Int']['output']>;
  selectedSize: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of PaymentsExport */
export type PaymentsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of PaymentsExport */
export type PaymentsExportPayload = {
  __typename?: 'PaymentsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type PaymentsSourceType = {
  __typename?: 'PaymentsSourceType';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney?: Maybe<Money>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PaymentsTransferBetweenDeals */
export type PaymentsTransferBetweenDealsInput = {
  amountBrut: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  datevComment?: InputMaybe<Scalars['String']['input']>;
  intacctComment?: InputMaybe<Scalars['String']['input']>;
  newDealId: Scalars['ID']['input'];
  paidAt: Scalars['String']['input'];
  paymentId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};

/** Autogenerated return type of PaymentsTransferBetweenDeals */
export type PaymentsTransferBetweenDealsPayload = {
  __typename?: 'PaymentsTransferBetweenDealsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  refundPayment: Payment;
};

/** Autogenerated input type of PaymentsUpdateRegisteredAt */
export type PaymentsUpdateRegisteredAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  exportId: Scalars['ID']['input'];
  overwrite: Scalars['Boolean']['input'];
  registeredAt: Scalars['String']['input'];
};

/** Autogenerated return type of PaymentsUpdateRegisteredAt */
export type PaymentsUpdateRegisteredAtPayload = {
  __typename?: 'PaymentsUpdateRegisteredAtPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payments: Array<Payment>;
};

/** Autogenerated input type of PaymetsUpdateMethod */
export type PaymetsUpdateMethodInput = {
  attributes: OmsInputsPaymentsUpdateMethodType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PaymetsUpdateMethod */
export type PaymetsUpdateMethodPayload = {
  __typename?: 'PaymetsUpdateMethodPayload';
  bankPayment?: Maybe<WorkspacesUpdatedBankPayment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type Payout = {
  __typename?: 'Payout';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney: Money;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  datevComment?: Maybe<Scalars['String']['output']>;
  deal: Deal;
  dealId: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fastPay: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instantPay: Scalars['Boolean']['output'];
  intacctComment?: Maybe<Scalars['String']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isRefund: Scalars['Boolean']['output'];
  method?: Maybe<Method>;
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  paymentDetails?: Maybe<Scalars['String']['output']>;
  prepayment: Scalars['Boolean']['output'];
  provider: Provider;
  providerId: Scalars['ID']['output'];
  realloc?: Maybe<Payout>;
  refunds: Array<Payout>;
  source: PayoutSourceType;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  state: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of PayoutBankPaymentApproveAndCreate */
export type PayoutBankPaymentApproveAndCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  payoutId: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutBankPaymentApproveAndCreate */
export type PayoutBankPaymentApproveAndCreatePayload = {
  __typename?: 'PayoutBankPaymentApproveAndCreatePayload';
  bankPayment: BankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type PayoutSourceType = IncomingInvoice | Payout | ProviderOrder;

/** Autogenerated input type of PayoutsBulkBankPaymentApproveAndCreate */
export type PayoutsBulkBankPaymentApproveAndCreateInput = {
  attributes: Scalars['JSON']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PayoutsBulkBankPaymentApproveAndCreate */
export type PayoutsBulkBankPaymentApproveAndCreatePayload = {
  __typename?: 'PayoutsBulkBankPaymentApproveAndCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of PayoutsBulkSetAsPaid */
export type PayoutsBulkSetAsPaidInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of PayoutsBulkSetAsPaid */
export type PayoutsBulkSetAsPaidPayload = {
  __typename?: 'PayoutsBulkSetAsPaidPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of PayoutsCancel */
export type PayoutsCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutsCancel */
export type PayoutsCancelPayload = {
  __typename?: 'PayoutsCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

/** Autogenerated input type of PayoutsCompleteAgainstAnother */
export type PayoutsCompleteAgainstAnotherInput = {
  attributes: OmsInputsPayoutCompleteAgainstAnotherType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutsCompleteAgainstAnother */
export type PayoutsCompleteAgainstAnotherPayload = {
  __typename?: 'PayoutsCompleteAgainstAnotherPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

/** Autogenerated input type of PayoutsComplete */
export type PayoutsCompleteInput = {
  attributes: OmsInputsPayoutCompleteType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutsComplete */
export type PayoutsCompletePayload = {
  __typename?: 'PayoutsCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

/** Autogenerated input type of PayoutsCreate */
export type PayoutsCreateInput = {
  attributes: OmsInputsPayoutType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of PayoutsCreate */
export type PayoutsCreatePayload = {
  __typename?: 'PayoutsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

/** Autogenerated input type of PayoutsDestroy */
export type PayoutsDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutsDestroy */
export type PayoutsDestroyPayload = {
  __typename?: 'PayoutsDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

/** Autogenerated input type of PayoutsExport */
export type PayoutsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of PayoutsExport */
export type PayoutsExportPayload = {
  __typename?: 'PayoutsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of PayoutsUpdate */
export type PayoutsUpdateInput = {
  attributes: OmsInputsPayoutType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of PayoutsUpdateMethod */
export type PayoutsUpdateMethodInput = {
  attributes: OmsInputsPayoutsUpdateMethodType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PayoutsUpdateMethod */
export type PayoutsUpdateMethodPayload = {
  __typename?: 'PayoutsUpdateMethodPayload';
  bankPayment: WorkspacesUpdatedBankPayment;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of PayoutsUpdate */
export type PayoutsUpdatePayload = {
  __typename?: 'PayoutsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payout: Payout;
};

export type PdUser = {
  __typename?: 'PdUser';
  id?: Maybe<Scalars['BigInt']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Performance = {
  __typename?: 'Performance';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  ordersBePaidCount?: Maybe<Scalars['BigInt']['output']>;
  ordersBePaidValue?: Maybe<Scalars['BigInt']['output']>;
  ordersInProductionCount?: Maybe<Scalars['BigInt']['output']>;
  ordersInProductionMoney?: Maybe<Money>;
  ordersInProductionValue?: Maybe<Scalars['BigInt']['output']>;
  ordersPaidCount?: Maybe<Scalars['BigInt']['output']>;
  ordersPaidValue?: Maybe<Scalars['BigInt']['output']>;
  totalOrdersCount?: Maybe<Scalars['BigInt']['output']>;
  totalOrdersValue?: Maybe<Scalars['BigInt']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Person = {
  __typename?: 'Person';
  billingAccountId?: Maybe<Scalars['ID']['output']>;
  billingAccountPerson?: Maybe<BillingAccountsPerson>;
  billingAccounts: Array<BillingAccount>;
  billingAccountsPersons: Array<BillingAccountsPerson>;
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  carbonCopyEmails: Array<Scalars['String']['output']>;
  contact?: Maybe<PersonContact>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deals?: Maybe<Array<Deal>>;
  dealsOrderedCount: Scalars['Int']['output'];
  dealsTotalCount: Scalars['Int']['output'];
  defaultBillingAccount?: Maybe<BillingAccount>;
  defaultShippingAddress?: Maybe<ShippingAddress>;
  deleted: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['String']['output'];
  emailHasPublicDomain: Scalars['Boolean']['output'];
  externalCrmId?: Maybe<Scalars['String']['output']>;
  externalCrmUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inLoyaltyProgram: Scalars['Boolean']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  invoiceEmails: Array<Scalars['String']['output']>;
  isRobot: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  logisticEmails: Array<Scalars['String']['output']>;
  mailingSuspendedTill?: Maybe<Scalars['ISO8601Date']['output']>;
  mailingsSubscription?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  movedTo?: Maybe<IntercompanyObject>;
  movedToRegion?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  nps?: Maybe<Scalars['String']['output']>;
  ocEmails: Array<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  outcomingInvoiceEmail: Scalars['String']['output'];
  owner?: Maybe<PdUser>;
  paymentReminderEmails: Array<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveUrl: Scalars['String']['output'];
  shippingAddresses?: Maybe<Array<ShippingAddress>>;
  technicalEmails: Array<Scalars['String']['output']>;
  tpoEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  whatsappAllowed: Scalars['Boolean']['output'];
  whatsappNumber?: Maybe<Scalars['String']['output']>;
};


export type PersonBillingAccountPersonArgs = {
  billingAccountId: Scalars['ID']['input'];
};

export type PersonContact = {
  __typename?: 'PersonContact';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastVisitedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  verifiedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of PersonsCreate */
export type PersonsCreateInput = {
  attributes: OmsInputsPersonType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PersonsCreate */
export type PersonsCreatePayload = {
  __typename?: 'PersonsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsExport */
export type PersonsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of PersonsExport */
export type PersonsExportPayload = {
  __typename?: 'PersonsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of PersonsImpersonate */
export type PersonsImpersonateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsImpersonate */
export type PersonsImpersonatePayload = {
  __typename?: 'PersonsImpersonatePayload';
  accessToken: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PersonsMerge */
export type PersonsMergeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mergedPersonId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsMerge */
export type PersonsMergePayload = {
  __typename?: 'PersonsMergePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsOrganizationChange */
export type PersonsOrganizationChangeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsOrganizationChange */
export type PersonsOrganizationChangePayload = {
  __typename?: 'PersonsOrganizationChangePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsReactivationTransfer */
export type PersonsReactivationTransferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsReactivationTransfer */
export type PersonsReactivationTransferPayload = {
  __typename?: 'PersonsReactivationTransferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsSyncContact */
export type PersonsSyncContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsSyncContact */
export type PersonsSyncContactPayload = {
  __typename?: 'PersonsSyncContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsUpateDefaultAddress */
export type PersonsUpateDefaultAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  shippingAddressId: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsUpateDefaultAddress */
export type PersonsUpateDefaultAddressPayload = {
  __typename?: 'PersonsUpateDefaultAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsUpdate */
export type PersonsUpdateInput = {
  attributes: OmsInputsPersonType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsUpdate */
export type PersonsUpdatePayload = {
  __typename?: 'PersonsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

/** Autogenerated input type of PersonsVerifyWhatsAppNumber */
export type PersonsVerifyWhatsAppNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PersonsVerifyWhatsAppNumber */
export type PersonsVerifyWhatsAppNumberPayload = {
  __typename?: 'PersonsVerifyWhatsAppNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  person: Person;
};

export type Phone = {
  __typename?: 'Phone';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  isdCode?: Maybe<Scalars['String']['output']>;
  telephoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum PinnedDirectionsEnum {
  All = 'All',
  Finance = 'Finance',
  Logistics = 'Logistics',
  Placing = 'Placing',
  Production = 'Production',
  Sales = 'Sales'
}

export type PinnedMessage = {
  __typename?: 'PinnedMessage';
  attachments: Array<PinnedMessageAttachment>;
  author: PinnedMessageAuthor;
  content?: Maybe<Scalars['String']['output']>;
  contentRaw?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isTask: Scalars['Boolean']['output'];
  isVisibleToClient: Scalars['Boolean']['output'];
  isVisibleToPartner: Scalars['Boolean']['output'];
  pinnedAt: Scalars['ISO8601DateTime']['output'];
  pinnedDirection: PinnedDirectionsEnum;
  region: RegionEnum;
  source: PinnedMessageSource;
};

export type PinnedMessageAttachment = {
  __typename?: 'PinnedMessageAttachment';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewLargeUrl?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  uuidId: Scalars['String']['output'];
};

export type PinnedMessageAuthor = {
  __typename?: 'PinnedMessageAuthor';
  authorType: UserEnum;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type PinnedMessageSource = {
  __typename?: 'PinnedMessageSource';
  dealId?: Maybe<Scalars['ID']['output']>;
  parent?: Maybe<PinnedMessageSource>;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
};

export type PinnedMessagesCollection = {
  __typename?: 'PinnedMessagesCollection';
  collection: Array<PinnedMessage>;
  failedRegions?: Maybe<Array<RegionEnum>>;
  total: Scalars['Int']['output'];
};

export type PipedriveStage = {
  __typename?: 'PipedriveStage';
  pipedrivePipeline: Scalars['String']['output'];
  pipedrivePipelineOrder: Scalars['Int']['output'];
  pipedriveStageId: Scalars['Int']['output'];
  pipedriveStageName?: Maybe<Scalars['String']['output']>;
  pipedriveStageOrder: Scalars['Int']['output'];
  pipedriveStageTitle: Scalars['String']['output'];
};

/** Deal Pipedrive status */
export enum PipedriveStatusEnum {
  /** deleted */
  Deleted = 'deleted',
  /** lost */
  Lost = 'lost',
  /** open */
  Open = 'open',
  /** won */
  Won = 'won'
}

export type PlacingData = {
  __typename?: 'PlacingData';
  cancellationRatio?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Position = {
  __typename?: 'Position';
  height: Scalars['Float']['output'];
  length: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  partId?: Maybe<Scalars['Int']['output']>;
  quantity: Scalars['Int']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type Price = {
  __typename?: 'Price';
  a2c?: Maybe<Money>;
  default: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  p2x?: Maybe<Money>;
  selected: Scalars['Boolean']['output'];
  sender: Scalars['String']['output'];
  total: Money;
  x2x?: Maybe<Money>;
};

export enum PriceMethodEnum {
  Auto = 'Auto',
  Manual = 'Manual',
  Rfq = 'RFQ',
  Tpo = 'TPO'
}

export type PrimaryAddress = {
  __typename?: 'PrimaryAddress';
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum Priority {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type ProductionEventType = {
  __typename?: 'ProductionEventType';
  endDate: Scalars['ISO8601Date']['output'];
  name: Scalars['String']['output'];
  productionEventId: Scalars['ID']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  type: EventsTypeEnum;
};

/** Autogenerated input type of ProductionEventsCreate */
export type ProductionEventsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['ISO8601Date']['input'];
  name: Scalars['String']['input'];
  providerId: Scalars['ID']['input'];
  startDate: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of ProductionEventsCreate */
export type ProductionEventsCreatePayload = {
  __typename?: 'ProductionEventsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ProductionEventsDelete */
export type ProductionEventsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProductionEventsDelete */
export type ProductionEventsDeletePayload = {
  __typename?: 'ProductionEventsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ProductionEventsUpdate */
export type ProductionEventsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['ISO8601Date']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['ISO8601Date']['input'];
};

/** Autogenerated return type of ProductionEventsUpdate */
export type ProductionEventsUpdatePayload = {
  __typename?: 'ProductionEventsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ProductionManager = {
  __typename?: 'ProductionManager';
  alarmDeals: Scalars['Int']['output'];
  consistencyCheckFailed: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealsOnHold: Scalars['Int']['output'];
  dealsPaidButOpen: Scalars['Int']['output'];
  dealsPartsNeedInfo: Scalars['Int']['output'];
  dealsSoldButNotPlaced: Scalars['Int']['output'];
  dealsWithSamples: Scalars['Int']['output'];
  dealsWonWithoutInvoices: Scalars['Int']['output'];
  frameworkDeals: Scalars['Int']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  importantDeals: Scalars['Int']['output'];
  incomingInvoicesNeedReview: Scalars['Int']['output'];
  newClaims: Scalars['Int']['output'];
  onHoldDeals: Scalars['Int']['output'];
  openCheckpoints: Scalars['Int']['output'];
  openClaims: Scalars['Int']['output'];
  openDeals: Scalars['Int']['output'];
  openIncomingInvoices: Scalars['Int']['output'];
  openOutcomingInvoices: Scalars['Int']['output'];
  openProductionOrders: Scalars['Int']['output'];
  openQualityControls: Scalars['Int']['output'];
  openShippings: Scalars['Int']['output'];
  openTasks: Scalars['Int']['output'];
  outcomingInvoicesNeedFix: Scalars['Int']['output'];
  partnerIsFixing: Scalars['Int']['output'];
  pmId?: Maybe<Scalars['ID']['output']>;
  poAbandoned: Scalars['Int']['output'];
  poLeadtimeOverdue: Scalars['Int']['output'];
  poPartiallyShipped: Scalars['Int']['output'];
  poProviderSelected: Scalars['Int']['output'];
  poSwitchToShipped: Scalars['Int']['output'];
  poWithoutInvoices: Scalars['Int']['output'];
  podId?: Maybe<Scalars['Int']['output']>;
  qcIssuesNeedVerdict: Scalars['Int']['output'];
  qcWithoutTechnicalVerdict: Scalars['Int']['output'];
  sendOc: Scalars['Int']['output'];
  sfCasesNew: Scalars['Int']['output'];
  sfCasesOnHold: Scalars['Int']['output'];
  sfCasesPending: Scalars['Int']['output'];
  sfCasesSolved: Scalars['Int']['output'];
  shippingsAbandoned: Scalars['Int']['output'];
  shippingsBoxReady: Scalars['Int']['output'];
  shippingsReadyForShipping: Scalars['Int']['output'];
  shippingsWithoutInvoices: Scalars['Int']['output'];
  storagesNotEmpty: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum ProductionManagerCascadeOptionEnum {
  /** Do not cascade update to any Deal */
  NoUpdate = 'no_update',
  /** Cascade update to OPEN Deals of this Org. with current PM */
  UpdateAll = 'update_all',
  /** Cascade update to OPEN Deals of this Org. */
  UpdateOpen = 'update_open',
  /** Cascade update to all Deals of this Org. */
  UpdateOpenWithCurrentPm = 'update_open_with_current_pm'
}

export enum ProductionStageEnum {
  /** calculated */
  Calculated = 'calculated',
  /** draft */
  Draft = 'draft',
  /** lost */
  Lost = 'lost',
  /** need_calculate */
  NeedCalculate = 'need_calculate',
  /** pending */
  Pending = 'pending',
  /** prod_delivered */
  ProdDelivered = 'prod_delivered',
  /** prod_placed */
  ProdPlaced = 'prod_placed',
  /** prod_placing */
  ProdPlacing = 'prod_placing',
  /** prod_processing */
  ProdProcessing = 'prod_processing',
  /** prod_shipped2c */
  ProdShipped2c = 'prod_shipped2c',
  /** prod_shipped2x */
  ProdShipped2x = 'prod_shipped2x',
  /** prod_warehouse */
  ProdWarehouse = 'prod_warehouse',
  /** submitted */
  Submitted = 'submitted',
  /** won */
  Won = 'won'
}

/** Autogenerated input type of PromotionsCreateDiscount */
export type PromotionsCreateDiscountInput = {
  attributes: OmsInputsPromotionDiscountType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PromotionsCreateDiscount */
export type PromotionsCreateDiscountPayload = {
  __typename?: 'PromotionsCreateDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of PromotionsCreate */
export type PromotionsCreateInput = {
  attributes: OmsInputsPromotionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PromotionsCreate */
export type PromotionsCreatePayload = {
  __typename?: 'PromotionsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiryPromotion: OmsEnquiryPromotionType;
};

/** Autogenerated input type of PromotionsDestroyDiscount */
export type PromotionsDestroyDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PromotionsDestroyDiscount */
export type PromotionsDestroyDiscountPayload = {
  __typename?: 'PromotionsDestroyDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiry: Enquiry;
};

/** Autogenerated input type of PromotionsUpdateDiscount */
export type PromotionsUpdateDiscountInput = {
  attributes: OmsInputsPromotionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enquiryId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PromotionsUpdateDiscount */
export type PromotionsUpdateDiscountPayload = {
  __typename?: 'PromotionsUpdateDiscountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiryPromotion: OmsEnquiryPromotionType;
};

/** Autogenerated input type of PromotionsUpdate */
export type PromotionsUpdateInput = {
  attributes: OmsInputsPromotionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of PromotionsUpdate */
export type PromotionsUpdatePayload = {
  __typename?: 'PromotionsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enquiryPromotion: OmsEnquiryPromotionType;
};

export type Provider = {
  __typename?: 'Provider';
  acceptSelfBilling: Scalars['Boolean']['output'];
  allowAutoacceptOffers?: Maybe<Scalars['Boolean']['output']>;
  allowAutoacceptOffersFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  allowDirectshipOrders?: Maybe<Scalars['Boolean']['output']>;
  allowDirectshipOrdersFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  autoacceptPoNumberLimit?: Maybe<Scalars['Int']['output']>;
  autoacceptPoNumberLimitFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  autoacceptPoValueLimit?: Maybe<Scalars['Float']['output']>;
  autoacceptPoValueLimitFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  autoacceptPoValueLimitMoney?: Maybe<Money>;
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankAccountHolderName?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankCountry?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  bankPayments?: Maybe<Array<BankPayment>>;
  billingAddress?: Maybe<Address>;
  comment?: Maybe<Scalars['String']['output']>;
  contacts: Array<Contact>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  datevKonto?: Maybe<Scalars['String']['output']>;
  decoratedName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  freeCapacity: Scalars['Boolean']['output'];
  freeCapacityFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  generatedComment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  incomingInvoices: Array<IncomingInvoice>;
  intacctId?: Maybe<Scalars['String']['output']>;
  isEuropean?: Maybe<Scalars['Boolean']['output']>;
  lastVisitAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  limitsComment?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notVatPayer: Scalars['Boolean']['output'];
  onImprovement: Scalars['Boolean']['output'];
  onImprovementComment?: Maybe<Scalars['String']['output']>;
  onImprovementFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  onImprovementStartedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  partnerNetworkManager?: Maybe<User>;
  partnerNetworkManagerFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  payoutTransferType?: Maybe<Scalars['String']['output']>;
  payouts: Array<Payout>;
  performance?: Maybe<Performance>;
  phone?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  placingData?: Maybe<PlacingData>;
  poNumberLimit?: Maybe<Scalars['Int']['output']>;
  poNumberLimitFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  poValueLimit?: Maybe<Scalars['Float']['output']>;
  poValueLimitFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  poValueLimitMoney?: Maybe<Money>;
  primaryContact?: Maybe<Contact>;
  primaryContactId?: Maybe<Scalars['Int']['output']>;
  productionHoliday: Scalars['Boolean']['output'];
  providerOrders?: Maybe<Array<ProviderOrder>>;
  publicComment?: Maybe<Scalars['String']['output']>;
  publicHoliday: Scalars['Boolean']['output'];
  rank?: Maybe<Rank>;
  registeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  salesforceId?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  state: ProviderStateEnum;
  tags: Array<Tag>;
  testPartner: Scalars['Boolean']['output'];
  updatedAfterRegistered?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


export type ProviderBankPaymentsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type ProviderGeneratedCommentArgs = {
  dealId: Scalars['ID']['input'];
};


export type ProviderIncomingInvoicesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type ProviderPayoutsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type ProviderBalanceRecord = {
  __typename?: 'ProviderBalanceRecord';
  balanceBrutto: Scalars['Float']['output'];
  balanceBruttoMoney: Money;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentDue: Scalars['Float']['output'];
  currentDueMoney: Money;
  date: Scalars['ISO8601Date']['output'];
  id: Scalars['Int']['output'];
  invoicedBrutto: Scalars['Float']['output'];
  invoicedBruttoMoney: Money;
  paidBrutto: Scalars['Float']['output'];
  paidBruttoMoney: Money;
  prepayments: Scalars['Float']['output'];
  prepaymentsMoney: Money;
  provider: Provider;
  providerId: Scalars['ID']['output'];
  providerOrderBalanceRecords?: Maybe<Array<ProviderOrderBalanceRecord>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ProviderOrder = {
  __typename?: 'ProviderOrder';
  additionalServices: Array<AdditionalService>;
  allowDirectShipping: Scalars['Boolean']['output'];
  attachedFiles: Array<AttachedFile>;
  conformityCheck?: Maybe<ConformityCheckEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  deadline?: Maybe<Scalars['String']['output']>;
  deadlineInitial?: Maybe<Scalars['ISO8601Date']['output']>;
  deadlineUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deadlineUpdatedBy?: Maybe<User>;
  deal: Deal;
  dealId: Scalars['Int']['output'];
  fullyPaidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['ID']['output'];
  incomingInvoices?: Maybe<Array<IncomingInvoice>>;
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lots: Array<Lot>;
  number: Scalars['String']['output'];
  partsValueNetto?: Maybe<Scalars['Float']['output']>;
  partsValueNettoMoney: Money;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  payouts: Array<Payout>;
  provider?: Maybe<Provider>;
  providerId: Scalars['ID']['output'];
  publicComment?: Maybe<Scalars['String']['output']>;
  qcReportRequired: Scalars['Boolean']['output'];
  relatedDeliveries: Array<Delivery>;
  relatedShippings: Array<Shipping>;
  renegotiatedValueNettoMoney: Money;
  samplesConfirmationStatus?: Maybe<ProviderOrdersSamplesConfirmationStatusEnum>;
  samplesConfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  samplesConfirmedBy?: Maybe<ProviderOrdersSamplesConfirmedBy>;
  samplesConfirmedById?: Maybe<Scalars['ID']['output']>;
  samplesMainProviderOrder?: Maybe<ProviderOrder>;
  samplesMainProviderOrderId?: Maybe<Scalars['ID']['output']>;
  samplesPoType?: Maybe<ProviderOrdersSamplesProviderOrderTypeEnum>;
  samplesProviderOrder?: Maybe<ProviderOrder>;
  samplesProviderOrderId?: Maybe<Scalars['ID']['output']>;
  secondaryProduction: Scalars['Boolean']['output'];
  sendReminders: Scalars['Boolean']['output'];
  servicesValueNetto?: Maybe<Scalars['Float']['output']>;
  servicesValueNettoMoney: Money;
  shipAllowDirectLegal?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectLegalXomSales?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectOrder?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectProvider?: Maybe<Scalars['Boolean']['output']>;
  shipDirection?: Maybe<ProviderOrdersShipDirectionEnum>;
  shipDirectionToEndCustomer?: Maybe<Scalars['Boolean']['output']>;
  shipIncoterms?: Maybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly: Scalars['Boolean']['output'];
  shipIncotermsCity?: Maybe<Scalars['String']['output']>;
  shipIncotermsCountry?: Maybe<Scalars['String']['output']>;
  /** @deprecated This field is deprecated, please use [ship_warehouse] instead. */
  shipQc?: Maybe<ProviderOrdersShipQcEnum>;
  shipQcRequired?: Maybe<Scalars['Boolean']['output']>;
  shipWarehouse?: Maybe<WarehouseInstructionEnum>;
  shipmentDateRevisedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  shippingInstructionsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingInstructionsUpdatedBy?: Maybe<User>;
  shippingProcessingInstruction?: Maybe<ShippingInstructionEnum>;
  state: ProviderOrderStateEnum;
  stateLastUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  totalAmountNet: Scalars['Float']['output'];
  totalAmountNetMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  visibleInPa: Scalars['Boolean']['output'];
  xometryVatNumber?: Maybe<XometryVatNumber>;
};


export type ProviderOrderAttachedFilesArgs = {
  context?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type ProviderOrderIncomingInvoicesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type ProviderOrderPayoutsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type ProviderOrderBalanceRecord = {
  __typename?: 'ProviderOrderBalanceRecord';
  balanceBrutto: Scalars['Float']['output'];
  balanceBruttoMoney: Money;
  createdAt: Scalars['ISO8601DateTime']['output'];
  date: Scalars['ISO8601Date']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  documentReferences: ProviderOrderBalanceRecordDocumentReferences;
  id: Scalars['Int']['output'];
  invoicedBrutto: Scalars['Float']['output'];
  invoicedBruttoMoney: Money;
  notCompletedObjects?: Maybe<ProviderOrderBalanceRecordDocumentReferences>;
  paidBrutto: Scalars['Float']['output'];
  paidBruttoMoney: Money;
  provider: Provider;
  providerId: Scalars['ID']['output'];
  providerOrder: ProviderOrder;
  providerOrderId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ProviderOrderBalanceRecordDocumentReference = {
  __typename?: 'ProviderOrderBalanceRecordDocumentReference';
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  displayName: Scalars['String']['output'];
  meta?: Maybe<ProviderOrderBalanceRecordDocumentReferenceMeta>;
  number?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['ID']['output'];
  objectType: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type ProviderOrderBalanceRecordDocumentReferenceMeta = {
  __typename?: 'ProviderOrderBalanceRecordDocumentReferenceMeta';
  creditNote?: Maybe<Scalars['Boolean']['output']>;
  isRefund?: Maybe<Scalars['Boolean']['output']>;
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  paymentDue?: Maybe<Scalars['ISO8601Date']['output']>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
};

export type ProviderOrderBalanceRecordDocumentReferences = {
  __typename?: 'ProviderOrderBalanceRecordDocumentReferences';
  invoiced?: Maybe<Array<ProviderOrderBalanceRecordDocumentReference>>;
  paid?: Maybe<Array<ProviderOrderBalanceRecordDocumentReference>>;
};

export type ProviderOrderLeadtimeEventType = {
  __typename?: 'ProviderOrderLeadtimeEventType';
  dealId: Scalars['ID']['output'];
  endDate: Scalars['ISO8601Date']['output'];
  name: Scalars['String']['output'];
  providerOrderId: Scalars['ID']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  type: EventsTypeEnum;
};

export enum ProviderOrderStateEnum {
  /** canceled */
  Canceled = 'canceled',
  /** placed */
  Placed = 'placed',
  /** produced */
  Produced = 'produced',
  /** provider_selected */
  ProviderSelected = 'provider_selected',
  /** shipped */
  Shipped = 'shipped'
}

/** Autogenerated input type of ProviderOrdersBalanceRecordsExportForProvider */
export type ProviderOrdersBalanceRecordsExportForProviderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersBalanceRecordsExportForProvider */
export type ProviderOrdersBalanceRecordsExportForProviderPayload = {
  __typename?: 'ProviderOrdersBalanceRecordsExportForProviderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ProviderOrdersCreate */
export type ProviderOrdersCreateInput = {
  attributes: OmsInputsProviderOrderCreateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProviderOrdersCreate */
export type ProviderOrdersCreatePayload = {
  __typename?: 'ProviderOrdersCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

/** Autogenerated input type of ProviderOrdersDestroy */
export type ProviderOrdersDestroyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersDestroy */
export type ProviderOrdersDestroyPayload = {
  __typename?: 'ProviderOrdersDestroyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

/** Autogenerated input type of ProviderOrdersExport */
export type ProviderOrdersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of ProviderOrdersExport */
export type ProviderOrdersExportPayload = {
  __typename?: 'ProviderOrdersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export enum ProviderOrdersIncotermsEnum {
  /** DAP */
  Dap = 'DAP',
  /** EXW */
  Exw = 'EXW'
}

export enum ProviderOrdersSamplesConfirmationStatusEnum {
  /** Confirmed */
  Confirmed = 'confirmed',
  /** Rejected */
  Rejected = 'rejected',
  /** Required */
  Required = 'required'
}

export type ProviderOrdersSamplesConfirmedBy = Person | User;

export enum ProviderOrdersSamplesProviderOrderTypeEnum {
  /** Main Batch */
  MainBatch = 'main_batch',
  /** Samples */
  Samples = 'samples'
}

export enum ProviderOrdersShipDirectionEnum {
  /** P2C */
  P2c = 'p2c',
  /** P2X */
  P2x = 'p2x'
}

export enum ProviderOrdersShipQcEnum {
  /** No QC */
  Nqc = 'nqc',
  /** Xometry QC */
  Xqc = 'xqc'
}

/** Autogenerated input type of ProviderOrdersShippingInstructionUpdates */
export type ProviderOrdersShippingInstructionUpdatesInput = {
  attributes: OmsInputsShippingInstructions;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersShippingInstructionUpdates */
export type ProviderOrdersShippingInstructionUpdatesPayload = {
  __typename?: 'ProviderOrdersShippingInstructionUpdatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

/** Autogenerated input type of ProviderOrdersSplit */
export type ProviderOrdersSplitInput = {
  attributes: OmsInputsProviderOrderCreateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerOrderIdToSplit: Scalars['ID']['input'];
  sourceProviderOrderComment?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProviderOrdersSplit */
export type ProviderOrdersSplitPayload = {
  __typename?: 'ProviderOrdersSplitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

/** Autogenerated input type of ProviderOrdersUpdate */
export type ProviderOrdersUpdateInput = {
  attributes: OmsInputsProviderOrderUpdateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProviderOrdersUpdate */
export type ProviderOrdersUpdatePayload = {
  __typename?: 'ProviderOrdersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  providerOrder: ProviderOrder;
};

export enum ProviderStateEnum {
  /** blacklist */
  Blacklist = 'blacklist',
  /** deleted */
  Deleted = 'deleted',
  /** enrolled_via_website */
  EnrolledViaWebsite = 'enrolled_via_website',
  /** found_by_us */
  FoundByUs = 'found_by_us',
  /** probation */
  Probation = 'probation',
  /** test_part */
  TestPart = 'test_part',
  /** trusted */
  Trusted = 'trusted'
}

/** Autogenerated input type of ProvidersCreate */
export type ProvidersCreateInput = {
  attributes?: InputMaybe<OmsInputsProviderType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProvidersCreate */
export type ProvidersCreatePayload = {
  __typename?: 'ProvidersCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

/** Autogenerated input type of ProvidersDelete */
export type ProvidersDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProvidersDelete */
export type ProvidersDeletePayload = {
  __typename?: 'ProvidersDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

/** Autogenerated input type of ProvidersExport */
export type ProvidersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of ProvidersExport */
export type ProvidersExportPayload = {
  __typename?: 'ProvidersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ProvidersUpdate */
export type ProvidersUpdateInput = {
  attributes: OmsInputsProviderType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ProvidersUpdate */
export type ProvidersUpdatePayload = {
  __typename?: 'ProvidersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  provider: Provider;
};

/** Autogenerated input type of PublicDomainsCreate */
export type PublicDomainsCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of PublicDomainsCreate */
export type PublicDomainsCreatePayload = {
  __typename?: 'PublicDomainsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PublicHolidayEventType = {
  __typename?: 'PublicHolidayEventType';
  endDate: Scalars['ISO8601Date']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  type: EventsTypeEnum;
};

export type QualityControl = {
  __typename?: 'QualityControl';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<QualityControlsPositionType>>;
  relatedProviderOrders?: Maybe<Array<ProviderOrder>>;
  reportComment?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of QualityControlsCreate */
export type QualityControlsCreateInput = {
  attributes: OmsInputsQualityControlType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of QualityControlsCreate */
export type QualityControlsCreatePayload = {
  __typename?: 'QualityControlsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  qualityControl: QualityControl;
};

/** Autogenerated input type of QualityControlsExport */
export type QualityControlsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of QualityControlsExport */
export type QualityControlsExportPayload = {
  __typename?: 'QualityControlsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of QualityControlsGeneratePdfReport */
export type QualityControlsGeneratePdfReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  reportParams: OmsInputsQualityControlsPdfReportParams;
};

/** Autogenerated return type of QualityControlsGeneratePdfReport */
export type QualityControlsGeneratePdfReportPayload = {
  __typename?: 'QualityControlsGeneratePdfReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type QualityControlsMeasurementMarkCordsType = {
  __typename?: 'QualityControlsMeasurementMarkCordsType';
  offsetX?: Maybe<Scalars['Float']['output']>;
  offsetY?: Maybe<Scalars['Float']['output']>;
  r?: Maybe<Scalars['Float']['output']>;
  x?: Maybe<Scalars['Float']['output']>;
  y?: Maybe<Scalars['Float']['output']>;
};

export type QualityControlsMeasurementMarkType = {
  __typename?: 'QualityControlsMeasurementMarkType';
  active: Scalars['Boolean']['output'];
  canvasId: Scalars['BigInt']['output'];
  fill: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: QualityControlsMeasurementMarkCordsType;
  point: QualityControlsMeasurementMarkCordsType;
  position: Scalars['String']['output'];
  v: Scalars['String']['output'];
};

export type QualityControlsMeasurementType = {
  __typename?: 'QualityControlsMeasurementType';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  mark?: Maybe<QualityControlsMeasurementMarkType>;
  maxValue?: Maybe<Scalars['String']['output']>;
  measurements?: Maybe<Scalars['JSON']['output']>;
  minMaxStr?: Maybe<Scalars['String']['output']>;
  minValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nominalValue: Scalars['String']['output'];
  part?: Maybe<Part>;
  partId?: Maybe<Scalars['BigInt']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  qcCanvas: AttachedFile;
  qcCanvasId: Scalars['BigInt']['output'];
  qcPosition?: Maybe<QualityControlsPositionType>;
  qcPositionId?: Maybe<Scalars['BigInt']['output']>;
  toleranceClass?: Maybe<Scalars['String']['output']>;
  toolset?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  verdict?: Maybe<Scalars['String']['output']>;
};

export type QualityControlsPositionType = {
  __typename?: 'QualityControlsPositionType';
  atmPressure?: Maybe<Scalars['Float']['output']>;
  businessVerdict?: Maybe<Scalars['String']['output']>;
  businessVerdictComment?: Maybe<Scalars['String']['output']>;
  businessVerdictPublished?: Maybe<Scalars['Boolean']['output']>;
  businessVerdictUpdatedBy?: Maybe<ByJson>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerPrice: Scalars['Float']['output'];
  customerPriceCurrency: Scalars['String']['output'];
  customerPriceMoney: Money;
  humidity?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  lot?: Maybe<Lot>;
  lotId: Scalars['Int']['output'];
  material?: Maybe<Scalars['String']['output']>;
  measurements?: Maybe<Array<QualityControlsMeasurementType>>;
  measurementsCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  officialVerdictComment?: Maybe<Scalars['String']['output']>;
  part: Part;
  partDetails?: Maybe<Scalars['String']['output']>;
  partId: Scalars['Int']['output'];
  positionUpdatedBy?: Maybe<ByJson>;
  postProcessing?: Maybe<Scalars['String']['output']>;
  providerOrder?: Maybe<ProviderOrder>;
  providerOrderId?: Maybe<Scalars['ID']['output']>;
  providerPrice: Scalars['Float']['output'];
  providerPriceCurrency: Scalars['String']['output'];
  providerPriceMoney: Money;
  qcCoating?: Maybe<Scalars['String']['output']>;
  qcCoatingComment?: Maybe<Scalars['String']['output']>;
  qcCompliance?: Maybe<Scalars['String']['output']>;
  qcComplianceComment?: Maybe<Scalars['String']['output']>;
  qcConservation?: Maybe<Scalars['String']['output']>;
  qcConservationComment?: Maybe<Scalars['String']['output']>;
  qcCreatedBy?: Maybe<ByJson>;
  qcDocuments?: Maybe<Scalars['String']['output']>;
  qcDocumentsComment?: Maybe<Scalars['String']['output']>;
  qcFinish?: Maybe<Scalars['String']['output']>;
  qcFinishComment?: Maybe<Scalars['String']['output']>;
  qcMarking?: Maybe<Scalars['String']['output']>;
  qcMarkingComment?: Maybe<Scalars['String']['output']>;
  qcOther?: Maybe<Scalars['String']['output']>;
  qcOtherComment?: Maybe<Scalars['String']['output']>;
  qcPackaging?: Maybe<Scalars['String']['output']>;
  qcPackagingComment?: Maybe<Scalars['String']['output']>;
  qcQuantity?: Maybe<Scalars['String']['output']>;
  qcQuantityComment?: Maybe<Scalars['String']['output']>;
  qcQuantityObserved?: Maybe<Scalars['Int']['output']>;
  qtyAccepted?: Maybe<Scalars['Int']['output']>;
  qtyChecked?: Maybe<Scalars['Int']['output']>;
  qtyHold?: Maybe<Scalars['Int']['output']>;
  qtyRejected?: Maybe<Scalars['Int']['output']>;
  qualityControlId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  techVerdict?: Maybe<Scalars['String']['output']>;
  techVerdictComment?: Maybe<Scalars['String']['output']>;
  techVerdictPublished?: Maybe<Scalars['Boolean']['output']>;
  techVerdictUpdatedBy?: Maybe<ByJson>;
  temperature?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of QualityControlsPositionsDelete */
export type QualityControlsPositionsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated return type of QualityControlsPositionsDelete */
export type QualityControlsPositionsDeletePayload = {
  __typename?: 'QualityControlsPositionsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of QualityControlsPositionsUpdate */
export type QualityControlsPositionsUpdateInput = {
  attributes: OmsInputsQualityControlsPositionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated input type of QualityControlsPositionsUpdateMeasurements */
export type QualityControlsPositionsUpdateMeasurementsInput = {
  attributes: Array<OmsInputsQualityControlsMeasurementType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  previewBase64?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of QualityControlsPositionsUpdateMeasurements */
export type QualityControlsPositionsUpdateMeasurementsPayload = {
  __typename?: 'QualityControlsPositionsUpdateMeasurementsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  qualityControlPosition: QualityControlsPositionType;
};

/** Autogenerated return type of QualityControlsPositionsUpdate */
export type QualityControlsPositionsUpdatePayload = {
  __typename?: 'QualityControlsPositionsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  qualityControlPosition: QualityControlsPositionType;
};

/** Autogenerated input type of QualityControlsRepack */
export type QualityControlsRepackInput = {
  attributes: OmsInputsQualityControlsRepackType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated return type of QualityControlsRepack */
export type QualityControlsRepackPayload = {
  __typename?: 'QualityControlsRepackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  result: DealsSourceType;
};

/** Autogenerated input type of QualityControlsUpdate */
export type QualityControlsUpdateInput = {
  attributes: OmsInputsQualityControlType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated return type of QualityControlsUpdate */
export type QualityControlsUpdatePayload = {
  __typename?: 'QualityControlsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  qualityControl: QualityControl;
};

export type Query = {
  __typename?: 'Query';
  activityRecords?: Maybe<Array<ActivityRecord>>;
  activityRecordsByDeal?: Maybe<Array<ActivityRecord>>;
  authorizeAction: Authorization;
  bankAccount?: Maybe<BankAccount>;
  bankPayment?: Maybe<BankPayment>;
  bankPaymentExport: Export;
  bankPaymentExports: Array<Export>;
  bankPaymentImport: Import;
  bankPaymentImports: Array<Import>;
  /** NOT IMPLEMENTED IN UK */
  bankPaymentsToBeExported?: Maybe<Array<BankPayment>>;
  billingAccount?: Maybe<BillingAccount>;
  billingAccountBalanceDetails: BalanceDetails;
  billingAccountsMatchingVatOrTaxId?: Maybe<Array<BillingAccount>>;
  billingAccountsOrganizationsByBillingAccount: Array<BillingAccountsOrganization>;
  /** Fetch list of related objects for BA */
  billingAccountsRelatedObjects: RelatedObjects;
  /** Fetch BA vat validations */
  billingAccountsValidations: Validations;
  buildRelationsForOutcomingInvoices: Array<BuildedOutcomingInvoiceRelation>;
  /** Fetch list of cascade objects for BA */
  cascadeBillingAccountsObjectList: CascadeBillingAccountsObjectListType;
  /** Fetch list of cascade objects for SA */
  cascadeShippingAddressesObjectList: CascadeShippingAddressesObjectListType;
  /** Fetch certificates */
  certificates: Array<Certificate>;
  claim: Claim;
  claims: Array<Claim>;
  /** Fetch tags by context */
  contextTags?: Maybe<Array<Tag>>;
  countersDraftingEngineers: DraftingEngineer;
  countersFinance: Finance;
  countersProductionManagers: ProductionManager;
  countersSettings?: Maybe<Array<CountersSettings>>;
  currencyRate: CurrencyRate;
  /** NOT IMPLEMENTED IN UK */
  datevBankPaymentsExport?: Maybe<BankPaymentsExport>;
  /** NOT IMPLEMENTED IN UK */
  datevBankPaymentsExports?: Maybe<Array<BankPaymentsExport>>;
  /** NOT IMPLEMENTED IN UK */
  datevPaymentOutcomingInvoiceRelationsExport?: Maybe<PaymentOutcomingInvoiceRelationsExport>;
  /** NOT IMPLEMENTED IN UK */
  datevPaymentOutcomingInvoiceRelationsExports?: Maybe<Array<PaymentOutcomingInvoiceRelationsExport>>;
  /** NOT IMPLEMENTED IN UK */
  datevPaymentsExport?: Maybe<PaymentsExport>;
  /** NOT IMPLEMENTED IN UK */
  datevPaymentsExports?: Maybe<Array<PaymentsExport>>;
  /** Fetch deal details */
  deal?: Maybe<Deal>;
  dealAggregations: DealAggregations;
  dealBalance: Balance;
  dealDelivery: Delivery;
  dealIncomingInvoice: IncomingInvoice;
  dealIncomingInvoices: Array<IncomingInvoice>;
  dealLotsByIds: Array<Lot>;
  /** Fetch list of deal objects for sidebar */
  dealObjectList: ObjectList;
  dealOrderConfirmation: OrderConfirmation;
  /** Get XomVatNumber + VatRate for order confirmation */
  dealOrderConfirmationVat: OrderConfirmationVat;
  dealOutcomingInvoice: OutcomingInvoice;
  dealParticipants: Participants;
  /** Retreive parts state records */
  dealPartsState: Array<PartStateRecordsGroup>;
  dealPaymentOutcomingInvoiceRelationsLoad: Array<PaymentOutcomingInvoiceRelation>;
  dealProviderOrders: Array<ProviderOrder>;
  dealQualityControl: QualityControl;
  dealQuotation: Quotation;
  dealRelatedDocuments: RelatedCollection;
  dealShipping: Shipping;
  dealSource: DealsSourceType;
  dealSources: SourceCollection;
  defaultWorkspacePreset?: Maybe<WorkspacePreset>;
  /** D&B: Request Credit Limit */
  dnbCreditLimit: ExtendedMatch;
  /** D&B: Find organization */
  dnbExtendedMatch: ExtendedMatch;
  /** D&B: Fetch logs */
  dnbFetchLogs?: Maybe<Array<RequestLog>>;
  /** Fetch forecast for document currency conversion */
  documentCurrencyConversionForecast: Base;
  /** OMS DocumentNotes list */
  documentNotes?: Maybe<Array<DocumentNoteType>>;
  draftShippingCalculation: ShippingCalculation;
  /** Fetch enquiry */
  enquiry?: Maybe<Enquiry>;
  /** Fetch enquiry lead time based on date and working days */
  enquiryLeadTime: LeadTime;
  /** Fetch enquiry materials */
  enquiryMaterials: Array<Material>;
  /** Fetch enquiry processes */
  enquiryProcesses?: Maybe<Array<OmsEnquiryProcessType>>;
  /** Fetch enquiry promotion */
  enquiryPromotion?: Maybe<OmsEnquiryPromotionType>;
  /** Search enquiries */
  enquirySearch?: Maybe<Array<Enquiry>>;
  /** Get tags related to enquiry */
  enquiryTagsMapping?: Maybe<Array<OmsEnquiryPartTagsMappingType>>;
  enquiryValidateCreditLimit?: Maybe<OmsEnquiryCreditLimitValidationErrorType>;
  /** All permissions list */
  erpPermissions?: Maybe<Array<ErpPermissionsGrouped>>;
  eventTypes?: Maybe<Array<EventType>>;
  exportBillingAccounts: ExportBillingAccountType;
  exportProviders: ExportProviderType;
  exportsBillingAccounts: PaginationResult;
  exportsProviders: PaginationResult;
  /** Fetch feature_flag status by name */
  featureFlag: FeatureFlag;
  /** Fetch feature_flags for erp */
  featureFlags: Array<FeatureFlag>;
  fetchShippingInstructor?: Maybe<ShippingInstructorFields>;
  /** Fetch files by source_type source_id */
  files?: Maybe<Array<AttachedFile>>;
  /** Fetch deal batches */
  frameworkAvailableSources: Array<BatchSource>;
  /** Fetch Batch Groups */
  frameworkBatchGroups: BatchGroup;
  /** Suggested Provider Orders */
  frameworkBatchSuggestedProviderOrders: Array<BatchProviderOrder>;
  /** Fetch !!!ALL!!! help links */
  helpLinks?: Maybe<Array<HelpLink>>;
  hsCodes: Array<HsCode>;
  hsCodesMatchMaterial?: Maybe<HsCode>;
  incomingInvoicesBulkBankPaymentInit?: Maybe<Array<Array<Init>>>;
  /** NOT IMPLEMENTED IN UK */
  incomingInvoicesDatevFields?: Maybe<DatevFieldsType>;
  incomingInvoicesRealloc: Realloc;
  /** Fetch job global offers */
  jobGlobalOffers?: Maybe<Array<GlobalJobOffer>>;
  /** Fetch job global statistic */
  jobGlobalStatistic?: Maybe<JobStatistic>;
  /** Search jobs */
  jobSearch?: Maybe<Array<Job>>;
  /** KAM users */
  kams?: Maybe<Array<User>>;
  /** NOT IMPLEMENTED IN UK */
  logisticInvoice: LogisticInvoice;
  /** NOT IMPLEMENTED IN UK */
  logisticInvoicePositions: PaginationResult;
  /** Person loyalty balance */
  loyaltyData: LoyaltyBalance;
  /** Person loyalty balance history */
  loyaltyHistoryData: Array<Transaction>;
  markingsForMaterial: Array<Marking>;
  masterShipping?: Maybe<MasterShipping>;
  masterShippingsChildShippingOptions: Array<Shipping>;
  masterShippingsGenerateDhlDescription?: Maybe<Scalars['String']['output']>;
  masterShippingsSearchForShipping: Array<MasterShipping>;
  /** Fetch enquiry Measurement Protocols */
  measurementProtocols?: Maybe<Array<OmsEnquiryMeasurementProtocol>>;
  /** Fetch my bookmarks */
  messengerBookmarks: Array<Message>;
  /** Fetch chat user list */
  messengerChatUsers: Array<MessageUser>;
  /** Fetch my counters */
  messengerCounter: Counter;
  /** Fetch my mentions */
  messengerMentions: Array<Message>;
  /** Fetch Message Filter Groups */
  messengerMessageFilterGroups: Array<MessageFilterGroup>;
  /** Fetch Message Filters */
  messengerMessageFilters: Array<MessageFilter>;
  /** Fetch deal messages */
  messengerMessages: MessageCollection;
  /** Fetch message */
  messengerMessagesById: Array<Message>;
  /** Fetch deal pinned messages */
  messengerPinnedMessages: PinnedMessagesCollection;
  /** Render message template */
  messengerRenderTemplate?: Maybe<RenderedTemplate>;
  /** Fetch source */
  messengerSource: MessageSource;
  /** Return related objects to the source */
  messengerSourceRelatedObjects?: Maybe<SourceRelatedObjects>;
  /** Fetch deal objects */
  messengerSources: Array<MessageSource>;
  /** Fetch subscriptions */
  messengerSubscriptions: Array<Subscription>;
  /** Fetch my tasks */
  messengerTasks: Array<Message>;
  /** Fetch Message Template Categories */
  messengerTemplateCategories: Array<TemplateCategory>;
  /** Fetch placeholders by type */
  messengerTemplatePlaceholders: TemplatePlaceholdersCollectionType;
  /** Fetch placeholders collection */
  messengerTemplatePlaceholdersCollection: Array<TemplatePlaceholderType>;
  /** Fetch users */
  messengerUsers: Array<MessageUser>;
  /** Fetch organization details */
  organization?: Maybe<Organization>;
  /** Fetch organization balance at given date */
  organizationBalanceRecordsFind: OrganizationBalanceRecord;
  organizationBillingAccounts: OrganizationBillingAccounts;
  organizationDeals: OrganizationDeals;
  organizationPersons: OrganizationPersons;
  /** NOT IMPLEMENTED IN UK */
  outcomingInvoiceExportZipState?: Maybe<ZipState>;
  outcomingInvoiceExportZipStateIntacct?: Maybe<IntacctZipStateType>;
  outcomingInvoicesAccountingFields?: Maybe<AccountingFieldsType>;
  /** Fetch deal overview */
  overview?: Maybe<Overview>;
  packageTrackingEvents: Array<PackageTrackingEvent>;
  /** Fetch deal part */
  part?: Maybe<Part>;
  /** Fetch deal part measurement tempaltes */
  partMeasurementTemplates: Array<QualityControlsMeasurementType>;
  /** Fetch parts with similar name */
  partsWithSimilarName?: Maybe<Array<Part>>;
  /** NOT IMPLEMENTED IN UK */
  paymentOutcomingInvoiceRelationsToBeExported?: Maybe<Array<PaymentOutcomingInvoiceRelation>>;
  /** NOT IMPLEMENTED IN UK */
  paymentsToBeExported?: Maybe<Array<Payment>>;
  payout: Payout;
  payouts: Array<Payout>;
  payoutsBulkBankPaymentInit?: Maybe<Array<Array<Init>>>;
  /** NOT IMPLEMENTED IN UK */
  payoutsToBeExported?: Maybe<Array<Payout>>;
  /** Fetch person details */
  person?: Maybe<Person>;
  /** Pipedrive stages */
  pipedriveStages: Array<PipedriveStage>;
  /** Fetch provider details */
  provider?: Maybe<Provider>;
  /** Fetch provider balance at given date */
  providerBalanceRecordsFind: ProviderBalanceRecord;
  /** Fetch partner's calendar events for specific year */
  providerCalendarEvents: Array<CalendarEvent>;
  providerOrder: ProviderOrder;
  providerOrderGenerateIncotermsComment?: Maybe<Scalars['String']['output']>;
  providerOrderPayoutsBalance: Money;
  providerPayoutsBalance: Money;
  providersSearch?: Maybe<Array<Provider>>;
  /** Fetch recent KAM organizations */
  recentKamOrganizations?: Maybe<Array<Organization>>;
  /** Fetch country regions */
  regions: Array<Region>;
  rfq: Rfq;
  rfqOffer: RfqOffer;
  rfqOfferApplyRates: Array<RfqsOffersRateType>;
  rfqTargetMarginRules: Array<RfqTargetMarginRule>;
  /** All roles list */
  roles?: Maybe<Array<Role>>;
  /** Roles list for specific user */
  rolesForUser?: Maybe<Array<Role>>;
  /** NOT IMPLEMENTED IN UK */
  sachkontos: Array<Sachkonto>;
  /** Fetch account objects for Bank Payment */
  searchAccount: Account;
  /** Fetch account related objects for Bank Payment */
  searchAccountRelatedObjects: AccountRelatedObjects;
  /** Fetch advanced search results */
  searchAdvanced?: Maybe<Results>;
  /** Fetch autocomplete search results */
  searchAutocomplete?: Maybe<Array<AutocompleteGroup>>;
  shippingCalculations: Array<ShippingCalculation>;
  shippingTargetFeesLoadForShipping: ShippingTargetFee;
  shippings: PaginationResult;
  shippingsGenerateDhlDescription?: Maybe<Scalars['String']['output']>;
  shippingsTransliterateAddress: DealShippingAddressType;
  supportTickets: SupportTicketsPageType;
  surfaceRoughnessesForMaterial: Array<SurfaceRoughness>;
  /** Fetch all or filtered by context tags */
  tags?: Maybe<Array<Tag>>;
  /** OMS user details */
  user?: Maybe<User>;
  /** Individual permissions list for specific user */
  userPermissions?: Maybe<Array<UserPermission>>;
  /** OMS users list */
  users?: Maybe<Array<User>>;
  workspacePresets: Array<WorkspacePreset>;
  workspacesBankPayment?: Maybe<WorkspacesUpdatedBankPayment>;
  workspacesBankPayments: WorkspacesBankPaymentsCollection;
  workspacesBankPaymentsDraft: WorkspacesBankPaymentsDraftCollection;
  workspacesBankPaymentsTotalAmounts: WorkspacesBankPaymentTotalAmounts;
  workspacesBillingAccounts: WorkspacesBillingAccountsCollection;
  workspacesClaims: WorkspacesClaimsCollection;
  workspacesDeals: WorkspacesDealsCollection;
  workspacesDraftingDealParts: WorkspacesDraftingDealPartsCollection;
  workspacesDraftingEnquiryParts: WorkspacesDraftingEnquiryPartsCollection;
  workspacesEnquiries: WorkspacesEnquiriesCollection;
  workspacesEnquiryPromotions: WorkspacesEnquiryPromotionsCollection;
  workspacesFrameworkBatches: WorkspacesFrameworkBatchesCollection;
  workspacesIncomingInvoices: WorkspacesIncomingInvoicesCollection;
  workspacesIncomingInvoicesTotalAmounts: WorkspacesIncomingInvoiceTotalAmounts;
  workspacesJobOffers: WorkspacesJobOffersCollection;
  workspacesJobs: WorkspacesJobsCollection;
  workspacesMessages: WorkspacesMessagesCollection;
  workspacesOrganizationBalances: WorkspacesOrganizationBalancesCollection;
  workspacesOrganizationBalancesTotalAmounts: WorkspacesOrganizationBalancesTotalAmounts;
  workspacesOrganizations: WorkspacesOrganizationsCollection;
  workspacesOutcomingInvoices: WorkspacesOutcomingInvoicesCollection;
  workspacesOutcomingInvoicesTotalAmounts: WorkspacesOutcomingInvoiceTotalAmounts;
  workspacesPartnerBalances: WorkspacesPartnerBalancesCollection;
  workspacesPartnerBalancesTotalAmounts: WorkspacesPartnerBalancesTotalAmounts;
  workspacesPayments: WorkspacesPaymentsCollection;
  workspacesPayouts: WorkspacesPayoutsCollection;
  workspacesPayoutsTotalAmounts: WorkspacesPayoutTotalAmounts;
  workspacesPersons: WorkspacesPersonsCollection;
  workspacesProviderOrders: WorkspacesProviderOrdersCollection;
  workspacesProviders: WorkspacesProvidersCollection;
  workspacesQualityControls: WorkspacesQualityControlsCollection;
  workspacesRefunds: WorkspacesRefundsCollection;
  workspacesRfqOffers: WorkspacesRfqOffersCollection;
  workspacesRfqPartners: WorkspacesRfqPartnersCollection;
  workspacesRfqs: WorkspacesRfqsCollection;
  workspacesShippingAddresses: WorkspacesShippingAddressesCollection;
  workspacesShippings: WorkspacesShippingsCollection;
  workspacesStorages: WorkspacesStoragesCollection;
  workspacesSubscriptions: WorkspacesSubscriptionsCollection;
  workspacesTasks: WorkspacesTasksCollection;
  workspacesUsers: WorkspacesUsersCollection;
  xometryBankAccount?: Maybe<XometryBankAccount>;
  xometryVatNumbers?: Maybe<Array<XometryVatNumber>>;
};


export type QueryActivityRecordsArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  objectId: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
  versions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryActivityRecordsByDealArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  dealId: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
  versions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAuthorizeActionArgs = {
  action: Scalars['String']['input'];
  group: Scalars['String']['input'];
  subjectClass?: InputMaybe<Scalars['String']['input']>;
  subjectId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBankAccountArgs = {
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};


export type QueryBankPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBankPaymentExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBankPaymentImportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBankPaymentsToBeExportedArgs = {
  accountId: Scalars['ID']['input'];
  durationFrom?: InputMaybe<Scalars['String']['input']>;
  durationTo?: InputMaybe<Scalars['String']['input']>;
  numbers?: InputMaybe<Scalars['String']['input']>;
  withRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBillingAccountArgs = {
  filesContext?: InputMaybe<Array<BillingAccountsAttachedFilesContextEnumType>>;
  id: Scalars['ID']['input'];
};


export type QueryBillingAccountBalanceDetailsArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  id: Scalars['ID']['input'];
  withLines?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBillingAccountsMatchingVatOrTaxIdArgs = {
  currentId?: InputMaybe<Scalars['ID']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBillingAccountsOrganizationsByBillingAccountArgs = {
  billingAccountId: Scalars['ID']['input'];
};


export type QueryBillingAccountsRelatedObjectsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBillingAccountsValidationsArgs = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryBuildRelationsForOutcomingInvoicesArgs = {
  dealId: Scalars['ID']['input'];
  outcomingInvoiceId: Scalars['ID']['input'];
  totalAmountBruttoMoney?: InputMaybe<OmsInputsMoneyType>;
};


export type QueryCascadeBillingAccountsObjectListArgs = {
  afterSave?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryCascadeShippingAddressesObjectListArgs = {
  afterSave?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryClaimArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClaimsArgs = {
  personId: Scalars['ID']['input'];
};


export type QueryContextTagsArgs = {
  contexts?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryCountersDraftingEngineersArgs = {
  customerGrade?: InputMaybe<Scalars['String']['input']>;
  podId?: InputMaybe<Scalars['Int']['input']>;
  process?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCountersProductionManagersArgs = {
  pmId?: InputMaybe<Scalars['ID']['input']>;
  podId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountersSettingsArgs = {
  targetTable?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrencyRateArgs = {
  currencyIsoFrom?: InputMaybe<Scalars['String']['input']>;
  currencyIsoTo?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
};


export type QueryDatevBankPaymentsExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDatevPaymentOutcomingInvoiceRelationsExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDatevPaymentsExportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealArgs = {
  id: Scalars['ID']['input'];
  isPipedriveId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDealAggregationsArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealBalanceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealIncomingInvoiceArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryDealIncomingInvoicesArgs = {
  dealId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDealLotsByIdsArgs = {
  filter: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
};


export type QueryDealObjectListArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealOrderConfirmationArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealOrderConfirmationVatArgs = {
  billingAccountId?: InputMaybe<Scalars['ID']['input']>;
  billingAccountType?: InputMaybe<Scalars['String']['input']>;
  billingAccountVatCountry?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  destinationCountry: Scalars['String']['input'];
};


export type QueryDealOutcomingInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealParticipantsArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealPartsStateArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealPaymentOutcomingInvoiceRelationsLoadArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  outcomingInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  paymentId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDealProviderOrdersArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryDealQualityControlArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealQuotationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealRelatedDocumentsArgs = {
  objectId: Scalars['Int']['input'];
  objectType: Scalars['String']['input'];
};


export type QueryDealShippingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDealSourceArgs = {
  sourceId: Scalars['Int']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryDealSourcesArgs = {
  dealId: Scalars['Int']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
  providerOrderId?: InputMaybe<Scalars['ID']['input']>;
  sources?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDefaultWorkspacePresetArgs = {
  workspaceName: Scalars['String']['input'];
};


export type QueryDnbCreditLimitArgs = {
  billingAccountId: Scalars['ID']['input'];
};


export type QueryDnbExtendedMatchArgs = {
  billingAccountId: Scalars['ID']['input'];
  criterias: OmsInputsDnBCriteriaType;
};


export type QueryDnbFetchLogsArgs = {
  billingAccountId: Scalars['ID']['input'];
};


export type QueryDocumentCurrencyConversionForecastArgs = {
  documentId: Scalars['ID']['input'];
  documentType: Scalars['String']['input'];
};


export type QueryDocumentNotesArgs = {
  documentId: Scalars['ID']['input'];
  documentType: Scalars['String']['input'];
};


export type QueryDraftShippingCalculationArgs = {
  sourceId: Scalars['Int']['input'];
  sourceType: ShippingCalculationSourceEnum;
};


export type QueryEnquiryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEnquiryLeadTimeArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  workingDays: Scalars['Int']['input'];
};


export type QueryEnquiryMaterialsArgs = {
  materialTypeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryEnquiryPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEnquirySearchArgs = {
  maxCount?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['JSON']['input'];
};


export type QueryEnquiryTagsMappingArgs = {
  id: Scalars['ID']['input'];
  rfqOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryEnquiryValidateCreditLimitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventTypesArgs = {
  dependence?: InputMaybe<DependenceEnum>;
};


export type QueryExportBillingAccountsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportProvidersArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExportsBillingAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExportsProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFeatureFlagArgs = {
  name: Scalars['String']['input'];
};


export type QueryFetchShippingInstructorArgs = {
  dealId: Scalars['ID']['input'];
  isSample: Scalars['Boolean']['input'];
  providerId: Scalars['ID']['input'];
};


export type QueryFilesArgs = {
  contexts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryFrameworkAvailableSourcesArgs = {
  batchType?: InputMaybe<BatchTypeEnum>;
  category?: InputMaybe<BatchCategoryEnum>;
  dealId: Scalars['ID']['input'];
};


export type QueryFrameworkBatchGroupsArgs = {
  dealId: Scalars['ID']['input'];
};


export type QueryFrameworkBatchSuggestedProviderOrdersArgs = {
  sourceTypes?: Array<OmsInputsFrameworkBatchesSourceType>;
};


export type QueryHsCodesMatchMaterialArgs = {
  material: Scalars['String']['input'];
};


export type QueryIncomingInvoicesBulkBankPaymentInitArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryIncomingInvoicesDatevFieldsArgs = {
  dealId: Scalars['ID']['input'];
  providerOrderId?: InputMaybe<Scalars['ID']['input']>;
  refInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  shippingId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryIncomingInvoicesReallocArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobGlobalOffersArgs = {
  jobId: Scalars['ID']['input'];
};


export type QueryJobGlobalStatisticArgs = {
  jobId: Scalars['ID']['input'];
};


export type QueryJobSearchArgs = {
  query: Scalars['String']['input'];
};


export type QueryLogisticInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLogisticInvoicePositionsArgs = {
  hideAllocated?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLoyaltyDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLoyaltyHistoryDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarkingsForMaterialArgs = {
  materialId: Scalars['ID']['input'];
};


export type QueryMasterShippingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMasterShippingsChildShippingOptionsArgs = {
  destinationAddress?: InputMaybe<OmsInputsMasterShippingsSearchAddressType>;
  direction: ShippingDirectionEnum;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  sourceAddress?: InputMaybe<OmsInputsMasterShippingsSearchAddressType>;
  state: ShippingStateEnum;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMasterShippingsGenerateDhlDescriptionArgs = {
  masterShippingId: Scalars['ID']['input'];
};


export type QueryMasterShippingsSearchForShippingArgs = {
  destinationAddress?: InputMaybe<OmsInputsMasterShippingsSearchAddressType>;
  direction: ShippingDirectionEnum;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  sourceAddress?: InputMaybe<OmsInputsMasterShippingsSearchAddressType>;
  state: ShippingStateEnum;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessengerBookmarksArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessengerChatUsersArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerMentionsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessengerMessagesArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessengerMessagesByIdArgs = {
  messageIds: Array<Scalars['ID']['input']>;
};


export type QueryMessengerPinnedMessagesArgs = {
  dealId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessengerRenderTemplateArgs = {
  id: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerSourceArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerSourceRelatedObjectsArgs = {
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerSourcesArgs = {
  dealId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessengerSubscriptionsArgs = {
  filter?: InputMaybe<OmsTypesMessengerSubscriptionFilterType>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessengerTasksArgs = {
  filter: OmsTypesMessengerTaskFilterType;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort: OmsTypesMessengerTaskSortType;
};


export type QueryMessengerTemplateCategoriesArgs = {
  entryPoint: TemplateEntryPointEnum;
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['String']['input'];
};


export type QueryMessengerTemplatePlaceholdersArgs = {
  type: TemplateTypeEnum;
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
  isPipedriveId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOrganizationBalanceRecordsFindArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationBillingAccountsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationDealsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  pipedriveStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryOrganizationPersonsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  searchByRelatedEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOutcomingInvoiceExportZipStateArgs = {
  exportId: Scalars['ID']['input'];
};


export type QueryOutcomingInvoiceExportZipStateIntacctArgs = {
  exportId: Scalars['ID']['input'];
};


export type QueryOutcomingInvoicesAccountingFieldsArgs = {
  dealId: Scalars['ID']['input'];
  refInvoiceId?: InputMaybe<Scalars['ID']['input']>;
  shippingId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOverviewArgs = {
  id: Scalars['ID']['input'];
  isPipedriveId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartMeasurementTemplatesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPartsWithSimilarNameArgs = {
  partId: Scalars['ID']['input'];
};


export type QueryPaymentOutcomingInvoiceRelationsToBeExportedArgs = {
  durationFrom: Scalars['ISO8601Date']['input'];
  durationTo: Scalars['ISO8601Date']['input'];
  withRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPaymentsToBeExportedArgs = {
  durationFrom?: InputMaybe<Scalars['String']['input']>;
  durationTo?: InputMaybe<Scalars['String']['input']>;
  methodType?: InputMaybe<Scalars['String']['input']>;
  numbers?: InputMaybe<Scalars['String']['input']>;
  withRegistered: Scalars['Boolean']['input'];
};


export type QueryPayoutArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPayoutsArgs = {
  dealId: Scalars['ID']['input'];
  filter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPayoutsBulkBankPaymentInitArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryPayoutsToBeExportedArgs = {
  durationFrom?: InputMaybe<Scalars['String']['input']>;
  durationTo?: InputMaybe<Scalars['String']['input']>;
  numbers?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPersonArgs = {
  id: Scalars['ID']['input'];
  isPipedriveId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProviderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProviderBalanceRecordsFindArgs = {
  date?: InputMaybe<Scalars['ISO8601Date']['input']>;
  providerId: Scalars['ID']['input'];
};


export type QueryProviderCalendarEventsArgs = {
  providerId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};


export type QueryProviderOrderArgs = {
  convertToProviderOrderCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type QueryProviderOrderGenerateIncotermsCommentArgs = {
  shipIncoterms?: InputMaybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly?: InputMaybe<Scalars['Boolean']['input']>;
  shipIncotermsCity?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProviderOrderPayoutsBalanceArgs = {
  providerOrderId: Scalars['ID']['input'];
};


export type QueryProviderPayoutsBalanceArgs = {
  providerId: Scalars['ID']['input'];
};


export type QueryProvidersSearchArgs = {
  filters: InputsProvidersSearch;
};


export type QueryRecentKamOrganizationsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRfqArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRfqOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRfqOfferApplyRatesArgs = {
  rates: Array<OmsInputsRfqsOffersRateType>;
};


export type QueryRfqTargetMarginRulesArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryRolesForUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QuerySachkontosArgs = {
  type: SachkontoType;
};


export type QuerySearchAccountArgs = {
  term: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QuerySearchAccountRelatedObjectsArgs = {
  term: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QuerySearchAdvancedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type QuerySearchAutocompleteArgs = {
  query: Scalars['String']['input'];
};


export type QueryShippingCalculationsArgs = {
  sourceId: Scalars['Int']['input'];
  sourceType: ShippingCalculationSourceEnum;
};


export type QueryShippingTargetFeesLoadForShippingArgs = {
  shippingId: Scalars['ID']['input'];
};


export type QueryShippingsArgs = {
  attributes?: InputMaybe<DealsShippings>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingsGenerateDhlDescriptionArgs = {
  shippingId: Scalars['ID']['input'];
};


export type QueryShippingsTransliterateAddressArgs = {
  address: OmsInputsShippingsAddressType;
};


export type QuerySupportTicketsArgs = {
  filter?: InputMaybe<SupportTicketsFilterType>;
};


export type QuerySurfaceRoughnessesForMaterialArgs = {
  materialId: Scalars['ID']['input'];
};


export type QueryTagsArgs = {
  context?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
  isPipedriveId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUserPermissionsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  roleName?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryWorkspacePresetsArgs = {
  workspaceName: Scalars['String']['input'];
};


export type QueryWorkspacesBankPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWorkspacesBankPaymentsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesBankPaymentsDraftArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesBankPaymentsTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  timezoneOffset: Scalars['Int']['input'];
  workspaceName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWorkspacesBillingAccountsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesClaimsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesDealsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesDraftingDealPartsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesDraftingEnquiryPartsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesEnquiriesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesEnquiryPromotionsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesFrameworkBatchesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesIncomingInvoicesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesIncomingInvoicesTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesJobOffersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesJobsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesMessagesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesOrganizationBalancesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesOrganizationBalancesTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  queryOptions: Scalars['JSON']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesOrganizationsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesOutcomingInvoicesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesOutcomingInvoicesTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPartnerBalancesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPartnerBalancesTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  queryOptions: Scalars['JSON']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPaymentsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPayoutsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPayoutsTotalAmountsArgs = {
  filterQuery: Scalars['String']['input'];
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesPersonsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesProviderOrdersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesProvidersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesQualityControlsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesRefundsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesRfqOffersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesRfqPartnersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesRfqsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesShippingAddressesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesShippingsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesStoragesArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesSubscriptionsArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesTasksArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryWorkspacesUsersArgs = {
  filterQuery?: InputMaybe<Scalars['String']['input']>;
  paginationParams?: InputMaybe<WorkspacesInputsGenericPaginationType>;
  presetId?: InputMaybe<Scalars['ID']['input']>;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};


export type QueryXometryBankAccountArgs = {
  accountId: Scalars['String']['input'];
};


export type QueryXometryVatNumbersArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Quotation = {
  __typename?: 'Quotation';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deal: Deal;
  dealId: Scalars['ID']['output'];
  documentState?: Maybe<Scalars['String']['output']>;
  driveFileGeneratedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  driveFileId?: Maybe<Scalars['String']['output']>;
  driveFileName?: Maybe<Scalars['String']['output']>;
  driveWebViewLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lots: Array<Lot>;
  number?: Maybe<Scalars['String']['output']>;
  paymentTerms?: Maybe<Scalars['Int']['output']>;
  prepayAmount?: Maybe<Scalars['Float']['output']>;
  prepayPercent?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
  validUntil?: Maybe<Scalars['ISO8601Date']['output']>;
};

export enum QuotingStateEnum {
  CalcFinished = 'calc_finished',
  Calculation = 'calculation',
  Editing = 'editing',
  Finalized = 'finalized',
  HighVolumePending = 'high_volume_pending',
  Moderation = 'moderation',
  SaChecked = 'sa_checked',
  Waiting = 'waiting'
}

export type Rank = {
  __typename?: 'Rank';
  cancelRatio?: Maybe<Scalars['Float']['output']>;
  commIssuesRatio?: Maybe<Scalars['Float']['output']>;
  communication?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  delayedProviderOrdersQuantity?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  overdueRatio?: Maybe<Scalars['Float']['output']>;
  packFailRatio?: Maybe<Scalars['Float']['output']>;
  packing?: Maybe<Scalars['Float']['output']>;
  poQuantity?: Maybe<Scalars['Float']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  qualityRatio?: Maybe<Scalars['Float']['output']>;
  timing?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Realloc = {
  __typename?: 'Realloc';
  creditNote: Scalars['Boolean']['output'];
  incomingInvoices: Array<IncomingInvoice>;
  parentInvoice?: Maybe<IncomingInvoice>;
  providerOrders: Array<ProviderOrder>;
  totalAmountBruttoMoney: Money;
};

export type Ref = AdditionalService | Lot;

export type RefInvoice = {
  __typename?: 'RefInvoice';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote: Scalars['Boolean']['output'];
  datevComment?: Maybe<Scalars['String']['output']>;
  dealId: Scalars['ID']['output'];
  dealProviderOrderId: Scalars['ID']['output'];
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  intacctComment?: Maybe<Scalars['String']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isPartial: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  numberErp: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentDueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  providerOrderId: Scalars['ID']['output'];
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  state: Scalars['String']['output'];
  totalAmountBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  totalAmountNettoCents?: Maybe<Scalars['BigInt']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validity?: Maybe<Scalars['String']['output']>;
  vatAmountCents?: Maybe<Scalars['BigInt']['output']>;
  vatAvg?: Maybe<Scalars['Float']['output']>;
};

export enum RefundMethodTypesEnum {
  AchBucs = 'ach_bucs',
  Paypal = 'paypal',
  Transfer = 'transfer',
  Wire = 'wire'
}

/** Autogenerated input type of RefundsExport */
export type RefundsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of RefundsExport */
export type RefundsExportPayload = {
  __typename?: 'RefundsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type Region = {
  __typename?: 'Region';
  cities: Array<City>;
  name: Scalars['String']['output'];
};

export enum RegionEnum {
  Eu = 'EU',
  Tr = 'TR',
  Uk = 'UK'
}

export type RelatedCollection = {
  __typename?: 'RelatedCollection';
  referring: Array<DealsSourceType>;
  related: Array<DealsSourceType>;
};

export type RelatedObject = {
  __typename?: 'RelatedObject';
  customerPriceCurrency: CurrencyEnum;
  customerPriceMoney: Money;
  id: Scalars['ID']['output'];
  meta?: Maybe<RelatedObjectMeta>;
  providerPriceCurrency: CurrencyEnum;
  providerPriceMoney: Money;
  quantity: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RelatedObjectMeta = {
  __typename?: 'RelatedObjectMeta';
  direction?: Maybe<Scalars['String']['output']>;
};

export type RelatedObjects = {
  __typename?: 'RelatedObjects';
  orderConfirmations?: Maybe<Array<OrderConfirmation>>;
  outcomingInvoices?: Maybe<Array<OutcomingInvoice>>;
};

/** Intercompany Object Relation */
export enum RelationEnum {
  /** migration */
  Migration = 'migration',
  /** outsourcing */
  Outsourcing = 'outsourcing',
  /** rfq */
  Rfq = 'rfq'
}

export type RenderedTemplate = {
  __typename?: 'RenderedTemplate';
  attachments: Array<Attachment>;
  /** abbreviated as "BCC" */
  blindCarbonCopy?: Maybe<Array<Scalars['String']['output']>>;
  /** abbreviated as "CC" */
  carbonCopy?: Maybe<Array<Scalars['String']['output']>>;
  constantName?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  direction: TemplateDirectionEnum;
  documents: Array<Attachment>;
  id: Scalars['ID']['output'];
  locale: TemplateLocaleEnum;
  mentions: Array<Scalars['ID']['output']>;
  messageType: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  readOnly: Scalars['Boolean']['output'];
  recipients?: Maybe<Array<Scalars['String']['output']>>;
  subject?: Maybe<Scalars['String']['output']>;
  type: TemplateTypeEnum;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of RequestAutomatically */
export type RequestAutomaticallyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
};

/** Autogenerated return type of RequestAutomatically */
export type RequestAutomaticallyPayload = {
  __typename?: 'RequestAutomaticallyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['ID']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RequestLog = {
  __typename?: 'RequestLog';
  billingAccountId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  duns?: Maybe<Scalars['String']['output']>;
  endpoint?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  matchDataCriteria?: Maybe<Scalars['String']['output']>;
  queryParams?: Maybe<Scalars['JSON']['output']>;
  response?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: Scalars['JSON']['output'];
  userId: Scalars['ID']['output'];
};

export type Results = {
  __typename?: 'Results';
  aggs: Array<Agg>;
  items: Array<SearchItemType>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Rfq = {
  __typename?: 'Rfq';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  deal: Deal;
  enquiry: Enquiry;
  id: Scalars['Int']['output'];
  needInfo: Scalars['Boolean']['output'];
  needInfoAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  needInfoBy?: Maybe<User>;
  offers: Array<RfqOffer>;
  owner?: Maybe<User>;
  positions: Array<RfqPosition>;
  publicComment?: Maybe<Scalars['String']['output']>;
  publicationEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publicationStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
  selectedPartners?: Maybe<Array<Provider>>;
  state: RfqsStateEnumType;
  statistics?: Maybe<RfqStatistics>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  xometryNumber?: Maybe<Scalars['String']['output']>;
};

export type RfqOffer = {
  __typename?: 'RfqOffer';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: CurrencyEnum;
  decidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decidedBy?: Maybe<User>;
  decisionRefusalReason?: Maybe<Scalars['String']['output']>;
  decisionState: RfqsOffersDecisionStateEnumType;
  id: Scalars['Int']['output'];
  interestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isPreferred: Scalars['Boolean']['output'];
  marginRules: Array<RfqPositionMarginRules>;
  prices?: Maybe<Array<RfqsOffersPriceType>>;
  provider: Provider;
  providerComment?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['ID']['output'];
  providerMatchedTags?: Maybe<Array<Tag>>;
  providerPricesTotal?: Maybe<Money>;
  qualifiedViewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  responseRefusalReason?: Maybe<Scalars['String']['output']>;
  responseState: RfqsOffersResponseStateEnumType;
  rfq: Rfq;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of RfqOffersExport */
export type RfqOffersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of RfqOffersExport */
export type RfqOffersExportPayload = {
  __typename?: 'RfqOffersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of RfqPartnersExport */
export type RfqPartnersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of RfqPartnersExport */
export type RfqPartnersExportPayload = {
  __typename?: 'RfqPartnersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type RfqPosition = {
  __typename?: 'RfqPosition';
  attachedFiles?: Maybe<Array<AttachedFile>>;
  color?: Maybe<Color>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customColor?: Maybe<Scalars['String']['output']>;
  customFinish?: Maybe<Scalars['String']['output']>;
  customMaterial?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Dimension>;
  enquiryPartId: Scalars['ID']['output'];
  enquiryQuantityOptions: Array<OmsEnquiryPartQuantityOption>;
  finish?: Maybe<Finish>;
  gauge?: Maybe<Scalars['JSON']['output']>;
  gaugeId?: Maybe<Scalars['ID']['output']>;
  hardwareQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  layerThickness?: Maybe<Scalars['Int']['output']>;
  manualPrice: Money;
  material?: Maybe<Material>;
  materialType?: Maybe<MaterialType>;
  measurementProtocol?: Maybe<OmsEnquiryMeasurementProtocol>;
  measurementProtocolNeeded?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partContent?: Maybe<Scalars['JSON']['output']>;
  partMarkings?: Maybe<Scalars['JSON']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  process?: Maybe<OmsEnquiryProcessType>;
  quantity: Scalars['Int']['output'];
  quantityOptions: Array<OmsEnquiryPartQuantityOption>;
  resolution?: Maybe<Scalars['String']['output']>;
  rfqId: Scalars['ID']['output'];
  samplesComment?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  samplesQuantity?: Maybe<Scalars['Int']['output']>;
  surfaceTitle?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  threadingQuantity?: Maybe<Scalars['Int']['output']>;
  toleranceTitle?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightKgPerPiece?: Maybe<Scalars['Float']['output']>;
};

export type RfqPositionMarginRules = {
  __typename?: 'RfqPositionMarginRules';
  rfqPositionId: Scalars['ID']['output'];
  rules: Array<RfqTargetMarginRule>;
};

export type RfqStatistics = {
  __typename?: 'RfqStatistics';
  ended?: Maybe<Scalars['ISO8601DateTime']['output']>;
  interested: Scalars['Int']['output'];
  offersPublished: Scalars['Int']['output'];
  qualifiedViews: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  responded: Scalars['Int']['output'];
  started?: Maybe<Scalars['ISO8601DateTime']['output']>;
  totalReaction: Scalars['Int']['output'];
  totalViews: Scalars['Int']['output'];
};

export type RfqTargetMarginRule = {
  __typename?: 'RfqTargetMarginRule';
  itemCostFrom: Money;
  itemCostTo: Money;
  margin: Scalars['Float']['output'];
  quantityFrom: Scalars['Int']['output'];
  quantityTo: Scalars['Int']['output'];
};

/** Autogenerated input type of RfqsCancel */
export type RfqsCancelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  decisionRefusalReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsCancel */
export type RfqsCancelPayload = {
  __typename?: 'RfqsCancelPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

/** Autogenerated input type of RfqsClose */
export type RfqsCloseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsClose */
export type RfqsClosePayload = {
  __typename?: 'RfqsClosePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

/** Autogenerated input type of RfqsDelete */
export type RfqsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsDelete */
export type RfqsDeletePayload = {
  __typename?: 'RfqsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

/** Autogenerated input type of RfqsExport */
export type RfqsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of RfqsExport */
export type RfqsExportPayload = {
  __typename?: 'RfqsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of RfqsOffersApply */
export type RfqsOffersApplyInput = {
  accept: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  positionIds: Array<Scalars['ID']['input']>;
  review: Scalars['Boolean']['input'];
};

/** Autogenerated return type of RfqsOffersApply */
export type RfqsOffersApplyPayload = {
  __typename?: 'RfqsOffersApplyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqOffer: RfqOffer;
};

export enum RfqsOffersDecisionStateEnumType {
  /** Accepted */
  Accepted = 'accepted',
  /** Negotiating */
  Negotiating = 'negotiating',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected'
}

/** Autogenerated input type of RfqsOffersDelete */
export type RfqsOffersDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsOffersDelete */
export type RfqsOffersDeletePayload = {
  __typename?: 'RfqsOffersDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqOffer: RfqOffer;
};

/** Autogenerated input type of RfqsOffersFindOrCreate */
export type RfqsOffersFindOrCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
  rfqId: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsOffersFindOrCreate */
export type RfqsOffersFindOrCreatePayload = {
  __typename?: 'RfqsOffersFindOrCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  new: Scalars['Boolean']['output'];
  rfqOffer: RfqOffer;
};

export type RfqsOffersPriceType = {
  __typename?: 'RfqsOffersPriceType';
  appliedMargin?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerPrice: Money;
  enquiryPartId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  leadtime?: Maybe<Scalars['Int']['output']>;
  providedById?: Maybe<Scalars['Int']['output']>;
  providedByType?: Maybe<Scalars['String']['output']>;
  providerPrice: Money;
  quantity: Scalars['Int']['output'];
  rfqOfferId: Scalars['Int']['output'];
  rfqPositionId: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of RfqsOffersPricesDelete */
export type RfqsOffersPricesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsOffersPricesDelete */
export type RfqsOffersPricesDeletePayload = {
  __typename?: 'RfqsOffersPricesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqOfferPrice: RfqsOffersPriceType;
};

export type RfqsOffersRateType = {
  __typename?: 'RfqsOffersRateType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  enquiryPartId: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  price: Money;
  quantity: Scalars['Int']['output'];
  quotePrice: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum RfqsOffersResponseStateEnumType {
  /** Canceled */
  Canceled = 'canceled',
  /** Empty */
  Empty = 'empty',
  /** Interested */
  Interested = 'interested',
  /** Rejected */
  Rejected = 'rejected',
  /** Submitted */
  Submitted = 'submitted'
}

/** Autogenerated input type of RfqsOffersUpdate */
export type RfqsOffersUpdateInput = {
  attributes: OmsInputsRfqsOfferType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsOffersUpdate */
export type RfqsOffersUpdatePayload = {
  __typename?: 'RfqsOffersUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqOffer: RfqOffer;
};

/** Autogenerated input type of RfqsPositionsDelete */
export type RfqsPositionsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsPositionsDelete */
export type RfqsPositionsDeletePayload = {
  __typename?: 'RfqsPositionsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfqPosition: RfqPosition;
};

/** Autogenerated input type of RfqsPublish */
export type RfqsPublishInput = {
  attributes: OmsInputsRfqType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsPublish */
export type RfqsPublishPayload = {
  __typename?: 'RfqsPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

/** Autogenerated input type of RfqsReactivate */
export type RfqsReactivateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  publicationEnd: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of RfqsReactivate */
export type RfqsReactivatePayload = {
  __typename?: 'RfqsReactivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

export enum RfqsStateEnumType {
  /** active */
  Active = 'active',
  /** canceled */
  Canceled = 'canceled',
  /** closed */
  Closed = 'closed',
  /** draft */
  Draft = 'draft',
  /** expired */
  Expired = 'expired'
}

/** Autogenerated input type of RfqsUpdate */
export type RfqsUpdateInput = {
  attributes: OmsInputsRfqType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RfqsUpdate */
export type RfqsUpdatePayload = {
  __typename?: 'RfqsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  rfq: Rfq;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['ISO8601DateTime']['output'];
  erpPermissions: Array<ErpPermission>;
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Intercompany Object Role */
export enum RoleEnum {
  /** prod */
  Prod = 'prod',
  /** rfq */
  Rfq = 'rfq',
  /** sales */
  Sales = 'sales',
  /** source */
  Source = 'source',
  /** target */
  Target = 'target'
}

/** Autogenerated input type of RolesUpdatePermissions */
export type RolesUpdatePermissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  erpPermissionIds: Array<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

/** Autogenerated return type of RolesUpdatePermissions */
export type RolesUpdatePermissionsPayload = {
  __typename?: 'RolesUpdatePermissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  role: Role;
};

export type Sachkonto = {
  __typename?: 'Sachkonto';
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  name: Scalars['String']['output'];
  number: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: SachkontoType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export enum SachkontoType {
  /** for OutcomingInvoices */
  Customer = 'customer',
  /** for IncomingInvoices */
  Partner = 'partner'
}

export type SearchItemType = {
  __typename?: 'SearchItemType';
  createdAt?: Maybe<Scalars['ISO8601Date']['output']>;
  dealId?: Maybe<Scalars['Int']['output']>;
  dealNumber?: Maybe<Scalars['String']['output']>;
  extras?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  number?: Maybe<Scalars['String']['output']>;
  organizationDisplayName?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizationNumber?: Maybe<Scalars['String']['output']>;
  personDisplayName?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['Int']['output']>;
  personNumber?: Maybe<Scalars['String']['output']>;
  providerDisplayName?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  providerNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** Autogenerated input type of ShipmentOrderCreateDhlOrder */
export type ShipmentOrderCreateDhlOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  destinationAddress: OmsInputsShipmentOrderDhlAddressType;
  expectedDeliveryDate: Scalars['ISO8601Date']['input'];
  movementReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  operator: Scalars['String']['input'];
  pickupOptions: OmsInputsShipmentOrderPickupOptionsType;
  plannedShippingDate: Scalars['ISO8601DateTime']['input'];
  productCode: Scalars['String']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  shippingCost?: InputMaybe<Scalars['Float']['input']>;
  shippingId: Scalars['ID']['input'];
  sourceAddress: OmsInputsShipmentOrderDhlAddressType;
};

/** Autogenerated return type of ShipmentOrderCreateDhlOrder */
export type ShipmentOrderCreateDhlOrderPayload = {
  __typename?: 'ShipmentOrderCreateDhlOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  steps: CreateDhlOrderSteps;
};

/** Autogenerated input type of ShipmentOrderCreateEurosenderOrder */
export type ShipmentOrderCreateEurosenderOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactEmail: Scalars['String']['input'];
  contentDescription: Scalars['String']['input'];
  destinationAddress: OmsInputsShipmentOrderEurosenderAddressType;
  pickupDatetime: Scalars['ISO8601DateTime']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  serviceType: Scalars['String']['input'];
  shippingId: Scalars['ID']['input'];
  sourceAddress: OmsInputsShipmentOrderEurosenderAddressType;
};

/** Autogenerated return type of ShipmentOrderCreateEurosenderOrder */
export type ShipmentOrderCreateEurosenderOrderPayload = {
  __typename?: 'ShipmentOrderCreateEurosenderOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ShipmentOrderCreateJumingoOrder */
export type ShipmentOrderCreateJumingoOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expectedDeliveryDate: Scalars['ISO8601Date']['input'];
  operator: Scalars['String']['input'];
  pickupDate: Scalars['ISO8601Date']['input'];
  pickupMaxTime: Scalars['String']['input'];
  pickupMinTime: Scalars['String']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  shippingId: Scalars['ID']['input'];
  tariffId: Scalars['String']['input'];
};

/** Autogenerated return type of ShipmentOrderCreateJumingoOrder */
export type ShipmentOrderCreateJumingoOrderPayload = {
  __typename?: 'ShipmentOrderCreateJumingoOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ShipmentOrderCreatePacklinkOrder */
export type ShipmentOrderCreatePacklinkOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  collectionDate: Scalars['ISO8601Date']['input'];
  collectionTime: Scalars['String']['input'];
  content: Scalars['String']['input'];
  destinationAddress: OmsInputsShipmentOrderPacklinkAddressType;
  expectedDeliveryDate: Scalars['ISO8601Date']['input'];
  operator: Scalars['String']['input'];
  productCode: Scalars['Int']['input'];
  shippingId: Scalars['ID']['input'];
  sourceAddress: OmsInputsShipmentOrderPacklinkAddressType;
};

/** Autogenerated return type of ShipmentOrderCreatePacklinkOrder */
export type ShipmentOrderCreatePacklinkOrderPayload = {
  __typename?: 'ShipmentOrderCreatePacklinkOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of ShipmentOrderCreateUpsOrder */
export type ShipmentOrderCreateUpsOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationAddress: OmsInputsShipmentOrderUpsAddressType;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  serviceCode: Scalars['String']['input'];
  shipmentDescription: Scalars['String']['input'];
  shippingId: Scalars['ID']['input'];
  sourceAddress: OmsInputsShipmentOrderUpsAddressType;
};

/** Autogenerated return type of ShipmentOrderCreateUpsOrder */
export type ShipmentOrderCreateUpsOrderPayload = {
  __typename?: 'ShipmentOrderCreateUpsOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ShipmentOrderCreateYurticiOrder */
export type ShipmentOrderCreateYurticiOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  destinationAddress: OmsInputsShipmentOrderYurticiAddressType;
  shippingId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShipmentOrderCreateYurticiOrder */
export type ShipmentOrderCreateYurticiOrderPayload = {
  __typename?: 'ShipmentOrderCreateYurticiOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export enum ShipmentOrderOperatorsEnum {
  /** DHL */
  Dhl = 'dhl',
  /** Eurosender */
  Eurosender = 'eurosender',
  /** Jumingo */
  Jumingo = 'jumingo',
  /** Packlink */
  Packlink = 'packlink',
  /** UPS */
  Ups = 'ups',
  /** Yurtici */
  Yurtici = 'yurtici'
}

/** Autogenerated input type of ShipmentOrderRequestShippingOptions */
export type ShipmentOrderRequestShippingOptionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  destinationAddress: OmsInputsShipmentOrderAddressType;
  operators: Array<ShipmentOrderOperatorsEnum>;
  packages: Array<OmsInputsShipmentOrderPackageType>;
  plannedShippingDate: Scalars['ISO8601Date']['input'];
  shippingId: Scalars['ID']['input'];
  sourceAddress: OmsInputsShipmentOrderAddressType;
};

/** Autogenerated return type of ShipmentOrderRequestShippingOptions */
export type ShipmentOrderRequestShippingOptionsPayload = {
  __typename?: 'ShipmentOrderRequestShippingOptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type Shipping = {
  __typename?: 'Shipping';
  actualDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  actualShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  allowDirectShipping: Scalars['Boolean']['output'];
  attachedFiles: Array<AttachedFile>;
  autoOrderState: ShippingAutoOrderStateEnum;
  /** N + 1, don't query for collection */
  countryOfOriginOfGoods?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentlyViewing: Scalars['Int']['output'];
  customsDocumentsStatus?: Maybe<ShippingCustomsDocumentsStatusEnum>;
  deal: Deal;
  dealId: Scalars['Int']['output'];
  deliveryBasis?: Maybe<ShippingDeliveryBasisEnum>;
  deliveryNoteComment?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<ShippingDeliveryTypeEnum>;
  destinationAddress?: Maybe<DealShippingAddressType>;
  direction: Scalars['String']['output'];
  expectedDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  expectedShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  holdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  holdBy?: Maybe<User>;
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  intercompanyObjects?: Maybe<Array<IntercompanyObject>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isExport: Scalars['Boolean']['output'];
  isImport: Scalars['Boolean']['output'];
  isImportAndExport: Scalars['Boolean']['output'];
  isPartial?: Maybe<Scalars['Boolean']['output']>;
  logisticAggregator?: Maybe<Scalars['String']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  lots: Array<Lot>;
  masterShipping?: Maybe<MasterShipping>;
  masterShippingId?: Maybe<Scalars['ID']['output']>;
  movementReferenceNumber?: Maybe<Scalars['String']['output']>;
  noInvoiceRequired: Scalars['Boolean']['output'];
  noInvoiceRequiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  noInvoiceRequiredBy?: Maybe<User>;
  noInvoiceRequiredComment?: Maybe<Scalars['String']['output']>;
  notifiedCustomerAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  notifiedPartnerAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  notifyCustomer?: Maybe<Scalars['Boolean']['output']>;
  notifyPartner?: Maybe<Scalars['Boolean']['output']>;
  number: Scalars['String']['output'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['ID']['output']>;
  packagingReportCheckStatus?: Maybe<Scalars['String']['output']>;
  packagingReportCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  positionNaming?: Maybe<ShippingPositionNamingEnum>;
  /** @deprecated This field is deprecated, please use [ship_warehouse] instead. */
  processingInstruction?: Maybe<ShippingInstructionEnum>;
  publishedInCa?: Maybe<Scalars['Boolean']['output']>;
  publishedInPa?: Maybe<Scalars['Boolean']['output']>;
  readyForCollection: Scalars['Boolean']['output'];
  readyForCollectionAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  receivedProcessingAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  receivedProcessingStatus: Scalars['String']['output'];
  referringDocuments: Array<ShippingRelatedObject>;
  relatedDocuments: Array<ShippingRelatedObject>;
  relatedProviderOrders?: Maybe<Array<ProviderOrder>>;
  services: Array<AdditionalService>;
  shippingAlertAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingAlertBy?: Maybe<User>;
  shippingAlertById?: Maybe<Scalars['ID']['output']>;
  shippingAlertMessage?: Maybe<Scalars['String']['output']>;
  shippingCost?: Maybe<Scalars['Float']['output']>;
  shippingCostCurrency: Scalars['String']['output'];
  shippingCostMoney: Money;
  shippingOrderNumber?: Maybe<Scalars['String']['output']>;
  shippingParameters?: Maybe<Array<ShippingParameters>>;
  sourceAddress?: Maybe<DealShippingAddressType>;
  sourceProviderOrder?: Maybe<ProviderOrder>;
  state: Scalars['String']['output'];
  trackingCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingRecords: Array<ShippingTrackingRecord>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  warehouseInstruction?: Maybe<WarehouseInstructionEnum>;
  xometryNumber: Scalars['String']['output'];
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['Int']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ShippingAddressesCreate */
export type ShippingAddressesCreateInput = {
  attributes: OmsInputsShippingAddressType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ShippingAddressesCreate */
export type ShippingAddressesCreatePayload = {
  __typename?: 'ShippingAddressesCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingAddress: ShippingAddress;
};

/** Autogenerated input type of ShippingAddressesDelete */
export type ShippingAddressesDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingAddressesDelete */
export type ShippingAddressesDeletePayload = {
  __typename?: 'ShippingAddressesDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingAddress: ShippingAddress;
};

/** Autogenerated input type of ShippingAddressesExport */
export type ShippingAddressesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of ShippingAddressesExport */
export type ShippingAddressesExportPayload = {
  __typename?: 'ShippingAddressesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ShippingAddressesForOrgPersonsCreate */
export type ShippingAddressesForOrgPersonsCreateInput = {
  attributes: OmsInputsShippingAddressForOrgPersonsType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ShippingAddressesForOrgPersonsCreate */
export type ShippingAddressesForOrgPersonsCreatePayload = {
  __typename?: 'ShippingAddressesForOrgPersonsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
};

/** Autogenerated input type of ShippingAddressesUpdateCascade */
export type ShippingAddressesUpdateCascadeInput = {
  attributes: OmsInputsShippingAddressUpdateCascadeType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingAddressesUpdateCascade */
export type ShippingAddressesUpdateCascadePayload = {
  __typename?: 'ShippingAddressesUpdateCascadePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingAddress: ShippingAddress;
};

/** Autogenerated input type of ShippingAddressesUpdate */
export type ShippingAddressesUpdateInput = {
  attributes: OmsInputsShippingAddressType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingAddressesUpdate */
export type ShippingAddressesUpdatePayload = {
  __typename?: 'ShippingAddressesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingAddress: ShippingAddress;
};

export enum ShippingAutoOrderStateEnum {
  /** failed */
  Failed = 'failed',
  /** manual_order */
  ManualOrder = 'manual_order',
  /** not_checked */
  NotChecked = 'not_checked',
  /** ordered */
  Ordered = 'ordered',
  /** skipped */
  Skipped = 'skipped',
  /** waits_for_order */
  WaitsForOrder = 'waits_for_order'
}

export type ShippingCalculation = {
  __typename?: 'ShippingCalculation';
  chargeableWeight?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency?: Maybe<CurrencyEnum>;
  destinationAddress: Address;
  id: Scalars['Int']['output'];
  positions: Array<Position>;
  prices: Array<Price>;
  sourceAddress: Address;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
};

/** A list of sources */
export enum ShippingCalculationSourceEnum {
  Enquiry = 'enquiry'
}

/** Autogenerated input type of ShippingCalculationsApplyCost */
export type ShippingCalculationsApplyCostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  priceId: Scalars['Int']['input'];
};

/** Autogenerated return type of ShippingCalculationsApplyCost */
export type ShippingCalculationsApplyCostPayload = {
  __typename?: 'ShippingCalculationsApplyCostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCalculation: ShippingCalculation;
};

/** Autogenerated input type of ShippingCalculationsCreate */
export type ShippingCalculationsCreateInput = {
  attributes: OmsInputsShippingCalculationType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ShippingCalculationsCreate */
export type ShippingCalculationsCreatePayload = {
  __typename?: 'ShippingCalculationsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCalculation: ShippingCalculation;
};

/** Autogenerated input type of ShippingCalculationsDelete */
export type ShippingCalculationsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingCalculationsDelete */
export type ShippingCalculationsDeletePayload = {
  __typename?: 'ShippingCalculationsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCalculation: ShippingCalculation;
};

export enum ShippingCustomsDocumentsStatusEnum {
  /** Completed */
  Completed = 'completed',
  /** In Progress */
  InProgress = 'in_progress',
  /** New */
  New = 'new',
  /** Not Required */
  NotRequired = 'not_required'
}

export enum ShippingDeliveryBasisEnum {
  /** CFR */
  Cfr = 'CFR',
  /** CIF */
  Cif = 'CIF',
  /** CIP */
  Cip = 'CIP',
  /** CPT */
  Cpt = 'CPT',
  /** DAP */
  Dap = 'DAP',
  /** DAT */
  Dat = 'DAT',
  /** DDP */
  Ddp = 'DDP',
  /** EXW */
  Exw = 'EXW',
  /** FAS */
  Fas = 'FAS',
  /** FCA */
  Fca = 'FCA',
  /** FOB */
  Fob = 'FOB'
}

export enum ShippingDeliveryTypeEnum {
  /** Box */
  Box = 'box',
  /** Packet */
  Packet = 'packet',
  /** Pallet */
  Pallet = 'pallet'
}

export enum ShippingDirectionEnum {
  /** Customer to Partner */
  C2p = 'c2p',
  /** Customer to Xometry */
  C2x = 'c2x',
  /** Partner to Customer */
  P2c = 'p2c',
  /** Partner to Partner */
  P2p = 'p2p',
  /** Partner to Xometry */
  P2x = 'p2x',
  /** Xometry to Customer */
  X2c = 'x2c',
  /** Xometry to Partner */
  X2p = 'x2p'
}

/** A list of shipping processing instruction */
export enum ShippingInstructionEnum {
  /** Repack */
  Repack = 'repack',
  /** Repack for Provider */
  Repack2p = 'repack2p',
  /** Xometry Scrap */
  Scrap = 'scrap',
  /** Xometry Store */
  Store = 'store',
  /** Xometry QC */
  Xqc = 'xqc'
}

export type ShippingInstructorFields = {
  __typename?: 'ShippingInstructorFields';
  shipAllowDirectLegal?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectLegalXomSales?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectOrder?: Maybe<Scalars['Boolean']['output']>;
  shipAllowDirectProvider?: Maybe<Scalars['Boolean']['output']>;
  shipDirection?: Maybe<ProviderOrdersShipDirectionEnum>;
  shipDirectionToEndCustomer?: Maybe<Scalars['Boolean']['output']>;
  shipIncoterms?: Maybe<ProviderOrdersIncotermsEnum>;
  shipIncotermsByPlaneOnly?: Maybe<Scalars['Boolean']['output']>;
  shipIncotermsCity?: Maybe<Scalars['String']['output']>;
  shipIncotermsCountry?: Maybe<Scalars['String']['output']>;
  shipQcRequired?: Maybe<Scalars['Boolean']['output']>;
  shipWarehouse?: Maybe<WarehouseInstructionEnum>;
};

export type ShippingParameters = {
  __typename?: 'ShippingParameters';
  brutto?: Maybe<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export enum ShippingPositionNamingEnum {
  /** clients */
  Clients = 'clients',
  /** customs */
  Customs = 'customs',
  /** customs_and_clients */
  CustomsAndClients = 'customs_and_clients'
}

export type ShippingRelatedObject = {
  __typename?: 'ShippingRelatedObject';
  canceled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  objectId: Scalars['BigInt']['output'];
  objectType: Scalars['String']['output'];
};

/** State of Shippings and Master Shippings */
export enum ShippingStateEnum {
  /** Box Ready */
  BoxReady = 'box_ready',
  /** Canceled */
  Canceled = 'canceled',
  /** Delivered */
  Delivered = 'delivered',
  /** Draft */
  Draft = 'draft',
  /** In Transit */
  InTransit = 'in_transit',
  /** Ready For Shipping */
  ReadyForShipping = 'ready_for_shipping',
  /** Shipping Ordered */
  ShippingOrdered = 'shipping_ordered'
}

export type ShippingTargetFee = {
  __typename?: 'ShippingTargetFee';
  acceptableFee: Money;
  targetFee: Money;
};

export type ShippingTrackingRecord = {
  __typename?: 'ShippingTrackingRecord';
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  data?: Maybe<ShippingTrackingRecordData>;
  id: Scalars['BigInt']['output'];
};

export type ShippingTrackingRecordData = {
  __typename?: 'ShippingTrackingRecordData';
  createdAt?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  packageStatus?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ShippingsAddToMasterShipping */
export type ShippingsAddToMasterShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  masterShippingId: Scalars['ID']['input'];
  shippingId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingsAddToMasterShipping */
export type ShippingsAddToMasterShippingPayload = {
  __typename?: 'ShippingsAddToMasterShippingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
};

/** Autogenerated input type of ShippingsBulkAddToMasterShipping */
export type ShippingsBulkAddToMasterShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  masterShippingId: Scalars['ID']['input'];
  shippingIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of ShippingsBulkAddToMasterShipping */
export type ShippingsBulkAddToMasterShippingPayload = {
  __typename?: 'ShippingsBulkAddToMasterShippingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
  validationErrors: Array<AddToMasterShippingValidationErrors>;
};

/** Autogenerated input type of ShippingsBulkMoveToState */
export type ShippingsBulkMoveToStateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingIds: Array<Scalars['ID']['input']>;
  state: Scalars['String']['input'];
};

/** Autogenerated return type of ShippingsBulkMoveToState */
export type ShippingsBulkMoveToStatePayload = {
  __typename?: 'ShippingsBulkMoveToStatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ShippingsBulkRemoveFromMasterShipping */
export type ShippingsBulkRemoveFromMasterShippingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  masterShippingId: Scalars['ID']['input'];
  shippingIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of ShippingsBulkRemoveFromMasterShipping */
export type ShippingsBulkRemoveFromMasterShippingPayload = {
  __typename?: 'ShippingsBulkRemoveFromMasterShippingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  masterShipping: MasterShipping;
};

/** Autogenerated input type of ShippingsBulkUpdateTracking */
export type ShippingsBulkUpdateTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated return type of ShippingsBulkUpdateTracking */
export type ShippingsBulkUpdateTrackingPayload = {
  __typename?: 'ShippingsBulkUpdateTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ShippingsCreate */
export type ShippingsCreateInput = {
  attributes: OmsInputsShippingsCreateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingsCreate */
export type ShippingsCreatePayload = {
  __typename?: 'ShippingsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of ShippingsExport */
export type ShippingsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of ShippingsExport */
export type ShippingsExportPayload = {
  __typename?: 'ShippingsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of ShippingsFormUpdate */
export type ShippingsFormUpdateInput = {
  attributes: OmsInputsShippingsUpdateType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /**
   * When provided, original attributes will be compared to database values and new attributes to detect conflicts.
   * If no conflicts found, only changed values will be written to database.
   *        When not provided, attributes will be written to database as is.
   *
   */
  originalAttributes?: InputMaybe<OmsInputsShippingsUpdateType>;
};

/** Autogenerated return type of ShippingsFormUpdate */
export type ShippingsFormUpdatePayload = {
  __typename?: 'ShippingsFormUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  conflicts?: Maybe<Array<AttributesConflict>>;
  createdOutcomingInvoiceId?: Maybe<Scalars['ID']['output']>;
  shipping: Shipping;
};

/** Autogenerated input type of ShippingsUpdateTracking */
export type ShippingsUpdateTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shippingId: Scalars['ID']['input'];
};

/** Autogenerated return type of ShippingsUpdateTracking */
export type ShippingsUpdateTrackingPayload = {
  __typename?: 'ShippingsUpdateTrackingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipping?: Maybe<Shipping>;
};

export enum SortDirectionEnum {
  /** Ascending sorting */
  Asc = 'asc',
  /** Descending sorting */
  Desc = 'desc'
}

export enum SortOptions {
  Author = 'author',
  Creation = 'creation',
  Deadline = 'deadline',
  Priority = 'priority',
  Type = 'type'
}

export type Source = ProviderOrder | Shipping;

export type SourceCollection = {
  __typename?: 'SourceCollection';
  sources: Array<DealsSourceType>;
};


export type SourceCollectionSourcesArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
};

export type SourceRelatedObjects = {
  __typename?: 'SourceRelatedObjects';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  person?: Maybe<Person>;
  provider?: Maybe<Provider>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Storage = {
  __typename?: 'Storage';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  location: StorageLocationEnum;
  number: Scalars['String']['output'];
  processingState: Scalars['String']['output'];
  quantityInital: Scalars['Int']['output'];
  quantityLeft: Scalars['Int']['output'];
  storageType: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum StorageLocationEnum {
  /** Basement */
  Basement = 'basement',
  /** Big Room */
  BigRoom = 'big_room',
  /** DB Schenker warehouse */
  DbSchenkerWarehouse = 'db_schenker_warehouse',
  /** Glonn Warehouse */
  GlonnWarehouse = 'glonn_warehouse',
  /** Otto-2 Warehouse */
  Otto2Warehouse = 'otto2_warehouse',
  /** QC Room */
  QcRoom = 'qc_room',
  /** Shipping Room */
  ShippingRoom = 'shipping_room',
  /** Storage Room */
  StorageRoom = 'storage_room',
  /** trashed */
  Trashed = 'trashed'
}

/** Autogenerated input type of StoragesExport */
export type StoragesExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of StoragesExport */
export type StoragesExportPayload = {
  __typename?: 'StoragesExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastReadMessageId?: Maybe<Scalars['ID']['output']>;
  lastReply?: Maybe<Message>;
  source?: Maybe<MessageSource>;
  unreadCount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of SubscriptionsExport */
export type SubscriptionsExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of SubscriptionsExport */
export type SubscriptionsExportPayload = {
  __typename?: 'SubscriptionsExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type SupportTicketsFilterType = {
  jobOfferId?: InputMaybe<Scalars['Int']['input']>;
  providerId?: InputMaybe<Scalars['Int']['input']>;
  providerOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type SupportTicketsPageType = {
  __typename?: 'SupportTicketsPageType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  tickets: Array<Ticket>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SurfaceRoughness = {
  __typename?: 'SurfaceRoughness';
  createdAt: Scalars['ISO8601DateTime']['output'];
  i18nKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  approved: Scalars['Boolean']['output'];
  context: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Tag>;
  parentId?: Maybe<Scalars['ID']['output']>;
  processType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum TaskType {
  Call = 'call',
  ChangesInDeal = 'changes_in_deal',
  CheckStatus = 'check_status',
  CheckingMaterialCertificate = 'checking_material_certificate',
  Checkpoint = 'checkpoint',
  CreateDocument = 'create_document',
  DiscussIssue = 'discuss_issue',
  Drawing = 'drawing',
  General = 'general',
  InvoiceIssue = 'invoice_issue',
  PlacingIssues = 'placing_issues',
  PlacingRepeatDeal = 'placing_repeat_deal',
  ReplacingRequest = 'replacing_request',
  RequestApprove = 'request_approve',
  RevenueForecastDiff = 'revenue_forecast_diff',
  Shipping = 'shipping',
  TalkToCustomer = 'talk_to_customer',
  TalkToPartner = 'talk_to_partner'
}

/** Autogenerated input type of TasksExport */
export type TasksExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of TasksExport */
export type TasksExportPayload = {
  __typename?: 'TasksExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

export type TemplateCategory = {
  __typename?: 'TemplateCategory';
  channels: Array<TemplateChannelEnum>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  entryPoints: Array<TemplateEntryPointEnum>;
  id: Scalars['ID']['output'];
  templates: Array<RenderedTemplate>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum TemplateChannelEnum {
  Email = 'EMAIL',
  Messenger = 'MESSENGER',
  Whatsapp = 'WHATSAPP'
}

export enum TemplateDirectionEnum {
  X2c = 'x2c',
  X2p = 'x2p',
  X2x = 'x2x'
}

export enum TemplateEntryPointEnum {
  DealHeader = 'DEAL_HEADER',
  DocumentEmail = 'DOCUMENT_EMAIL',
  DocumentMessenger = 'DOCUMENT_MESSENGER',
  GeneralMessengerPerson = 'GENERAL_MESSENGER_PERSON',
  GeneralMessengerProvider = 'GENERAL_MESSENGER_PROVIDER',
  PersonEmail = 'PERSON_EMAIL'
}

export enum TemplateLocaleEnum {
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fi = 'FI',
  Fr = 'FR',
  It = 'IT',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  Sv = 'SV',
  Tr = 'TR'
}

export type TemplatePlaceholderItem = {
  __typename?: 'TemplatePlaceholderItem';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type TemplatePlaceholderType = {
  __typename?: 'TemplatePlaceholderType';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  example?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type TemplatePlaceholdersCollectionType = {
  __typename?: 'TemplatePlaceholdersCollectionType';
  content: Array<TemplatePlaceholderItem>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  recipients: Array<TemplatePlaceholderItem>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum TemplateTypeEnum {
  BillingAccount = 'billing_account',
  Claim = 'claim',
  DeliveryObsolete = 'delivery_obsolete',
  Enquiry = 'enquiry',
  EnquiryNotifications = 'enquiry_notifications',
  EnquiryRfq = 'enquiry_rfq',
  GeneralMailer = 'general_mailer',
  IncomingInvoice = 'incoming_invoice',
  Job = 'job',
  Offer = 'offer',
  OrderConfirmation = 'order_confirmation',
  Organization = 'organization',
  OutcomingInvoice = 'outcoming_invoice',
  Payment = 'payment',
  PoReminder = 'po_reminder',
  PoolApplication = 'pool_application',
  ProviderContact = 'provider_contact',
  ProviderOrder = 'provider_order',
  Publication = 'publication',
  QualityControl = 'quality_control',
  Quotation = 'quotation',
  Shipping = 'shipping',
  ShippingObsolete = 'shipping_obsolete',
  SpecialOffer = 'special_offer',
  StatusChange = 'status_change',
  Whatsapp = 'whatsapp'
}

export type Ticket = {
  __typename?: 'Ticket';
  categoryName?: Maybe<Scalars['String']['output']>;
  claimId?: Maybe<Scalars['Int']['output']>;
  dealId?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  jobOfferId?: Maybe<Scalars['Int']['output']>;
  providerOrderId?: Maybe<Scalars['Int']['output']>;
  requestedAt: Scalars['ISO8601DateTime']['output'];
  status: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Tolerance = {
  __typename?: 'Tolerance';
  createdAt: Scalars['ISO8601DateTime']['output'];
  default: Scalars['Boolean']['output'];
  i18nKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  quantityEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type Transaction = {
  __typename?: 'Transaction';
  createdAt: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  moneyAmount: Scalars['Int']['output'];
  personId: Scalars['ID']['output'];
  pointsAmount: Scalars['Int']['output'];
  referenceId?: Maybe<Scalars['ID']['output']>;
  referenceType?: Maybe<LoyaltyTransactionsReferenceEnumType>;
  state: Scalars['String']['output'];
  transactionType: LoyaltyTransactionsTypeEnumType;
  trigger: LoyaltyTransactionsTriggerEnumType;
};

export enum TransferEnum {
  /** Wire */
  Wire = 'Wire',
  /** UK Only: ach_bucs */
  AchBucs = 'ach_bucs',
  /** ach_sepa */
  AchSepa = 'ach_sepa'
}

export enum TypeNameEnum {
  /** cert_material */
  CertMaterial = 'cert_material',
  /** correction */
  Correction = 'correction',
  /** coupon */
  Coupon = 'coupon',
  /** custom_clearance */
  CustomClearance = 'custom_clearance',
  /** discount */
  Discount = 'discount',
  /** discount_prepay */
  DiscountPrepay = 'discount_prepay',
  /** im_tools */
  ImTools = 'im_tools',
  /** material */
  Material = 'material',
  /** measurement_protocols */
  MeasurementProtocols = 'measurement_protocols',
  /** min_order_charge */
  MinOrderCharge = 'min_order_charge',
  /** other */
  Other = 'other',
  /** packaging */
  Packaging = 'packaging',
  /** parts */
  Parts = 'parts',
  /** service */
  Service = 'service',
  /** total */
  Total = 'total',
  /** transport */
  Transport = 'transport'
}

/** Autogenerated input type of UpdateLogisticInvoice */
export type UpdateLogisticInvoiceInput = {
  amountBrutto?: InputMaybe<Scalars['Float']['input']>;
  amountNetto?: InputMaybe<Scalars['Float']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  invoiceFiles?: InputMaybe<Array<OmsInputsAttachmentType>>;
  isCanceled?: InputMaybe<Scalars['Boolean']['input']>;
  isChecked?: InputMaybe<Scalars['Boolean']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  issueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  logisticOperator?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  paymentDueDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  registeredAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  sourceFiles?: InputMaybe<Array<OmsInputsAttachmentType>>;
};

/** Autogenerated return type of UpdateLogisticInvoice */
export type UpdateLogisticInvoicePayload = {
  __typename?: 'UpdateLogisticInvoicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  logisticInvoice?: Maybe<LogisticInvoice>;
};

/** Autogenerated input type of UpdateLogisticInvoicePosition */
export type UpdateLogisticInvoicePositionInput = {
  allocatedCharges?: InputMaybe<Scalars['Float']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  expenseType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  logisticInvoiceId: Scalars['ID']['input'];
  shippingId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateLogisticInvoicePosition */
export type UpdateLogisticInvoicePositionPayload = {
  __typename?: 'UpdateLogisticInvoicePositionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  logisticInvoice?: Maybe<LogisticInvoice>;
};

/** Autogenerated input type of UpdateTag */
export type UpdateTagInput = {
  approved: Scalars['Boolean']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerId: Scalars['ID']['input'];
  tagId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateTag */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tag: Tag;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  autoAssignmentSettings?: Maybe<AutoAssignmentSettings>;
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  couponLimit: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deputyUser?: Maybe<User>;
  deputyUserId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isRobot: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  messageFilterGroups?: Maybe<Array<MessageFilterGroup>>;
  messageFilters?: Maybe<Array<MessageFilter>>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['ID']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<UserRegionEnum>;
  rfqLimit?: Maybe<Scalars['Int']['output']>;
  roles: Array<Role>;
  salesforceId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum UserEnum {
  Client = 'CLIENT',
  ExternalContact = 'EXTERNAL_CONTACT',
  Partner = 'PARTNER',
  Xometry = 'XOMETRY'
}

export type UserPermission = {
  __typename?: 'UserPermission';
  createdAt: Scalars['ISO8601DateTime']['output'];
  erpPermission: ErpPermission;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum UserRegionEnum {
  De = 'DE',
  Fr = 'FR',
  NordixBenelux = 'NordixBenelux',
  Others = 'Others',
  Ukie = 'UKIE'
}

/** Autogenerated input type of UsersAssignRoles */
export type UsersAssignRolesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UsersAssignRoles */
export type UsersAssignRolesPayload = {
  __typename?: 'UsersAssignRolesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
};

/** Autogenerated input type of UsersExport */
export type UsersExportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns: Array<WorkspacesInputsExportColumnType>;
  filename: Scalars['String']['input'];
  filterQuery: Scalars['String']['input'];
  format: Scalars['String']['input'];
  paginationParams: WorkspacesInputsGenericPaginationType;
  queryOptions?: InputMaybe<Scalars['JSON']['input']>;
  selectedRecords?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortParams?: InputMaybe<Array<WorkspacesInputsGenericSortingType>>;
  timezoneOffset: Scalars['Int']['input'];
};

/** Autogenerated return type of UsersExport */
export type UsersExportPayload = {
  __typename?: 'UsersExportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  jobId: Scalars['String']['output'];
};

/** Autogenerated input type of UsersUpdatePermissions */
export type UsersUpdatePermissionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<OmsInputsUserPermission>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UsersUpdatePermissions */
export type UsersUpdatePermissionsPayload = {
  __typename?: 'UsersUpdatePermissionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userPermissions: Array<UserPermission>;
};

/** Autogenerated input type of UsersUpdateProfile */
export type UsersUpdateProfileInput = {
  attributes: OmsInputsUserProfile;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UsersUpdateProfile */
export type UsersUpdateProfilePayload = {
  __typename?: 'UsersUpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type Utm = {
  __typename?: 'Utm';
  campaign?: Maybe<Scalars['BigInt']['output']>;
  medium?: Maybe<Scalars['String']['output']>;
  term?: Maybe<Scalars['String']['output']>;
};

export type Validations = {
  __typename?: 'Validations';
  bffValidation?: Maybe<VatValidation>;
  ecValidation?: Maybe<VatValidation>;
  lastValidation?: Maybe<VatValidation>;
  total: Scalars['Int']['output'];
  validations: Array<VatValidation>;
};

export type VatValidation = {
  __typename?: 'VatValidation';
  bffCityValid?: Maybe<Scalars['Boolean']['output']>;
  bffCode?: Maybe<Scalars['Int']['output']>;
  bffMessage?: Maybe<Scalars['String']['output']>;
  bffNameValid?: Maybe<Scalars['Boolean']['output']>;
  bffResponse?: Maybe<Scalars['JSON']['output']>;
  bffStreetValid?: Maybe<Scalars['Boolean']['output']>;
  bffVatValid?: Maybe<Scalars['Boolean']['output']>;
  bffZipValid?: Maybe<Scalars['Boolean']['output']>;
  checkedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  ecAddress?: Maybe<Scalars['String']['output']>;
  ecName?: Maybe<Scalars['String']['output']>;
  ecRequestDate?: Maybe<Scalars['String']['output']>;
  ecResponse?: Maybe<Scalars['JSON']['output']>;
  ecValid?: Maybe<Scalars['Boolean']['output']>;
  ecVatNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  request?: Maybe<Scalars['JSON']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** A list of WarehouseInstruction */
export enum WarehouseInstructionEnum {
  /** Not Applicable */
  NotApplicable = 'not_applicable',
  /** Repack */
  Repack = 'repack',
  /** Repack for Provider */
  Repack2p = 'repack2p',
  /** Xometry Scrap */
  Scrap = 'scrap',
  /** Xometry Store */
  Store = 'store',
  /** Xometry QC */
  Xqc = 'xqc'
}

export enum WhatsAppStatusEnum {
  DialogClosed = 'dialog_closed',
  DialogInitialized = 'dialog_initialized',
  DialogOpen = 'dialog_open',
  NoVerifiedNumber = 'no_verified_number',
  VerifiedNoMessages = 'verified_no_messages'
}

export type WorkspacePreset = {
  __typename?: 'WorkspacePreset';
  columns?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  exportColumns?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  lastUpdatedBy?: Maybe<User>;
  name: Scalars['String']['output'];
  owner: User;
  public: Scalars['Boolean']['output'];
  query?: Maybe<Scalars['String']['output']>;
  sortColumn?: Maybe<Scalars['String']['output']>;
  sortDirection?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  workspaceName: Scalars['String']['output'];
};

/** Autogenerated input type of WorkspacePresetsAssignDefaultPreset */
export type WorkspacePresetsAssignDefaultPresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  presetId: Scalars['ID']['input'];
  workspaceName: Scalars['String']['input'];
};

/** Autogenerated return type of WorkspacePresetsAssignDefaultPreset */
export type WorkspacePresetsAssignDefaultPresetPayload = {
  __typename?: 'WorkspacePresetsAssignDefaultPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of WorkspacePresetsCreate */
export type WorkspacePresetsCreateInput = {
  attributes: OmsInputsWorkspacePresetType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WorkspacePresetsCreate */
export type WorkspacePresetsCreatePayload = {
  __typename?: 'WorkspacePresetsCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  workspacePreset: WorkspacePreset;
};

/** Autogenerated input type of WorkspacePresetsDelete */
export type WorkspacePresetsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of WorkspacePresetsDelete */
export type WorkspacePresetsDeletePayload = {
  __typename?: 'WorkspacePresetsDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of WorkspacePresetsRemoveDefaultPreset */
export type WorkspacePresetsRemoveDefaultPresetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  workspaceName: Scalars['String']['input'];
};

/** Autogenerated return type of WorkspacePresetsRemoveDefaultPreset */
export type WorkspacePresetsRemoveDefaultPresetPayload = {
  __typename?: 'WorkspacePresetsRemoveDefaultPresetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of WorkspacePresetsUpdate */
export type WorkspacePresetsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  exportColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of WorkspacePresetsUpdate */
export type WorkspacePresetsUpdatePayload = {
  __typename?: 'WorkspacePresetsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  workspacePreset: WorkspacePreset;
};

export type WorkspacesAcceptedJobOffer = {
  __typename?: 'WorkspacesAcceptedJobOffer';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  providerOrderId?: Maybe<Scalars['ID']['output']>;
  responseState?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPayment = {
  __typename?: 'WorkspacesBankPayment';
  amountCents?: Maybe<Scalars['BigInt']['output']>;
  amountCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountMoney: Money;
  amountToBeRecognizedCents?: Maybe<Scalars['BigInt']['output']>;
  amountToBeRecognizedCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountToBeRecognizedMoney: Money;
  approvedAt?: Maybe<Scalars['Float']['output']>;
  /** custom workspace_object */
  approvedBy?: Maybe<WorkspacesPayoutUser>;
  batchImportId?: Maybe<Scalars['BigInt']['output']>;
  billingAccountId?: Maybe<Scalars['String']['output']>;
  billingAccountName?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  counterparty?: Maybe<Scalars['JSON']['output']>;
  counterpartyId?: Maybe<Scalars['BigInt']['output']>;
  counterpartyType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  direction: Scalars['String']['output'];
  endToEndId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  outlyingTransactionType?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  paymentDetails?: Maybe<Scalars['JSON']['output']>;
  paymentDetailsBankAccount?: Maybe<Scalars['String']['output']>;
  paymentDetailsBankCode?: Maybe<Scalars['String']['output']>;
  paymentDetailsBankCountry?: Maybe<Scalars['String']['output']>;
  paymentDetailsPayoutTransferType?: Maybe<Scalars['String']['output']>;
  paymentDetailsProviderCountry?: Maybe<Scalars['String']['output']>;
  paymentDetailsProviderName?: Maybe<Scalars['String']['output']>;
  paymentDetailsString?: Maybe<Scalars['String']['output']>;
  paymentDetailsTransactionId?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  payments?: Maybe<Array<WorkspacesBankPaymentPayment>>;
  /** custom workspace_object */
  payouts?: Maybe<Array<WorkspacesBankPaymentPayout>>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerLegalName?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  state: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** custom workspace_object */
  xometryBankAccount?: Maybe<Scalars['JSON']['output']>;
  xometryBankAccountAccountId?: Maybe<Scalars['String']['output']>;
  xometryBankAccountAccountNumber?: Maybe<Scalars['String']['output']>;
  xometryBankAccountString?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesBankPaymentPayment = {
  __typename?: 'WorkspacesBankPaymentPayment';
  amountBrut?: Maybe<Scalars['Float']['output']>;
  amountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  amountBrutMoney?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isRefund: Scalars['Boolean']['output'];
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  source?: Maybe<Scalars['JSON']['output']>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentPayout = {
  __typename?: 'WorkspacesBankPaymentPayout';
  amount: Scalars['Float']['output'];
  amountMoney: Money;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  fastPay: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instantPay: Scalars['Boolean']['output'];
  isRefund: Scalars['Boolean']['output'];
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  prepayment: Scalars['Boolean']['output'];
  source?: Maybe<Scalars['JSON']['output']>;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentTotalAmounts = {
  __typename?: 'WorkspacesBankPaymentTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalAmount: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentUpdatedPayment = {
  __typename?: 'WorkspacesBankPaymentUpdatedPayment';
  amountBrut?: Maybe<Scalars['Float']['output']>;
  amountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  amountBrutMoney?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  isRefund: Scalars['Boolean']['output'];
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  source?: Maybe<WorkspacesBankPaymentUpdatedPaymentSource>;
  sourceId?: Maybe<Scalars['ID']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentUpdatedPaymentSource = {
  __typename?: 'WorkspacesBankPaymentUpdatedPaymentSource';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney?: Maybe<Money>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesBankPaymentUpdatedPayout = {
  __typename?: 'WorkspacesBankPaymentUpdatedPayout';
  amount: Scalars['Float']['output'];
  amountCents: Scalars['BigInt']['output'];
  amountMoney: Money;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  fastPay: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instantPay: Scalars['Boolean']['output'];
  isRefund: Scalars['Boolean']['output'];
  methodId?: Maybe<Scalars['ID']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  prepayment: Scalars['Boolean']['output'];
  source?: Maybe<WorkspacesBankPaymentUpdatedPayoutSource>;
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['String']['output'];
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentUpdatedPayoutSource = {
  __typename?: 'WorkspacesBankPaymentUpdatedPayoutSource';
  amount?: Maybe<Scalars['Float']['output']>;
  amountMoney?: Maybe<Money>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  number?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesBankPaymentsCollection = {
  __typename?: 'WorkspacesBankPaymentsCollection';
  objects: Array<WorkspacesBankPayment>;
  total: Scalars['Int']['output'];
};

export type WorkspacesBankPaymentsDraft = {
  __typename?: 'WorkspacesBankPaymentsDraft';
  amountCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  amountMoney: Money;
  amountToBeRecognizedCents?: Maybe<Scalars['BigInt']['output']>;
  amountToBeRecognizedCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountToBeRecognizedMoney: Money;
  approvedAt?: Maybe<Scalars['Float']['output']>;
  /** custom workspace_object */
  approvedBy?: Maybe<WorkspacesPayoutUser>;
  bankPaymentExportStatusCode?: Maybe<Scalars['String']['output']>;
  bankPaymentExportStatusText?: Maybe<Scalars['String']['output']>;
  batchImportId?: Maybe<Scalars['BigInt']['output']>;
  billingAccountId?: Maybe<Scalars['String']['output']>;
  billingAccountName?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  counterparty?: Maybe<Scalars['JSON']['output']>;
  counterpartyId?: Maybe<Scalars['BigInt']['output']>;
  counterpartyType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  direction: Scalars['String']['output'];
  endToEndId?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  jpmCheckAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  jpmStatus?: Maybe<Scalars['String']['output']>;
  lastExportRowBatchId?: Maybe<Scalars['BigInt']['output']>;
  lastExportRowResponse?: Maybe<Scalars['String']['output']>;
  outlyingTransactionType?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  paymentDetails?: Maybe<Scalars['JSON']['output']>;
  paymentDetailsBankAccount?: Maybe<Scalars['String']['output']>;
  paymentDetailsBankCode?: Maybe<Scalars['String']['output']>;
  paymentDetailsBankCountry?: Maybe<Scalars['String']['output']>;
  paymentDetailsPayoutTransferType?: Maybe<Scalars['String']['output']>;
  paymentDetailsProviderCountry?: Maybe<Scalars['String']['output']>;
  paymentDetailsProviderName?: Maybe<Scalars['String']['output']>;
  paymentDetailsString?: Maybe<Scalars['String']['output']>;
  paymentDetailsTransactionId?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  payments?: Maybe<Array<WorkspacesBankPaymentPayment>>;
  /** custom workspace_object */
  payouts?: Maybe<Array<WorkspacesBankPaymentPayout>>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerLegalName?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  state: Scalars['String']['output'];
  transactionId?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** custom workspace_object */
  xometryBankAccount?: Maybe<Scalars['JSON']['output']>;
  xometryBankAccountAccountId?: Maybe<Scalars['String']['output']>;
  xometryBankAccountString?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesBankPaymentsDraftCollection = {
  __typename?: 'WorkspacesBankPaymentsDraftCollection';
  objects: Array<WorkspacesBankPaymentsDraft>;
  total: Scalars['Int']['output'];
};

export type WorkspacesBillingAccount = {
  __typename?: 'WorkspacesBillingAccount';
  accountType?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creditLimitCents: Scalars['BigInt']['output'];
  creditLimitCurrency: Scalars['String']['output'];
  /** custom workspace_object */
  creditLimitMoney: Money;
  creditLimitRequestCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  creditLimitRequestMoney: Money;
  dnbRecommendationCents: Scalars['BigInt']['output'];
  dnbRecommendationCurrency: Scalars['String']['output'];
  /** custom workspace_object */
  dnbRecommendationMoney: Money;
  id: Scalars['BigInt']['output'];
  intacctId?: Maybe<Scalars['String']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentTermsDays?: Maybe<Scalars['Int']['output']>;
  paymentTermsType: Scalars['String']['output'];
  paymentTermsUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  paymentTermsUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  paymentTermsUpdatedByName?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  personsLinked?: Maybe<Array<Scalars['JSON']['output']>>;
  registeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAfterRegistered?: Maybe<Scalars['Boolean']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
  vatValidationStatus?: Maybe<Scalars['String']['output']>;
  vatValidationTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesBillingAccountsCollection = {
  __typename?: 'WorkspacesBillingAccountsCollection';
  objects: Array<WorkspacesBillingAccount>;
  total: Scalars['Int']['output'];
};

export type WorkspacesClaim = {
  __typename?: 'WorkspacesClaim';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  directExpenses?: Maybe<Scalars['Float']['output']>;
  euCustoms: Scalars['Boolean']['output'];
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  itemsCount?: Maybe<Scalars['Int']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  ocBillingCountry?: Maybe<Scalars['String']['output']>;
  ocBillingName?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  positionsCount?: Maybe<Scalars['Int']['output']>;
  /** workspace_object */
  providerOrders?: Maybe<Array<WorkspacesClaimProviderOrder>>;
  /** workspace_object */
  providers?: Maybe<Array<WorkspacesClaimProvider>>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalCustomerValueCents?: Maybe<Scalars['BigInt']['output']>;
  totalCustomerValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalCustomerValueMoney: Money;
  totalProviderValueCents?: Maybe<Scalars['BigInt']['output']>;
  totalProviderValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalProviderValueMoney: Money;
  typeClaim?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesClaimProvider = {
  __typename?: 'WorkspacesClaimProvider';
  /** skip */
  createdAt: Scalars['ISO8601Date']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** skip */
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesClaimProviderOrder = {
  __typename?: 'WorkspacesClaimProviderOrder';
  createdAt: Scalars['ISO8601Date']['output'];
  id: Scalars['Int']['output'];
  number?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesClaimsCollection = {
  __typename?: 'WorkspacesClaimsCollection';
  objects: Array<WorkspacesClaim>;
  total: Scalars['Int']['output'];
};

export type WorkspacesDeal = {
  __typename?: 'WorkspacesDeal';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingAccountName?: Maybe<Scalars['String']['output']>;
  billingAccountTaxId?: Maybe<Scalars['String']['output']>;
  billingAccountType?: Maybe<Scalars['String']['output']>;
  billingAccountVatId?: Maybe<Scalars['String']['output']>;
  billingAccountVatValidationStatus?: Maybe<Scalars['String']['output']>;
  billingAccountVatValidationTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  billingAddressValue?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingZip?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  confirmDate?: Maybe<Scalars['ISO8601Date']['output']>;
  consistencyCheck?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency: Scalars['String']['output'];
  /** custom workspace_object */
  customerInvoicesSumNettoMoney?: Maybe<Money>;
  customerOrderCode?: Maybe<Scalars['String']['output']>;
  customerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deadlineAlarm?: Maybe<Scalars['Boolean']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealNotes?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDueDays?: Maybe<Scalars['Int']['output']>;
  drawingCleanupStatus?: Maybe<Scalars['String']['output']>;
  enquiryId?: Maybe<Scalars['BigInt']['output']>;
  euCustoms: Scalars['Boolean']['output'];
  frameworkAgreement: Scalars['Boolean']['output'];
  grade?: Maybe<Scalars['String']['output']>;
  /** custom */
  hasCustomerCreditNote?: Maybe<Scalars['Boolean']['output']>;
  hasCustomerInvoice: Scalars['Boolean']['output'];
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting: Scalars['Boolean']['output'];
  isFullyInvoiced: Scalars['Boolean']['output'];
  isFullyPaid: Scalars['Boolean']['output'];
  isFullyShipped: Scalars['Boolean']['output'];
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isNpg: Scalars['Boolean']['output'];
  isOnHold?: Maybe<Scalars['Boolean']['output']>;
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  kamId?: Maybe<Scalars['Int']['output']>;
  kamName?: Maybe<Scalars['String']['output']>;
  manualProcessingComment?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationIndustry?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationWebsite?: Maybe<Scalars['String']['output']>;
  partsPlaced?: Maybe<Scalars['String']['output']>;
  personContactId?: Maybe<Scalars['Int']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personLanguage?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveOrderValueCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  pipedriveOrderValueMoney: Money;
  pipedrivePipelineName?: Maybe<Scalars['String']['output']>;
  pipedriveStageId?: Maybe<Scalars['Int']['output']>;
  pipedriveStageName?: Maybe<Scalars['String']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  pipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  pipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  pipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  pricingMethod?: Maybe<Array<Scalars['String']['output']>>;
  productionCompleted?: Maybe<Scalars['Boolean']['output']>;
  purchaseOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  readyForPlacing?: Maybe<Scalars['Boolean']['output']>;
  reasonOfLose?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  sendOc?: Maybe<Scalars['Boolean']['output']>;
  shippingAddressValue?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  techAuditStatuses?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  techTags?: Maybe<Array<Scalars['String']['output']>>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalAmountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  totalAmountBrutCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalAmountBrutMoney: Money;
  totalAmountNetCents?: Maybe<Scalars['BigInt']['output']>;
  totalAmountNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalAmountNetMoney: Money;
  totalPaidNettCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  totalPaidNettMoney: Money;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type WorkspacesDealsCollection = {
  __typename?: 'WorkspacesDealsCollection';
  objects: Array<WorkspacesDeal>;
  total: Scalars['Int']['output'];
};

export type WorkspacesDraftingDealPart = {
  __typename?: 'WorkspacesDraftingDealPart';
  auditDrawingReworkTask?: Maybe<Scalars['String']['output']>;
  auditDrawingsReworkedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** _allow_array */
  auditDrawingsReworkedBy?: Maybe<Array<Scalars['BigInt']['output']>>;
  /** _allow_array */
  auditState?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  auditUpdatedBy?: Maybe<Array<Scalars['BigInt']['output']>>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsOpen: Scalars['Int']['output'];
  claimsState?: Maybe<Scalars['String']['output']>;
  claimsTotal: Scalars['Int']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  dealsOpen: Scalars['Int']['output'];
  dealsTotal: Scalars['Int']['output'];
  dimensionsError?: Maybe<Scalars['Boolean']['output']>;
  drawingTaskType?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  enquiryDrawingsReworkedBy?: Maybe<Array<Scalars['BigInt']['output']>>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  kamId?: Maybe<Scalars['Int']['output']>;
  kamName?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  minutesSinceLastAudit: Scalars['Int']['output'];
  needInfoAge?: Maybe<Scalars['Int']['output']>;
  orderConfirmationAgeMinutes: Scalars['Int']['output'];
  orderConfirmationCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  orderConfirmationTotalAmountNetCents?: Maybe<Scalars['BigInt']['output']>;
  orderConfirmationTotalAmountNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  orderConfirmationTotalAmountNetMoney: Money;
  /** custom _allow_array */
  orderedBy?: Maybe<Array<Scalars['String']['output']>>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  partsCount?: Maybe<Scalars['Int']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personLocale?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  poCount?: Maybe<Scalars['Int']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  process?: Maybe<Array<Scalars['String']['output']>>;
  reworkStatus?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  slackLink?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspacesDraftingDealPartsCollection = {
  __typename?: 'WorkspacesDraftingDealPartsCollection';
  objects: Array<WorkspacesDraftingDealPart>;
  total: Scalars['Int']['output'];
};

export type WorkspacesDraftingEnquiryPart = {
  __typename?: 'WorkspacesDraftingEnquiryPart';
  allDrawingsReworked: Scalars['Boolean']['output'];
  allPartsCleaned: Scalars['Boolean']['output'];
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealIsForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  dealIsImportant?: Maybe<Scalars['Boolean']['output']>;
  dealIsOnHold?: Maybe<Scalars['Boolean']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  drawingsReworkedBy?: Maybe<Array<Scalars['BigInt']['output']>>;
  id: Scalars['BigInt']['output'];
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationKamId?: Maybe<Scalars['BigInt']['output']>;
  partsCount: Scalars['Int']['output'];
  podId?: Maybe<Scalars['Int']['output']>;
  priority: Scalars['Int']['output'];
  /** _allow_array */
  process?: Maybe<Array<Scalars['String']['output']>>;
  rfqAgeMinutes: Scalars['Int']['output'];
  /** _allow_array */
  rfqOwners?: Maybe<Array<Scalars['BigInt']['output']>>;
  rfqRequired: Scalars['Boolean']['output'];
  rfqRequiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  samplesQuotaNeeded?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspacesDraftingEnquiryPartsCollection = {
  __typename?: 'WorkspacesDraftingEnquiryPartsCollection';
  objects: Array<WorkspacesDraftingEnquiryPart>;
  total: Scalars['Int']['output'];
};

export type WorkspacesEnquiriesCollection = {
  __typename?: 'WorkspacesEnquiriesCollection';
  objects: Array<WorkspacesEnquiry>;
  total: Scalars['Int']['output'];
};

export type WorkspacesEnquiry = {
  __typename?: 'WorkspacesEnquiry';
  /** custom */
  additionalServices: Scalars['JSON']['output'];
  assignedEng?: Maybe<Scalars['Int']['output']>;
  assignedEngineerName?: Maybe<Scalars['String']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  /** custom */
  conversion?: Maybe<Scalars['JSON']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  couponId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  currency: Scalars['String']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  /** _allow_array */
  dealIntercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  dealIsOnHold?: Maybe<Scalars['Boolean']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealSalesforceId?: Maybe<Scalars['String']['output']>;
  dealSource?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  extraKamModerationRequired?: Maybe<Scalars['Boolean']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  kamModerationRequired?: Maybe<Scalars['Boolean']['output']>;
  /** custom */
  lastMessage?: Maybe<Scalars['JSON']['output']>;
  number: Scalars['String']['output'];
  orderedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** custom */
  parts: Scalars['JSON']['output'];
  partsValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  partsValueMoney: Money;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  processesNames?: Maybe<Array<Scalars['String']['output']>>;
  quotationMethod?: Maybe<Scalars['String']['output']>;
  quotingState?: Maybe<Scalars['String']['output']>;
  rfqAge?: Maybe<Scalars['Int']['output']>;
  rfqIsUrgent?: Maybe<Scalars['Boolean']['output']>;
  rfqNeedEngineer?: Maybe<Scalars['Boolean']['output']>;
  rfqNeedInfo?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  rfqOwnerIds?: Maybe<Array<Scalars['BigInt']['output']>>;
  rfqOwnerNames?: Maybe<Scalars['String']['output']>;
  rfqPartsCleaned: Scalars['Boolean']['output'];
  /** _allow_array */
  rfqStatuses?: Maybe<Array<Scalars['String']['output']>>;
  salesAssistantId?: Maybe<Scalars['BigInt']['output']>;
  salesAssistantName?: Maybe<Scalars['String']['output']>;
  salesPersonId?: Maybe<Scalars['BigInt']['output']>;
  salesPersonName?: Maybe<Scalars['String']['output']>;
  samplesQuotaNeeded?: Maybe<Scalars['Boolean']['output']>;
  shippingCostCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  shippingCostMoney: Money;
  state: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalAmountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  totalAmountBrutMoney: Money;
  totalAmountNetCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  totalAmountNetMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  vatAmountCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  vatAmountMoney: Money;
};

export type WorkspacesEnquiryPromotion = {
  __typename?: 'WorkspacesEnquiryPromotion';
  code: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  discountAmount: Scalars['Float']['output'];
  discountAmountCents: Scalars['BigInt']['output'];
  discountAmountPercent: Scalars['BigInt']['output'];
  discountUnit: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** custom workspace_object */
  settings?: Maybe<Scalars['JSON']['output']>;
};

export type WorkspacesEnquiryPromotionsCollection = {
  __typename?: 'WorkspacesEnquiryPromotionsCollection';
  objects: Array<WorkspacesEnquiryPromotion>;
  total: Scalars['Int']['output'];
};

export type WorkspacesFrameworkBatch = {
  __typename?: 'WorkspacesFrameworkBatch';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingAccountName?: Maybe<Scalars['String']['output']>;
  billingAccountTaxId?: Maybe<Scalars['String']['output']>;
  billingAccountType?: Maybe<Scalars['String']['output']>;
  billingAccountVatId?: Maybe<Scalars['String']['output']>;
  billingAccountVatValidationStatus?: Maybe<Scalars['String']['output']>;
  billingAccountVatValidationTimestamp?: Maybe<Scalars['ISO8601DateTime']['output']>;
  billingAddressValue?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingZip?: Maybe<Scalars['String']['output']>;
  certificateNeeded?: Maybe<Scalars['Boolean']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  confirmDate?: Maybe<Scalars['ISO8601Date']['output']>;
  consistencyCheck?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency: Scalars['String']['output'];
  /** custom workspace_object */
  customerInvoicesSumNettoMoney?: Maybe<Money>;
  customerOrderCode?: Maybe<Scalars['String']['output']>;
  customerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deadlineAlarm?: Maybe<Scalars['Boolean']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  deliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  deliveryDueDays?: Maybe<Scalars['Int']['output']>;
  drawingCleanupStatus?: Maybe<Scalars['String']['output']>;
  enquiryId?: Maybe<Scalars['BigInt']['output']>;
  euCustoms?: Maybe<Scalars['Boolean']['output']>;
  frameworkAgreement: Scalars['Boolean']['output'];
  frameworkBatchClientSchedule: Scalars['Boolean']['output'];
  frameworkBatchDate?: Maybe<Scalars['ISO8601Date']['output']>;
  frameworkBatchDaysLeft?: Maybe<Scalars['Int']['output']>;
  frameworkBatchFulfilledQuantity: Scalars['Int']['output'];
  frameworkBatchFulfilledValueCents?: Maybe<Scalars['BigInt']['output']>;
  frameworkBatchFulfilledValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  frameworkBatchFulfilledValueMoney: Money;
  frameworkBatchQuantity: Scalars['Int']['output'];
  frameworkBatchRemainingQuantity: Scalars['Int']['output'];
  frameworkBatchType?: Maybe<Scalars['String']['output']>;
  frameworkBatchValueCents?: Maybe<Scalars['BigInt']['output']>;
  frameworkBatchValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  frameworkBatchValueMoney: Money;
  grade?: Maybe<Scalars['String']['output']>;
  /** custom */
  hasCustomerCreditNote?: Maybe<Scalars['Boolean']['output']>;
  hasCustomerInvoice: Scalars['Boolean']['output'];
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting: Scalars['Boolean']['output'];
  isFullyInvoiced: Scalars['Boolean']['output'];
  isFullyPaid: Scalars['Boolean']['output'];
  isFullyShipped: Scalars['Boolean']['output'];
  isImportant?: Maybe<Scalars['Boolean']['output']>;
  isNpg: Scalars['Boolean']['output'];
  isOnHold?: Maybe<Scalars['Boolean']['output']>;
  kamId?: Maybe<Scalars['Int']['output']>;
  kamName?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  /** custom */
  number?: Maybe<Scalars['String']['output']>;
  ocPublishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationIndustry?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationWebsite?: Maybe<Scalars['String']['output']>;
  partsPlaced?: Maybe<Scalars['String']['output']>;
  personContactId?: Maybe<Scalars['Int']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personLanguage?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveOrderValueCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  pipedriveOrderValueMoney: Money;
  pipedrivePipelineName?: Maybe<Scalars['String']['output']>;
  pipedriveStageId?: Maybe<Scalars['Int']['output']>;
  pipedriveStageName?: Maybe<Scalars['String']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  pricingMethod?: Maybe<Array<Scalars['String']['output']>>;
  productionCompleted?: Maybe<Scalars['Boolean']['output']>;
  purchaseOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  readyForPlacing?: Maybe<Scalars['Boolean']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  sendOc?: Maybe<Scalars['Boolean']['output']>;
  shippingAddressValue?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  techAuditStatuses?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  techTags?: Maybe<Array<Scalars['String']['output']>>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalAmountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  totalAmountBrutCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalAmountBrutMoney: Money;
  totalAmountNetCents?: Maybe<Scalars['BigInt']['output']>;
  totalAmountNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalAmountNetMoney: Money;
  totalPaidNettCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  totalPaidNettMoney: Money;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type WorkspacesFrameworkBatchesCollection = {
  __typename?: 'WorkspacesFrameworkBatchesCollection';
  objects: Array<WorkspacesFrameworkBatch>;
  total: Scalars['Int']['output'];
};

export type WorkspacesIncomingInvoice = {
  __typename?: 'WorkspacesIncomingInvoice';
  amountLeftCents?: Maybe<Scalars['BigInt']['output']>;
  amountLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountLeftMoney: Money;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveState?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealProviderOrderId: Scalars['BigInt']['output'];
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  intacctComment?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  invoiceChecked?: Maybe<Scalars['JSON']['output']>;
  invoicePartnerCountry?: Maybe<Scalars['String']['output']>;
  invoicePartnerLegalName?: Maybe<Scalars['String']['output']>;
  invoicePartnerLegalNameLower?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  invoicePrepared?: Maybe<Scalars['JSON']['output']>;
  /** custom workspace_object */
  invoiceReady?: Maybe<Scalars['JSON']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isPartial: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  organizationCountry?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  partnerBankName?: Maybe<Scalars['String']['output']>;
  partnerBic?: Maybe<Scalars['String']['output']>;
  partnerCountry?: Maybe<Scalars['String']['output']>;
  partnerIban?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  partnerLegalName?: Maybe<Scalars['String']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  partnerPayoutTransferType?: Maybe<Scalars['String']['output']>;
  partnerRank?: Maybe<Scalars['Float']['output']>;
  /** _allow_array */
  partnerSegments?: Maybe<Array<Scalars['Int']['output']>>;
  partnerState?: Maybe<Scalars['String']['output']>;
  paymentDueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentDueDays?: Maybe<Scalars['Int']['output']>;
  /** custom workspace_object */
  payouts?: Maybe<Array<WorkspacesIncomingInvoicePayout>>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  poComment?: Maybe<Scalars['String']['output']>;
  poConformityCheck?: Maybe<Scalars['String']['output']>;
  poFullyPaidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  poLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  poPayouts?: Maybe<Array<WorkspacesIncomingInvoicePayout>>;
  poState?: Maybe<Scalars['String']['output']>;
  poValueCents?: Maybe<Scalars['BigInt']['output']>;
  poValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  poValueMoney: Money;
  podId?: Maybe<Scalars['Int']['output']>;
  providerOrderId: Scalars['BigInt']['output'];
  /** workspace_object */
  refInvoice?: Maybe<RefInvoice>;
  /** custom workspace_object */
  refunds?: Maybe<Array<WorkspacesIncomingInvoicePayout>>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  selfBilling?: Maybe<Scalars['Boolean']['output']>;
  shippingState?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalAmountBruttoCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  totalAmountBruttoMoney: Money;
  totalAmountNettoCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  totalAmountNettoMoney: Money;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  validity?: Maybe<Scalars['String']['output']>;
  vatAmountCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  vatAmountMoney: Money;
  vatAvg?: Maybe<Scalars['Float']['output']>;
  vatPdfCorrectionRequired: Scalars['Boolean']['output'];
};

export type WorkspacesIncomingInvoicePayout = {
  __typename?: 'WorkspacesIncomingInvoicePayout';
  amountMoney: Money;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isRefund: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesIncomingInvoiceTotalAmounts = {
  __typename?: 'WorkspacesIncomingInvoiceTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalAmountBrutto: Money;
  totalAmountNetto: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesIncomingInvoicesCollection = {
  __typename?: 'WorkspacesIncomingInvoicesCollection';
  objects: Array<WorkspacesIncomingInvoice>;
  total: Scalars['Int']['output'];
};

/** Export column settings */
export type WorkspacesInputsExportColumnType = {
  key: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Attributes for paginating workspaces */
export type WorkspacesInputsGenericPaginationType = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

/** Attributes for sorting workspaces records */
export type WorkspacesInputsGenericSortingType = {
  direction?: InputMaybe<SortDirectionEnum>;
  sortBy: Scalars['String']['input'];
};

export type WorkspacesJob = {
  __typename?: 'WorkspacesJob';
  /** custom workspace_object */
  acceptedOffer?: Maybe<WorkspacesAcceptedJobOffer>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  customerLotsValueCents: Scalars['BigInt']['output'];
  customerLotsValueCurrency: Scalars['String']['output'];
  /** custom workspace_object */
  customerLotsValueMoney: Money;
  /** workspace_object */
  deal?: Maybe<WorkspacesJobDeal>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  /** custom */
  offersCountActionable?: Maybe<Scalars['Int']['output']>;
  /** custom */
  offersCountTotal?: Maybe<Scalars['Int']['output']>;
  /** custom */
  offersCountViewed?: Maybe<Scalars['Int']['output']>;
  /** workspace_object */
  organization?: Maybe<WorkspacesJobOrganization>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** workspace_object */
  owner?: Maybe<WorkspacesJobUser>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** custom workspace_object */
  providerOrder?: Maybe<WorkspacesJobProviderOrder>;
  publicationEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publicationStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** custom */
  responsesApplication?: Maybe<Scalars['Int']['output']>;
  /** custom */
  responsesCounteroffer?: Maybe<Scalars['Int']['output']>;
  /** custom */
  responsesIrrelevant?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  /** _allow_array */
  tags?: Maybe<Array<Scalars['Int']['output']>>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  valueCents: Scalars['BigInt']['output'];
  valueCurrency: Scalars['String']['output'];
  /** custom workspace_object */
  valueMoney: Money;
};

export type WorkspacesJobDeal = {
  __typename?: 'WorkspacesJobDeal';
  createdAt: Scalars['ISO8601Date']['output'];
  deliveryDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pipedriveOrderValueCents?: Maybe<Scalars['BigInt']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  pmId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesJobOffer = {
  __typename?: 'WorkspacesJobOffer';
  allowAutoConfirmation?: Maybe<Scalars['Boolean']['output']>;
  allowCounterofferFrom?: Maybe<Scalars['ISO8601DateTime']['output']>;
  allowDirectShipping?: Maybe<Scalars['Boolean']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  bonusAvailableAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  bonusCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  bonusMoney: Money;
  claimsState?: Maybe<Scalars['String']['output']>;
  counterofferLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  counterofferValueCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  counterofferValueMoney: Money;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  dapCostsApproxCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  dapCostsApproxMoney: Money;
  dapDeliveryDaysApprox: Scalars['Int']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveState?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  decidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decidedBy?: Maybe<Scalars['Int']['output']>;
  decisionComment?: Maybe<Scalars['String']['output']>;
  decisionRefusalReason?: Maybe<Scalars['String']['output']>;
  decisionState?: Maybe<Scalars['String']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isUrgent: Scalars['Boolean']['output'];
  jobCustomerLotsValueCents?: Maybe<Scalars['BigInt']['output']>;
  jobCustomerLotsValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  jobCustomerLotsValueMoney: Money;
  jobId: Scalars['BigInt']['output'];
  jobOwnerId?: Maybe<Scalars['BigInt']['output']>;
  jobState?: Maybe<Scalars['String']['output']>;
  leadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  marginForecast?: Maybe<Scalars['Float']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  providerAne?: Maybe<Scalars['Float']['output']>;
  providerCancellationRatio?: Maybe<Scalars['Float']['output']>;
  providerClaimRatio?: Maybe<Scalars['Float']['output']>;
  providerCountry?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['BigInt']['output'];
  providerName?: Maybe<Scalars['String']['output']>;
  providerOrderId?: Maybe<Scalars['Int']['output']>;
  providerPartnerNetworkManagerId?: Maybe<Scalars['BigInt']['output']>;
  providerPartnerNetworkManagerName?: Maybe<Scalars['String']['output']>;
  providerQuality?: Maybe<Scalars['Int']['output']>;
  providerRank?: Maybe<Scalars['Float']['output']>;
  providerRegion?: Maybe<Scalars['String']['output']>;
  providerState?: Maybe<Scalars['String']['output']>;
  providerTiming?: Maybe<Scalars['Int']['output']>;
  providerTimingRatio?: Maybe<Scalars['Float']['output']>;
  publicComment?: Maybe<Scalars['String']['output']>;
  publicationEnd: Scalars['ISO8601DateTime']['output'];
  publicationStart: Scalars['ISO8601DateTime']['output'];
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  respondedBy?: Maybe<Scalars['Int']['output']>;
  responseComment?: Maybe<Scalars['String']['output']>;
  responseRefusalReason?: Maybe<Scalars['String']['output']>;
  responseState?: Maybe<Scalars['String']['output']>;
  showPositionPrices?: Maybe<Scalars['Boolean']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalPoInProgress?: Maybe<Scalars['Int']['output']>;
  totalValueForAllPoInProgress?: Maybe<Scalars['Float']['output']>;
  /** custom workspace_object */
  totalValueMoney: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  valueCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  valueMoney: Money;
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zendeskTicketId?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesJobOffersCollection = {
  __typename?: 'WorkspacesJobOffersCollection';
  objects: Array<WorkspacesJobOffer>;
  total: Scalars['Int']['output'];
};

export type WorkspacesJobOrganization = {
  __typename?: 'WorkspacesJobOrganization';
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerOrderGrade?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesJobProviderOrder = {
  __typename?: 'WorkspacesJobProviderOrder';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  partsValueNetto?: Maybe<Money>;
  servicesValueNetto?: Maybe<Money>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesJobUser = {
  __typename?: 'WorkspacesJobUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type WorkspacesJobsCollection = {
  __typename?: 'WorkspacesJobsCollection';
  objects: Array<WorkspacesJob>;
  total: Scalars['Int']['output'];
};

export type WorkspacesMessage = {
  __typename?: 'WorkspacesMessage';
  /** _allow_array */
  assigneeNames?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  assigneePods?: Maybe<Array<Scalars['BigInt']['output']>>;
  /** _allow_array */
  assignees?: Maybe<Array<Scalars['BigInt']['output']>>;
  authorId?: Maybe<Scalars['BigInt']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  authorProviderId?: Maybe<Scalars['BigInt']['output']>;
  authorType?: Maybe<Scalars['String']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601Date']['output']>;
  dealCustomerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  process?: Maybe<Array<Scalars['String']['output']>>;
  sourceId?: Maybe<Scalars['BigInt']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  subscriberNames?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  subscribers?: Maybe<Array<Scalars['BigInt']['output']>>;
  taskCompletedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  taskCompletedBy?: Maybe<Scalars['BigInt']['output']>;
  taskDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  taskPriority?: Maybe<Scalars['String']['output']>;
  taskSetAt?: Maybe<Scalars['ISO8601Date']['output']>;
  taskType?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspacesMessagesCollection = {
  __typename?: 'WorkspacesMessagesCollection';
  objects: Array<WorkspacesMessage>;
  total: Scalars['Int']['output'];
};

export type WorkspacesOrganization = {
  __typename?: 'WorkspacesOrganization';
  createdAt: Scalars['ISO8601DateTime']['output'];
  customerOrderGrade?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dealsLastCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealsLastId?: Maybe<Scalars['Int']['output']>;
  dealsLastPipedriveId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  dealsStatuses?: Maybe<Array<Scalars['String']['output']>>;
  dealsTotal?: Maybe<Scalars['Int']['output']>;
  id: Scalars['BigInt']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  kamId?: Maybe<Scalars['Int']['output']>;
  kamName?: Maybe<Scalars['String']['output']>;
  lastPersonEmail?: Maybe<Scalars['String']['output']>;
  lastPersonId?: Maybe<Scalars['Int']['output']>;
  lastPersonLanguage?: Maybe<Scalars['String']['output']>;
  lastPersonName?: Maybe<Scalars['String']['output']>;
  lastPersonPhone?: Maybe<Scalars['String']['output']>;
  lastPersonPipedriveId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** custom */
  number: Scalars['String']['output'];
  ordersBookedRevenueCents?: Maybe<Scalars['BigInt']['output']>;
  ordersBookedRevenueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  ordersBookedRevenueMoney: Money;
  ordersLastCreated?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ordersLastId?: Maybe<Scalars['Int']['output']>;
  ordersLastPipedriveId?: Maybe<Scalars['Int']['output']>;
  ordersTotal?: Maybe<Scalars['Int']['output']>;
  personsTotal?: Maybe<Scalars['Int']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesOrganizationBalance = {
  __typename?: 'WorkspacesOrganizationBalance';
  balanceBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  balanceBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  balanceBruttoMoney: Money;
  currency?: Maybe<Scalars['String']['output']>;
  currentDueCents?: Maybe<Scalars['BigInt']['output']>;
  currentDueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  currentDueMoney: Money;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['BigInt']['output'];
  invoicedBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  invoicedBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  invoicedBruttoMoney: Money;
  organizationCountry?: Maybe<Scalars['String']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationPmId?: Maybe<Scalars['Int']['output']>;
  organizationPmName?: Maybe<Scalars['String']['output']>;
  overdueAbove30DaysCents?: Maybe<Scalars['BigInt']['output']>;
  overdueAbove30DaysCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  overdueAbove30DaysMoney: Money;
  overdueBelow30DaysCents?: Maybe<Scalars['BigInt']['output']>;
  overdueBelow30DaysCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  overdueBelow30DaysMoney: Money;
  overdueBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  overdueBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  overdueBruttoMoney: Money;
  paidBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  paidBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  paidBruttoMoney: Money;
  prepaymentsCents?: Maybe<Scalars['BigInt']['output']>;
  prepaymentsCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  prepaymentsMoney: Money;
};

export type WorkspacesOrganizationBalancesCollection = {
  __typename?: 'WorkspacesOrganizationBalancesCollection';
  objects: Array<WorkspacesOrganizationBalance>;
  total: Scalars['Int']['output'];
};

export type WorkspacesOrganizationBalancesTotalAmounts = {
  __typename?: 'WorkspacesOrganizationBalancesTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalBalanceBrutto: Money;
  totalCurrentDue: Money;
  totalInvoicedBrutto: Money;
  totalOverdueBrutto: Money;
  totalPaidBrutto: Money;
  totalPrepayments: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesOrganizationsCollection = {
  __typename?: 'WorkspacesOrganizationsCollection';
  objects: Array<WorkspacesOrganization>;
  total: Scalars['Int']['output'];
};

export type WorkspacesOutcomingInvoice = {
  __typename?: 'WorkspacesOutcomingInvoice';
  baAccountType?: Maybe<Scalars['String']['output']>;
  baCountry?: Maybe<Scalars['String']['output']>;
  baId?: Maybe<Scalars['Int']['output']>;
  baIntacctId?: Maybe<Scalars['String']['output']>;
  baName?: Maybe<Scalars['String']['output']>;
  baNameLower?: Maybe<Scalars['String']['output']>;
  baTaxId?: Maybe<Scalars['String']['output']>;
  baVatId?: Maybe<Scalars['String']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  cachedPersonEmail?: Maybe<Scalars['String']['output']>;
  cachedPersonName?: Maybe<Scalars['String']['output']>;
  cachedPersonPhone?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  creditNote: Scalars['Boolean']['output'];
  creditNoteReason?: Maybe<OutcomingInvoicesCreditNoteReasonEnumType>;
  currency?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  innerComment?: Maybe<Scalars['String']['output']>;
  intacctComment?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  invoiceBalanceCents?: Maybe<Scalars['BigInt']['output']>;
  invoiceBalanceCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  invoiceBalanceMoney: Money;
  invoiceCheckedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoiceCheckedBy?: Maybe<Scalars['String']['output']>;
  invoiceNeedFixAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoiceNeedFixBy?: Maybe<Scalars['String']['output']>;
  invoiceReadyAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoiceReadyBy?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isStorno: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  orderConfirmationTotalAmountBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  orderConfirmationTotalAmountBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  orderConfirmationTotalAmountBruttoMoney: Money;
  orderConfirmationTotalAmountNettoCents?: Maybe<Scalars['BigInt']['output']>;
  orderConfirmationTotalAmountNettoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  orderConfirmationTotalAmountNettoMoney: Money;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentTermsDate?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentTermsType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  positionsDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  purchaseOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  refPositionsSources?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601Date']['output']>;
  sendReminders?: Maybe<Scalars['Boolean']['output']>;
  state: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  toBePaidAmountCents?: Maybe<Scalars['BigInt']['output']>;
  toBePaidAmountCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  toBePaidAmountMoney: Money;
  totalAmountBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  totalAmountBruttoMoney: Money;
  totalAmountNettoCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  totalAmountNettoMoney: Money;
  updatedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  vatAmountCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  vatAmountMoney: Money;
  vatAvg?: Maybe<Scalars['Float']['output']>;
};

export type WorkspacesOutcomingInvoiceTotalAmounts = {
  __typename?: 'WorkspacesOutcomingInvoiceTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalAmountBrutto: Money;
  totalAmountNetto: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesOutcomingInvoicesCollection = {
  __typename?: 'WorkspacesOutcomingInvoicesCollection';
  objects: Array<WorkspacesOutcomingInvoice>;
  total: Scalars['Int']['output'];
};

export type WorkspacesPartnerBalance = {
  __typename?: 'WorkspacesPartnerBalance';
  balanceBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  balanceBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  balanceBruttoMoney: Money;
  currency?: Maybe<Scalars['String']['output']>;
  currentDueCents?: Maybe<Scalars['BigInt']['output']>;
  currentDueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  currentDueMoney: Money;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['BigInt']['output'];
  invoicedBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  invoicedBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  invoicedBruttoMoney: Money;
  paidBruttoCents?: Maybe<Scalars['BigInt']['output']>;
  paidBruttoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  paidBruttoMoney: Money;
  partnerCountry?: Maybe<Scalars['String']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  prepaymentsCents?: Maybe<Scalars['BigInt']['output']>;
  prepaymentsCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  prepaymentsMoney: Money;
};

export type WorkspacesPartnerBalancesCollection = {
  __typename?: 'WorkspacesPartnerBalancesCollection';
  objects: Array<WorkspacesPartnerBalance>;
  total: Scalars['Int']['output'];
};

export type WorkspacesPartnerBalancesTotalAmounts = {
  __typename?: 'WorkspacesPartnerBalancesTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalBalanceBrutto: Money;
  totalCurrentDue: Money;
  totalInvoicedBrutto: Money;
  totalPaidBrutto: Money;
  totalPrepayments: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesPayment = {
  __typename?: 'WorkspacesPayment';
  amountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  amountBrutLeftCents?: Maybe<Scalars['BigInt']['output']>;
  amountBrutLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountBrutLeftMoney: Money;
  /** custom workspace_object */
  amountBrutMoney: Money;
  amountFeeCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  amountFeeMoney: Money;
  amountNetCents?: Maybe<Scalars['BigInt']['output']>;
  amountNetLeftCents?: Maybe<Scalars['BigInt']['output']>;
  amountNetLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  amountNetLeftMoney: Money;
  /** custom workspace_object */
  amountNetMoney: Money;
  amountReceivedCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  amountReceivedMoney: Money;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingAddressCompanyName?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedByUsersName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  customerIntacctId?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealPmEmail?: Maybe<Scalars['String']['output']>;
  dealPmName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  intacctComment?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isRefund: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentMethodName?: Maybe<Scalars['String']['output']>;
  paymentMethodOrderId?: Maybe<Scalars['String']['output']>;
  paymentMethodTransactionId?: Maybe<Scalars['String']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  refAmountBrutLeftCents?: Maybe<Scalars['BigInt']['output']>;
  refAmountBrutLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refAmountBrutLeftMoney: Money;
  refAmountNetLeftCents?: Maybe<Scalars['BigInt']['output']>;
  refAmountNetLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refAmountNetLeftMoney: Money;
  refNumber?: Maybe<Scalars['String']['output']>;
  refPaymentMethodName?: Maybe<Scalars['String']['output']>;
  refPaymentMethodOrderId?: Maybe<Scalars['String']['output']>;
  refPaymentMethodTransactionId?: Maybe<Scalars['String']['output']>;
  refRefundedBrutCents?: Maybe<Scalars['BigInt']['output']>;
  refRefundedBrutCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refRefundedBrutMoney: Money;
  refRefundedNetCents?: Maybe<Scalars['BigInt']['output']>;
  refRefundedNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refRefundedNetMoney: Money;
  refundedBrutCents?: Maybe<Scalars['BigInt']['output']>;
  refundedBrutCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refundedBrutMoney: Money;
  refundedNetCents?: Maybe<Scalars['BigInt']['output']>;
  refundedNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refundedNetMoney: Money;
  sourceId?: Maybe<Scalars['BigInt']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601Date']['output'];
  vatCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  vatMoney: Money;
  vatPercent?: Maybe<Scalars['Float']['output']>;
};

export type WorkspacesPaymentsCollection = {
  __typename?: 'WorkspacesPaymentsCollection';
  objects: Array<WorkspacesPayment>;
  total: Scalars['Int']['output'];
};

export type WorkspacesPayout = {
  __typename?: 'WorkspacesPayout';
  amountCents: Scalars['BigInt']['output'];
  /** custom workspace_object */
  amountMoney: Money;
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** workspace_object */
  approvedBy?: Maybe<WorkspacesPayoutUser>;
  approvedById?: Maybe<Scalars['BigInt']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  fastPay: Scalars['Boolean']['output'];
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  instantPay: Scalars['Boolean']['output'];
  intacctComment?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  invoiceDueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoiceHoldUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isRefund: Scalars['Boolean']['output'];
  methodId?: Maybe<Scalars['BigInt']['output']>;
  methodType?: Maybe<Scalars['String']['output']>;
  number: Scalars['String']['output'];
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  partnerLegalNameLower?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  partnerSegments?: Maybe<Array<Scalars['Int']['output']>>;
  paymentDetails?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  prepayment: Scalars['Boolean']['output'];
  /** workspace_object */
  provider?: Maybe<WorkspacesPayoutProvider>;
  providerId: Scalars['BigInt']['output'];
  providerOrderNumber?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  reference?: Maybe<Scalars['JSON']['output']>;
  sourceId: Scalars['BigInt']['output'];
  sourceType: Scalars['String']['output'];
  state: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspacesPayoutProvider = {
  __typename?: 'WorkspacesPayoutProvider';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  payoutTransferType?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesPayoutTotalAmounts = {
  __typename?: 'WorkspacesPayoutTotalAmounts';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  totalAmount: Money;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesPayoutUpdatedUser = {
  __typename?: 'WorkspacesPayoutUpdatedUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type WorkspacesPayoutUser = {
  __typename?: 'WorkspacesPayoutUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type WorkspacesPayoutsCollection = {
  __typename?: 'WorkspacesPayoutsCollection';
  objects: Array<WorkspacesPayout>;
  total: Scalars['Int']['output'];
};

export type WorkspacesPerson = {
  __typename?: 'WorkspacesPerson';
  billingAccountAddress?: Maybe<Scalars['String']['output']>;
  billingAccountCity?: Maybe<Scalars['String']['output']>;
  billingAccountCountry?: Maybe<Scalars['String']['output']>;
  billingAccountJoinedId?: Maybe<Scalars['Int']['output']>;
  billingAccountName?: Maybe<Scalars['String']['output']>;
  billingAccountTaxId?: Maybe<Scalars['String']['output']>;
  billingAccountType?: Maybe<Scalars['String']['output']>;
  billingAccountVatId?: Maybe<Scalars['String']['output']>;
  billingAccountVatValidationStatus?: Maybe<Scalars['String']['output']>;
  billingAccountZip?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  contactCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  contactLastVisitedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  contactVerifiedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealsLastAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealsLastId?: Maybe<Scalars['Int']['output']>;
  dealsLastPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealsTotal: Scalars['Int']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  /** _allow_array */
  invoiceEmails?: Maybe<Array<Scalars['String']['output']>>;
  isRobot: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  mailingSuspendedTill?: Maybe<Scalars['ISO8601Date']['output']>;
  mailingsSubscription?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  nps?: Maybe<Scalars['String']['output']>;
  ordersLastAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  ordersLastId?: Maybe<Scalars['Int']['output']>;
  ordersLastPipedriveId?: Maybe<Scalars['Int']['output']>;
  ordersTotal: Scalars['Int']['output'];
  ordersTotalValueCents?: Maybe<Scalars['BigInt']['output']>;
  ordersTotalValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  ordersTotalValueMoney: Money;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationIndustry?: Maybe<Scalars['String']['output']>;
  organizationJoinedId?: Maybe<Scalars['Int']['output']>;
  organizationKamId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  shippingAddressCity?: Maybe<Scalars['String']['output']>;
  shippingAddressCompanyName?: Maybe<Scalars['String']['output']>;
  shippingAddressContactName?: Maybe<Scalars['String']['output']>;
  shippingAddressContactPhone?: Maybe<Scalars['String']['output']>;
  shippingAddressCountry?: Maybe<Scalars['String']['output']>;
  shippingAddressId?: Maybe<Scalars['Int']['output']>;
  shippingAddressLine?: Maybe<Scalars['String']['output']>;
  shippingAddressZip?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesPersonsCollection = {
  __typename?: 'WorkspacesPersonsCollection';
  objects: Array<WorkspacesPerson>;
  total: Scalars['Int']['output'];
};

export type WorkspacesProvider = {
  __typename?: 'WorkspacesProvider';
  acceptSelfBilling?: Maybe<Scalars['Boolean']['output']>;
  allowAutoacceptOffers?: Maybe<Scalars['Boolean']['output']>;
  allowAutoacceptOffersFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  allowDirectshipOrders?: Maybe<Scalars['Boolean']['output']>;
  allowDirectshipOrdersFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  bankAccount?: Maybe<Scalars['String']['output']>;
  bankCode?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  billingAddressValue?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingZip?: Maybe<Scalars['String']['output']>;
  claimRank?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  communicationRank?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['ISO8601Date']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  daysAwayFromPa?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  freeCapacity: Scalars['Boolean']['output'];
  freeCapacityFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['BigInt']['output'];
  intacctId?: Maybe<Scalars['String']['output']>;
  lastVisitAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  limitsComment?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onImprovement: Scalars['Boolean']['output'];
  onImprovementComment?: Maybe<Scalars['String']['output']>;
  onImprovementFreezeUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  onImprovementStartedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  packingRank?: Maybe<Scalars['Float']['output']>;
  partnerNetworkManagerId?: Maybe<Scalars['BigInt']['output']>;
  partnerNetworkManagerName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  pincode?: Maybe<Scalars['String']['output']>;
  poCountCanceled?: Maybe<Scalars['Int']['output']>;
  poCountConformityIssues?: Maybe<Scalars['Int']['output']>;
  poCountDone?: Maybe<Scalars['Int']['output']>;
  poCountInProgress?: Maybe<Scalars['Int']['output']>;
  poCountLastMonth?: Maybe<Scalars['Int']['output']>;
  poFirstAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  poLastAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  poNumberLimit?: Maybe<Scalars['Int']['output']>;
  poNumberLimitFreezeUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  poValueLimitCents?: Maybe<Scalars['BigInt']['output']>;
  poValueLimitFreezeUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** custom workspace_object */
  poValueLimitMoney: Money;
  /** workspace_object */
  primaryContact?: Maybe<WorkspacesProviderPrimaryContact>;
  primaryContactId?: Maybe<Scalars['Int']['output']>;
  qualityRank?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingAddressValue?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingZip?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  /** _allow_array */
  tagsApprovedMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsCertifications?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsIndustries?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMaterials?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMethodFeatures?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsQualities?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsRestrictedMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsSegments?: Maybe<Array<Scalars['Int']['output']>>;
  timingRank?: Maybe<Scalars['Float']['output']>;
  totalRank?: Maybe<Scalars['Float']['output']>;
  updatedAfterRegistered?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  vatId?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesProviderOrder = {
  __typename?: 'WorkspacesProviderOrder';
  allowDirectShipping?: Maybe<Scalars['Boolean']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  conformityCheck?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601Date']['output']>;
  currency: Scalars['String']['output'];
  customerCountry?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  deadline?: Maybe<Scalars['ISO8601Date']['output']>;
  deadlineExceedsDeal?: Maybe<Scalars['Boolean']['output']>;
  deadlineInitial?: Maybe<Scalars['ISO8601Date']['output']>;
  deadlineUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deadlineUpdatedById?: Maybe<Scalars['Int']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  euCustoms: Scalars['Boolean']['output'];
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  fullyPaidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  hasInvoice?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** custom workspace_object */
  incomingInvoices?: Maybe<Array<WorkspacesProviderOrderIncomingInvoice>>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  lastComment?: Maybe<Scalars['String']['output']>;
  lastPinnedMessageContent?: Maybe<Scalars['String']['output']>;
  listed?: Maybe<Scalars['Boolean']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  organizationKamId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  partsTagsMethodFeatures?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  partsTagsMethods?: Maybe<Array<Scalars['Int']['output']>>;
  partsValueNettoCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  partsValueNettoMoney: Money;
  paymentTerms?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  providerCountry?: Maybe<Scalars['String']['output']>;
  providerEmail?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['BigInt']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerOnImprovement?: Maybe<Scalars['Boolean']['output']>;
  providerPartnerNetworkManagerId?: Maybe<Scalars['BigInt']['output']>;
  providerPartnerNetworkManagerName?: Maybe<Scalars['String']['output']>;
  providerPrimaryEmail?: Maybe<Scalars['String']['output']>;
  providerRegion?: Maybe<Scalars['String']['output']>;
  providerState?: Maybe<Scalars['String']['output']>;
  providerTotalRank?: Maybe<Scalars['Float']['output']>;
  publicComment?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  renegotiatedValueNettoCents?: Maybe<Scalars['BigInt']['output']>;
  samplesConfirmationStatusCombined?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  samplesPoType?: Maybe<Scalars['String']['output']>;
  secondaryProduction?: Maybe<Scalars['Boolean']['output']>;
  servicesValueNettoCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  servicesValueNettoMoney: Money;
  /** custom workspace_object */
  shippings?: Maybe<Array<WorkspacesProviderOrderShipping>>;
  state?: Maybe<Scalars['String']['output']>;
  stateLastUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalValueNettoCents?: Maybe<Scalars['BigInt']['output']>;
  totalValueNettoCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalValueNettoMoney: Money;
  updatedAt?: Maybe<Scalars['ISO8601Date']['output']>;
};

export type WorkspacesProviderOrderIncomingInvoice = {
  __typename?: 'WorkspacesProviderOrderIncomingInvoice';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditNote: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesProviderOrderShipping = {
  __typename?: 'WorkspacesProviderOrderShipping';
  createdAt: Scalars['ISO8601DateTime']['output'];
  direction?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesProviderOrdersCollection = {
  __typename?: 'WorkspacesProviderOrdersCollection';
  objects: Array<WorkspacesProviderOrder>;
  total: Scalars['Int']['output'];
};

export type WorkspacesProviderPrimaryContact = {
  __typename?: 'WorkspacesProviderPrimaryContact';
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesProvidersCollection = {
  __typename?: 'WorkspacesProvidersCollection';
  objects: Array<WorkspacesProvider>;
  total: Scalars['Int']['output'];
};

export type WorkspacesQualityControl = {
  __typename?: 'WorkspacesQualityControl';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  businessVerdicts?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  businessVerdictsBy?: Maybe<Array<Scalars['String']['output']>>;
  claimsState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  customerGrade?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  euCustoms: Scalars['Boolean']['output'];
  /** custom _allow_array */
  followingObjects?: Maybe<Array<Scalars['String']['output']>>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** custom _allow_array */
  parentObjects?: Maybe<Array<Scalars['String']['output']>>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  positionsCount?: Maybe<Scalars['Int']['output']>;
  providerId?: Maybe<Scalars['BigInt']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  providerVqcTags?: Maybe<Array<Scalars['BigInt']['output']>>;
  /** _allow_array */
  relatedProviderOrders?: Maybe<Array<Scalars['Int']['output']>>;
  reportComment?: Maybe<Scalars['String']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  state: Scalars['String']['output'];
  /** _allow_array */
  techVerdicts?: Maybe<Array<Scalars['String']['output']>>;
  /** _allow_array */
  techVerdictsBy?: Maybe<Array<Scalars['String']['output']>>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalCustomerPriceCents?: Maybe<Scalars['BigInt']['output']>;
  totalCustomerPriceCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalCustomerPriceMoney: Money;
  totalProviderPriceCents?: Maybe<Scalars['BigInt']['output']>;
  totalProviderPriceCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalProviderPriceMoney: Money;
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesQualityControlsCollection = {
  __typename?: 'WorkspacesQualityControlsCollection';
  objects: Array<WorkspacesQualityControl>;
  total: Scalars['Int']['output'];
};

export type WorkspacesRefund = {
  __typename?: 'WorkspacesRefund';
  amountBrutCents?: Maybe<Scalars['BigInt']['output']>;
  /** custom workspace_object */
  amountBrutMoney: Money;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedByUsersName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealPmEmail?: Maybe<Scalars['String']['output']>;
  dealPmName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  intacctComment?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['ISO8601Date']['output']>;
  paymentMethodName?: Maybe<Scalars['String']['output']>;
  paymentMethodOrderId?: Maybe<Scalars['String']['output']>;
  paymentMethodTransactionId?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  refAmountBrutLeftCents?: Maybe<Scalars['BigInt']['output']>;
  refAmountBrutLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refAmountBrutLeftMoney: Money;
  refAmountNetLeftCents?: Maybe<Scalars['BigInt']['output']>;
  refAmountNetLeftCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refAmountNetLeftMoney: Money;
  refPaymentMethodName?: Maybe<Scalars['String']['output']>;
  refPaymentMethodOrderId?: Maybe<Scalars['String']['output']>;
  refPaymentMethodTransactionId?: Maybe<Scalars['String']['output']>;
  refRefundedBrutCents?: Maybe<Scalars['BigInt']['output']>;
  refRefundedBrutCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refRefundedBrutMoney: Money;
  refRefundedNetCents?: Maybe<Scalars['BigInt']['output']>;
  refRefundedNetCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  refRefundedNetMoney: Money;
  /** custom workspace_object */
  reference?: Maybe<Scalars['JSON']['output']>;
  sourceId?: Maybe<Scalars['Int']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesRefundsCollection = {
  __typename?: 'WorkspacesRefundsCollection';
  objects: Array<WorkspacesRefund>;
  total: Scalars['Int']['output'];
};

export type WorkspacesRfq = {
  __typename?: 'WorkspacesRfq';
  allPartsCleaned: Scalars['Boolean']['output'];
  country?: Maybe<Scalars['String']['output']>;
  dealId: Scalars['BigInt']['output'];
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  enquiryId: Scalars['BigInt']['output'];
  grade?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  interestedResponsesCount: Scalars['Int']['output'];
  needInfo: Scalars['Boolean']['output'];
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['BigInt']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  publicationEnd?: Maybe<Scalars['ISO8601DateTime']['output']>;
  publicationStart?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rejectedResponsesCount: Scalars['Int']['output'];
  salesPersonId?: Maybe<Scalars['BigInt']['output']>;
  salesPersonName?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  submittedResponsesCount: Scalars['Int']['output'];
};

export type WorkspacesRfqOffer = {
  __typename?: 'WorkspacesRfqOffer';
  /** custom workspace_object */
  attachedFiles?: Maybe<Array<AttachedFile>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dealId: Scalars['BigInt']['output'];
  decidedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  decisionState: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  interestedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isPreferred: Scalars['Boolean']['output'];
  pipedrivePipelineName?: Maybe<Scalars['String']['output']>;
  pipedriveStageId?: Maybe<Scalars['Int']['output']>;
  pipedriveStatus?: Maybe<Scalars['String']['output']>;
  providerAne?: Maybe<Scalars['Float']['output']>;
  providerCancellationRatio?: Maybe<Scalars['Float']['output']>;
  providerClaimRatio?: Maybe<Scalars['Float']['output']>;
  providerCountry?: Maybe<Scalars['String']['output']>;
  providerId: Scalars['BigInt']['output'];
  providerName?: Maybe<Scalars['String']['output']>;
  providerQuality?: Maybe<Scalars['Int']['output']>;
  providerRank?: Maybe<Scalars['Float']['output']>;
  providerState?: Maybe<Scalars['String']['output']>;
  providerTiming?: Maybe<Scalars['Int']['output']>;
  providerTimingRatio?: Maybe<Scalars['Float']['output']>;
  qualifiedViewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  respondedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  responseRefusalReason?: Maybe<Scalars['String']['output']>;
  responseState: Scalars['String']['output'];
  responseStateSorted?: Maybe<Scalars['Int']['output']>;
  rfqId: Scalars['BigInt']['output'];
  rfqOwnerId?: Maybe<Scalars['BigInt']['output']>;
  rfqOwnerName?: Maybe<Scalars['String']['output']>;
  rfqState?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  viewedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type WorkspacesRfqOffersCollection = {
  __typename?: 'WorkspacesRfqOffersCollection';
  objects: Array<WorkspacesRfqOffer>;
  total: Scalars['Int']['output'];
};

export type WorkspacesRfqPartner = {
  __typename?: 'WorkspacesRfqPartner';
  averageOpenTimeForLast30days?: Maybe<Scalars['Int']['output']>;
  averageResponseTimeForLast30days?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  daysAwayFromPa?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['BigInt']['output'];
  lastVisitAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** workspace_object */
  primaryContact?: Maybe<WorkspacesProviderPrimaryContact>;
  primaryContactId?: Maybe<Scalars['BigInt']['output']>;
  qualityRank?: Maybe<Scalars['Float']['output']>;
  requestsForLast90days?: Maybe<Scalars['Int']['output']>;
  responsesForLast90days?: Maybe<Scalars['Int']['output']>;
  responsesPercentForLast90days?: Maybe<Scalars['Float']['output']>;
  rfqSentLast3days?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  /** _allow_array */
  tagsApprovedMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsCertifications?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsIndustries?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMaterials?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMethodFeatures?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsQualities?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsRestrictedMethods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  tagsSegments?: Maybe<Array<Scalars['Int']['output']>>;
  totalRank?: Maybe<Scalars['Float']['output']>;
};

export type WorkspacesRfqPartnersCollection = {
  __typename?: 'WorkspacesRfqPartnersCollection';
  objects: Array<WorkspacesRfqPartner>;
  total: Scalars['Int']['output'];
};

export type WorkspacesRfqsCollection = {
  __typename?: 'WorkspacesRfqsCollection';
  objects: Array<WorkspacesRfq>;
  total: Scalars['Int']['output'];
};

export type WorkspacesShipping = {
  __typename?: 'WorkspacesShipping';
  actualDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  actualShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  bruttoSum?: Maybe<Scalars['Float']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  customerGrade?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerNotes?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  customsDocumentsStatus: Scalars['String']['output'];
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  deliveryBasis?: Maybe<Scalars['String']['output']>;
  deliveryNoteComment?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  destinationAddress?: Maybe<WorkspacesShippingAddressType>;
  destinationCountry?: Maybe<Scalars['String']['output']>;
  direction: Scalars['String']['output'];
  /** custom */
  dueIn?: Maybe<Scalars['Int']['output']>;
  euCustoms: Scalars['Boolean']['output'];
  expectedDeliveryDate?: Maybe<Scalars['ISO8601Date']['output']>;
  expectedShipmentDate?: Maybe<Scalars['ISO8601Date']['output']>;
  /** custom _allow_array */
  followingObjects?: Maybe<Array<Scalars['String']['output']>>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  hasInvoice?: Maybe<Scalars['Boolean']['output']>;
  hasProofOfDelivery?: Maybe<Scalars['Boolean']['output']>;
  hasPublishedInvoice: Scalars['Boolean']['output'];
  hasShippingLabel?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  height?: Maybe<Array<Scalars['Float']['output']>>;
  holdUntil?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  /** custom */
  invoicesState?: Maybe<Scalars['String']['output']>;
  isExport: Scalars['Boolean']['output'];
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isImport: Scalars['Boolean']['output'];
  isImportAndExport: Scalars['Boolean']['output'];
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isPartial?: Maybe<Scalars['Boolean']['output']>;
  labelsAttached: Scalars['Boolean']['output'];
  /** _allow_array */
  length?: Maybe<Array<Scalars['Float']['output']>>;
  logisticAggregator?: Maybe<Scalars['String']['output']>;
  logisticOperator?: Maybe<Scalars['String']['output']>;
  masterShippingDirection?: Maybe<Scalars['String']['output']>;
  masterShippingId?: Maybe<Scalars['BigInt']['output']>;
  masterShippingNumber?: Maybe<Scalars['String']['output']>;
  masterShippingState?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  noInvoiceRequired: Scalars['Boolean']['output'];
  ocInternalComment?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  ocShippingAddress?: Maybe<WorkspacesShippingOrderConfirmationAddressType>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** workspace_object */
  owner?: Maybe<WorkspacesShippingUser>;
  packagesCount?: Maybe<Scalars['Int']['output']>;
  personDefaultShippingAddress?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personLocale?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  receivedProcessingStatus?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  relatedProviderOrders?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  relatedProviders?: Maybe<Array<Scalars['Int']['output']>>;
  /** custom */
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  shippingAlertAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingAlertMessage?: Maybe<Scalars['String']['output']>;
  shippingCostCents?: Maybe<Scalars['BigInt']['output']>;
  shippingCostCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  shippingCostMoney: Money;
  /** custom workspace_object */
  sourceAddress?: Maybe<WorkspacesShippingAddressType>;
  sourceCountry?: Maybe<Scalars['String']['output']>;
  /** custom _allow_array */
  sourceObjects?: Maybe<Array<Scalars['String']['output']>>;
  state: Scalars['String']['output'];
  stateOrdered: Scalars['String']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  totalCustomerPriceCents?: Maybe<Scalars['BigInt']['output']>;
  totalCustomerPriceCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalCustomerPriceMoney: Money;
  totalProviderPriceCents?: Maybe<Scalars['BigInt']['output']>;
  totalProviderPriceCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  totalProviderPriceMoney: Money;
  /** custom */
  trackingLink?: Maybe<Scalars['String']['output']>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** _allow_array */
  width?: Maybe<Array<Scalars['Float']['output']>>;
};

export type WorkspacesShippingAddress = {
  __typename?: 'WorkspacesShippingAddress';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['BigInt']['output'];
  internalComment?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['Int']['output'];
  sourceType: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesShippingAddressType = {
  __typename?: 'WorkspacesShippingAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesShippingAddressesCollection = {
  __typename?: 'WorkspacesShippingAddressesCollection';
  objects: Array<WorkspacesShippingAddress>;
  total: Scalars['Int']['output'];
};

export type WorkspacesShippingOrderConfirmationAddressType = {
  __typename?: 'WorkspacesShippingOrderConfirmationAddressType';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalComment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesShippingUser = {
  __typename?: 'WorkspacesShippingUser';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesShippingsCollection = {
  __typename?: 'WorkspacesShippingsCollection';
  objects: Array<WorkspacesShipping>;
  total: Scalars['Int']['output'];
};

export type WorkspacesStorage = {
  __typename?: 'WorkspacesStorage';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601Date']['output'];
  customerGrade?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasBigPart?: Maybe<Scalars['Boolean']['output']>;
  dealHasNoquote?: Maybe<Scalars['Boolean']['output']>;
  dealHasPrequote?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  euCustoms: Scalars['Boolean']['output'];
  /** custom _allow_array */
  followingObjects: Array<Scalars['String']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasExpressParts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  internalComment?: Maybe<Scalars['String']['output']>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['ISO8601Date']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  measurementProtocolNeeded?: Maybe<Scalars['Boolean']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  /** custom _allow_array */
  parentObjects: Array<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  processingState?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  relatedProviderOrders: Array<Scalars['Int']['output']>;
  samplesNeeded?: Maybe<Scalars['Boolean']['output']>;
  storageType?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['ISO8601Date']['output'];
};

export type WorkspacesStoragesCollection = {
  __typename?: 'WorkspacesStoragesCollection';
  objects: Array<WorkspacesStorage>;
  total: Scalars['Int']['output'];
};

export type WorkspacesSubscription = {
  __typename?: 'WorkspacesSubscription';
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  channelType?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  /** custom */
  currentUserSubscribed?: Maybe<Scalars['Boolean']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealHasOwner?: Maybe<Scalars['Boolean']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  deputyPmId?: Maybe<Scalars['BigInt']['output']>;
  deputyPmName?: Maybe<Scalars['String']['output']>;
  externalOwnerName?: Maybe<Scalars['String']['output']>;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isFullyInvoiced: Scalars['Boolean']['output'];
  isFullyShipped: Scalars['Boolean']['output'];
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  isPersonDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastMessageAuthorId?: Maybe<Scalars['BigInt']['output']>;
  lastMessageAuthorType?: Maybe<Scalars['String']['output']>;
  lastMessageContent?: Maybe<Scalars['String']['output']>;
  lastPersonMessageAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personGeneral?: Maybe<Scalars['Boolean']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personLanguage?: Maybe<Scalars['String']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  sourceHasOwner?: Maybe<Scalars['Boolean']['output']>;
  sourceId?: Maybe<Scalars['BigInt']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  subscriberId?: Maybe<Scalars['BigInt']['output']>;
  subscriberType?: Maybe<Scalars['String']['output']>;
  supportRepliedLast: Scalars['Boolean']['output'];
  testDeal?: Maybe<Scalars['Boolean']['output']>;
  userRepliedLast: Scalars['Boolean']['output'];
  xometryOwnerId?: Maybe<Scalars['BigInt']['output']>;
  xometryOwnerName?: Maybe<Scalars['String']['output']>;
};

export type WorkspacesSubscriptionsCollection = {
  __typename?: 'WorkspacesSubscriptionsCollection';
  objects: Array<WorkspacesSubscription>;
  total: Scalars['Int']['output'];
};

export type WorkspacesTask = {
  __typename?: 'WorkspacesTask';
  /** _allow_array */
  assigneePods?: Maybe<Array<Scalars['Int']['output']>>;
  /** _allow_array */
  assignees?: Maybe<Array<Scalars['Int']['output']>>;
  authorId?: Maybe<Scalars['BigInt']['output']>;
  authorType?: Maybe<Scalars['String']['output']>;
  billingAccountId?: Maybe<Scalars['Int']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  claimsState?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  customerGrade?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerType?: Maybe<Scalars['String']['output']>;
  dealCustomerOrderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  dealGrade?: Maybe<Scalars['String']['output']>;
  dealId?: Maybe<Scalars['BigInt']['output']>;
  dealLeadtime?: Maybe<Scalars['ISO8601Date']['output']>;
  dealManualProcessingComment?: Maybe<Scalars['String']['output']>;
  dealPipedriveId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStage?: Maybe<Scalars['String']['output']>;
  dealPipedriveStageId?: Maybe<Scalars['Int']['output']>;
  dealPipedriveStatus?: Maybe<Scalars['String']['output']>;
  dealPipedriveStatusUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  dealPipedriveStatusUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  dealPipedriveStatusUpdatedByName?: Maybe<Scalars['String']['output']>;
  dealProductionStage?: Maybe<Scalars['String']['output']>;
  dealReasonOfLose?: Maybe<Scalars['String']['output']>;
  dealValueCents?: Maybe<Scalars['BigInt']['output']>;
  dealValueCurrency?: Maybe<Scalars['String']['output']>;
  /** custom workspace_object */
  dealValueMoney: Money;
  frameworkAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['BigInt']['output'];
  importantDeal?: Maybe<Scalars['Boolean']['output']>;
  /** _allow_array */
  intercompanyRoles?: Maybe<Array<Scalars['String']['output']>>;
  isForSoftwareTesting?: Maybe<Scalars['Boolean']['output']>;
  isNpg?: Maybe<Scalars['Boolean']['output']>;
  isOnHold: Scalars['Boolean']['output'];
  isOnHoldUpdatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  isOnHoldUpdatedById?: Maybe<Scalars['BigInt']['output']>;
  isOnHoldUpdatedByName?: Maybe<Scalars['String']['output']>;
  organizationGrade?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  organizationKamId?: Maybe<Scalars['Int']['output']>;
  organizationKamName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  organizationPipedriveId?: Maybe<Scalars['Int']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  personEmail?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['BigInt']['output']>;
  personName?: Maybe<Scalars['String']['output']>;
  personNotes?: Maybe<Scalars['String']['output']>;
  personPhone?: Maybe<Scalars['String']['output']>;
  personPipedriveId?: Maybe<Scalars['Int']['output']>;
  pmId?: Maybe<Scalars['BigInt']['output']>;
  pmName?: Maybe<Scalars['String']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
  /** _allow_array */
  process?: Maybe<Array<Scalars['String']['output']>>;
  sourceId?: Maybe<Scalars['BigInt']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  /** _allow_array */
  subscribers?: Maybe<Array<Scalars['Int']['output']>>;
  taskCompletedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  taskCompletedBy?: Maybe<Scalars['BigInt']['output']>;
  taskDeadline?: Maybe<Scalars['ISO8601Date']['output']>;
  taskPriority?: Maybe<Scalars['String']['output']>;
  taskSetAt?: Maybe<Scalars['ISO8601Date']['output']>;
  taskType?: Maybe<Scalars['String']['output']>;
  testDeal?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkspacesTasksCollection = {
  __typename?: 'WorkspacesTasksCollection';
  objects: Array<WorkspacesTask>;
  total: Scalars['Int']['output'];
};

export type WorkspacesUpdatedBankPayment = {
  __typename?: 'WorkspacesUpdatedBankPayment';
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** workspace_object */
  approvedBy?: Maybe<WorkspacesPayoutUpdatedUser>;
  counterparty?: Maybe<Scalars['JSON']['output']>;
  counterpartyId?: Maybe<Scalars['ID']['output']>;
  counterpartyType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  issuedAt?: Maybe<Scalars['ISO8601Date']['output']>;
  outlyingTransactionType?: Maybe<Scalars['String']['output']>;
  /** workspace_object custom */
  payments?: Maybe<Array<WorkspacesBankPaymentUpdatedPayment>>;
  /** workspace_object custom */
  payouts?: Maybe<Array<WorkspacesBankPaymentUpdatedPayout>>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type WorkspacesUser = {
  __typename?: 'WorkspacesUser';
  active: Scalars['Boolean']['output'];
  birthday?: Maybe<Scalars['ISO8601Date']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  deputyUserId?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  isRobot: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['BigInt']['output']>;
  podId?: Maybe<Scalars['Int']['output']>;
};

export type WorkspacesUsersCollection = {
  __typename?: 'WorkspacesUsersCollection';
  objects: Array<WorkspacesUser>;
  total: Scalars['Int']['output'];
};

export type XometryBankAccount = {
  __typename?: 'XometryBankAccount';
  balance?: Maybe<Scalars['Float']['output']>;
};

export enum XometryVatCountryEnum {
  /** France */
  France = 'France',
  /** Germany */
  Germany = 'Germany',
  /** Italy */
  Italy = 'Italy',
  /** Poland */
  Poland = 'Poland',
  /** Sweden */
  Sweden = 'Sweden'
}

export type XometryVatNumber = {
  __typename?: 'XometryVatNumber';
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  reverseCharge?: Maybe<Scalars['Boolean']['output']>;
  reverseChargeRemark?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<Scalars['ISO8601Date']['output']>;
};

export type ZipState = {
  __typename?: 'ZipState';
  createdAt: Scalars['ISO8601DateTime']['output'];
  fileId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zipState: ZipStateEnumType;
};

export enum ZipStateEnumType {
  /** completed */
  Completed = 'completed',
  /** failed */
  Failed = 'failed',
  /** in_progress */
  InProgress = 'in_progress',
  /** not_requested */
  NotRequested = 'not_requested'
}

export enum ZipStateIntacctEnumType {
  /** completed */
  Completed = 'completed',
  /** failed */
  Failed = 'failed',
  /** in_progress */
  InProgress = 'in_progress',
  /** not_requested */
  NotRequested = 'not_requested'
}
